import React from "react";
import CardWarranty from "./CardWarranty";

function WarrantyOffer({ implants }) {
  function bothImplantsHaveTheSameProgram() {
    return (
      implants.length === 2 &&
      new Set(implants.map((i) => i.period)).size === 1
    );
  }

  return (
    <>
      {bothImplantsHaveTheSameProgram() ? (
        <CardWarranty {...Object.assign({}, implants[0], { side: "both" })} />
      ) : (
        implants.map((data, idx) => <CardWarranty key={idx} {...data} />)
      )}
    </>
  );
}

export default WarrantyOffer;
