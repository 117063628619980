import React, { useContext } from "react";
import "./Button.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AccountContext } from "../../../contexts/AccountContext";

export default function ButtonLogOut({ light }) {
  const { logOut } = useContext(AccountContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleOnClick() {
    logOut().catch(() => navigate("#/"));
  }

  return (
    <span
      className={`logout-btn-${light ? "light" : "dark"}`}
      onClick={handleOnClick}
    >
      {t("Global.LogOut")}
    </span>
  );
}
