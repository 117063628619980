import React, { useState, useEffect } from "react"
import ImageRadioSelectItem from "./ImageRadioSelectItem"
import "./ImageRadioSelect.scss"

const ImageRadioSelectList = ({
  items,
  name,
  handleSelectedItem = () => {},
  removeUndefined = () => {},
  ...props
}) => {
  // const image = process.env.PUBLIC_URL + "/incision-kind-image.png";
  // const alt = "some alt text";
  const [isSelected, setIsSelected] = useState(null)

  useEffect(() => {
    handleSelectedItem(isSelected)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected])

  return (
    <div>
      {items &&
        items.map((item) => {
          if (removeUndefined && item.title !== "Undefined") {
            return (
              <ImageRadioSelectItem
                key={`image-radio-select-item-${item.id}`}
                name={name}
                title={item.title}
                id={item.id}
                removeUndefined={true}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
              />
            )
          } else {
            return null
          }
        })}
    </div>
  )
}

export default ImageRadioSelectList
