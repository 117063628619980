import React, {useContext} from "react";
import { useTranslation } from "react-i18next";
import ButtonFilled from "../Button/ButtonFilled";
import LinkSimple from "../Link/LinkSimple";
import Modal from "./Modal";
import {UserContext} from "../../../contexts/UserContext";
import SpinnerSmall from "../Spinner/SpinnerSmall";

const PreRegistrationModal = ({ onClose, isMia }) => {
  const {
    loadPreregistrationInformation,
    isLoading
  } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <>
      <Modal title={isLoading ? '' : isMia ? t("PreRegistration.PromptMia.Title"): t("PreRegistration.Prompt.Title")} onClose={onClose}>
        {isLoading ? <SpinnerSmall />
        :
          <>
            <p>{isMia ? t("PreRegistration.PromptMia.Details") : t("PreRegistration.Prompt.Details")}</p>
            <ButtonFilled
            title={isMia ? t("PreRegistration.Prompt.SeeDetail") : t("PreRegistration.Prompt.GoToPreRegistration")}
            nextLink="/registration/full"
            onClick={()=>loadPreregistrationInformation()}
            />
            <LinkSimple
            cancel
            onClick={onClose}
            to="#"
            label={t("PreRegistration.Prompt.Skip")}
            />
          </>
        }
      </Modal>
    </>
  );
};

export default PreRegistrationModal;
