import React from 'react'
import { useTranslation } from "react-i18next";
import {Box, Typography} from "@mui/material";



export default function DateRenderer({date, label}) {
  const { t } = useTranslation();
  let dateObj = null
  if ( date instanceof Date ){
    dateObj = date
  }
  else if(date) {
    try {
      const dateArr = date.split("-")
      dateObj = new Date(`${dateArr[0]}/${dateArr[1]}/${dateArr[2]}`);
    } catch (error) {
      console.error(error);
    }
  }

  const months = [
    { name: t("Global.January"), value: "01" },
    { name: t("Global.February"), value: "02" },
    { name: t("Global.March"), value: "03" },
    { name: t("Global.April"), value: "04" },
    { name: t("Global.May"), value: "05" },
    { name: t("Global.June"), value: "06" },
    { name: t("Global.July"), value: "07" },
    { name: t("Global.August"), value: "08" },
    { name: t("Global.September"), value: "09" },
    { name: t("Global.October"), value: "10" },
    { name: t("Global.November"), value: "11" },
    { name: t("Global.December"), value: "12" },
  ]
  return (
    <span>
      {dateObj && (
        <Box display="flex">
          <span>
            {dateObj.getDate()} {t(months[dateObj.getMonth()].name)} {dateObj.getFullYear()} {label && (<span style={{color: "red", ml:1}}>{label.toUpperCase()}</span>)}
          </span>
        </Box>
      )
      }
    </span>
  )
}
