import React from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LinkSkip({ to }) {
  const { t } = useTranslation();

  return (
    <Link to={to}>
      {t("Global.Skip")} <ChevronRight size="12" />
    </Link>
  );
}
