import { useContext, useEffect } from "react";

import { UserContext } from "../../contexts/UserContext";
import EstaAPIKit from "../../data/EstaAPIKit";

const estaApiKit = new EstaAPIKit();

export default function EnsureToken({ children }) {
  const { logOut } = useContext(UserContext);

  useEffect(() => {
    const intv = setInterval(
      () => estaApiKit.ensureToken().catch(logOut),
      10000
    );

    estaApiKit.ensureToken().catch(logOut);

    return () => clearInterval(intv);
  });

  return children;
}
