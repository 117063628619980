import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { InputComponent } from "../../FormComponents/Input";
import { DatePickerComponent } from "../../FormComponents/DatePicker";
import { TextAreaComponent } from "../../FormComponents/TextArea";
import { SerialNumber } from "../../FormComponents/SerialComponent";
import { ToggleSwitch } from "../../FormComponents/ToogleSwitch";
import { RadioButton } from "../../FormComponents/RadioButton";
import { SelectComponent } from "../../FormComponents/Select";
import { capitalLetterInitial, getMask } from "../../../../utils";
import MaskedInput from "react-text-mask";

const serialFormComponent = (formik, t, side) => {
  let SNSide = `serial.SN${side}`;
  let VCSide = `serial.VC${side}`;

  const errors =
    (typeof formik !== "undefined" && formik.errors[SNSide]) ||
    (formik.errors[SNSide.split(".")[0]] &&
      formik.errors[SNSide.split(".")[0]][SNSide.split(".")[1]]);
  const touched =
    (typeof formik !== "undefined" && formik.touched[SNSide]) ||
    (formik.touched[SNSide.split(".")[0]] &&
      formik.touched[SNSide.split(".")[0]][SNSide.split(".")[1]]);

  return (
    <Grid container>
      <Grid item xs={12} md={5} display="flex" alignItems="center">
        <Typography variant="body1" fontSize={16} fontWeight={300}>
          {formik.values.typeSerialNumber === "SN/ESN"
            ? t("Implant-Registration.SerialNumber", { lng: "en" })
            : t("Implant-Registration.lot", { lng: "en" })}
          *
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        {formik.values.typeSerialNumber === "SN/ESN" ? (
          <SerialNumber
            autoMask
            serial={SNSide}
            vc={VCSide}
            side={side}
            formik={formik}
            isExplantedFlow={true}
          />
        ) : (
          <MaskedInput
            name="serial.lot"
            className="form-control serial-number-input"
            type="text"
            mask={getMask("lot")}
            placeholder="00000000"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            guide={false}
          />
        )}

        <Box mt={1}>
          {formik && errors && touched ? (
            <Typography variant="subtitle2" color="red">
              {formik.errors[SNSide] ||
                formik.errors[SNSide.split(".")[0]][SNSide.split(".")[1]]}
            </Typography>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};

export const ExplantedImplantInformation = ({ formik }) => {
  const { t } = useTranslation();

  const handleExplantDateSelected = (selectedDate) => {
    formik.setFieldValue("explantDate", selectedDate);
  };
  const handleOriginalImplantingDateSelected = (selectedDate) => {
    formik.setFieldValue("originalImplantingDate", selectedDate);
  };

  const optionsSwitch = [t("No").toUpperCase(), t("Yes").toUpperCase()];
  const optionsSerialType = [{ label: "SN/ESN" }, { label: "LOT" }];

  const sides = [
    { id: 1, name: "Left" },
    { id: 2, name: "Right" },
  ];

  useEffect(() => {
    if (formik.values.typeSerialNumber !== "LOT") {
      formik.setFieldValue("lot", "");
    }
  }, [formik.values.typeSerialNumber]);

  const isOriginalRegisterinfo =
    formik.values?.implantInfo?.originalRegisterInfo?.hasOwnProperty(
      "originalImplantDate",
    );

  return (
    <>
      <Box mb={6}>
        <h3>{t("PreRegistration.ImplantInformation", { lng: "en" })}</h3>
      </Box>
      <Box my={2}>
        <InputComponent
          formik={formik}
          title={t("Device.Tracking.ImplantTypeLabel")}
          name="typeOfImplant"
          isDisabled={true}
        />
      </Box>
      <Box my={2}>
        <SelectComponent
          formik={formik}
          name="side"
          title={capitalLetterInitial(t("Global.Side", { lng: "en" }))}
          options={sides}
          handleChange={(e) => {
            formik.setFieldValue("side", e.target.value);
            formik.setFieldValue("serial.SNLeft", "");
            formik.setFieldValue("serial.SNRight", "");
            formik.setFieldValue("serial.isValidLeft", false);
            formik.setFieldValue("serial.isValidRight", false);
            formik.setFieldValue("lot", "");
          }}
          required
        />
      </Box>
      <Box my={2}>
        <RadioButton
          formik={formik}
          title={t("Device.Tracking.RegisterWith")}
          name="typeSerialNumber"
          options={optionsSerialType}
        />
      </Box>
      {formik && formik.values.side === 1 ? (
        <Box my={2}>{serialFormComponent(formik, t, "Left")}</Box>
      ) : (
        formik.values.side === 2 && (
          <Box my={2}>{serialFormComponent(formik, t, "Right")}</Box>
        )
      )}
      <Box my={2}>
        <DatePickerComponent
          formik={formik}
          title={t("Device.Tracking.ExplantDate")}
          name="explantDate"
          onChange={handleExplantDateSelected}
          reset={false}
          required
        />
      </Box>
      {isOriginalRegisterinfo ? null : (
        <Box my={2}>
          <InputComponent
            formik={formik}
            title={t("Device.Tracking.OriginalImplantingPhysician")}
            name="originalImplantingPhysician"
            required
          />
        </Box>
      )}
      {isOriginalRegisterinfo ? null : (
        <Box my={2}>
          <DatePickerComponent
            formik={formik}
            title={t("Device.Tracking.OriginalImplantingDate")}
            name="originalImplantingDate"
            onChange={handleOriginalImplantingDateSelected}
            reset={false}
            required
          />
        </Box>
      )}
      <Box my={3}>
        <ToggleSwitch
          formik={formik}
          title={t("Device.Tracking.DeviceReturned")}
          name="deviceReturned"
          options={optionsSwitch}
        />
      </Box>
      <Box my={3}>
        <ToggleSwitch
          formik={formik}
          title={t("Device.Tracking.ReasonForRemovalOption")}
          name="reasonForRemovalOption"
          options={optionsSwitch}
        />
      </Box>
      <Box>
        <TextAreaComponent
          formik={formik}
          title={t("Device.Tracking.ReasonForRemoval")}
          name="reasonForRemoval"
          rows={6}
          required
        />
      </Box>
    </>
  );
};
