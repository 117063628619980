import APITester from "../pages/APITester";

import CardSelectionPage from "../pages/CardSelectionPage/CardSelectionPage";

import DoctorSearchImplantPage from "../pages/DoctorSearchImplantPage/DoctorSearchImplantPage";
import DoctorFirstPage from "../pages/DoctorFirstPage/DoctorFirstPage";

import FullRegistration from "../pages/FullRegistration/FullRegistration";

import ImplantPlacementPage from "../pages/ImplantPlacementPage/ImplantPlacementPage";
import ImplantTypePage from "../pages/ImplantTypePage/ImplantTypePage";
import ImplantSideSelectionPage from "../pages/ImplantSideSelectionPage/ImplantSideSelectionPage";
import ImplantQidTechnologyPage from "../pages/ImplantQidTechnologyPage/ImplantQidTechnologyPage";
import ImplantRegisterMethodPage from "../pages/ImplantRegisterMethodPage/ImplantRegisterMethodPage";
import ImplantRegistrationPageESN from "../pages/ImplantRegistrationPageESN/ImplantRegistrationPageESN";
import ImplantRegistrationPage from "../pages/ImplantRegistrationPage/ImplantRegistrationPage";
import ImplantRegistrationSuccess from "../pages/ImplantRegistrationSuccess/ImplantRegistrationSuccess";
import ImplantRegistrationWarrantyExtended from "../pages/ImplantRegistrationSuccess/ImplantRegistrationWarrantyExtended";
import IncisionTypeSelectionPage from "../pages/IncisionTypeSelectionPage/IncisionTypeSelectionPage";

import LoginPatient from "../pages/LoginPatient";
import LoginDoctor from "../pages/LoginDoctor";
import LoginLoading from "../pages/LoginLoading";
// const LoginPage = React.lazy(() => import ("../pages/LoginPage/LoginPage"));
import LogoutPage from "../pages/LogoutPage";

import RouteTest from "../pages/routetest";

import MyImplantsInformationThanksYouForVisitingPage from "../pages/MyImplantsInformationThanksYouForVisitingPage/MyImplantsInformationThanksYouForVisitingPage";
import MyImplantsInformation from "../pages/MyImplantsInformation/MyImplantsInformation";
import MyImplantsView from "../pages/MyImplantsView/MyImplantsView";
import MaintenancePage from "../pages/MaintenancePage";

import PreImplantRegistrationPage from "../pages/PreImplantRegistrationPage/PreImplantRegistrationPageOne";
import PreImplantRegistrationPageTwo from "../pages/PreImplantRegistrationPage/PreImplantRegistrationPageTwo";
import PreImplantRegistrationPageThree from "../pages/PreImplantRegistrationPage/PreImplantRegistrationPageThree";
import PreImplantRegistrationPageYesNo from "../pages/PreImplantRegistrationPage/PreImplantRegistrationPageYesNo";
import PatientListClientPage from "../pages/PatientListClientPage/PatientListClientPage";
import PatientListViewPage from "../pages/PatientListViewPage/PatientListViewPage";

import SurgeryIndicationPage from "../pages/SurgeryIndicationPage/SurgeryIndicationPage";
import SurgeryInformationPage from "../pages/SurgeryInformation/SurgeryInformationPage";

import UIComponents from "../pages/UIComponents";
import UnAuthorized from "../pages/UnAuthorized";

import WarrantyConfirmationPage from "../pages/Warranty/WarrantyConfirmationPage";
import WarrantyPaymentResponsePage from "../pages/Warranty/WarrantyPaymentResponsePage";
import WarrantyPaymentPage from "../pages/Warranty/WarrantyPaymentPage";
import WarrantyOptionPage from "../pages/Warranty/WarrantyOptionPage";

import NotFound from "../pages/NotFound";
import ServerError from "../pages/ServerError";
import { DisposalInformation } from "../pages/DisposalInformation";
import { ExplantPage } from "../pages/ExplantInformationPage";
import UnderReview from "../pages/UnderReview";

export default {
  APITester,

  CardSelectionPage,

  DoctorSearchImplantPage,
  DoctorFirstPage,

  FullRegistration,

  ImplantTypePage,
  ImplantPlacementPage,
  ImplantSideSelectionPage,
  ImplantQidTechnologyPage,
  ImplantRegisterMethodPage,
  ImplantRegistrationPageESN,
  ImplantRegistrationPage,
  ImplantRegistrationSuccess,
  ImplantRegistrationWarrantyExtended,
  IncisionTypeSelectionPage,

  LoginPatient,
  LoginDoctor,
  LoginLoading,
  RouteTest,
  LogoutPage,

  MyImplantsInformationThanksYouForVisitingPage,
  MyImplantsInformation,
  MyImplantsView,
  MaintenancePage,

  PreImplantRegistrationPage,
  PreImplantRegistrationPageTwo,
  PreImplantRegistrationPageThree,
  PreImplantRegistrationPageYesNo,
  PatientListClientPage,
  PatientListViewPage,

  SurgeryIndicationPage,
  SurgeryInformationPage,

  UIComponents,
  UnAuthorized,

  WarrantyConfirmationPage,
  WarrantyPaymentResponsePage,
  WarrantyPaymentPage,
  WarrantyOptionPage,

  NotFound,
  ServerError,

  DisposalInformation,
  ExplantPage,

  UnderReview,
};
