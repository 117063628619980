import i18n from "i18next";
import { differenceInYears } from "date-fns";

export function getLanguage() {
  if (
    i18n.language === "en" ||
    i18n.language === "es" ||
    i18n.language === "pt" ||
    i18n.language === "it" ||
    i18n.language === "fr" ||
    i18n.language === "de" ||
    i18n.language === "da" ||
    i18n.language === "nn-NO" ||
    i18n.language === "sv"
  ) {
    if (i18n.language === "nn-NO") return "no";
    return (
      i18n.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "en"
    );
  } else {
    return "en";
  }
}

export function getMask(typeDevice) {
  switch (typeDevice) {
    case "lot":
      return Array(8).fill(/[0-9]/);
    case "injector":
      return Array(5)
        .fill(/[0-9]/)
        .concat([/[a-zA-Z]/])
        .concat(["-"])
        .concat(Array(3).fill(/[0-9]/)); //20709B-534
    case "ESN":
      return Array(15).fill(/[0-9]/); // 986100000664250
    case "SN":
      return Array(8)
        .fill(/[0-9]/)
        .concat(["-"])
        .concat(Array(2).fill(/[0-9]/)); // 10090025-03
    default:
      console.log(`Not support ${typeDevice} Device`);
  }
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getMetaContent = (name) => {
  return document.getElementsByTagName("meta")[name].getAttribute("content");
};

export const capitalLetterInitial = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const validEmail = (email) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export const differenceYears = (endDate, starDate) => {
  return differenceInYears(endDate, starDate);
};

export function formatFullAddress(
  { addressLine1, addressLine2, city, state, postalCode, country },
  countryList,
) {
  const addressComponents = [
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    countryList.find((_country) => _country.id === country)?.label,
  ];

  return addressComponents.filter((component) => component).join(", ");
}
