import React, { useContext } from "react";
import "./PatientCard.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PatientListContext } from "../../../contexts/PatientListContext";
import DateRenderer from "../DateRenderer";

const PatientCard = ({ patientData, patientIndex }) => {
  const { setSpecificPatientContext } = useContext(PatientListContext);

  const { t } = useTranslation();

  return (
    <Link
      style={{ textDecoration: "none", color: "#58595c" }}
      to={`/doctor/patient-list/${patientIndex}`}
      onClick={() => setSpecificPatientContext(patientData)}
    >
      <div className="patient-card">
        <div>
          <h2 className="patient-card__name color-new-purple">{patientData.patient_full_name}</h2>
          <div className="patient-card__surgery-date">
            <h2>{t("Patient-List-Card.SurgeryDate")}:</h2>
            <p className="date-renderer">
              {patientData.date ? (
                <DateRenderer date={patientData.date} />
              ) : (
                "No surgery date"
              )}
            </p>
          </div>
        </div>
        <svg className="patient-card__arrow-right" viewBox="0 0 21.166 38.554">
          <g id="arrow-right" transform="translate(75.475) rotate(90)">
            <path
              id="Expand_Less"
              d="M20.652,54.871a1.966,1.966,0,0,0-2.752,0L.569,72.158A1.943,1.943,0,0,0,3.319,74.9L19.277,58.99,35.233,74.906a1.944,1.944,0,1,0,2.752-2.746Z"
              fill="#6e0072"
            />
          </g>
        </svg>
      </div>
    </Link>
  );
};

export default PatientCard;
