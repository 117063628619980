import React from "react";
import { useTranslation } from "react-i18next";
import ButtonFilled from "../Button/ButtonFilled";
import LinkSimple from "../Link/LinkSimple";
import Modal from "./Modal";

const RejectConfirmationModal = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal title={t("PreRegistration.RejectionPrompt.Title")} onClose={onClose}>
      <p>{t("PreRegistration.RejectionPrompt.Details")}</p>
      <ButtonFilled
        title={t("PreRegistration.RejectionPrompt.Cancel")}
        onClick={onClose}
      />
      <LinkSimple
        cancel
        onClick={onConfirm}
        label={t("PreRegistration.RejectionPrompt.Reject")}
      />
    </Modal>
  );
};

export default RejectConfirmationModal;
