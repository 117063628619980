import React, {useContext} from "react";
import "./Button.scss";
import CreateTicketModule from "../../../components/library/PopUpModules/CreateTicketModule";
import {ReactComponent as HELP} from "../../../assets/Icon/question-circle.svg";
import {useDispatch, useSelector} from "react-redux";
import {setSupportModalOn} from "../../../store/slices/supportSlice";
import {useTagManager} from "../../../hooks/useTagManager";
import {isUndefined} from "lodash";
import {AccountContext} from "../../../contexts/AccountContext";

export default function ButtonOpenTicket() {
  const {modal, modal_type} = useSelector((state) => state.supportSlice);

  const {accountInfo} = useContext(AccountContext);
  const gtm = useTagManager();
  const dispatch = useDispatch();

  function createTicket() {
    dispatch(setSupportModalOn('general'));
    window.scrollTo(0, 0);

    gtm({
      category: 'Asking_for_help',
      action: 'Click_for_help',
      label: 'Page_support',
      event: 'interaction',
      segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
    });
  }

  return (
    <>
      {(modal && modal_type === 'general') && (
        <CreateTicketModule general/>
      )}
      <button className={`open-ticket-btn`} onClick={createTicket}>
        <HELP className={`iconSize`}/>
      </button>
    </>
  );
}
