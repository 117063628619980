import { Grid, Typography } from "@mui/material";
import React from "react";
import DatePicker from "../../DatePicker/Datepicker";

export const DatePickerComponent = (props) => {
  const { formik, title, name, onChange, reset, value, id, required } = props;

  const meta = formik?.getFieldMeta(name);

  return (
    <Grid container>
      <Grid item xs={12} md={5} display="flex" alignItems="center">
        <Typography variant="body1" fontSize={16} fontWeight={300}>
          {title}
          {required && "*"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <DatePicker
          id={id}
          removeTitle
          handleOnChange={onChange}
          value={typeof name === "undefined" ? value : meta?.value}
          resetAutomate={reset}
        />
      </Grid>
    </Grid>
  );
};
