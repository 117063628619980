import React, { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";

import "./LayoutPreImplant.scss";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

export default function LayoutPreImplant({ children, pageTitle, subtitle }) {
  const { userError } = useContext(UserContext);

  return (
    <>
      <h3 className="font-size-32 font-black">{pageTitle}</h3>

      <h6 className="font-book font-size-21">{subtitle}</h6>

      {children}

      {userError && <ErrorComponent userError={userError} />}
    </>
  );
}
