import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserContext";
import { StaticFormContext } from "../../contexts/StaticFormContext";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import "./IncisionTypeSelectionPage.scss";
import LinkBack from "../../components/library/Link/LinkBack";
import { Navigate, useNavigate } from "react-router-dom";
import Registration from "../../components/library/Registration.jsx";
import {Helmet} from 'react-helmet-async';
import {isUndefined} from "lodash";
import {AccountContext} from "../../contexts/AccountContext";
import {useTagManager} from "../../hooks/useTagManager";

export default function IncisionTypeSelectionPage() {
  const { me, setMe } = useContext(UserContext);
  const navigate = useNavigate();
  const { incisionKindList } = useContext(StaticFormContext);
  const { t } = useTranslation();

  const {accountInfo} = useContext(AccountContext);
  const gtm = useTagManager();

  const [nextLink, setNextLink] = useState(null);

  function handleSelectedItem(selectedItem) {
    setMe((prevState) => ({ ...prevState, incisionKind: selectedItem }));

    let value;
    switch (selectedItem){
      case 1 :
        value = "Inframammary";
        break;
      case 2 :
        value = "Mastectomy_scar";
        break;
      case 3 :
        value = "Periareolar";
        break;
      case 4 :
        value = "Transaxilary";
        break;
      case 5 :
        value = "Mastopexy";
        break;
      default:
        value = "I_dont_know";
    }

    gtm({
      category: 'Incision_type',
      action: value,
      event: 'interaction',
      segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
    });
  }

  function goNext() {
    setNextLink("/registration/implant-placement");

    gtm({
      category: 'Incision_type',
      action: "I_dont_know",
      event: 'interaction',
      segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
    });
  }

  useEffect(() => {
    if (me?.country_id === undefined) {
      navigate("/registration/implant-type");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (nextLink) return <Navigate push to={nextLink} />;

  return (
    <>
      <Helmet>
        <title>Incision Type | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>
      <Registration step={6}>
        <FiftyFiftyLayout
          backgroundImgPath="fifty-fifty-bg-1"
          title={t("Incision-Type-Selection.Title")}
          subtitle={t("Incision-Type-Selection.Description")}
        >
          <div className="fixed-image-block-size">
            <img
              className="fifty-fifty-layout__content__img2"
              src="breast-dark.jpg"
              alt={t("Global.Breast")}
            />
          </div>

          <div className="incision-type-selection__btn-container">
            <div className="pre-next-btns pre-next-btns-top">
              <LinkBack linkBack="/registration/implant-register-method" />
            </div>
            {incisionKindList &&
              incisionKindList
                .map((incisionKindItem) => {
                  return (
                    incisionKindItem.title !== "Undefined" && (
                      <label
                        key={incisionKindItem.id}
                        className="label"
                        onClick={() => {
                          handleSelectedItem(incisionKindItem.id);
                          setNextLink("/registration/implant-placement");
                        }}
                      >
                        {t(`API.IncisionType-${incisionKindItem.id}`)}
                        <input type="radio" name="radio" />
                        <span className="radio-btn"></span>
                      </label>
                    )
                  );
                })
                .reverse()}

            <label className="label" onClick={() => goNext()}>
              {t("Global.IDontKnow")}
              <input type="radio" name="radio" />
              <span className="radio-btn"></span>
            </label>
          </div>
        </FiftyFiftyLayout>
      </Registration>
    </>
  );
}
