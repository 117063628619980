import React, {useState, useEffect} from "react";
import "./MyImplantsInformation.scss";
import {useTranslation} from "react-i18next";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import LinkSimple from "../../components/library/Link/LinkSimple";
import {Link} from "react-router-dom";
import EstaAPIKit from "../../data/EstaAPIKit";
import SpinnerSmall from "../../components/library/Spinner/SpinnerSmall";
import EnsureToken from "../../components/library/EnsureToken";
import {Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from "react-redux";
import {AlertMessage} from "../../components/library/AlertMessage/";
import {getDoctors} from "../../store/slices/uiSlice";
import {Box, Chip} from "@mui/material";
import {ImplantInformationSummary} from "../../components/library/ImplantInformationSummary";
import {isEmpty} from "lodash";

const MyImplantsInformation = () => {
  const [breastData, setBreastData] = useState(null);
  const [glutealData, setGlutealData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const preregistration = useSelector(state => state.preRegisterSlice);
  const registration = useSelector(state => state.registrationSlice);

  const {t} = useTranslation();
  const estaAPIKit = new EstaAPIKit();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDoctors());
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--variant-height', registration?.editImplantInformation ? "60vh" : 'calc(36vh + 50px)');
  }, [registration?.editImplantInformation]);

  const [page, setPage] = React.useState(1);
  const [typeOfImplant, setTypeOfImplant] = React.useState("b");
  const [statusSurgery, setStatusSurgery] = React.useState(1);

  const handleChipClick = (key, chip) => {
    if (chip === "statusSurgery") {
      setStatusSurgery(key);
    } else {
      setTypeOfImplant(key);
    }
  };

  async function implantsInfo() {
    setIsLoading(true);
    setBreastData([]);

    let payload = {
      statusSurgery: statusSurgery,
      surgeryType: typeOfImplant,
      page: page
    }

    await estaAPIKit
      .getMeSummaryDevices(payload)
      .then((response) => response.json())
      .then((data) => {
        setBreastData(data);
      })
      .catch((error) => console.log(error));

    setIsLoading(false);
  }

  useEffect(() => {
    implantsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, statusSurgery, typeOfImplant]);

  const hasImplants = glutealData?.surgeries || breastData?.surgeries;

  const [chipDataTypeOfImplant, setChipDataTypeOfImplant] = React.useState([
    {key: 0, label: 'Breasts', value: "b"},
    {key: 1, label: 'Gluteal', value: "g"}
  ]);

  const [chipDataStatus, setChipDataStatus] = React.useState([
    {key: 0, label: 'Current', value: 1},
    {key: 1, label: 'Previous', value: 0}
  ]);


  return (
    <>
      <Helmet>
        <title>My Implants | MotivaImagine®</title>
        <meta name="pageType" content="My Implants"/>
      </Helmet>
      <EnsureToken>
        <div className="pre-next-btns pre-next-btns-top">
          <LinkSimple back label={t("Global.Back")} to="/my-implants"/>
        </div>
        <FiftyFiftyLayout
          backgroundImgPath="fifty-fifty-bg-2"
          //title={preregistration?.me?.isMIACustomer ? t("Implant-Registration.Devices") : t("My-Gluteal-Implants.Title")}
        >
          {isLoading && (
            <div className={"loader"}>
              <SpinnerSmall/>
            </div>
          )}
          {!isLoading && hasImplants && (
            <>
              <Box>
                <AlertMessage/>
                <Box display="flex" justifyContent="space-between" py={1}>
                  <Box display="flex">
                    {chipDataTypeOfImplant.filter((data) =>
                      ((breastData?.haveBreast && data.key === 0)) ||
                      ((breastData?.haveGluteal && data.key === 1))
                    ).map((data) => (
                      <Chip
                        onClick={() => handleChipClick(data.value, "typeOfImplant")}
                        key={data.key}
                        label={data.label}
                        sx={{
                          backgroundColor: typeOfImplant === data.value ? "#6e0072" : "default",
                          color: typeOfImplant === data.value ? "white" : "black",
                          mr: 1
                        }}
                      />
                    ))}
                  </Box>


                  <Box display="flex">
                    {chipDataStatus.filter((data) =>
                      ((typeOfImplant === "g" && breastData?.havePreviousGluteal && data.key === 1) || (typeOfImplant === "g" && breastData?.haveCurrentGluteal && data.key === 0) || (typeOfImplant === "b" && breastData?.havePreviousBreast && data.key === 1) || (typeOfImplant === "b" && breastData?.haveCurrentBreast && data.key === 0))
                    ).map((data) => (
                      <Chip
                        onClick={() => handleChipClick(data.value, "statusSurgery")}
                        key={data.key}
                        label={data.label}
                        sx={{
                          backgroundColor: statusSurgery === data.value ? "#6e0072" : "default",
                          color: statusSurgery === data.value ? "white" : "black",
                          mr: 1
                        }}
                      />
                    ))}
                  </Box>
                </Box>
                <Box>
                  <ImplantInformationSummary breastData={breastData} setPage={setPage} page={page} statusSurgery={statusSurgery}/>
                </Box>
                {!isLoading && isEmpty(hasImplants) && (
                  <div className="no-implants">
                    <p>{t("Global.NoResults")}</p>
                  </div>
                )}
              </Box>
            </>
          )}
          {(!isLoading && !registration?.editImplantInformation) && (
            <Link
              to="/registration/implant-type"
              className={`add-implants-btn mt-4 mb-5`}
            >
              {t("Global.AddImplants")}
            </Link>
          )}
        </FiftyFiftyLayout>
      </EnsureToken>
    </>
  );
};

export default MyImplantsInformation;
