import React from "react";
import ButtonComponent from "./ButtonComponent";

export default function ButtonFilled(props) {
  return (
    <>
      <ButtonComponent {...props} />
    </>
  );
}
