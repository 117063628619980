import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  preRegistrationId: null,
  surgeryDate: null,
  implantPlacement: 0,
  incisionKind: 0,
  surgeryIndication: 0,
  surgeon: [
    {
      id: null,
      value: null,
      label: '',
    },
  ],
  isQ: null,
  isESN: true,
  country: null,
  implantsFor: 'both',
  implantsInformation: {
    implant_type: 'B',
    implantL: "",
    implantR: "",
    tools:{
      injector: null,
      balloon: null
    }
  },
  patientSummary: {
    patientFirstName: "",
    patientLastName: "",
    patientEmail: "",
    patientSSN: "",
    patientPhone: ""
  },
  serialNumberType: "ESN",
  isMiaImplants: false,
  isMiaValid: false,
  implantsValid : {
    implantL: "",
    implantR: "",
  },
}

export const preRegisterSlice = createSlice({
  name: 'preRegister',
  initialState,
  reducers: {
    preRegistrationInformation: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    cleanPreRegistrationInformation: (state) => {
      return {
        ...state,
        ...initialState
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { preRegistrationInformation, cleanPreRegistrationInformation } = preRegisterSlice.actions

export default preRegisterSlice.reducer
