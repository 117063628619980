import React, { useCallback, useContext, useEffect, useState } from "react";
import views from "../views";
import GuardRoute from "../hocs/GuardRoute";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import EstaAPIKit from "../data/EstaAPIKit";
import { useDispatch } from "react-redux";
import { setMaintenanceMode } from "../store/slices/uiSlice";
import { UserContext } from "../contexts/UserContext";
import { AccountContext } from "../contexts/AccountContext";
import Cookies from "js-cookie";

const estaApiKit = new EstaAPIKit();

export const RoutesList = () => {
  const role = {
    Patient: "Patient",
    Surgeon: "Surgeon",
    GeneralUser: "General User",
  };

  const { me, isMeLoading } = useContext(UserContext);
  const { accountReviewState } = useContext(AccountContext);

  const location = useLocation();
  const dispatch = useDispatch();

  const [maintenanceModeState, setMaintenanceModeState] = useState(false);

  const maintenance = () => {
    estaApiKit
      .getVersion()
      .then((res) => res.json())
      .then((data) => {
        setMaintenanceModeState(data.Version.maintenance_mode);
        dispatch(
          setMaintenanceMode({
            maintenanceMode: data.Version.maintenance_mode,
          }),
        );
      });
  };

  useEffect(() => {
    maintenance();
  }, [location.pathname]);

  const getViewForCurrentAuthState = useCallback(
    (fallback) => {
      if (maintenanceModeState) return <Navigate to="/MaintenancePage" />;

      const gigyaSessionCookie = `glt_${process.env.REACT_APP_GIGYA_APY_KEY}`;
      const isGigyaSessionValid = !!Cookies.get(gigyaSessionCookie);

      if (accountReviewState == "required") {
        return <Navigate to="/under-review" />;
      }

      if (accountReviewState == "loading") {
        return <views.LoginLoading />;
      }

      // Check if the URL has the post-login parameters from Gigya
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      const isPostGigyaLoginUrl = searchParams.get("provider");

      if (me && accountReviewState == "OK") {
        return me.isDoctor ? (
          <Navigate to="/doctor" />
        ) : (
          <Navigate to="/my-implants" />
        );
      } else if (isGigyaSessionValid && (isPostGigyaLoginUrl || isMeLoading)) {
        return <views.LoginLoading />;
      }
      return fallback;
    },
    [me, isMeLoading, accountReviewState],
  );

  const list = [
    {
      path: "/registration/implant-type",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantTypePage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/implant-side-selection",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantSideSelectionPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/preimplant-registration",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.PreImplantRegistrationPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/preimplant-registration-two",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.PreImplantRegistrationPageTwo />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/preimplant-registration-three",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.PreImplantRegistrationPageThree />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/preimplant-registration-yes-no",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.PreImplantRegistrationPageYesNo />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/card-type-selection",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.CardSelectionPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/implant-qid-technology",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantQidTechnologyPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/implant-register-method",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantRegisterMethodPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/implant-registration-with-sn",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantRegistrationPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/implant-register-method",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantRegisterMethodPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/implant-registration-with-esn",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantRegistrationPageESN />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/implant-registration-with-sn",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantRegistrationPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/incision-type-selection",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.IncisionTypeSelectionPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/implant-placement",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantPlacementPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/surgery-indication",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.SurgeryIndicationPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/surgery-information",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.SurgeryInformationPage />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/success",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantRegistrationSuccess />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/warranty-extended",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantRegistrationWarrantyExtended />
        </GuardRoute>
      ),
    },
    {
      path: "/registration/full",
      element: (
        <GuardRoute
          allowedRoles={[role.Patient, role.Surgeon, role.GeneralUser]}
        >
          <views.FullRegistration />
        </GuardRoute>
      ),
    },

    {
      path: "/implant-registration-with-esn",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.ImplantRegistrationPageESN />
        </GuardRoute>
      ),
    },
    {
      path: "/my-implants/information",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.MyImplantsInformation />
        </GuardRoute>
      ),
    },
    {
      path: "/my-implants/thank-you-for-visiting",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.MyImplantsInformationThanksYouForVisitingPage />
        </GuardRoute>
      ),
    },
    {
      path: "/warranty",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.WarrantyOptionPage />
        </GuardRoute>
      ),
    },
    {
      path: "/warranty/payment",
      element: <views.WarrantyPaymentPage />,
    },
    {
      path: "/warranty/payment/confirmation",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.WarrantyConfirmationPage />
        </GuardRoute>
      ),
    },
    {
      path: "/warranty/payment/response",
      element: <views.WarrantyPaymentResponsePage />,
    },
    {
      path: "/doctor",
      element: (
        <GuardRoute allowedRoles={[role.Surgeon]}>
          <views.DoctorFirstPage />
        </GuardRoute>
      ),
    },
    {
      path: "/doctor/search-implant",
      element: (
        <GuardRoute allowedRoles={[role.Surgeon]}>
          <views.DoctorSearchImplantPage />
        </GuardRoute>
      ),
    },
    {
      path: "/doctor/patient-list/:id",
      element: (
        <GuardRoute allowedRoles={[role.Surgeon]}>
          <views.PatientListClientPage />
        </GuardRoute>
      ),
    },

    {
      path: "/doctor/patient-list",
      element: (
        <GuardRoute allowedRoles={[role.Surgeon]}>
          <views.PatientListViewPage />
        </GuardRoute>
      ),
    },
    {
      path: "/doctor/disposal-information",
      element: (
        <GuardRoute allowedRoles={[role.Surgeon]}>
          <views.DisposalInformation />
        </GuardRoute>
      ),
    },
    {
      path: "/doctor/explant-information",
      element: (
        <GuardRoute allowedRoles={[role.Surgeon]}>
          <views.ExplantPage />
        </GuardRoute>
      ),
    },
    {
      path: "/my-implants",
      element: (
        <GuardRoute allowedRoles={[role.Patient, role.GeneralUser]}>
          <views.MyImplantsView />
        </GuardRoute>
      ),
    },
    {
      path: "/logout",
      element: <views.LogoutPage />,
    },
    {
      path: "/api-tester",
      element: <views.APITester />,
    },
    {
      path: "/ui-elements",
      element: <views.UIComponents />,
    },
    {
      path: "/my-test",
      element: <views.RouteTest />,
    },
    {
      path: "/",
      index: true,
      element: getViewForCurrentAuthState(<views.LoginPatient />),
    },
    {
      path: "/login-surgeon",
      element: getViewForCurrentAuthState(<views.LoginDoctor />),
    },
    {
      path: "/under-review",
      element: <views.UnderReview />,
    },
    {
      path: "/unauthorized",
      element: <views.UnAuthorized />,
    },
    {
      path: "/MaintenancePage",
      element: <views.MaintenancePage />,
    },
    {
      path: "*",
      element: <views.NotFound />,
    },
  ];

  return (
    <Routes>
      {list.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          index={route.index}
          element={route.element}
        />
      ))}
    </Routes>
  );
};
