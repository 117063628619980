import React, { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import "./Layout.scss";
import BackgroundImage from "../BackgroundImage/BackgroundImage";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

export default function PageWrapper({ children, backgroundImageAlt }) {
  const { me, userError } = useContext(UserContext);
  return (
    <div className="patient-list-view">
      <div
        style={{ minHeight: "100vh" }}
        className="patient-list-view__patient-list body-containerr"
      >
        {me && children}
        {userError && <ErrorComponent userError={userError} />}
      </div>
      <BackgroundImage backgroundImageAlt={backgroundImageAlt} />
    </div>
  );
}
