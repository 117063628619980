import React, { createContext, useState } from "react";

export const PatientListContext = createContext({});

export const PatientListContextProvider = ({ children }) => {
  const [patientList, setPatientList] = useState(null);
  const [specificPatientContext, setSpecificPatientContext] = useState(null);
  return (
    <PatientListContext.Provider
      value={{
        patientList,
        setPatientList,
        specificPatientContext,
        setSpecificPatientContext,
      }}
    >
      {children}
    </PatientListContext.Provider>
  );
};
