import { CameraFill } from "react-bootstrap-icons";
import React from 'react';

import ButtonAddImplants from "../components/library/Button/ButtonAddImplants";
import ButtonBothSides from "../components/library/Button/ButtonBothSides";
import ButtonFilled from "../components/library/Button/ButtonFilled";
import ButtonNext from "../components/library/Button/ButtonNext";
import ButtonProceed from "../components/library/Button/ButtonProceed";
import CardSelectHorizontalList from "../components/library/CardSelect/CardSelectHorizontalList";
import CardSelectVerticalList from "../components/library/CardSelectVertical/CardSelectVerticalList";
import DatePicker from "../components/library/DatePicker/Datepicker";
import Form from "../components/library/Form/Form";
import ImageRadioSelectList from "../components/library/ImageRadioSelectList/ImageRadioSelectList";
import ImplantCard from "../components/library/ImplantCard/ImplantCard";
import LinkBack from "../components/library/Link/LinkBack";
import LinkSimple from "../components/library/Link/LinkSimple";
import LinkSkip from "../components/library/Link/LinkSkip";
import SelectComponent from "../components/library/Select/Select";

// import DatePickerMaterialUI from "../components/library/Dropdown/DatePickerMaterialUI";

const breastDummy = {
  id: "id",
  serialNumber: "serialNumber",
  reference: "reference",
  type: "type",
  surface: "surface",
  base: "base",
  projection: "projection",
  volume: "volume",
  // if extendedWarranty !== true then show buy warranty Btn
  extendedWarranty: {
    program: "program",
    coverage: "coverage",
    currency: "currency",
    startDate: "startDate",
    endDate: "endDate",
  },
};
const dummyData = {
  surgery: [breastDummy],
  left: [breastDummy],
  right: [breastDummy],
};
const dummyHandleOnChange = (startDate) => {
};
const dummyLinkTo = "/";

const items = [
  {
    id: 1,
    title: "Breast",
    image: process.env.PUBLIC_URL + "/implant.png",
    alt: "Alt Text",
  },
  {
    id: 2,
    title: "Gluteal",
    image: process.env.PUBLIC_URL + "/implant.png",
    alt: "Alt Text",
  },
];

const verticalItems = [
  {
    id: 1,
    title: "Sub-glandular",
    description:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz",
    image: process.env.PUBLIC_URL + "/verticalcardimg.png",
    alt: "Alt Text",
  },
  {
    id: 2,
    title: "Sub-glandular",
    description:
      "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz",
    image: process.env.PUBLIC_URL + "/verticalcardimg.png",
    alt: "Alt Text",
  },
];

const verticalRadioItems = [
  {
    id: 1,
    title: "Augmentation - First Time Surgery",
    description:
      "First time surgery, indicated to increase breast size as an aesthetic procedure for.",
    image: process.env.PUBLIC_URL + "/verticalcardimg.png",
    alt: "Alt Text",
  },
  {
    id: 2,
    title: "Augmentation - First Time Surgery",
    description:
      "First time surgery, indicated to increase breast size as an aesthetic procedure for.",
    image: process.env.PUBLIC_URL + "/verticalcardimg.png",
    alt: "Alt Text",
  },
];

const radioSelectItems = [
  {
    id: 1,
    title: "Transaxillary",
  },
  {
    id: 2,
    title: "Preiareolar",
  },
  {
    id: 3,
    title: "Inframmary",
  },
  {
    id: 4,
    title: "Mastectomy Scar",
  },
];

const countryOptions = [
  { value: "Sweden", label: "Sweden" },
  { value: "Norway", label: "Norway" },
  { value: "Denmark", label: "Denmark" },
  { value: "Finland", label: "Finland" },
  { value: "Germany", label: "Germany" },
];

const clinicOptions = [
  { value: "Akademikliniken Stockholm", label: "Akademikliniken Stockholm" },
  { value: "Akademikliniken Göteborg", label: "Akademikliniken Göteborg" },
  { value: "Akademikliniken Malmö", label: "Akademikliniken Malmö" },
  { value: "Akademikliniken Örebro", label: "Akademikliniken Örebro" },
  { value: "Art Clinic Stockholm", label: "Art Clinic Stockholm" },
];

const surgeonName = [
  { value: "Dr.Firstname Lastname", label: "Dr.Firstname Lastname" },
  { value: "Dr.Firstname Lastname", label: "Dr.Firstname Lastname" },
  { value: "Dr.Firstname Lastname", label: "Dr.Firstname Lastname" },
  { value: "Dr.Firstname Lastname", label: "Dr.Firstname Lastname" },
];

export default function UIComponents() {
  function renderLinks() {
    const links = [
      "/registration/preimplant-registration",
      "/registration/preimplant-registration-two",
      "/registration/preimplant-registration-three",
      "/registration/implant-placement",
      "/registration/implant-type",
      "/registration/implant-side-selection",
      "/registration/implant-registration",
      "/registration/incision-type-selection",
      "/registration/surgery-indication",
      "/registration/surgery-information",
      "/registration/full",

      "/warranty",
      "/warranty/payment",
      "/warranty/payment/confirmation",

      "/my-implants",
      "/my-implants/breast",
      "/my-implants/gluteal",

      "/confirm-uploaded-image-quality",

      "/doctor/patient-list",
      "/logout",
    ];
    return links.map((item, index) => {
      return (
        <div key={`link-simple-${index}`}>
          <LinkSimple to={item} label={item} />
          <br />
        </div>
      );
    });
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h3>Page Links</h3>
          {renderLinks()}
          <CardSelectHorizontalList items={items} />
          <br />
          <br />
          <br />
          <ImageRadioSelectList name="Incision Kind" items={radioSelectItems} />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <SelectComponent
            title="COUNTRY"
            placeholder="Where did you have your surgery?"
            options={countryOptions}
            icon="country"
          />
          <SelectComponent
            title="CLINIC"
            placeholder="Search for clinic name"
            options={clinicOptions}
            icon="clinic"
          />
          <SelectComponent
            title="SURGEON"
            placeholder="Select Surgeon"
            options={surgeonName}
            icon="surgeon"
          />

          <br />
        </div>
        <div className="col-md-6">
          <ButtonNext /> <br />
          <LinkSkip to={dummyLinkTo} /> <br />
          <LinkBack /> <br />
          <ButtonFilled
            prepend={<CameraFill />}
            title="Take a new Image"
            subtitle="(Recommended)"
            colorScheme="true"
          />{" "}
          <br />
          <ButtonProceed />
          <ButtonBothSides />
          <ButtonAddImplants />
          <br />
          <br />
          <br />
          <CardSelectVerticalList
            verticalItems={verticalItems}
            showImage="true"
          />
          <CardSelectVerticalList
            verticalItems={verticalRadioItems}
            showRadioBtn="true"
          />
          <br />
          <br />
          <br />
          <Form setContainsValue={() => {}} />
          <br />
          <br />
          {/* <Dropdown
            title="SURGEON"
            placeholder="Select Surgeon"
            options={surgeonName}
          />{" "}
          */}
          <br />
          <DatePicker handleOnChange={dummyHandleOnChange} />
          <ImplantCard implantType="Breast" items={dummyData} />
        </div>
      </div>
    </div>
  );
}
