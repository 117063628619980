import React from "react";
import "./BackgroundImage.scss";

export default function BackgroundImage({ backgroundImageAlt }) {
  return (
    <div
      className={`patient-list__background col d-none d-md-inline ${
        backgroundImageAlt ? "background-image-alt" : "background-image"
      }`}
    ></div>
  );
}
