import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  registrationResult: {
    result: "",
    implants: [],
    implantsRegisteredApplyWarranty: false
  },
  editImplantInformation: false,
  selectionCard: {
    defaultCard: true,
    snLeft: '',
    snRight: '',
    vcLeft: '',
    vcRight: '',
  }
}

export const registrationSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setRegistrationResult: (state, action) => {
      return {
        ...state,
        registrationResult: action.payload
      }
    },
    setSelectionCard: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    cleanSelectionCard: (state) => {
      return {
        ...state,
        ...initialState
      }
    },
    setInformation: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
  },
})
// Action creators are generated for each case reducer function
export const {setRegistrationResult, setSelectionCard, cleanSelectionCard, setInformation} = registrationSlice.actions
export default registrationSlice.reducer

