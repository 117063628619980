import React, { useMemo } from "react";
import { AutoComplete } from "../AutoComplete";
import { useTranslation } from "react-i18next";
import { useCountries } from "../../../../hooks/useCountries";

export const CountryAutocomplete = ({
  formik,
  name,
  title,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  const { countryList } = useCountries();

  // Build the list of options from the country list and memoize it
  const options = useMemo(
    () =>
      countryList.map(({ value, label }) => ({
        id: value,
        label: t(label),
      })),
    [t, countryList],
  );

  // We'll use the id of the country as the field value
  const handleChange = (e, value) => {
    formik.setFieldValue(name, value?.id ?? null);
  };

  // Get the label either from an option (for example: {id: 157, label: "Costa rica"}) or directly from the id (for
  // example: 157)
  const getOptionLabel = (valueOrOption) =>
    valueOrOption?.label ||
    options.find((option) => option.id === valueOrOption)?.label ||
    "";

  return (
    <AutoComplete
      formik={formik}
      name={name}
      title={title ?? t("PreRegistration.PatientCountry")}
      placeholder={placeholder ?? t("Surgery-Information.Country")}
      options={options}
      handleChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  );
};
