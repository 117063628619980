import React from "react"
// import CardSelectHorizontalItem from "./CardSelectHorizontalItem";
import "./CardSelect.scss"

export default function CardSelectHorizontalList({
  items,
  handleSelected,
  setNextLink,
  imageLabels,
}) {
  // const [isSelected, setIsSelected] = useState(null);

  // function handleChange(id) {
  //   setIsSelected(id);
  //   handleSelected(id);
  // }

  return <div></div>
}
