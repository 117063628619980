import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StaticFormContext } from "../.../../../../contexts/StaticFormContext";
import SelectComponent from "../../library/Select/Select";
import { useCountries } from "../../../hooks/useCountries";
import { useDispatch, useSelector } from "react-redux";
import { preRegistrationInformation } from "../../../store/slices/preRegisterSlice";
import { InputComponent } from "../FormComponents/Input";
import { Box } from "@mui/material";
import { AutoComplete } from "../FormComponents/AutoComplete";
import { isNull } from "lodash";

export const PatientDetailsForm = ({ setIsValid }) => {
  const preRegistration = useSelector((state) => state.preRegisterSlice);
  const { t } = useTranslation();
  const { countryList } = useCountries();
  const dispatch = useDispatch();

  const {
    setPatientFirstName,
    setPatientLastName,
    setPatientEmail,
    setPatientCountry,
    setPatientSSN,
    setPatientPhone,
  } = useContext(StaticFormContext);

  const inputsForm = [
    {
      title: t("PreRegistration.PatientFirstName"),
      name: "patientFirstName",
      value: preRegistration?.patientSummary?.patientFirstName,
      ctx: setPatientFirstName,
      id: "preRegistration-patientFirstName",
      required: true
    },
    {
      title: t("PreRegistration.PatientLastName"),
      name: "patientLastName",
      value: preRegistration?.patientSummary?.patientLastName,
      ctx: setPatientLastName,
      id: "preRegistration-patientLastName",
      required: true
    },
    {
      title: t("PreRegistration.PatientEmail"),
      name: "patientEmail",
      value: preRegistration?.patientSummary?.patientEmail,
      ctx: setPatientEmail,
      id: "preRegistration-patientEmail",
      required: true
    },
    {
      title: t("Global.SSN"),
      name: "patientSSN",
      value: preRegistration?.patientSummary?.patientSSN,
      ctx: setPatientSSN,
      id: "preRegistration-patientSSN",
    },
    {
      title: t("Global.Phone"),
      name: "patientPhone",
      value: preRegistration?.patientSummary?.patientPhone,
      ctx: setPatientPhone,
      id: "preRegistration-patientPhone",
    },
  ];

  return (
    <div className="patient-details">
      <h3>{t("PreRegistration.PatientSummary")}</h3>
      <div className="input-wrapper">
        {inputsForm.map((input, idx) => (
          <Box mt={2} key={idx}>
            <InputComponent
              id={input.id}
              title={input.title}
              value={input.value}
              handleChange={({ target }) => {
                dispatch(
                  preRegistrationInformation({
                    patientSummary: {
                      ...preRegistration?.patientSummary,
                      [input.name]: target.value,
                    },
                  }),
                );
                input.ctx(target.value);
              }}
              required={input.required}
            />
          </Box>
        ))}
      </div>
      <div className="input-wrapper">
        <AutoComplete
          id="preRegistration-patientCountry"
          title={t("PreRegistration.PatientCountry")}
          buildObject="patientInformation.country"
          placeholder={t("Surgery-Information.Country")}
          options={countryList.map((o) => ({ ...o, label: t(o.label) }))}
          handleChange={(e, value) => {
            dispatch(
              preRegistrationInformation({
                ...preRegistration,
                country: [value],
              }),
            );
            setPatientCountry([value]);
          }}
          value={preRegistration?.country?.[0]?.label || null}
          isOptionEqualToValue={(option, value) => option.label === value}
        />
      </div>
    </div>
  );
};
