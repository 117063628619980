import React, {Suspense, useEffect} from "react";
import {HashRouter} from "react-router-dom";
import {ImplantContextProvider} from "./contexts/ImplantContext";
import {PatientListContextProvider} from "./contexts/PatientListContext";
import {StaticFormContextProvider} from "./contexts/StaticFormContext";
import {UserContextProvider} from "./contexts/UserContext";
import {AccountContextProvider} from "./contexts/AccountContext";
import Spinner from "./components/library/Spinner/Spinner";
import TagManager from "react-gtm-module";

import {RoutesList} from './routes';
import "./styles/custom.scss";

import ErrorDialog from "./components/library/ErrorDialog";
import CreateTicketModule from "./components/library/PopUpModules/CreateTicketModule";
import {useSelector} from "react-redux";
import {HelmetProvider} from 'react-helmet-async';
import useAwsRumClient from "./hooks/useAwsRumClient";

const tagManagerArgs = {
  gtmId: "GTM-WWPCK58",
};

TagManager.initialize(tagManagerArgs);

function App() {
  const buildNumber = "1.0.10";
  const iu = useSelector((state) => state.uiSlice);
  useAwsRumClient();

  return (
    <HelmetProvider>
      <HashRouter>
        <Suspense fallback={<Spinner/>}>
          <AccountContextProvider>
            <StaticFormContextProvider>
              <UserContextProvider>
                <PatientListContextProvider>
                  <ImplantContextProvider>
                    <RoutesList/>
                  </ImplantContextProvider>
                </PatientListContextProvider>
                <>
                  {iu?.errorDialog?.open && (<ErrorDialog/>)}
                  {iu?.createTicket?.open && (<CreateTicketModule general/>)}
                </>
              </UserContextProvider>
            </StaticFormContextProvider>
          </AccountContextProvider>
        </Suspense>
      </HashRouter>
    </HelmetProvider>
  );
}

export default App;
