import React from "react";
import ButtonComponent from "./ButtonComponent";

export default function ButtonOutlined(props) {
  return (
    <>
      <ButtonComponent colorScheme="outlinedPurple" {...props} />
    </>
  );
}
