import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export const RadioButton = (props) => {
  const { formik, title, name, options } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={5} display="flex" alignItems="center">
        <Typography variant="body1" fontSize={16} fontWeight={300}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box display="flex" alignItems="center">
          {options.map((option, idx) => (
            <label
              key={idx}
              style={{
                fontSize: 14,
                display: "flex",
                alignItems: "center",
              }}
            >
              {option.label}
              <input
                style={{ width: "50px" }}
                type="radio"
                name={name}
                value={option.label}
                checked={formik.values[name] === option.label}
                onChange={(event) => {
                  formik.setFieldValue(name, event.target.value);
                }}
              />
            </label>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
