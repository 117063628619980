import React from "react";
import { createRoot } from 'react-dom/client';

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from './store'
import { Provider } from 'react-redux'

import Locize from "i18next-locize-backend";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import makeServer from "./__mocks__/server";

if (process.env.REACT_APP_USE_FAKE_API === "true") {
  makeServer();
}

const locizeBackendOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
  referenceLng: "en",
  allowedAddOrUpdateHosts: ["localhost"],
};

i18next.use(Locize).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: "en",
  backend: locizeBackendOptions,
  ns: process.env.REACT_APP_LOCIZE_DEFAULT_NAMESPACE,
  defaultNS: process.env.REACT_APP_LOCIZE_DEFAULT_NAMESPACE,
  saveMissing: true,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}><App /></Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
