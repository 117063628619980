import React, {useState, useEffect, useContext} from "react";
import EstaAPIKit from "../../data/EstaAPIKit";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import {PatientListContext} from "../../contexts/PatientListContext";
import {useTranslation} from "react-i18next";
import "./PatientListClientPage.scss";
import {useNavigate, useParams} from "react-router-dom";
import LinkSimple from "../../components/library/Link/LinkSimple";
import EnsureToken from "../../components/library/EnsureToken";
import LinkBack from "../../components/library/Link/LinkBack";
import SpinnerSmall from "../../components/library/Spinner/SpinnerSmall";
import {ReactComponent as CLIPBOARD} from "../../assets/Icon/icon_copy.svg";
import {Box, Snackbar, SnackbarContent} from "@mui/material";
import {Helmet} from 'react-helmet-async';
import {ImplantInformationSummary} from "../../components/library/ImplantInformationSummary";
import {setExplantInformation} from "../../store/slices/explantSlice";
import {useDispatch} from "react-redux";

const PatientListClientPage = ({match}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const param = useParams();
  const estaAPIKit = new EstaAPIKit();
  const dispatch = useDispatch();

  const patientIndex = param.id;
  const {patientList} = useContext(PatientListContext);
  const [isLoading, setIsLoading] = useState(false);
  const [patientInfo, setPatientInfo] = useState(null);
  const [surgeryInfo, setSurgeryInfo] = useState(null);
  const [isClipboardActive, setIsClipboardActive] = useState(false);
  const [rightDeviceString, setRightDeviceString] = useState("");
  const [leftDeviceString, setLeftDeviceString] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  // const [currentBreastDevices, setCurrentBreastDevices] = useState(null);

  useEffect(() => {
    if (patientList === null) {
      navigate("/doctor/patient-list");
    } else {
      setPatientInfo(patientList[patientIndex]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientIndex]);

  useEffect(() => {
    if (patientInfo) {
      surgeryDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInfo, t]);

  async function surgeryDetails() {
    setSurgeryInfo(null);
    setIsLoading(true);
    const result = await getSurgeryDetails({
      surgery_id: patientInfo.surgery_id,
    });
    if (result?.code === 200) {
      setSurgeryInfo({surgeries: [result?.result]});
    }
    setIsLoading(false);
  }

  function getSurgeryDetails(params) {
    return estaAPIKit.getSurgeryDetails(params);
  }

  function splitDeviceInfo(device, side) {
    const {
      family,
      volume,
      texture,
      base,
      projection,
      reference,
      serialNumber: SN,
      ESN,
      extendedWarranty,
    } = device;
    return {
      mainData: {
        side: side,
        family,
        volume,
        texture,
        base,
        projection,
        reference,
        SN,
        ESN,
      },
      warrantyData: {...extendedWarranty},
    };
  }

  async function handleClipboard() {
    setOpenSnackBar(true);
    setIsClipboardActive(true);
    navigator.clipboard.writeText(await buildDataToExport());
    setTimeout(() => {
      setIsClipboardActive(false);
    }, 500);
  }

  async function buildDataToExport() {
    const surgery = surgeryInfo && surgeryInfo?.surgeries[0];
    console.log(surgery)
    const surgeryType = surgery.devices.implants.type === "B" ? t("Global.Breast") : t("Global.Gluteal");

    const data =
      (await `${t("MyProfile.PatientName")} ${
        patientInfo?.patient_full_name
      }\n\n`) +
      `${t("Patient-List-Card.SurgeryInformation")}\n\n` +
      `${t("Patient-List-Implant-Card.Type")}: ${surgeryType}\n` +
      `${t("Patient-List-Implant-Card.Date")}: ${surgeryInfo?.date}\n` +
      `${t("Patient-List-Implant-Card.IncisionSite")}: ${t(
        `API.IncisionType-${surgeryInfo?.incision_site_id}`
      )}\n` +
      `${t("Patient-List-Card.Indication")}: ${t(
        `API.SurgeryIndication-${surgeryInfo?.indication_id}`
      )}\n` +
      `${t("Patient-List-Implant-Card.implantPlacement")}: ${t(
        `API.ImplantPlacement-${surgeryInfo?.placement_id}`
      )}\n\n` +
      `${t("Patient-List-Implant-Card.ImplantDetails")}\n\n` +
      `${leftDeviceString}\n` +
      `${rightDeviceString}\n`;
    return data;
  }

  const handleSetInformationExplant = (side) => {
    const surgery = surgeryInfo && surgeryInfo?.surgeries[0];

    dispatch(setExplantInformation({
      side: side === "left" ? 1 : side === "right" && 2,
      originalImplantingDate: surgery.procedureDetails.date,
      serial: {
        SNLeft: side === "left" ? surgery.devices.implants.left.serialNumber : "",
        SNRight: side === "right" ? surgery.devices.implants.right.serialNumber : "",
        VCLeft: side === "left" ? surgery.devices.implants.left.validationCode : "",
        VCRight: side === "right" ? surgery.devices.implants.right.validationCode : "",
      },
      patientInformation: {
        firstName: surgery.patientDetails?.firstName,
        lastName: surgery.patientDetails?.lastName,
        email: surgery.patientDetails?.email,
        country: surgery.patientDetails?.country,
        phone: "",
        SSN: "",
        address: ""
      }
    }));
    navigate("/doctor/explant-information");
  }

  return (
    <>
      <Helmet>
        <title>Patient Detail | MotivaImagine®</title>
        <meta name="pageType" content="Patient Detail"/>
      </Helmet>

      <EnsureToken>
        <div className="pre-next-btns pre-next-btns-top">
          <LinkBack linkBack="/doctor/patient-list"/>
        </div>
        {
          <FiftyFiftyLayout
            backgroundImgPath="fifty-fifty-bg-1"
            title={""}
          >
            <h1>
              {`${patientInfo?.patient_full_name}`}
              <CLIPBOARD
                className="ml-2 mb-1 clipboard"
                onClick={handleClipboard}
                style={isClipboardActive ? {fill: "rgba(99, 64, 57, 0.45098)"} : {fill: "#634039"}}
                data-toggle="tooltip"
                data-placement="top"
                title={t("Patient-Copy-Details")}
              />
              <Snackbar
                sx={{
                  top: {xs: 300, md: 410, lg: 410},
                  left: {xs: 145, md: 500, lg: 600}
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                autoHideDuration={1000}
                onClose={() => setOpenSnackBar(false)}
                open={openSnackBar}
              >
                <SnackbarContent className="clipboardMessage"
                                 message={<span style={{fontSize: '18px'}}> ✓ {t("Patient-Copy-Details")}</span>}
                />
              </Snackbar>
            </h1>

            {isLoading && (
              <div className={"loader"}>
                <SpinnerSmall/>
              </div>
            )}
            {!isLoading && (
              <div>
                <ImplantInformationSummary breastData={surgeryInfo}
                                       handleSetInformationExplant={handleSetInformationExplant}/>
                <Box mt={3} display="flex" justifyContent="space-between" px={3}>
                  {patientIndex > 0 ? (
                    <LinkSimple
                      back
                      label={t("Global.Previous")}
                      to={`/doctor/patient-list/${parseInt(patientIndex) - 1}`}
                    />
                  ) : (
                    <span/>
                  )}
                  {patientList?.length - 2 >= patientIndex && (
                    <LinkSimple
                      label={t("Global.Next")}
                      to={
                        patientList.length - 2 >= patientIndex
                          ? `/doctor/patient-list/${parseInt(patientIndex) + 1}`
                          : `#`
                      }
                    />
                  )}
                </Box>
              </div>
            )}
          </FiftyFiftyLayout>
        }
      </EnsureToken>
    </>
  );
};

export default PatientListClientPage;
