import React from "react";
import {Card as SimpleCard, CardContent} from "@mui/material";

export const Card = (props) => {
  const {children, bgColor = "#F3F3F4"} = props;
  return (
    <SimpleCard elevation={0}>
      <CardContent sx={{
        borderRadius: "10px",
        backgroundColor: bgColor,
      }}>
        {children}
      </CardContent>
    </SimpleCard>
  );
};
