import React, { useContext } from "react";
import GigyaKit from "../../../data/GigyaKit";
import { UserContext } from "../../../contexts/UserContext";
import ButtonFilled from "./ButtonFilled";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useTagManager } from "../../../hooks/useTagManager";

export default function ButtonLogin() {
  const gigyaKit = new GigyaKit();
  const { userError, setUserError } = useContext(UserContext);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const gtm = useTagManager();

  const pathDoctor = location.pathname === "/login-surgeon";

  function getUrl(path = "") {
    return `${process.env.REACT_APP_DEPLOYED_FOLDER}${path}`;
  }

  async function handleOnClick() {
    const redirectAfterLoginURL = getUrl(pathDoctor ? "login-surgeon" : "");
    const screenSet = pathDoctor ? "SURGEON_REG" : "Default-RegistrationLogin";

    if (pathDoctor) {
      gtm({
        category: "login",
        action: "open",
        segment: "surgeon",
        event: "interaction",
      });
    } else {
      gtm({
        category: "login",
        action: "open",
        segment: "patient",
        event: "interaction",
      });
    }

    const onError = (e) => {
      if (e.errorCode === 403041) {
        gigyaKit.hideLoginScreenSet(screenSet);
        navigate("/under-review");
      }
    };

    gigyaKit
      .showLoginScreenSet(redirectAfterLoginURL, screenSet, { onError })
      .catch((error) => setUserError(error));
  }

  return (
    <div>
      <ButtonFilled
        title={t("Global.LogIn")}
        onClick={handleOnClick}
      ></ButtonFilled>
      {userError && <ErrorComponent userError={userError} />}
    </div>
  );
}
