import React, { useState, useEffect, useContext } from "react";
import { StaticFormContext } from "../../contexts/StaticFormContext";
import { useTranslation } from "react-i18next";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import Registration from "../../components/library/Registration.jsx";
import LinkBack from "../../components/library/Link/LinkBack";
import { Navigate } from "react-router-dom";
import {Helmet} from 'react-helmet-async';
import {getDoctors} from "../../store/slices/uiSlice";
import {useDispatch} from "react-redux";
import {useTagManager} from "../../hooks/useTagManager";
import {AccountContext} from "../../contexts/AccountContext";
import {isUndefined} from "lodash";

export default function ImplantTypePage() {
  const { setCurrentWizard, setHasStartedRegistration } =
    useContext(StaticFormContext);
  const {accountInfo} = useContext(AccountContext);
  const gtm = useTagManager();

  const [nextLink, setNextLink] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDoctors());
  }, []);

  const btnList = [
    {
      id: 1,
      title: t("Global.Breasts"),
      image: process.env.PUBLIC_URL + "/breast-implant.png",
      alt: t("Global.Breast"),
    },
    {
      id: 2,
      title: t("Global.Gluteal"),
      image: process.env.PUBLIC_URL + "/gluteal-implant.png",
      alt: t("Global.Gluteal"),
    },
  ];

  useEffect(() => {
    setHasStartedRegistration(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (nextLink) return <Navigate push to={nextLink} />;


  const breastSelected =()=>{
    setCurrentWizard("breast");
    gtm({
      category: 'Implant_selection',
      action: 'Breast',
      event: 'interaction',
      segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
    });
  }

  const glutealSelected =()=>{
    setCurrentWizard("gluteal");
    gtm({
      category: 'Implant_selection',
      action: 'Gluteal',
      event: 'interaction',
      segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
    });
  }

  return (
    <>
      <Helmet>
        <title>Implant Type | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>

      <Registration step={1}>
        <FiftyFiftyLayout
          backgroundImgPath="fifty-fifty-bg-1"
          title={t("Implant-Type-Page.Title")}
        >
          <div className="row card-select-horizontal-list d-flex justify-content-center">
            <div
              className="fifty-fifty-layout__main-btn-container-col"
              style={{ marginBottom: "0vh" }}
            >
              {btnList.map((btn) => (
                <label
                  key={btn.id}
                  onClick={() => {
                    btn.id === 1
                      ? breastSelected()
                      : glutealSelected();
                    setNextLink("/registration/implant-side-selection");
                  }}
                  className="implant-register-with__description-container__card-2 label-card-2"
                >
                  <div className="vertical-list-item__img-wrapper-2">
                    <img src={btn.image} alt={btn.title} />
                  </div>

                  <h3 className="text-muted">{btn.title}</h3>

                  <input type="radio" name="radio2" />
                  <span className="radio-btn-card"></span>
                </label>
              ))}
            </div>
          </div>

          <div className="pre-next-btns pre-next-btns-top">
            <div>
              <LinkBack linkBack="/" />
            </div>
          </div>
        </FiftyFiftyLayout>
      </Registration>
    </>
  );
}
