import React from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.scss";

export default function Pagination({
  hidden = false,
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  return (
    !hidden &&
    totalPages !== 1 && (
      <ReactPaginate
        className="pagination"
        breakLabel="-"
        nextLabel={currentPage !== totalPages && ">"}
        onPageChange={(e) => setCurrentPage(e.selected + 1)}
        pageCount={totalPages}
        previousLabel={currentPage !== 1 && "<"}
        renderOnZeroPageCount={null}
      />
    )
  );
}
