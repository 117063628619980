import React from "react";
import { useTranslation } from "react-i18next";
import ButtonFilled from "../Button/ButtonFilled";
import LinkSimple from "../Link/LinkSimple";
import Modal from "./Modal";

const PreRegistrationConfirmationModal = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal title={t("PreRegistration.Confirmation.Title")} onClose={onClose}>
      <p>{t("PreRegistration.Confirmation.Details")}</p>
      <ButtonFilled
        title={t("PreRegistration.Confirmation.Clinic")}
        onClick={() => {
          window.open(process.env.REACT_APP_CLINICS_RETURN_URL, "_parent");
          onClose();
        }}
      />
      <LinkSimple
        to="/"
        cancel
        onClick={() => {
          onClose();
        }}
        label={t("PreRegistration.Confirmation.Ok")}
      />
    </Modal>
  );
};

export default PreRegistrationConfirmationModal;
