import "./ImplantRegistrationPageESN.scss";

import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {Navigate, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import LinkAction from "../../components/library/Link/LinkAction";
import LinkBack from "../../components/library/Link/LinkBack";
import ButtonFilled from "../../components/library/Button/ButtonFilled";
import CreateTicketModule from "../../components/library/PopUpModules/CreateTicketModule";
import Registration from "../../components/library/Registration.jsx";
import {StaticFormContext} from "../../contexts/StaticFormContext";

import {ImplantsFormESN} from "../../components/library/Form/ImplantsFormESN";
import {useDispatch, useSelector} from "react-redux";
import {setSupportModalOn} from "../../store/slices/supportSlice";
import {DevicesForm} from "../../components/library/Form/DevicesForm";
import {preRegistrationInformation} from "../../store/slices/preRegisterSlice";
import {Helmet} from 'react-helmet-async';

export default function ImplantRegistrationPageESN() {

  const preRegistration = useSelector((state) => state.preRegisterSlice);
  const [isDevicesValid, setIsDevicesValid] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);
  const {modal, modal_type} = useSelector((state) => state.supportSlice);
  const [nextLink, setNextLink] = useState(null);
  const navigate = useNavigate();
  const {
    currentWizard,
    registerImplantsFor,
    shouldRenderLeftForm,
    shouldRenderRightForm,
    serialNumberLeft,
    serialNumberRight,
    leftVC,
    rightVC
  } = useContext(StaticFormContext);

  const {me, breastImplants, glutealImplants} = useContext(UserContext);

  const {t} = useTranslation();

  const dispatch = useDispatch();

  function validateForms() {
    let valid = false;
    if (registerImplantsFor === "both") {
      if (currentWizard === "breast") {
        const left_valid = isImplantValid(breastImplants, "L");
        const right_valid = isImplantValid(breastImplants, "R");
        valid = left_valid && right_valid;
      }
      if (currentWizard === "gluteal") {
        valid =
          isImplantValid(glutealImplants, "L") &&
          isImplantValid(glutealImplants, "R");
      }
    } else if (registerImplantsFor === "left") {
      if (currentWizard === "breast") {
        valid = isImplantValid(breastImplants, "L");
      }
      if (currentWizard === "gluteal") {
        valid = isImplantValid(glutealImplants, "L");
      }
    } else if (registerImplantsFor === "right") {
      if (currentWizard === "breast") {
        valid = isImplantValid(breastImplants, "R");
      }
      if (currentWizard === "gluteal") {
        valid = isImplantValid(glutealImplants, "R");
      }
    }
    return valid;
  }

  function handleOnOK() {
    const validForms = validateForms();
    if (validForms) {
      setNextLink(getNextPath);
    }
  }

  function isImplantValid(data, placement) {
    const result_status = data[placement].status;
    if (result_status) {
      if (result_status === "false") {
        return false;
      }
      if (result_status === "true") {
        return true;
      }
    } else {
      return false;
    }
  }

  function isValidWithMia (){
    let valid = true;
    if(isFormValid && (preRegistration?.implantsValid?.implantL === true && preRegistration?.implantsValid?.implantR === false) || (preRegistration?.implantsValid?.implantL === false && preRegistration?.implantsValid?.implantR === true)){
      valid = true;
    }else if(isFormValid && !isDevicesValid && (preRegistration?.implantsValid?.implantL === true || preRegistration?.implantsValid?.implantR === true)){
      valid = true;
    }else if(isFormValid && isDevicesValid && (preRegistration?.implantsValid?.implantL === true || preRegistration?.implantsValid?.implantR === true)){
      valid = false;
    }else if(isFormValid && !(preRegistration?.implantsValid?.implantL === true || preRegistration?.implantsValid?.implantR === true)){
      valid = false;
    }
    return valid;
  }

  useEffect(() => {
      dispatch(preRegistrationInformation({
        ...preRegistration,
        implantsInformation: {
          ...preRegistration.implantsInformation,
          tools: {
            injector: preRegistration.implantsInformation?.tools?.injector && !preRegistration?.isMiaImplants ? null : preRegistration.implantsInformation?.tools?.injector,
            balloon: preRegistration.implantsInformation?.tools?.balloon && !preRegistration?.isMiaImplants ? null : preRegistration.implantsInformation?.tools?.balloon,
          }
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      serialNumberLeft, serialNumberRight, leftVC, rightVC, preRegistration?.isMiaImplants
    ]
  );

  function getNextPath() {

    if(preRegistration.isMiaImplants){
      return "/registration/surgery-information"
    }

    if (currentWizard === "breast") {
      return "/registration/incision-type-selection";
    } else if (currentWizard === "gluteal") {
      return "/registration/surgery-information";
    }
  }

  function createTicket() {
    dispatch(setSupportModalOn('fullForm'));
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (me?.country_id === undefined) {
      navigate("/registration/implant-type");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (nextLink) return <Navigate push to={nextLink} />;

  return (
    <>
      <Helmet>
        <title>Implant Information ESN | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>
      <Registration step={5}>
        <div className="pre-next-btns pre-next-btns-top">
          <LinkBack linkBack={"/registration/implant-register-method"}/>
        </div>


        {(modal && modal_type === 'fullForm') && (
          <CreateTicketModule
            ticketForESN
            leftSideImplant={shouldRenderLeftForm()}
            rightSideImplant={shouldRenderRightForm()}
          />
        )}

      <FiftyFiftyLayout
        backgroundImgPath="fifty-fifty-bg-1"
        title={t("Implant-Registration-ESN.Title")}
        subtitle={t("Implant-Registration-ESN.Description")}
      >
        <div className="info-and-form-wrapper">
          <ImplantsFormESN setIsValid={setIsFormValid} />
        </div>
        {preRegistration.isMiaImplants &&
          <div className="info-and-form-wrapper ml-5">
            <DevicesForm setIsValid={setIsDevicesValid}/>
          </div>
        }
        <div className="fifty-fifty-layout__btn-container--middle">
          <LinkAction
            to="/registration/card-type-selection"
            className="support"
            label={t("Global.ChangeCardType")}
          />
          <LinkAction
            onClick={createTicket}
            className="support"
            label={t("Global.HavingTrouble")}
          />
        </div>
        <div className="pre-next-btns">
          <span />
          <ButtonFilled
            size={"lg"}
            inline
            disabled={isValidWithMia()}
            title={t("Global.Verify")}
            onClick={handleOnOK}
          />
        </div>
      </FiftyFiftyLayout>
    </Registration>
    </>
  );
}
