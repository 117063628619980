import React from "react";
import { CameraFill, Upload } from "react-bootstrap-icons";
import ButtonFilled from "./ButtonFilled";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export default function ButtonAddImplants(props) {
  const { t } = useTranslation();

  return (
    <>
      <ButtonFilled
        prepend={isMobile ? <CameraFill /> : <Upload />}
        title={
          isMobile
            ? t("Implant-Registration.ScanLabel")
            : t("Implant-Registration.UploadLabel")
        }
        inline
        {...props}
      />
    </>
  );
}
