import React from "react"

export const AvatarDoctor = ({color, size}) => (
  <div className="svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="#634039" width={size} height={size}>
      <path
        d="M18 18a4 4 0 10-4-4 4 4 0 004 4zm-3.75 5.25a.75.75 0 10.75-.75.75.75 0 00-.75.74zm7.83-4.74L21 18.33v1.72a2.5 2.5 0 012 2.45v1.75a.49.49 0 01-.4.49l-1 .2a.25.25 0 01-.29-.19l-.1-.49a.25.25 0 01.19-.29l.6-.12V22.5a1.5 1.5 0 00-1.5-1.5 1.53 1.53 0 00-.3 0 1.54 1.54 0 00-1.2 1.56v1.28l.6.12a.26.26 0 01.2.3l-.1.49a.26.26 0 01-.29.2l-1-.13a.51.51 0 01-.43-.5V22.5a2.5 2.5 0 012-2.45v-1.47a4.88 4.88 0 01-4.5-.27v3.27a1.75 1.75 0 11-1 0v-3.16l-.57.1A3.5 3.5 0 0011 22v3a1 1 0 001 1h12a1 1 0 001-1v-3a3.51 3.51 0 00-2.93-3.46z"
        fill="#634039"></path>
    </svg>
  </div>
);
