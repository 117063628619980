import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export const InputComponent = ({
  formik,
  title,
  name,
  isDisabled = false,
  handleChange,
  value,
  id,
  handleBlur,
  isLoading,
  t,
  fullwidth,
  type,
  required,
  handleFocus = undefined,
}) => {
  const meta = formik?.getFieldMeta(name);

  return (
    <Grid container>
      {title && (
        <Grid item xs={12} md={5} display="flex" alignItems="center">
          <Typography as="label" variant="body1" fontSize={16} fontWeight={300}>
            {title}
            {required && "*"}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} md={fullwidth ? 12 : 7}>
        <input
          id={id}
          name={name && name}
          className="form-control"
          type={type || "text"}
          style={{ width: "100%", height: "50px" }}
          value={
            isLoading
              ? `${t("Global.Loading")}...`
              : typeof name === "undefined"
                ? value
                : meta?.value
          }
          onChange={handleChange ? handleChange : formik?.handleChange}
          onBlur={handleBlur ? handleBlur : formik && formik?.handleBlur}
          onFocus={handleFocus}
          disabled={isDisabled}
        />

        {name && meta?.error && meta?.touched ? (
          <Box mt={1}>
            <Typography variant="subtitle2" color="red">
              {meta.error}
            </Typography>
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
};
