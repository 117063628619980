import React, {useState, useContext, useRef, useEffect} from "react";
import { useTranslation } from "react-i18next";
import "./ScanModule.scss";

import SpinnerSmall from "../Spinner/SpinnerSmall";
import { StaticFormContext } from "../../../contexts/StaticFormContext";
import EstaAPIKit from "../../../data/EstaAPIKit";
import {AccountContext} from "../../../contexts/AccountContext";
import {useTagManager} from "../../../hooks/useTagManager";
import {isUndefined} from "lodash";

const FileUploadModule = ({ setShowModule }) => {
  const [isLoadingDetection, setIsLoadingDetection] = useState(false);
  const [detectionError, setDetectionError] = useState(false);
  const [isDetected, setIsDetected] = useState(false);
  const inputRef = useRef();
  const estaAPIKit = new EstaAPIKit();
  const {accountInfo} = useContext(AccountContext);
  const gtm = useTagManager();

  const { setDetectedLeftSerialNumber, setDetectedRightSerialNumber, setLeftVC,setRightVC, registerImplantsFor, setIsSuccessScanned } =
    useContext(StaticFormContext);

  const { t } = useTranslation();

  function handleFileOnChange(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      toBase64(file).then((result) => {
        sendToTextract(file, result);
      });
    }
  }

  function sendToTextract(file, base64) {
    setIsLoadingDetection(true);
    const fileType = file.type;
    const image = base64.replace(`data:${fileType};base64,`, "");
    const payload = {
      image: image,
    };
    estaAPIKit
      .extractSerialNumbers(payload)
      .then((res) => res.json())
      .then((data) => {

        setIsLoadingDetection(false);
        setIsDetected(true);
        if (data.length === 0) {
          setDetectionError(true);
          setIsSuccessScanned(false);
        } else {
          if (data.length === 2) {
            setDetectedRightSerialNumber(data[1].serial);
            setRightVC(data[1].validationCode);
          }
          setDetectedLeftSerialNumber(data[0].serial);
          setLeftVC(data[0].validationCode);

          if (registerImplantsFor === "right" && data.length === 1){
            setDetectedRightSerialNumber(data[0].serial);
            setRightVC(data[0].validationCode);
          }

          // Close ScanModule
          setShowModule(false);
          window.scrollBy(0, 330);
          setIsSuccessScanned(true);
        }
      })
      .catch((error) => {
        setIsLoadingDetection(false);
        setDetectionError(true);
        setIsSuccessScanned(false);
      });

    gtm({
      category: 'Registration_method',
      action: 'Upload_label_complete',
      event: 'interaction',
      segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
    });
  }

  function toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const resetModal = () => {
    setDetectionError(false);
    setIsDetected(false);
    setIsLoadingDetection(false);
  };

  return (
    <div className="module">
      <div className="module__box">
        <p
          className="module__box__close-btn"
          onClick={() => {
            window.scrollBy(0, 330);
            resetModal();
            setShowModule(false);
          }}
        >
          X
        </p>
        <h1>{t("FileUpload.Title")}</h1>
        <p className="text-muted">{t("FileUpload.Description")}</p>
        {!isDetected && !isLoadingDetection && (
          <>
            <input
              ref={inputRef}
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              onChange={handleFileOnChange}
              capture
            />
            {detectionError && (
              <p
                style={{
                  color: "red",
                  fontSize: "18px",
                  marginTop: "-20px",
                  marginBottom: "0px",
                }}
              >
                {t("Detection.ErrorOccured")}
              </p>
            )}
            <button
              className="submit"
              onClick={() => {
                inputRef.current.click();
              }}
            >
              {t("FileUpload.Upload")}
            </button>
          </>
        )}
        {isLoadingDetection && <SpinnerSmall />}
      </div>
    </div>
  );
};

export default FileUploadModule;
