import React from "react";
import "./ImplantCard.scss";
import PurchaseWarrantyComponent from "./PurchaseWarrantyComponent/PurchaseWarrantyComponent";
import WarrantyInfo from "./WarrantyInfo/WarrantyInfo";
import { useTranslation } from "react-i18next";

// 'doctor' needed to show/hide warranty purchase button
const ImplantCard = ({
  items,
  implantType,
  isDoctor,
  patientDataId,
  breastDetails,
  glutealDetails,
}) => {
  const implantIconUri =
    implantType === "Breast"
      ? "/breast-implants-icon.png"
      : "/gluteal-implants-icon.png";
  const implantIcon = process.env.PUBLIC_URL + implantIconUri;

  const { t } = useTranslation();
  let surgery = null;
  let left = null;
  let right = null;
  if (items.left && items.left.length > 0) {
    surgery = items.left[0];
    left = items.left[0];
  }
  if (items.right && items.right.length > 0) {
    surgery = items.right[0];
    right = items.right[0];
  }

  const getWarrantyInfo = () => {
    if (surgery && isDoctor) {
      return <WarrantyInfo data={surgery.extendedWarranty} />;
    } else {
      if (surgery && surgery.extendedWarranty) {
        return <WarrantyInfo data={surgery.extendedWarranty} />;
      } else {
        if (!isDoctor) {
          return <PurchaseWarrantyComponent />;
        }
      }
    }
  };

  function renderSurgeryInformationData(translationKey, value) {
    return (
      <div className="text-muted">
        <p className="mb-2 d-inline-block font-heavy pr-2 ">
          {t(translationKey)}:
        </p>
        <p className=" d-inline-block">{value || t("Global.NotFound")}</p>
      </div>
    );
  }

  function renderImplantDetailData(translationKey, typeDetails, side, value) {
    // typeDetails &&
    //   typeDetails[side] &&
    //   console.log(typeDetails[side][typeDetails[side].length - 1][value])
    return (
      <div className="implant-detail-data text-muted">
        <p className="font-heavy mb-0">{t(translationKey)}</p>
        <h5 className="implant-detail-data__value">
          {(typeDetails &&
            typeDetails[side] &&
            typeDetails[side][typeDetails[side].length - 1][value]) ||
            t("Global.NotFound")}
        </h5>
      </div>
    );
  }

  function renderImplantMeasurementData(
    translationKey,
    typeDetails,
    side,
    value
  ) {
    return (
      <div>
        <p className="font-size-14 mb-0">{t(translationKey)}</p>
        <h5 className="font-heavy font-size-16">
          {(typeDetails &&
            typeDetails[side] &&
            typeDetails[side][typeDetails[side].length - 1][value]) ||
            t("Global.NotFound")}
        </h5>
      </div>
    );
  }

  return (
    <div className="implant-wrapper">
      <div id="implant-accordion-parent">
        <div className="card">
          <div className="card-header--inplant-card" id={left && left.id}>
            <button
              className="implant-info-btn btn btn-link btn-block text-left"
              data-toggle="collapse"
              data-target={`#implant-accordion-child${patientDataId}`}
              aria-expanded="false"
              aria-controls={`implant-accordion-child${patientDataId}`}
            >
              <div className="header-wrapper">
                <div className="icon-title-box">
                  <div className="px-2 mb-0 d-inline">
                    <img alt="Implant icon" src={implantIcon} />
                  </div>
                  {/* add px-0 and px-2 after scaling to make responsive and line up with accordion padding */}
                  <div className="pl-2 mb-0 font-size-16 d-inline font-black">
                    {t(`Patient-List-Implant-Card.My${implantType}Implants`)}
                  </div>
                </div>
                <div className="mb-0 pl-md-0 ml-md-0 pr-0 text-muted d-sm-inline font-size-14">
                  {/* {t("Patient-List-Implant-Card.NoExtendedWarranty")} */}
                </div>

                <div className="color-gray-600 font-size-14 font-black pl-2">
                  {t("Patient-List-Implant-Card.SeeDetails")}
                </div>
              </div>
            </button>
          </div>

          {/* start of expandable content  */}

          <div
            id={`implant-accordion-child${patientDataId}`}
            className="collapse"
            // aria-labelledby="implant-accordion"
            // data-parent="#implant-accordion-parent"
          >
            <div className="card-body py-4">
              <div className="container">
                <div className="row">
                  <div className="col-sm pl-3 pl-md-5 pr-0">
                    {surgery && (
                      <>
                        <h5 className="font-black border-bottom font-size-14">
                          {t("Patient-List-Implant-Card.SurgeryInformation")}:
                        </h5>

                        {renderSurgeryInformationData(
                          "Patient-List-Implant-Card.SurgeryDate",
                          surgery.dateOfSurgery
                        )}

                        {renderSurgeryInformationData(
                          "Patient-List-Implant-Card.Doctor",
                          surgery.surgeonName
                        )}

                        {renderSurgeryInformationData(
                          "Patient-List-Implant-Card.Clinic",
                          surgery.clinic
                        )}

                        {renderSurgeryInformationData(
                          "Patient-List-Implant-Card.Country",
                          surgery.surgeryCountry
                        )}

                        {renderSurgeryInformationData(
                          "Patient-List-Implant-Card.Incision",
                          surgery.incisionKind
                        )}

                        {renderSurgeryInformationData(
                          "Patient-List-Implant-Card.Indication",
                          surgery.indication
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-sm ">{getWarrantyInfo()}</div>
                </div>
              </div>
            </div>
            <div className="card-body bg-light">
              <div className="implant-details-header">
                <div className="px-2 mb-0 d-inline">
                  <img alt="Implant Icon" src={implantIcon} />
                </div>

                <h5 className="font-black border-bottom font-size-14">
                  {t("Patient-List-Implant-Card.ImplantDetails")}
                </h5>
              </div>

              <div className="container">
                <div className="row">
                  {/* LEFT */}
                  {left && (
                    <>
                      <div className="col-sm pl-3 pl-md-5">
                        <h5 className="text-uppercase font-black font-size-14">
                          {t("Patient-List-Implant-Card.LeftImplant")}
                        </h5>
                        <div className="text-muted">
                          {renderImplantDetailData(
                            "Patient-List-Implant-Card.SerialNumber",
                            breastDetails,
                            "left",
                            "serialNumber"
                          )}
                          {renderImplantDetailData(
                            "Patient-List-Implant-Card.Reference",
                            breastDetails,
                            "left",
                            "reference"
                          )}
                          {renderImplantDetailData(
                            "Patient-List-Implant-Card.Type",
                            breastDetails,
                            "left",
                            "type"
                          )}
                          {renderImplantDetailData(
                            "Patient-List-Implant-Card.Surface",
                            breastDetails,
                            "left",
                            "surface"
                          )}

                          <div className="container">
                            <div className="d-flex justify-content-between">
                              {renderImplantMeasurementData(
                                "Patient-List-Implant-Card.Base",
                                breastDetails,
                                "left",
                                "base"
                              )}
                              {renderImplantMeasurementData(
                                "Patient-List-Implant-Card.Projection",
                                breastDetails,
                                "left",
                                "projection"
                              )}
                              {renderImplantMeasurementData(
                                "Patient-List-Implant-Card.Volume",
                                breastDetails,
                                "left",
                                "volume"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* RIGHT */}
                  {right && (
                    <>
                      <div className="col-sm pl-3 pl-md-0">
                        <h5 className="text-uppercase font-black font-size-14">
                          {t("Patient-List-Implant-Card.RightImplant")}
                        </h5>
                        <div className="text-muted">
                          {renderImplantDetailData(
                            "Patient-List-Implant-Card.SerialNumber",
                            breastDetails,
                            "right",
                            "serialNumber"
                          )}
                          {renderImplantDetailData(
                            "Patient-List-Implant-Card.Reference",
                            breastDetails,
                            "right",
                            "reference"
                          )}
                          {renderImplantDetailData(
                            "Patient-List-Implant-Card.Type",
                            breastDetails,
                            "right",
                            "type"
                          )}
                          {renderImplantDetailData(
                            "Patient-List-Implant-Card.Surface",
                            breastDetails,
                            "right",
                            "surface"
                          )}

                          <div className="container">
                            <div className="d-flex justify-content-between">
                              {renderImplantMeasurementData(
                                "Patient-List-Implant-Card.Base",
                                breastDetails,
                                "right",
                                "base"
                              )}
                              {renderImplantMeasurementData(
                                "Patient-List-Implant-Card.Projection",
                                breastDetails,
                                "right",
                                "projection"
                              )}
                              {renderImplantMeasurementData(
                                "Patient-List-Implant-Card.Volume",
                                breastDetails,
                                "right",
                                "volume"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImplantCard;
