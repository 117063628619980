import { ProgressBar } from "react-bootstrap";
import React, { useContext } from "react";

import { StaticFormContext } from "../../contexts/StaticFormContext";
import EnsureToken from "./EnsureToken";
import RegistrationStartedCorrectly from "./RegistrationStartedCorrectly.jsx";

export default function Registration({ step = 0, debug = true, children }) {
  const { currentWizard } = useContext(StaticFormContext);
  const totalSteps = currentWizard === "breast" ? 10 : 7;
  const currentProgress = (step / totalSteps) * 100;
  return (
    <EnsureToken>
      <RegistrationStartedCorrectly debug={debug}>
        <ProgressBar now={currentProgress} className="fixed-top" />
        {children}
      </RegistrationStartedCorrectly>
    </EnsureToken>
  );
}
