import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";

import {StaticFormContext} from "../../contexts/StaticFormContext";
import CreateTicketModule from "../../components/library/PopUpModules/CreateTicketModule";
import EnsureToken from "../../components/library/EnsureToken";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import TermsAndConditionsModule from "../../components/library/PopUpModules/TermsAndConditionsModule";
import WarrantyDetail from "../../components/library/Warranty/WarrantyDetail";
import WarrantyPaymentForm from "../../components/library/WarrantyPaymentForm/WarrantyPaymentForm";
import EstaAPIKit from "../../data/EstaAPIKit";
import SpinnerSmall from "../../components/library/Spinner/SpinnerSmall";
import {Grid, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setSupportModalOn} from "../../store/slices/supportSlice";
import {Helmet} from 'react-helmet-async';

export default function WarrantyPaymentPage() {
  const {paymentValid, setPaymentValid, codePayment, setCodePayment} = useContext(StaticFormContext);
  const [warrantyDetail, setWarrantyDetail] = useState(null);
  const [openPaymentErrorDialog, setPaymentErrorDialog] = useState(false);
  const [responsePaymentError, setResponsePaymentError] = useState("");
  const estaAPIKit = new EstaAPIKit();

  useEffect(() => {
    estaAPIKit
      .getWarrantyDetails()
      .then((res) => res.json())
      .then((data) => setWarrantyDetail(data)); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const {modal} = useSelector((state) => state.supportSlice);

  const dispatch = useDispatch();


  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  function getResponse() {
    return queryParams.get("response");
  }

  useEffect(() => {
    getResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(location.pathname === '/warranty/payment'){
      navigate("/warranty/payment", { replace: true });
    }
  }, []);


  function implantsHaveDistinctProgram(implants) {
    return (
      implants &&
      implants.length > 2 &&
      implants.length === new Set(implants.map((i) => i.period)).size
    );
  }

  function renderDescription() {
    return (
      <>
        {t("Extend-Warranty-Option.Description")}
        <div className="full-page-layout__section-container">
          {warrantyDetail && warrantyDetail["implants"] instanceof Array && (
            <WarrantyDetail
              price={warrantyDetail.price}
              currency={warrantyDetail.currency}
              hideValidUntil
              disclaimer={
                implantsHaveDistinctProgram(warrantyDetail["implants"]) &&
                t("Extend-Warranty-Option.AmountDisclaimer")
              }
              implants={warrantyDetail["implants"].filter(
                (i) => i.is_extended === 0
              )}
            />
          )}
        </div>
      </>
    );
  }

  useEffect(() => {
    if(codePayment !== 100 && typeof codePayment === 'string'){

      dispatch(setSupportModalOn());
      setPaymentErrorDialog(true);
      getError();
    }
  }, [codePayment]);

  const getError = async ()=>{
    await estaAPIKit
      .getErrorResponse(codePayment)
      .then((res) => res.json())
      .then((data) => setResponsePaymentError(data));
  }
  const handleCloseDialog = () => {
    setPaymentErrorDialog(false);
    setCodePayment(null);
  };

  return (
    <>
      <Helmet>
        <title>Payment Details | MotivaImagine®</title>
        <meta name="pageType" content="Extended Warranty" />
      </Helmet>
      <EnsureToken>
        {(modal && paymentValid) &&
          <CreateTicketModule setPaymentValid={setPaymentValid} paymentModule errorBAC={responsePaymentError && responsePaymentError.internationalization_key}/>
        }


        {showTermsAndConditions && (
          <TermsAndConditionsModule
            setShowTermsAndConditions={setShowTermsAndConditions}
          />
        )}

        {warrantyDetail ? (
          <FullPageLayout
            backgroundImgPath="homepage-bg-5"
            position="right"
            title={t("Extend-Warranty-Option.Title")}
            subtitle={renderDescription()}
            buttonList={[]}
          >
            <WarrantyPaymentForm
              showTermsAndConditions={showTermsAndConditions}
              setShowTermsAndConditions={setShowTermsAndConditions}
            />
          </FullPageLayout>
        ) : (
          <FullPageLayout
            backgroundImgPath="homepage-bg-5"
            position="right"
            title={
              <div className={"spin"}>
                <SpinnerSmall />
              </div>
            }
            buttonList={[]}
          />
        )}
      </EnsureToken>
    </>
  );
}
