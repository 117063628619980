import type {FC, ReactNode} from 'react'
import React, {useContext} from "react";
import {Navigate} from "react-router-dom";
import GigyaKit from "../data/GigyaKit";
import {useSelector} from "react-redux";
import {UserContext} from "../contexts/UserContext";

interface AuthGuardProps {
  children: ReactNode;
  allowedRoles: []
}

const GuardRoute: FC<AuthGuardProps> = ({children, allowedRoles}) => {
  const gigyaKit = new GigyaKit();
  const iu  = useSelector((state) => state.uiSlice);

  const {me} = useContext(UserContext);

  const userType = me?.userType === 0 ? "General User" : me?.userType === 1 ? "Patient" : me?.userType === 2 ? "Surgeon" : undefined;

  return (
    <>
      {
        iu.maintenanceMode ?
          <Navigate to="/MaintenancePage"/> : (allowedRoles?.includes(userType) ? <>{children}</>
            : typeof userType === "undefined"
              ? <Navigate to="/"/> /*{later replaced by unauthorized}*/
              : <Navigate to="/"/>)
      }
    </>
  )
};

export default GuardRoute;
