import React, {useEffect} from "react";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import {useTranslation, withTranslation} from "react-i18next";
import {Helmet} from 'react-helmet-async';

import TagManager from "react-gtm-module";
import {LoginComponent} from "../../components/library/LoginComponent";
import {LoginSelection} from "../../components/library/LoginSelection";

const LoginPatient = () => {
  const { t } = useTranslation();

  useEffect(() => {

    const url = new URL(window.location.href);
    const hasHash = url.hash.length > 0;
    const searchParams = new URLSearchParams(hasHash ? url.hash.substring(2) : url.search);

    const type = searchParams.get('type');
    const side = searchParams.get('side');
    const serialLeft = searchParams.get('serialLeft');
    const serialRight = searchParams.get('serialRight');

    let setDateNFC = {
      type,
      side,
      serialLeft,
      serialRight
    }

    setDateNFC?.type && localStorage.setItem('nfc', JSON.stringify(setDateNFC));
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        location: '/',
        title: (document && document.title),
        pageType: 'Start Page',
        event: "pageView",
        lang: localStorage.getItem('i18nextLng')
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }, [])


  return (
    <>
      <Helmet>
        <title>MotivaImagine®</title>
        <meta name="pageType" content="Start Page"/>
      </Helmet>
      <LoginSelection />
      <FullPageLayout
        isPublic
        backgroundImgPath="homepage-bg-1"
        position="center"
        megaTitle={t("Global.Welcome")}
        description={t("Login-Page.Description")}
        buttonList={[]}
        loginPage
      >
        <LoginComponent profile="patient"/>
      </FullPageLayout>
    </>
  );
}

export default withTranslation()(LoginPatient);
