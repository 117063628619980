import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import EstaAPIKit from "../../data/EstaAPIKit";

const initialState = {
  errorDialog: {
    open: false
  },
  alertMessage:{
    open: false,
    message: null,
    success: null
  },
  createTicket: {
    open: false,
    create: false
  },
  maintenanceMode: false,
  doctors: null
}
const estaAPIKit = new EstaAPIKit();

export const getDoctors = createAsyncThunk('register/getAll',
  async (arg, thunkAPI) => {
    return await estaAPIKit.getSurgeons().then((response) => response.json()).then((data)=>{
      return data;
    });
  }
);

export const uiSlice = createSlice({
  name: 'iu',
  initialState,
  reducers: {
    openErrorDialog: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    close: (state) => {
      return {
        ...state,
        ...initialState
      }
    },
    setMaintenanceMode: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setAlert: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDoctors.fulfilled, (state, action) => {
      state.doctors = action.payload
    })
  }
})

export const {openErrorDialog, close, setMaintenanceMode, setAlert} = uiSlice.actions;

export default uiSlice.reducer;
