import React from "react";
import CardInfo from "../CardInfo/CardInfo";
import "./CardWarranty.scss";
import { useTranslation } from "react-i18next";
import WarrantyDescription from "./WarrantyDescription";

export default function CardWarranty({ side, period, coverage, currency }) {
  const { t } = useTranslation();
  return (
    <div className="cardWarranty">
      <div className="description">
        <span className="descriptionLabel">
          {side === "both"
            ? `${t("WarrantyInfo.IndividualElegibilityIndicationLabel")}`
            : t("WarrantyInfo.ElegibilityIndicationLabel", {
                side: side === "L" ? t("Global.Left") : t("Global.Right"),
              })}
        </span>
        &nbsp;
        <span className="descriptionDetail">
          <WarrantyDescription period={period} />
        </span>
      </div>
      <div className="cardList">
        <CardInfo
          title={t("WarrantyInfo.CoverageTitle")}
          description={t("WarrantyInfo.CoverageDescription")}
        />
        <CardInfo
          title={t("WarrantyInfo.FinanctialSuportTitle")}
          description={t("WarrantyInfo.FinanctialSuportDescription", {
            amount: coverage,
            currency,
          })}
        />
      </div>
    </div>
  );
}
