import React from "react";
import { Link } from "react-router-dom";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import "./ImplantRegistrationSuccess.scss";
import { useTranslation } from "react-i18next";
import {Helmet} from 'react-helmet-async';

const ImplantRegistrationSuccess = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Registration successful | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>
      <FullPageLayout
        backgroundImgPath="homepage-bg-6"
        position="right"
        buttonList={[]}
      >
        <h1 className="thanks-for-visiting-page__title">
          {t("ImplantRegistration.Success.Title")}
        </h1>
        <h1 className="thanks-for-visiting-page__subtitle">
          {t("ImplantRegistration.Success.Description")}
        </h1>

        <Link to="/my-implants" className="go-back-to-browsing-btn">
          {t("Global.GoToHome")}
        </Link>
      </FullPageLayout>
    </>
  );
};

export default ImplantRegistrationSuccess;
