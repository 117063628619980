import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../contexts/UserContext";
import { StaticFormContext } from "../../../contexts/StaticFormContext";
import { preRegistrationInformation } from "../../../store/slices/preRegisterSlice";

import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { SelectComponent } from "../FormComponents/Select";

export const SurgeryDetailsForm = () => {
  const { t } = useTranslation();
  const preRegistration = useSelector((state) => state.preRegisterSlice);
  const dispatch = useDispatch();
  const { isDoctor, setMe } = useContext(UserContext);
  const { incisionKindList, breastPlacementList, surgeryKindList } =
    useContext(StaticFormContext);

  const inputsForm = [
    {
      title: isDoctor()
        ? t("PreRegistration.ImplantPlacementLabel")
        : t("Implant-Placement.Title"),
      options: breastPlacementList,
      translationKey: "API.ImplantPlacement-%s",
      value: preRegistration?.implantPlacement,
      name: "implantPlacement",
      id: "preRegistration-implantPlacement",
      required: true,
    },
    {
      title: isDoctor()
        ? t("PreRegistration.SurgeryIncisionTypeLabel")
        : t("Incision-Type-Selection.Title"),
      options: incisionKindList,
      translationKey: "API.IncisionType-%s",
      value: preRegistration?.incisionKind,
      name: "incisionKind",
      id: "preRegistration-incisionKind",
      required: true,
    },
    {
      title: isDoctor()
        ? t(
            `PreRegistration.${preRegistration.isMiaImplants ? "Procedure" : "Surgery"}IndicationLabel`,
          )
        : t("Surgery-Indication.Title"),
      options: preRegistration?.isMiaImplants
        ? surgeryKindList
        : surgeryKindList &&
          surgeryKindList.filter((filter) => filter.id !== 5),
      translationKey: "API.SurgeryIndication-%s",
      value: preRegistration?.surgeryIndication,
      name: "surgeryIndication",
      id: "preRegistration-surgeryIndication",
      required: true,
    },
  ];

  return (
    <>
      {inputsForm.map((input, idx) => (
        <Box mt={2} key={idx}>
          <SelectComponent
            id={input.id}
            title={input.title}
            options={input.options}
            translationKey={input.translationKey}
            value={input.value}
            handleChange={(e) => {
              dispatch(
                preRegistrationInformation({
                  ...preRegistration,
                  [input.name]: e.target.value,
                }),
              );
            }}
            required={input.required}
            isDisabled={preRegistration?.isMiaImplants}
          />
        </Box>
      ))}
    </>
  );
};
