import React, { useContext, useEffect, useState } from "react";

import { UserContext } from "../../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { StaticFormContext } from "../../../contexts/StaticFormContext";
import { ImplantDetailsForm } from "./ImplantDetailsForm";
import { SurgeryDetailsForm } from "./SurgeryDetailsForm";
import { ImplantsFormSN } from "./ImplantsFormSN";
import { ImplantsFormESN } from "./ImplantsFormESN";
import { PatientDetailsForm } from "./PatientDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import { PreRegisterSurgeryInformationForm } from "./PreRegisterSurgeryInformationForm";
import { preRegistrationInformation } from "../../../store/slices/preRegisterSlice";
import { DevicesForm } from "./DevicesForm";
import { delay } from "../../../utils";
import { Grid } from "@mui/material";
import { RequiredText } from "./RequiredText";

export default function FullRegistrationForm({ setIsValid }) {
  const preRegistration = useSelector((state) => state.preRegisterSlice);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    currentWizard,
    setSerialNumberLeft,
    setSerialNumberRight,
    setLeftVC,
    setRightVC,
    serialNumberLeft,
    serialNumberRight,
    leftVC,
    rightVC,
  } = useContext(StaticFormContext);
  const { setMe, me, isDoctor } = useContext(UserContext);
  const featureFlag = useSelector((state) => state.featureFlagSlice.features);

  const [serialNumberType, setSerialNumberType] = useState(
    leftVC || rightVC ? "SN" : me.isDoctor ? "SN" : "ESN",
  );
  const [isPatientDefailsFormValid, setIsPatientDetailsFormValid] =
    useState(false);
  const [isImplantSNValid, setIsImplantSNValid] = useState(false);
  const [isImplantESNValid, setIsImplantESNValid] = useState(false);
  const [isDevicesValid, setIsDevicesValid] = useState(false);

  const [isSurgeryInformationValid, setIsSurgeryInformationValid] =
    useState(false);
  const [isSurgeryDetailFormValid, setIsSurgeryDetailFormValid] =
    useState(false);

  let isFeatureStatus = () =>
    featureFlag &&
    featureFlag.filter((feat) => feat.feature === "us_surgeon_dt")[0].status;
  let accountCountryUS =
    me?.userCountry === "US" && featureFlag && isFeatureStatus() === 1;

  const checkIfFormIsValid = () =>
    (!isDoctor() || isPatientDefailsFormValid) &&
    (serialNumberType === "ESN" ? isImplantESNValid : isImplantSNValid) &&
    (currentWizard === "gluteal" || isSurgeryDetailFormValid) &&
    isSurgeryInformationValid &&
    isDevicesValid;

  const checkFormIsValid = () =>
    preRegistration?.implantsInformation?.implant_type !== "" &&
    preRegistration?.implantsFor !== "" &&
    (serialNumberType === "ESN" ? isImplantESNValid : isImplantSNValid) &&
    (preRegistration?.implantsInformation?.implant_type === "B"
      ? checkValidTypeBreast()
      : true) &&
    (preRegistration?.isMiaValid ? isDevicesValid : true);

  const checkValidTypeBreast = () =>
    preRegistration?.implantPlacement !== 0 &&
    preRegistration?.incisionKind !== 0 &&
    preRegistration?.surgeryIndication !== 0;

  const checkInfoPatient = () =>
    preRegistration?.patientSummary.patientFirstName?.length > 0 &&
    preRegistration?.patientSummary.patientLastName?.length > 0 &&
    preRegistration?.patientSummary.patientEmail?.length > 0;

  const handleSerialNumberTypeChange = ({ target: { value } }) => {
    const isESN = value === "ESN";
    setMe((prev) => ({ ...prev, isESN, isQ: isESN }));
    setSerialNumberType(value);
    if (value) {
      dispatch(
        preRegistrationInformation({
          serialNumberType: value,
          isESN: isESN,
          implantsInformation: {
            implant_type: preRegistration?.implantsInformation?.implant_type,
            implantL: "",
            implantR: "",
            validationL: "",
            validationR: "",
          },
          implantsValid: {
            implantL: "",
            implantR: "",
          },
          isMiaValid: false,
          isMiaImplants: false,
          implantPlacement: 0,
          incisionKind: 0,
          surgeryIndication: 0,
        }),
      );
    }
  };

  useEffect(
    () => {
      let valid = isDoctor()
        ? checkFormIsValid() && checkInfoPatient()
        : checkFormIsValid();
      setIsValid(valid);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      serialNumberType,
      isImplantSNValid,
      isImplantESNValid,
      isDevicesValid,
      preRegistration,
    ],
  );

  useEffect(
    () => {
      dispatch(
        preRegistrationInformation({
          ...preRegistration,
          isQ: leftVC || rightVC ? 0 : 1,
          implantsInformation: {
            ...preRegistration.implantsInformation,
            implantL: serialNumberLeft,
            implantR: serialNumberRight,
            validationL: leftVC,
            validationR: rightVC,
            tools: {
              injector:
                preRegistration.implantsInformation?.tools?.injector &&
                !preRegistration?.isMiaImplants
                  ? null
                  : preRegistration.implantsInformation?.tools?.injector,
              balloon:
                preRegistration.implantsInformation?.tools?.balloon &&
                !preRegistration?.isMiaImplants
                  ? null
                  : preRegistration.implantsInformation?.tools?.balloon,
            },
          },
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serialNumberLeft, serialNumberRight, leftVC, rightVC],
  );

  useEffect(() => {
    setSerialNumberLeft(preRegistration?.implantsInformation?.implantL ?? "");
    setSerialNumberRight(preRegistration?.implantsInformation?.implantR ?? "");
    setLeftVC(preRegistration?.implantsInformation?.validationL ?? "");
    setRightVC(preRegistration?.implantsInformation?.validationR ?? "");
  }, [me]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      preRegistration?.implantsValid?.implantL === true ||
      preRegistration?.implantsValid?.implantR === true
    ) {
      dispatch(
        preRegistrationInformation({
          isMiaValid: true,
        }),
      );
    } else {
      dispatch(
        preRegistrationInformation({
          isMiaValid: false,
        }),
      );
    }
  }, [
    preRegistration?.implantsValid?.implantL,
    preRegistration?.implantsValid?.implantR,
  ]);

  useEffect(() => {
    async function setSurgeonInformationPre() {
      await delay(1000);
      dispatch(
        preRegistrationInformation({
          implantPlacement: preRegistration?.implantPlacement,
          incisionKind: preRegistration?.incisionKind,
          surgeryIndication: preRegistration?.surgeryIndication,
          implantsInformation: {
            ...preRegistration.implantsInformation,
            tools: {
              injector:
                preRegistration.preRegistrationId === null
                  ? null
                  : preRegistration.implantsInformation?.tools?.injector,
              balloon:
                preRegistration.preRegistrationId === null
                  ? null
                  : preRegistration.implantsInformation?.tools?.balloon,
            },
          },
        }),
      );
    }

    setSurgeonInformationPre();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} order={accountCountryUS ? 3 : 1}>
          {isDoctor() && (
            <PatientDetailsForm setIsValid={setIsPatientDetailsFormValid} />
          )}
        </Grid>

        <Grid item xs={12} md={12} order={accountCountryUS ? 1 : 2}>
          <ImplantDetailsForm />
          <br />
          <div className="registration-type">
            <h3>{t("PreRegistration.RegisterWithSerialNumberType")}</h3>
            <label htmlFor="serialNumberType" className="listtitle">
              <p>{"SN"}</p>
              <input
                style={{ width: "50px" }}
                type="radio"
                id="preRegistration-implantTypeSN"
                name="serialNumberType"
                value={"SN"}
                checked={serialNumberType === "SN"}
                onChange={handleSerialNumberTypeChange}
              />
              <p>{"ESN"}</p>
              <input
                style={{ width: "50px" }}
                type="radio"
                id="preRegistration-implantTypeESN"
                name="serialNumberType"
                value={"ESN"}
                checked={serialNumberType === "ESN"}
                onChange={handleSerialNumberTypeChange}
              />
            </label>
          </div>
          <br />
          {serialNumberType === "SN" ? (
            <ImplantsFormSN setIsValid={setIsImplantSNValid} showValid={true} />
          ) : (
            serialNumberType === "ESN" && (
              <ImplantsFormESN
                setIsValid={setIsImplantESNValid}
                showCardClue={false}
                showValid={true}
              />
            )
          )}
          {preRegistration.isMiaImplants && (
            <DevicesForm setIsValid={setIsDevicesValid} />
          )}
        </Grid>

        <Grid item xs={12} md={12} order={accountCountryUS ? 2 : 3}>
          <div className="surgery-information">
            <h3>
              {t(
                `${preRegistration.isMiaImplants ? "Procedure" : "Surgery"}-Information.Title`,
              )}
            </h3>
            {(preRegistration?.implantsInformation?.implant_type === "B"
              ? "breast"
              : "gluteal" === "breast") && <SurgeryDetailsForm />}

            <PreRegisterSurgeryInformationForm
              setIsValid={setIsSurgeryInformationValid}
              disabled
            />
          </div>
        </Grid>

        <Grid item xs={12} order={5}>
          <RequiredText />
        </Grid>
      </Grid>
    </>
  );
}
