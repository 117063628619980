import React, {useContext, useEffect, useState} from "react";
import LinkBack from "../../components/library/Link/LinkBack";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../contexts/UserContext";
import {StaticFormContext} from "../../contexts/StaticFormContext";
import {useTranslation} from "react-i18next";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import "./FullRegistration.scss";
import Registration from "../../components/library/Registration.jsx";
import PreRegistrationDetails from "../../components/library/PreRegistrationDetails/PreRegistrationDetails";
import ButtonFilled from "../../components/library/Button/ButtonFilled.jsx";
import FullRegistrationForm from "../../components/library/Form/FullRegistrationForm.jsx";
import RejectConfirmationModal from "../../components/library/PopUpModules/RejectConfirmationModal";
import PreRegistrationConfirmationModal from "../../components/library/PopUpModules/PreRegistrationConfirmationModal";
import {useDispatch, useSelector} from "react-redux";
import LinkAction from "../../components/library/Link/LinkAction";
import {AlertMessage} from "../../components/library/AlertMessage";
import CreateTicketModule from "../../components/library/PopUpModules/CreateTicketModule";

import GigyaKit from "../../data/GigyaKit";
import {cleanPreRegistrationInformation} from "../../store/slices/preRegisterSlice";
import {openErrorDialog, setAlert} from "../../store/slices/uiSlice";
import {setSupportModalOn} from "../../store/slices/supportSlice";
import {Helmet} from "react-helmet-async";
import {isEmpty} from "lodash";
import EstaAPIKit from "../../data/EstaAPIKit";
import {Box} from "@mui/material";
import {AccountContext} from "../../contexts/AccountContext";


export default function FullRegistration() {
  const gigyaKit = new GigyaKit();
  const estaAPIKit = new EstaAPIKit();
  const dispatch = useDispatch();
  const {
    preRegisterMeDevicesBreastPatient,
    preRegisterMeDevicesBreastDoctor,
    preRegisterReject,
    me,
    setMe,
    isDoctor,
    loadPreregistrationInformation,
    updateMeWarranty,
  } = useContext(UserContext);

  const {accountInfo} = useContext(AccountContext);

  const preRegistration = useSelector((state) => state.preRegisterSlice);
  let isMia =
    !isEmpty(preRegistration?.implantsInformation?.tools?.injector) &&
    !isEmpty(preRegistration?.implantsInformation?.tools?.balloon);

  const [isValid, setIsValid] = useState(false);
  const [useEditable, setUseEditable] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);

  const {modal, modal_type} = useSelector((state) => state.supportSlice);

  const [
    showPreRegistrationConfirmationModal,
    setShowPreRegistrationConfirmationModal,
  ] = useState(false);
  const featureFlag = useSelector((state) => state.featureFlagSlice.features);

  const {
    goToStepAfterRegistration,
    shouldRenderLeftForm,
    shouldRenderRightForm,
    setRegisterImplantsFor,
  } = useContext(StaticFormContext);

  const navigate = useNavigate();

  const {t} = useTranslation();

  let isFeatureStatus = () =>
    featureFlag &&
    featureFlag.filter((feat) => feat.feature === "us_surgeon_dt")[0].status;


  let accountCountryUS =
    me?.userCountry === "US" && featureFlag && isFeatureStatus() === 1;

  const handleOnClick = async () => {
    if (isDoctor()) {
      // Request the password first in US
      if (accountCountryUS) {
        await gigyaKit.requestPasswordConfirmation();
      }

      finishPreRegistration();
    } else {
      finishRegistration();
    }
  };

  const preRegisterMiaComplete = () => {
    let payload = {
      preRegistrationId: preRegistration?.preRegistrationId,
      leftImplant: preRegistration?.implantsInformation?.implantL,
      rightImplant: preRegistration?.implantsInformation?.implantR,
      injector: preRegistration?.implantsInformation?.tools?.injector,
      balloon: preRegistration?.implantsInformation?.tools?.balloon,
    };
    estaAPIKit
      .preRegisterMiaComplete(payload)
      .then((data) => {
        dispatch(cleanPreRegistrationInformation());
        updateMeWarranty({});
        navigate("/my-implants");
      })
      .catch((data) => {
        dispatch(
          openErrorDialog({
            errorDialog: {
              open: true,
            },
          }),
        );
      });
  };

  const handleRejection = () => {
    preRegisterReject().then(() => {
      navigate("/my-implants");
    });
  };

  const createTicket = () => {
    dispatch(setSupportModalOn("fullForm"));
    window.scrollTo(0, 0);
  };

  const finishPreRegistration = () => {
    preRegisterMeDevicesBreastDoctor()
      .then(() => {
        setShowPreRegistrationConfirmationModal(true)
        dispatch(
          setAlert({
            alertMessage: {
              open: true,
              message: "Implantation Success",
              success: "success",
            },
          }),
        );
      })
      .catch((data) => {
        dispatch(
          openErrorDialog({
            errorDialog: {
              open: true,
            },
          }),
        );
      })
      .finally(() => dispatch(cleanPreRegistrationInformation()));
  };

  const finishRegistration = () => {
    gigyaKit
      .showRegisterConsentScreenSet(accountInfo?.profile?.country)
      .then(() => preRegisterMeDevicesBreastPatient())
      .then((data) => goToStepAfterRegistration(data))
      .catch((error) => {
        dispatch(
          openErrorDialog({
            errorDialog: {
              open: true,
            },
          }),
        );
      });
  };

  const showEditable = () => isDoctor() || useEditable;

  useEffect(() => {
    setRegisterImplantsFor("both");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      me?.notifications?.preregistrations &&
      me?.notifications?.preregistrations.length >= 0
    ) {
      loadPreregistrationInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.notifications?.preregistrations]);

  useEffect(() => {
    setMe((prev) => ({...prev, isESN: true}));
  }, []);

  const validButton = () => {
    let valid = true;
    if (
      (isValid &&
        preRegistration?.implantsValid?.implantL === true &&
        preRegistration?.implantsValid?.implantR === false) ||
      (preRegistration?.implantsValid?.implantL === false &&
        preRegistration?.implantsValid?.implantR === true)
    ) {
      valid = true;
    } else if (isValid) {
      valid = false;
    }
    return valid;
  };

  return (
    <>
      <Helmet>
        <title>Pre-Registration | MotivaImagine®</title>
        <meta name="pageType" content="PreRegistration Form"/>
      </Helmet>
      <Registration>


        <div className="pre-next-btns pre-next-btns-top">
          <LinkBack linkBack="/"/>
        </div>

        {showPreRegistrationConfirmationModal && !accountCountryUS && (
          <PreRegistrationConfirmationModal
            onClose={() => setShowPreRegistrationConfirmationModal(false)}
          />
        )}


        {modal && modal_type === "fullForm" && (
          <CreateTicketModule
            ticketForSN={!me.isESN}
            ticketForESN={me.isESN}
            leftSideImplant={shouldRenderLeftForm()}
            rightSideImplant={shouldRenderRightForm()}
          />
        )}

        {showRejectionModal && (
          <RejectConfirmationModal
            onClose={() => setShowRejectionModal(false)}
            onConfirm={() => {
              setShowRejectionModal(false);
              handleRejection();
            }}
          />
        )}
        <FiftyFiftyLayout
          className="full-registration"
          backgroundImgPath={
            isDoctor() && accountCountryUS
              ? "fifty-fifty-bg-3"
              : "fifty-fifty-bg-1"
          }
          title={
            isDoctor()
              ? accountCountryUS
                ? t("Device.Tracking.Title")
                : t("PreRegistration.Title")
              : t("CompletePreRegistration.Title")
          }
          subtitle={
            isDoctor()
              ? accountCountryUS
                ? t("My-Info.ReportAnImplant")
                : null
              : isMia
                ? t("CompletePreRegistrationMIA.Description")
                : t("CompletePreRegistration.Description")
          }
        >
          {!showEditable() && !isMia && (
            <div className="button-container">
              <LinkAction
                className="edit-button"
                onClick={() => setUseEditable(true)}
                icon="edit"
              />
            </div>
          )}


          {accountCountryUS ? (
            <Box mb={4}>
              <AlertMessage/>
            </Box>
          ) : null}


          {showEditable() ? (
            <FullRegistrationForm setIsValid={setIsValid}/>
          ) : (
            <PreRegistrationDetails/>
          )}

          <div className="fifty-fifty-layout__btn-container--middle">
            <LinkAction
              onClick={createTicket}
              label={t("Global.HavingTrouble")}
            />
          </div>
          {showEditable() ? (
            <div className="fifty-fifty-layout__btn-container">
              <ButtonFilled
                inline
                title={t("Global.Submit")}
                disabled={validButton()}
                onClick={handleOnClick}
              />
            </div>
          ) : (
            <div className="fifty-fifty-layout__btn-container centered-actions">
              {!isMia ? (
                <>
                  <ButtonFilled
                    inline
                    title={t("PreRegistration.Confirm")}
                    onClick={handleOnClick}
                  />
                  <LinkAction
                    onClick={() => setShowRejectionModal(true)}
                    label={t("PreRegistration.Reject")}
                  />
                </>
              ) : (
                <ButtonFilled
                  inline
                  title={t("Global.OK")}
                  onClick={() => preRegisterMiaComplete()}
                />
              )}
            </div>
          )}
        </FiftyFiftyLayout>
      </Registration>
    </>
  );
}
