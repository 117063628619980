import { createServer } from "miragejs";

export default function () {
  createServer({
    routes() {
      this.passthrough("https://api.locize.app/**");
      this.passthrough(
        "https://y9tb2yubfh.execute-api.eu-west-1.amazonaws.com/**"
      );
      // this.passthrough("https://*.gigya.com/**");
      //this.passthrough("https://api-dev.motiva.health/api/implants/**");
      this.urlPrefix = "https://api-dev.motiva.health";
      this.namespace = "api";
      this.get(
        "https://y9tb2yubfh.execute-api.eu-west-1.amazonaws.com/dev/clinics/patients/*",
        () => ({
          id: 23,
          master_id: "123e4567-e89b-12d3-a456-426614174000",
          gigya_id: "49ec0d4a11ad4d7eac215fc72eef8a43",
          first_name: "Andres",
          last_name: "Mora",
          email: "amoraz@establishmentlabs.com",
          phone: "77777778",
          birth_date: "1999-01-01 17:56:50",
          has_breast_implants: 1,
          has_motiva_implants: 1,
          surgery_date: "2021-01-04 17:56:50",
          additional_info_consent: 0,
          additional_info_hash: null,
          height: 155,
          height_unit: "cm",
          weight: 51,
          weight_unit: "kg",
          photos: [
            {
              id: 40,
              url: "https://app-cdn.media-lab.digital/clinic/patient-ba-gallery/8349bdf4-213e-463e-b774-1ccc7f84a3e4",
              degree: 90,
              months_after_surgery: 6,
              before_photo: 0,
            },
          ],
          gigya_data: {
            bodyType: "body-type-5",
            hasBreastImplantsSince: "2020-12-09",
            consideringImplants: false,
            profileCompleted: true,
            weight: 70,
            surgeonName: "Sforza kun",
            surgeryJourney: "starting",
            consideringImplantsSince: "Less than a month",
            hasBreastImplants: false,
            breastType: "breast-type-2",
            implantsBrand: "unknown",
            userType: "Clinic Admin",
            heightUnit: "cm",
            height: 156,
            weightUnit: "kg",
          },
          motiva_match: {
            match: "0",
            status: "completed",
            timestamp: "1623948570511",
            user: "49ec0d4a11ad4d7eac215fc72eef8a43",
            answers: [
              {
                title: "Consideration factors?",
                answers: [
                  {
                    key: "0",
                    text: "My cup is too small",
                  },
                  {
                    key: "3",
                    text: "I want more volume",
                  },
                ],
                key: "reason",
              },
              {
                title: "What’s the closest to your dream result?",
                answers: [
                  {
                    key: "0",
                    text: "Cleavage",
                  },
                  {
                    key: "1",
                    text: "Natural results",
                  },
                  {
                    key: "2",
                    text: "Softness and movement",
                  },
                  {
                    key: "5",
                    text: "Small/non-existent scars",
                  },
                ],
                key: "dream-results",
              },
              {
                title: "What is your ideal experience?",
                answers: [
                  {
                    key: "0",
                    text: "No general anaesthesia",
                  },
                  {
                    key: "1",
                    text: "A faster, less painful recovery",
                  },
                  {
                    key: "2",
                    text: "Free unlimited follow ups",
                  },
                  {
                    key: "3",
                    text: "An improved warranty",
                  },
                ],
                key: "experience",
              },
              {
                title: "How soon are you looking to schedule your surgery?",
                answers: [
                  {
                    key: "2",
                    text: "In the next 6 months",
                  },
                ],
                key: "schedule",
              },
              {
                title: "In which country would you like to have surgery?",
                answers: [
                  {
                    key: "CR",
                    text: "Costa Rica",
                  },
                ],
                key: "country",
              },
            ],
            country: "CR",
          },
          inspoboard: [
            {
              id: 145,
              url: "https://app-motiva-interactive.s3-eu-west-1.amazonaws.com/match/image-feed/214562aa-7ad2-45e1-9509-2afa2f5125b0",
              type: "BA-V",
              urlBefore:
                "https://app-motiva-interactive.s3-eu-west-1.amazonaws.com/match/image-feed/3b42ac68-04bb-4c9c-affa-2dba8275206f",
              implantType: "Ergonomix",
              volumeLeft: 360,
              volumeRight: 360,
              name: "Ergonomix",
              tags: ["Left: 360cc / Right: 360cc"],
            },
            {
              id: 151,
              url: "https://app-motiva-interactive.s3-eu-west-1.amazonaws.com/match/image-feed/192c4864-4548-481f-8a6d-8a3e8a52f7c1",
              type: "BA-V",
              urlBefore:
                "https://app-motiva-interactive.s3-eu-west-1.amazonaws.com/match/image-feed/6cc001a3-56ae-4033-a1d7-967fdafc0053",
              implantType: "Ergonomix",
              volumeLeft: 360,
              volumeRight: 360,
              name: "Ergonomix",
              tags: ["Left: 360cc / Right: 360cc"],
            },
            {
              id: 152,
              url: "https://app-motiva-interactive.s3-eu-west-1.amazonaws.com/match/image-feed/34931bd3-0fa6-41ca-9213-b480b3a85eff",
              type: "BA-V",
              urlBefore:
                "https://app-motiva-interactive.s3-eu-west-1.amazonaws.com/match/image-feed/c3ae2ee6-cfa5-4f08-aa9e-c4a49f0e41e8",
              implantType: "Ergonomix",
              volumeLeft: 320,
              volumeRight: 320,
              name: "Ergonomix",
              tags: ["Left: 320cc / Right: 320cc"],
            },
            {
              id: 153,
              url: "https://app-motiva-interactive.s3-eu-west-1.amazonaws.com/match/image-feed/cb752d1e-afb2-4acc-b22c-3c98ae27f37f",
              type: "BA-V",
              urlBefore:
                "https://app-motiva-interactive.s3-eu-west-1.amazonaws.com/match/image-feed/fbee95d9-f7f1-4e6e-b587-0bba71e0cef5",
              implantType: "Ergonomix",
              volumeLeft: 320,
              volumeRight: 320,
              name: "Ergonomix",
              tags: ["Left: 320cc / Right: 320cc"],
            },
          ],
        })
      );

      this.post("/validate", () => ({
        access_token:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGktZGV2Lm1vdGl2YS5oZWFsdGhcL2FwaVwvdmFsaWRhdGUiLCJpYXQiOjE2MTYwOTAwNTYsImV4cCI6MTYxNjA5MDY1NiwibmJmIjoxNjE2MDkwMDU2LCJqdGkiOiIzQVN4QWZxTTBqNjZBVkI1Iiwic3ViIjoyMjIwOTQsInBydiI6ImM3ZGVjMGZkY2I0YzhkMDJhNTBmZDc0NzdhODY5NDY1YWEyMDg2ZGUiLCJnaWciOiJkY2FlMWFmZmNiODQ0ZGFjOGRiODY4NjJmMDE5NGJhNyJ9.7uCJheVtX_0bOuW3xIJDJ3z2o-GfOvJB3WaeVrhXjkg",
        token_type: "bearer",
        expires_in: 1200,
      }));

      this.post("/validate/e-serial-number", () => ({
        result: {
          id: 49414,
          catalog_id: 918,
          program_id: 1,
          distributor_id: 27,
          lot: "14020074",
          serial: "14020074-01",
          electronic_serial: 986100000671226,
          apply_warranty: 1,
          sold_warranty: 1,
          is_q_inside: 1,
          is_tracked: 0,
          is_registered: 0,
          not_blocked: 1,
          status: "true",
          allow_ew: 0,
          imported_at: "2018-02-14 15:42:49",
          created_at: "2014-02-27 19:00:00",
          updated_at: "2017-03-22 10:51:42",
          position: "L",
          catalog: {
            id: 918,
            reference: "ERSF-375Q",
            base: 11.75,
            family_id: 7,
            texture_id: 1,
            type_id: 2,
            projection: 4.8,
            volume: 375,
            created_at: "2017-03-01 10:56:25",
            updated_at: "2017-03-01 10:56:25",
          },
        },
      }));

      this.post("/validate/serial-number-and-validation-code", () => ({
        result: {
          id: 49414,
          catalog_id: 918,
          program_id: 1,
          distributor_id: 27,
          lot: "14020074",
          serial: "14020074-01",
          electronic_serial: 986100000671226,
          apply_warranty: 1,
          sold_warranty: 1,
          is_q_inside: 1,
          is_tracked: 0,
          is_registered: 0,
          not_blocked: 1,
          status: "true",
          allow_ew: 0,
          imported_at: "2018-02-14 15:42:49",
          created_at: "2014-02-27 19:00:00",
          updated_at: "2017-03-22 10:51:42",
          position: "L",
          catalog: {
            id: 918,
            reference: "ERSF-375Q",
            base: 11.75,
            family_id: 7,
            texture_id: 1,
            type_id: 2,
            projection: 4.8,
            volume: 375,
            created_at: "2017-03-01 10:56:25",
            updated_at: "2017-03-01 10:56:25",
          },
        },
      }));

      this.post("/auth/refresh", () => ({
        access_token:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGktZGV2Lm1vdGl2YS5oZWFsdGhcL2FwaVwvYXV0aFwvcmVmcmVzaCIsImlhdCI6MTYxNjE2NzkzOSwiZXhwIjoxNjE2MTY5ODUzLCJuYmYiOjE2MTYxNjkyNTMsImp0aSI6Ikc0a2RYTldJU05vUmhnbkwiLCJzdWIiOjIyMjA5NCwicHJ2IjoiYzdkZWMwZmRjYjRjOGQwMmE1MGZkNzQ3N2E4Njk0NjVhYTIwODZkZSIsImdpZyI6ImRjYWUxYWZmY2I4NDRkYWM4ZGI4Njg2MmYwMTk0YmE3In0.Mw066SGuiOCHBuG_I1MQA0yub1u53yZRphlHTVzVcGo",
        token_type: "bearer",
        expires_in: 1200,
      }));
      this.get("/countries", () => ({
        Countries: [
          { id: 24, name: "Belgium", code: "BE" },
          { id: 157, name: "Costa Rica", code: "CR" },
          { id: 174, name: "United States", code: "US" },
          {
            id: 179,
            name: "South Georgia and the South Sandwich Islands",
            code: "GS",
          },
          { id: 190, name: "Brazil", code: "BR" },
        ],
      }));
      this.get("/implants/breasts/placement", () => [
        { id: 0, title: "Undefined", description: "" },
        { id: 1, title: "Subglandular", description: "" },
        { id: 2, title: "Partial sub-muscular/Dual Plane", description: "" },
        { id: 3, title: "Sub-fascial", description: "" },
        { id: 4, title: "Complete muscle coverage", description: "" },
      ]);
      this.get("/implants/breasts/surgery-indication", () => [
        { id: 0, title: "Undefined", description: "" },
        { id: 1, title: "Primary Augmentation", description: "" },
        { id: 2, title: "Revision Augmentation", description: "" },
        { id: 3, title: "Primary Reconstruction", description: "" },
        { id: 4, title: "Revision Reconstruction", description: "" },
      ]);
      this.get("/implants/breasts/incision-kinds", () => [
        { id: 0, title: "Undefined", description: "" },
        { id: 1, title: "Inframammary", description: "" },
        { id: 2, title: "Mastectomy Scar", description: "" },
        { id: 3, title: "Periareolar", description: "" },
        { id: 4, title: "Transaxillary", description: "" },
      ]);

      this.get("/stages", () => [
        { id: 1, title: "Awareness", description: "" },
        { id: 2, title: "Researching", description: "" },
        { id: 3, title: "Finding a Doctor", description: "" },
        { id: 4, title: "Saving Money", description: "" },
      ]);

      this.get("/doctors", () => [
        { id: 1824, firstName: "Marcela", lastName: "Gonzalez Malatesta" },
        { id: 2201, firstName: "Francesco", lastName: "Malatesta" },
        { id: 41605, firstName: "Doctor", lastName: "Test" },
        { id: 42626, firstName: "Doctor", lastName: "Test 2" },
      ]);

      this.post("/register/implants", () => ({ result: "Implant registered" }));
      this.post("/register/warranty", () => ({
        result: "Warranty registered",
      }));

      this.post("/pre-register", () => ({ ok: "ok" }));
      this.post("/pre-register/rejecting/:id", () => ({ ok: "ok" }));
      this.get("/pre-register/:id", () => ({
        patient_name: "Name",
        patient_lastname: "LastName",
        patient_email: "patient+1@test.com",
        patient_country: 157,
        surgery_date: "12/24/2021",
        implant_type: "B",
        country_id: 24,
        placement_id: 1,
        indication_id: 1,
        incision_id: 1,
        doctor_id: 0,
        doctor_name: "Doctor",
        is_Q: false,
        implantL: "11111111-11",
        validationL: "A",
        implantR: "11111111-12",
        validationR: "B",
        isScanned: true,
        lang: "es",
      }));

      this.get("/me", () => ({
        id: 222094,
        hasBreastImplants: true,
        hasMotiva: true,
        firstName: "Giancarlo",
        lastName: "gpalavicini@qantamedia.com",
        username: "null",
        email: "gpalavicini@qantamedia.com",
        currentStage: "",
        points: "null",
        doctor_id: 100,
        isDoctor: false,
        userType: 1,
        //notifications: { preregistrations: [{ id: 27 }] },
        notifications: { preregistrations: [] },
        canBuyExtendedWarranty: true,
      }));

      this.get("/me/implants/breast", () => ({
        left: [
          {
            id: 49608,
            name: "g palavicini",
            country: 24,
            dateOfSurgery: "2021-02-24 00:00:00",
            surgeryCountry: "Afghanistan",
            surgeonName: "AasduelaFuh AasduelaFuh",
            is_explanted: 1,
            isActive: 0,
            indication: 1,
            incisionKind: 4,
            implantPlacement: 1,
            serialNumber: "14020095-07",
            validationCode: "B",
            reference: "ERSD-265Q",
            type: "Ergonomix\u00ae Round",
            family: "Demi with Qid\u00ae",
            surface: "SILKSURFACE\u00ae",
            base: 11,
            projection: 3.8,
            volume: 265,
            extendedWarranty: {
              isActive: false,
              program: "5Y",
              coverage: 2500,
              currency: "EUR",
              startDate: "2021-02-24 00:00:00",
              endDate: "2022-02-24 00:00:00",
            },
          },
          {
            id: 49601,
            name: "g palavicini",
            country: 24,
            dateOfSurgery: "2021-03-02 00:00:00",
            surgeryCountry: "Costa Rica",
            surgeonName: "AasduelaFuh AasduelaFuh",
            is_explanted: 1,
            isActive: 0,
            indication: 1,
            incisionKind: 4,
            implantPlacement: 1,
            serialNumber: "14020094-06",
            validationCode: "5",
            reference: "ERSD-230Q",
            type: "Ergonomix\u00ae Round",
            family: "Demi with Qid\u00ae",
            surface: "SILKSURFACE\u00ae",
            base: 10.5,
            projection: 3.6,
            volume: 230,
            extendedWarranty: {
              isActive: false,
              program: "5Y",
              coverage: 2500,
              currency: "EUR",
              startDate: "2021-03-02 00:00:00",
              endDate: "2022-03-02 00:00:00",
            },
          },
          {
            id: 49600,
            name: "g palavicini",
            country: 24,
            dateOfSurgery: "2021-03-02 00:00:00",
            surgeryCountry: "Afghanistan",
            surgeonName: "AasduelaFuh AasduelaFuh",
            is_explanted: 1,
            isActive: 0,
            indication: 1,
            incisionKind: 4,
            implantPlacement: 1,
            serialNumber: "14020094-05",
            validationCode: "F",
            reference: "ERSD-230Q",
            type: "Ergonomix\u00ae Round",
            family: "Demi with Qid\u00ae",
            surface: "SILKSURFACE\u00ae",
            base: 10.5,
            projection: 3.6,
            volume: 230,
            extendedWarranty: {
              isActive: false,
              program: "5Y",
              coverage: 2500,
              currency: "EUR",
              startDate: "2021-03-02 00:00:00",
              endDate: "2022-03-02 00:00:00",
            },
          },
          {
            id: 49458,
            name: "g palavicini",
            country: 24,
            dateOfSurgery: "2021-03-05 00:00:00",
            surgeryCountry: "Costa Rica",
            surgeonName: "AasduelaFuh AasduelaFuh",
            is_explanted: 1,
            isActive: 0,
            indication: 1,
            incisionKind: 4,
            implantPlacement: 1,
            serialNumber: "14020078-06",
            validationCode: "D",
            reference: "ERSD-380Q",
            type: "Ergonomix\u00ae Round",
            family: "Demi with Qid\u00ae",
            surface: "SILKSURFACE\u00ae",
            base: 12.5,
            projection: 4.1,
            volume: 380,
            extendedWarranty: {
              isActive: false,
              program: "5Y",
              coverage: 2500,
              currency: "EUR",
              startDate: "2021-03-05 00:00:00",
              endDate: "2022-03-05 00:00:00",
            },
          },
          {
            id: 49414,
            name: "g palavicini",
            country: 24,
            dateOfSurgery: "2021-03-12 00:00:00",
            surgeryCountry: "South Georgia and the South Sandwich Islands",
            surgeonName: "AasduelaFuh AasduelaFuh",
            is_explanted: 1,
            isActive: 0,
            indication: 1,
            incisionKind: 4,
            implantPlacement: 1,
            serialNumber: "14020074-01",
            validationCode: "6",
            reference: "ERSF-375Q",
            type: "Ergonomix\u00ae Round",
            family: "Full with Qid\u00ae",
            surface: "SILKSURFACE\u00ae",
            base: 11.75,
            projection: 4.8,
            volume: 375,
            extendedWarranty: {
              isActive: false,
              program: "5Y",
              coverage: 2500,
              currency: "EUR",
              startDate: "2021-03-12 00:00:00",
              endDate: "2022-03-12 00:00:00",
            },
          },
          {
            id: 46805,
            name: "g palavicini",
            country: 24,
            dateOfSurgery: "2021-03-11 00:00:00",
            surgeryCountry: "South Georgia and the South Sandwich Islands",
            surgeonName: "AasduelaFuh AasduelaFuh",
            is_explanted: 1,
            isActive: 0,
            indication: 1,
            incisionKind: 4,
            implantPlacement: 1,
            serialNumber: "13120087-10",
            validationCode: "D",
            reference: "RVC-300+Q",
            type: "Round",
            family: "Corse with Qid\u00ae",
            surface: "VELVETSURFACE\u00ae PLUS",
            base: 10.25,
            projection: 4.9,
            volume: 300,
            extendedWarranty: {
              program: "5Y",
              coverage: 2500,
              currency: "EUR",
            },
          },
          {
            id: 49634,
            name: "g palavicini",
            country: 24,
            dateOfSurgery: "2021-03-12 00:00:00",
            surgeryCountry: "South Georgia and the South Sandwich Islands",
            surgeonName: "AasduelaFuh AasduelaFuh",
            is_explanted: 0,
            isActive: 1,
            indication: 1,
            incisionKind: 4,
            implantPlacement: 1,
            serialNumber: "14020098-01",
            validationCode: "E",
            reference: "ERSD-380Q",
            type: "Ergonomix\u00ae Round",
            family: "Demi with Qid\u00ae",
            surface: "SILKSURFACE\u00ae",
            base: 12.5,
            projection: 4.1,
            volume: 380,
            extendedWarranty: {
              isActive: true,
              program: "5Y",
              coverage: 2500,
              currency: "EUR",
              startDate: "2021-03-12 00:00:00",
              endDate: "2022-03-12 00:00:00",
            },
          },
        ],
        right: [],
      }));
      this.get("/me/implants/gluteal", () => ({ left: [], right: [] }));

      this.get("/warranty/details", () => ({
        currency: "EUR",
        priceUSD: 2.2,
        localPrice: 2,
        endDateWarranty: "2022-03-12 00:00:00",
        warrantyExtended: false,
        canBuyExtendedWarranty: true,
        country_id: 24,
        Implants: [
          {
            serial: "14020098-01",
            allow_ew: 0,
            start: "12-Mar-2021",
            end: "12-Mar-2022",
            is_extended: 0,
            active: true,
            implant_type: "B",
            side: "L",
            currency: "EUR",
            coverage: 2500,
            usd_price: 2.2,
            price: 2,
            period: 5,
            can_purchase: true,
          },
        ],
      }));
    },
  });
}
