import i18next from "i18next";
import jwt_decode from "jwt-decode";
import GigyaKit from "./GigyaKit";

export class TokenRequiredForOperationError extends Error {}
export class InvalidSessionStatusError extends Error {}

export default class {
  constructor() {
    this.ROOT_URL = process.env.REACT_APP_ESTA_API_URL;
    this.BEARER_KEY = process.env.REACT_APP_ESTA_BEARER_KEY;
    this.CLINICS_API_KEY = process.env.REACT_APP_CLINICS_API_TOKEN;
  }

  async api(method, uri, payload, options = {}) {
    const allowTokenRefresh = !options.noTokenRefresh;
    return Promise.resolve()
      .then(() => allowTokenRefresh && this.ensureToken())
      .then(() => this.getRequest(method, uri, payload, options))
      .then((request) => this.request(request));
  }

  async request({ url, ...request }) {
    return fetch(url, request);
  }

  async getVersion() {
    let options = {
      rootUrl: this.ROOT_URL.substring(0,this.ROOT_URL.length-4),
      noTokenRefresh: true
    }
    const uri = `/version/W`;
    return this.api("get", uri, '',options);
  }

  tokenData() {
    try {
      return jwt_decode(this.getBearer());
    } catch (e) {
      return {};
    }
  }

  tokenExpiration() {
    const data = this.tokenData();

    if ("exp" in data) {
      return new Date(data.exp * 1000);
    } else {
      return new Date();
    }
  }

  isTokenExpired() {
    return new Date() >= this.tokenExpiration();
  }

  async ensureToken() {
    if (!this.tokenRequestPromise) {
      this.tokenRequestPromise = Promise.resolve()
        .then(() => this.isTokenExpired() && this.refreshToken())
        .then(() => (this.tokenRequestPromise = null));
    }
    return this.tokenRequestPromise;
  }

  async refreshToken() {
    const uri = "/auth/refresh";
    const token = this.getBearer();
    if (!token) return Promise.reject(new TokenRequiredForOperationError());

    return this.api("post", uri, { token }, { noTokenRefresh: true })
      .then((res) => {
        if (res.status === 500) {
          return Promise.reject(new InvalidSessionStatusError());
        } else {
          return res.json();
        }
      })
      .then((res) => this.setBearerFromResponse(res));
  }

  async createTicket(payload) {
    const uri = "/create-ticket";
    return this.api("post", uri, payload);
  }

  async createAppointmentRequest(payload) {
    const uri = "/ask-motiva/appointment-request";
    return this.api("post", uri, payload);
  }

  async getMe() {
    const uri = `/me`;
    return this.api("get", uri);
  }

  async putMe(payload) {
    const uri = `/me`;
    return this.api("put", uri, payload);
  }

  async putSurgeryDetails(payload,id) {
    const uri = `/doctor/me/updateSurgeryDetails/${id}`;
    return this.api("put", uri, payload);
  }

  async getMeDevicesGluteal() {
    // const userEmail = this.gigyaKit.getStoredAccountEmail()
    const uri = `/me/devices/gluteal`;
    return this.api("get", uri);
  }

  async getMeDevicesBreast() {
    // const userEmail = this.gigyaKit.getStoredAccountEmail()
    const uri = `/me/devices/breast`;
    return this.api("get", uri);
  }

  async getMeSummaryDevices({statusSurgery, surgeryType, page}) {
    const uri = `/me/summary/devices?active=${statusSurgery}&surgeryType=${surgeryType}&page=${page}`;
    return this.api("get", uri);
  }

  async getWarrantyDetails() {
    const uri = `/warranty/details`;
    return this.api("get", uri);
  }

  async getBoughtWarrantyDetails(order) {
    // Test data, remove later
    // const uri = `/warranty/details/buy/6296629228`;
    const uri = `/warranty/details/buy/${order}`;
    return this.api("get", uri);
  }

  async getPatientFromClinic(id, master_id) {
    // const uri = `clinics/patients/${id}?master_id=${master_id}&scope=info`;
    const uri = `clinics/patients/${id}?master_id=${master_id}&scope=["info"]`;
    const options = await this.getClinicOptions();

    return this.api("get", uri, null, options);
  }

  async registerWarranty(payload) {
    const uri = `/register/warranty`;
    return this.api("post", uri, payload);
  }

  async registerMeDevicesBreast(payload) {
    const uri = "/register/devices";
    const response = await this.api("post", uri, await payload);
    const data = await response.json();
    return data;
  }

  async preRegister(payload) {
    const uri = "/pre-register";
    const result = await this.api("post", uri, await payload);
    return result;
  }

  async preRegisterMiaComplete(payload) {
    const uri = "/pre-register/complete";
    const result = await this.api("post", uri, await payload);
    return result;
  }

  async preRegisterReject(id) {
    const uri = `/pre-register/rejecting/${id}`;
    return this.api("post", uri, {});
  }

  async preRegistration(id) {
    const uri = `/pre-register/${id}`;
    return this.api("get", uri);
  }

  async getCountries() {
    const uri = "/countries";
    return this.api("get", uri);
  }

  async getIncisionKinds() {
    const uri = "/devices/breasts/incision-kinds";
    return this.api("get", uri);
  }

  async getSurgeryKinds() {
    const uri = "/devices/breasts/surgery-indication";
    return this.api("get", uri);
  }

  async getBreastsPlacement() {
    const uri = "/devices/breasts/placement";
    return this.api("get", uri);
  }

  async getClinics(query = "") {
    const uri = `/clinics?search=${query}`;
    return this.api("get", uri);
  }

  async getSurgeons() {
    const uri = `/doctors`;
    return this.api("get", uri);
  }

  async getFeatures() {
    let options = {
      noTokenRefresh: true
    }
    const uri = `/features`;
    return this.api("get", uri, '',options);
  }

  async getPatientInformation(email) {
    // Encode the email to handle special characters
    const encodedEmail = encodeURIComponent(email);
    const uri = `/user/info?email=${encodedEmail}`;

    return this.api("get", uri, );
  }

  async getStages() {
    const uri = "/stages";
    return this.api("get", uri);
  }

  async getDoctorMePatients(payload) {
    const uri = `/doctor/me/getPatientsByDoctor?total_items=${payload.total_items}&page=${payload.page}&search=${payload.search}`;
    const response = await this.api("get", uri);
    const data = await response.json();
    return data;
  }

  async getSurgeryDetails(payload) {
    const uri = `/doctor/me/getSurgeryDetails?surgery_id=${payload.surgery_id}`;
    const response = await  this.api("get", uri);
    const data = await response.json();
    return data;
  }

  async getDeviceInfo(serialNumber, is_Q) {
    const uri = "/device";
    const payload = {
      serialNumber: serialNumber,
      is_Q: is_Q,
    };
    return this.api("post", uri, payload);
  }

  async validateDevice(payload) {
    const uri = "/validate/device";
    const response = await this.api("post", uri, payload);
    const data = await response.json();
    const { result } = data;
    return result;
  }

  async extractText(payload) {
    const uri = "/extract-text";
    return this.api("post", uri, payload);
  }

  async extractSerialNumbers(payload) {
    const uri = "/extract-serials-numbers";
    return this.api("post", uri, payload);
  }

  async extractSerialNumberByScan(payload) {
    const uri = "/scanUDI";
    return this.api("post", uri, payload);
  }

  async disposalDevice(payload) {
    const uri = "/disposal/device";
    return this.api("post", uri, payload);
  }

  async explant(payload) {
    const uri = "/explant/device";
    return this.api("post", uri, payload);
  }

  async getErrorResponse(code) {
    const uri = `/warranty/purchase/errorCode?errorCode=${code}`;
    return this.api("get", uri);
  }



  async postUIDAndIdToken(idToken, uid, email) {
    var payload = new FormData();
    payload.append("UID", uid);
    payload.append("username", email);

    let url = `${this.ROOT_URL}/validate`;

    let headers = new Headers();
    headers.append("Authorization", `Bearer ${idToken}`);

    let request = {
      url,
      method: "POST",
      headers: headers,
      body: payload,
    };

    return this.request(request).then((response) => response.json());
  }

  setBearerFromResponse(response) {
    const bearer = response.access_token;
    this.setBearer(bearer);
  }

  setBearer(bearer) {
    this.token = bearer;
    localStorage.setItem(this.BEARER_KEY, bearer);
  }

  deleteBearer() {
    localStorage.removeItem(this.BEARER_KEY);
  }

  getBearer() {
    if (!this.token) this.token = localStorage.getItem(this.BEARER_KEY);

    return this.token;
  }

  async getGigyaToken() {
    const gigyaKit = new GigyaKit();

    return gigyaKit.getJWT();
  }

  async getClinicHeaders() {
    const bearer = ((await this.getGigyaToken()) || {}).id_token;
    return {
      Authorization: `Bearer ${bearer}`,
      "x-api-key": this.CLINICS_API_KEY,
    };
  }

  async getClinicOptions() {
    const rootUrl = process.env.REACT_APP_CLINICS_BASE_URL;
    const headers = await this.getClinicHeaders();

    return { rootUrl, headers };
  }

  async getRequest(method, uri, payload, options = {}) {
    const url = `${options.rootUrl || this.ROOT_URL}${uri}`;

    switch (method) {
      case "get":
        return this.getGetRequest(url, options);
      case "post":
        return this.getPostRequest(url, payload);
      case "put":
        return this.getPutRequest(url, payload);
      default:
        return null;
    }
  }

  getGetRequest(url, options = {}) {
    return {
      url,
      method: "GET",
      headers: Object.assign(
        {},
        this.getRequestHeaders(),
        options.headers || {}
      ),
    };
  }

  getPutRequest(url, payload) {
    return {
      url,
      method: "PUT",
      headers: this.getRequestHeaders(),
      body: JSON.stringify(payload),
    };
  }

  getPostRequest(url, payload) {
    return {
      url,
      method: "POST",
      headers: this.getRequestHeaders(),
      body: JSON.stringify(payload),
    };
  }

  getRequestHeaders() {
    return {
      Authorization: `Bearer ${this.getBearer()}`,
      'Accept':'application/json',
      'Content-Type':'application/json',
    };
  }

  getUserLanguage() {
    const lang =
      i18next.language ||
      window.chosenLanguage ||
      window.navigator.userLanguage ||
      window.navigator.language;

    return lang.substring(0, 2);
  }

  //the userFriendly flag says if error should be friendly for the user(true) or detailed (false)
  errorMapping(errCode, userFriendly = false) {
    switch (errCode) {
      case "200":
        return userFriendly
          ? "ImplantRegistrationError.UnableToRegister"
          : "IR: Unknown 200";
      case "201":
        return userFriendly
          ? "ImplantRegistrationError.IncorrectVC"
          : "IR:  L Implant vc is wrong";
      case "202":
        return userFriendly
          ? "ImplantRegistrationError.IncorrectVC"
          : "IR:  R Implant vc is wrong";
      case "203":
        return userFriendly
          ? "ImplantRegistrationError.ImplantIsRegistered"
          : "IR:  L Implant is registered";
      case "204":
        return userFriendly
          ? "ImplantRegistrationError.ImplantIsRegistered"
          : "IR:  R Implant is registered";
      case "205":
        return userFriendly
          ? "ImplantRegistrationError.ImplantNotExistent"
          : "IR:  L Implant does not exist";
      case "206":
        return userFriendly
          ? "ImplantRegistrationError.ImplantNotExistent"
          : "IR:  R Implant does not exist";
      case "207":
        return userFriendly
          ? "ImplantRegistrationError.ImplantBlocked"
          : "IR:  L Implant is blocked";
      case "208":
        return userFriendly
          ? "ImplantRegistrationError.ImplantBlocked"
          : "IR: R Implant is blocked";
      case "209":
        return userFriendly
          ? "ImplantRegistrationError.InconsistentType"
          : "IR:  Different sugery and implant";
      case "210":
        return userFriendly
          ? "ImplantRegistrationError.SameSN"
          : "IR:  Same serial number";
      default:
        return userFriendly
          ? "ImplantRegistrationError.UnableToRegister"
          : "IR:  Unknown default";
    }
  }
}
