import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FormDevice from "./FormDevice";
import { Grid } from "@mui/material";

const DeviceForm = ({
  title,
  typeDevice,
  validLength,
  setIsDeviceValid,
  handleValidation,
  setHandleValidation,
}) => {
  return (
    <>
      <div className={`pt-1 font-black d-inline mt-4 mb-3`}>
        <span className="pr-3">{title}</span>
      </div>
      <div className="form-wrapper-wrapper">
        <FormDevice
          typeDevice={typeDevice}
          validLength={validLength}
          setIsDeviceValid={setIsDeviceValid}
          handleValidation={handleValidation}
          setHandleValidation={setHandleValidation}
          required
        />
      </div>
    </>
  );
};

export const DevicesForm = ({ setIsValid }) => {
  const { t } = useTranslation();
  const [isDeviceInjectorValid, setIsDeviceInjectorValid] = useState(false);
  const [isDeviceBalloonValid, setIsDeviceBalloonValid] = useState(false);
  const [handleValidation, setHandleValidation] = useState(false);
  const preRegistration = useSelector((state) => state.preRegisterSlice);

  const preValidation = () => checkIfAllInputsContainValue();

  const checkIfAllInputsContainValue = () =>
    (preRegistration?.implantsInformation?.tools?.injector &&
      preRegistration?.implantsInformation?.tools?.injector.trim().length ===
        10) ||
    (preRegistration?.implantsInformation?.tools?.balloon &&
      preRegistration?.implantsInformation?.tools?.balloon.trim().length ===
        11);

  function isFormValid() {
    return (
      checkIfAllInputsContainValue() &&
      isDeviceInjectorValid &&
      isDeviceBalloonValid
    );
  }

  useEffect(() => {
    const readyForValidation = preValidation();
    setHandleValidation(readyForValidation);
  }, [
    preRegistration?.implantsInformation?.tools?.injector,
    preRegistration?.implantsInformation?.tools?.balloon,
  ]);

  useEffect(() => {
    setIsValid(isFormValid());
  }, [
    preRegistration?.implantsInformation?.tools?.injector,
    preRegistration?.implantsInformation?.tools?.balloon,
    isDeviceInjectorValid,
    isDeviceBalloonValid,
  ]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={5.8} sx={{ paddingLeft: "18px !important" }}>
        <DeviceForm
          title={t("Implant-Registration.Injector")}
          typeDevice="injector"
          validLength={10}
          setIsDeviceValid={setIsDeviceInjectorValid}
          handleValidation={handleValidation}
          setHandleValidation={setHandleValidation}
        />
      </Grid>
      <Grid item xs={12} md={5.5} sx={{ paddingLeft: "18px !important" }}>
        <DeviceForm
          title={t("Implant-Registration.Balloon")}
          typeDevice="balloon"
          validLength={11}
          setIsDeviceValid={setIsDeviceBalloonValid}
          handleValidation={handleValidation}
          setHandleValidation={setHandleValidation}
        />
      </Grid>
    </Grid>
  );
};
