import React, { useState, useEffect, useContext } from "react";
import AsyncSearchTypeahead from "./AsyncSearchTypeahead";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

export default function SurgeonSearch({
  handleOnSelectedItem,
  required,
  isValid,
  disabled = false,
  ...props
}) {

  const iu  = useSelector((state) => state.uiSlice);
  const [selectedItem, setSelectedItem] = useState({});
  const [itemList, setItemList] = useState([]);
  const [validated, setValidated] = useState(false);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    handleOnSelectedItem(selectedItem);
    if (isValid !== null) {
      isValid(selectedItem);
    }
  }, [selectedItem]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    Array.isArray(props.defaultSelected) &&
      props.defaultSelected.length > 0 &&
      updateSelection(props.defaultSelected);
  }, [props.defaultSelected]);

  useEffect(() => {
    if(!isEmpty(iu.doctors)){
      fetchList();
    }
  }, [iu.doctors]);

  function fetchList() {

    if (iu.doctors && iu.doctors.length > 0) {
      const items = iu.doctors.map((item, index) => {
        return {
          id: item.id,
          value: item.id,
          label: `${item.first_name} ${item.last_name}`,
        };
      });
      setItemList(items);
      if (iu.doctors && iu.doctors.length === 0) {
        returnNotFound();
      }
    } else {
      returnNotFound();
    }
  }

  function returnNotFound(query) {
    updateSelection(query);
  }

  function validate() {
    let response = true;

    setValidated(true);

    if (required && selectedItem.length === 0) response = false;

    setValid(response);
  }

  function showInvalid() {
    return validated && !valid;
  }
  function updateSelection(selection) {
    if (typeof selection === "string") {
      if (selection.trim().length >= 2) {
        setSelectedItem([{ id: 0, value: 0, label: selection }]);
      } else {
        setSelectedItem([]);
      }
    } else if (
      selection && selection.length > 0 &&
      "id" in selection[0] &&
      "value" in selection[0] &&
      "label" in selection[0]
    ) {
      setSelectedItem(selection);
    } else {
      setSelectedItem([]);
    }
  }
  return (
    <>
      <AsyncSearchTypeahead
        id="surgeon-search"
        disabled={disabled}
        isInvalid={showInvalid()}
        className={showInvalid() && "is-invalid"}
        fetchList={()=>{}}
        itemList={itemList}
        setItemList={setItemList}
        selectedItem={updateSelection}
        setSelectedItem={setSelectedItem}
        onFocus={() => setValidated(false)}
        onBlur={validate}
        onInputChange={updateSelection}
        handleOnSelectedItem={updateSelection}
        {...props}
      />
    </>
  );
}
