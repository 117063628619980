import React, {useEffect} from "react";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import {useTranslation, withTranslation} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import './LoginPage.scss'

import TagManager from "react-gtm-module";
import {LoginComponent} from "../../components/library/LoginComponent";
import {LoginSelection} from "../../components/library/LoginSelection";

const LoginDoctor = () => {

  const { t } = useTranslation();

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        location: '/',
        title: (document && document.title),
        pageType: 'Start Page',
        event: "pageView",
        lang: localStorage.getItem('i18nextLng')
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }, [])

  return (
    <>
      <Helmet>
        <title>MotivaImagine®</title>
        <meta name="pageType" content="Start Page"/>
      </Helmet>
      <LoginSelection />
      <FullPageLayout
        isPublic
        backgroundImgPath="homepage-bg-5"
        position="right"
        megaTitle={t("Global.Welcome")}
        description={t("Login-Page-Surgeon.Description")}
        buttonList={[]}
        loginPage
      >
        <LoginComponent profile="doctor"/>
      </FullPageLayout>
    </>
  );
}

export default withTranslation()(LoginDoctor);
