import React from "react"
import { Link } from "react-router-dom"
import { ChevronRight, ChevronLeft, Pencil } from "react-bootstrap-icons"
import "./LinkBtn.scss"

export default function LinkAction({
  to,
  label,
  className,
  disabled,
  onClick,
  back,
  icon,
  next,
}) {
  return (
    <Link
      className={className}
      to={disabled ? "#" : ( to || "#") }
      variant="primary"
      onClick={disabled ? () => {} : onClick}
    >
    {icon && icon === "edit" && <Pencil />}
	  { back && <ChevronLeft size="12" /> }
	  {label}
	  { next && <ChevronRight size="12" /> }
    </Link>
  )
}
