import "./DoctorSearchImplantPage.scss";

import { useTranslation } from "react-i18next";
import MaskedInput from "react-text-mask";
import React, { useState, useEffect } from "react";

import DateRenderer from "../../components/library/DateRenderer";
import EstaAPIKit from "../../data/EstaAPIKit";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import LinkSimple from "../../components/library/Link/LinkSimple";
import EnsureToken from "../../components/library/EnsureToken";
import { ReactComponent as CLIPBOARD } from "../../assets/Icon/clipboard-minus.svg";
import {Helmet} from 'react-helmet-async';

const DoctorSearchImplantPage = () => {
  const [serialNumberData, setSerialNumberData] = useState(null);
  const [serialNumber, setSerialNumber] = useState("");
  const [searchBySerialNumber, setSearchBySerialNumber] = useState(true);
  const [searchByESNumber, setSearchByESNumber] = useState(false);
  const [canShowErrorMessage, setCanShowErrorMessage] = useState(false);
  const [isClipboardActive, setIsClipboardActive] = useState(false);

  const { t } = useTranslation();
  const estaAPIKit = new EstaAPIKit();

  useEffect(() => {
    const allowFetch = shouldDoSearch();
    allowFetch && unFocusSearch();
    allowFetch &&
      estaAPIKit
        .getDeviceInfo(serialNumber, searchByESNumber)
        .then((res) => res.json())
        .then((data) => {
          setCanShowErrorMessage(true);
          setSerialNumberData(data);
        }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumber, searchBySerialNumber, searchByESNumber, t]);

  useEffect(() => {
    setSerialNumber("");
  }, [searchBySerialNumber, searchByESNumber]);

  const unFocusSearch = () => {
    document.getElementById("search-input").disabled = true;
    document.getElementById("search-input").disabled = false;
  };

  const errorMessage = (serverError) => {
    return serverError
      ? t(`SearchImplant.Error.${serverError.replace(/\s/g, "")}`)
      : "";
  };

  const shouldDoSearch = () =>
    (searchBySerialNumber && serialNumber.trim().length === 11) ||
    (searchByESNumber && serialNumber.trim().length === 15);

  const shouldRenderErrorMessage = () => {
    return (
      canShowErrorMessage &&
      serialNumber.length > 0 &&
      (searchBySerialNumber || searchByESNumber) &&
      serialNumberData?.error
    );
  };

  async function handleClipboard() {
    setIsClipboardActive(true);
    navigator.clipboard.writeText(await buildDataToExport());
    setTimeout(() => {
      setIsClipboardActive(false);
    }, 500);
  }

  const buildDataToExport = () => {
    return (
      `${t("Patient-List-Implant-Card.ImplantDetails")}\n\n` +
      `${"SN"}: ${serialNumberData?.serialNumber || "-"}\n` +
      `${"ESN"}: ${serialNumberData?.ESN || "-"}\n\n` +
      `${t("Patient-List-Implant-Card.Type")}: ${
        serialNumberData?.family || "-"
      }\n` +
      `${t("Patient-List-Implant-Card.Reference")}: ${
        serialNumberData?.reference || "-"
      }\n` +
      `${t("Patient-List-Implant-Card.Texture")}: ${
        serialNumberData?.texture || "-"
      }\n` +
      `${t("Patient-List-Implant-Card.Volume")}: ${
        serialNumberData?.volume || "-"
      } cc\n` +
      `${t("Patient-List-Implant-Card.Base")}: ${
        serialNumberData?.base || "-"
      } cm\n` +
      `${t("Patient-List-Implant-Card.Projection")}: ${
        serialNumberData?.projection || "-"
      } cm\n` +
      `${t("Patient-List-Implant-Card.Program")}: ${
        serialNumberData?.extendedWarranty?.program || "-"
      }\n` +
      `${t("Patient-List-Warranty-Info.ExtendedWarrantyInfo")}: ${
        serialNumberData?.extendedWarranty?.isActive ? t("Yes") : t("No")
      }\n` +
      `${t("Patient-List-Implant-Card.Status")}: ${
        serialNumberData?.extendedWarranty?.endDate
          ? serialNumberData?.extendedWarranty?.isActive
            ? t("Patient-List-Warranty-Info.Valid")
            : t("Patient-List-Warranty-Info.Expired")
          : "-"
      }\n` +
      `${t("Patient-List-Warranty-Info.StartDate")}: ${
        serialNumberData?.extendedWarranty?.startDate || "-"
      }\n` +
      `${t("Patient-List-Implant-Card.EndDate")}: ${
        serialNumberData?.extendedWarranty?.endDate || "-"
      }\n\n`
    );
  };

  const SNMask = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
  ];
  const ESNMask = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,

    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,

    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  return (
    <>
      <Helmet>
        <title>Search implant | MotivaImagine®</title>
        <meta name="pageType" content="Search Implant" />
      </Helmet>
      <EnsureToken>
        <FullPageLayout
          backgroundImgPath="homepage-bg-5"
          position="right"
          title={t("Search-Implant-Information")}
          buttonList={[]}
        >
          <div className="search-implant-page">
            <div className="search-implant-page__radio-btns">
              <label
                className="custom-label"
                onClick={() => {
                  setSearchBySerialNumber(true);
                  setSearchByESNumber(false);
                }}
              >
                {t("By-Serial-Number")}
                <input
                  type="radio"
                  checked={searchBySerialNumber}
                  name="by-serial-number"
                />
                <span className="checkmark"></span>
              </label>
              <label
                className="custom-label"
                onClick={() => {
                  setSearchBySerialNumber(false);
                  setSearchByESNumber(true);
                }}
              >
                {t("By-Electronic-Serial-Number")}
                <input
                  type="radio"
                  checked={searchByESNumber}
                  name="by-serial-number"
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="search-implant-page__searchbar">
              <MaskedInput
                mask={searchBySerialNumber ? SNMask : ESNMask}
                placeholderChar={"\u2000"}
                type="text"
                placeholder={t("Search-Here")}
                // className="form-control serial-input"
                id="search-input"
                name="search-input"
                onChange={(e) => {
                  setSerialNumberData(null);
                  setCanShowErrorMessage(false);
                  setSerialNumber(e.target.value);
                }}
                value={serialNumber}
              />
              {serialNumberData && (
                <CLIPBOARD
                  className="copy"
                  onClick={handleClipboard}
                  style={isClipboardActive ? { fill: "#6b2670" } : null}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={t("Patient-Copy-Details")}
                />
              )}
            </div>

            {shouldRenderErrorMessage() && (
              <div className="search-implant-page__implant-info-box">
                <div className="search-implant-page__implant-info-box__row">
                  <p className="error">{errorMessage(serialNumberData?.error)}</p>
                </div>
              </div>
            )}
            {(serialNumberData?.serialNumber || serialNumberData?.ESN) && (
              <div className="search-implant-page__implant-info-box">
                {serialNumberData?.ESN !== 0 && serialNumberData?.ESN !== null && (
                  <div className="search-implant-page__implant-info-box__row">
                    <p>
                      {t("Patient-List-Implant-Card.ElectronicSerialNumber")}:
                    </p>
                    <p>{serialNumberData?.ESN}</p>
                  </div>
                )}

                {serialNumberData?.serialNumber && (
                  <div className="search-implant-page__implant-info-box__row">
                    <p>{t("Patient-List-Implant-Card.SerialNumber")}:</p>
                    <p>{serialNumberData?.serialNumber}</p>
                  </div>
                )}

                {serialNumberData?.reference && (
                  <div className="search-implant-page__implant-info-box__row">
                    <p>{t("Patient-List-Implant-Card.Reference")}:</p>
                    <p>{serialNumberData?.reference}</p>
                  </div>
                )}

                {serialNumberData?.family && (
                  <div className="search-implant-page__implant-info-box__row">
                    <p>{t("Patient-List-Implant-Card.Type")}:</p>
                    <p>{serialNumberData?.family}</p>
                  </div>
                )}

                {serialNumberData?.projection && (
                  <div className="search-implant-page__implant-info-box__row">
                    <p>{t("Patient-List-Implant-Card.Projection")}:</p>
                    <p>{serialNumberData?.projection}</p>
                    <p>cm</p>
                  </div>
                )}

                {serialNumberData?.texture && (
                  <div className="search-implant-page__implant-info-box__row">
                    <p>{t("Patient-List-Implant-Card.Texture")}:</p>
                    <p>{serialNumberData?.texture || t("Global.NoData")}</p>
                  </div>
                )}

                {serialNumberData?.base && (
                  <div className="search-implant-page__implant-info-box__row">
                    <p>{t("Patient-List-Implant-Card.Base")}:</p>
                    <p>{serialNumberData?.base || t("Global.NoData")}</p>
                    <p>cm</p>
                  </div>
                )}

                {serialNumberData?.volume && (
                  <div className="search-implant-page__implant-info-box__row">
                    <p>{t("Patient-List-Implant-Card.Volume")}:</p>
                    <p>{serialNumberData?.volume}</p>
                    <p>cc</p>
                  </div>
                )}

                {serialNumberData?.extendedWarranty?.program && (
                  <div className="search-implant-page__implant-info-box__row mt-3">
                    <p>{t("Patient-List-Implant-Card.Program")}:</p>
                    <p>{serialNumberData?.extendedWarranty?.program}</p>
                  </div>
                )}

                {serialNumberData?.extendedWarranty?.isActive && (
                  <div className="search-implant-page__implant-info-box__row">
                    <p>{t("Patient-List-Warranty-Info.ExtendedWarrantyInfo")}:</p>
                    <p>
                      {serialNumberData?.extendedWarranty?.isActive
                        ? t("Yes")
                        : t("No")}
                    </p>
                  </div>
                )}
                {serialNumberData?.extendedWarranty?.endDate && (
                  <div className="search-implant-page__implant-info-box__row">
                    <p>{t("Patient-List-Implant-Card.Status")}:</p>
                    <p>
                      {serialNumberData?.extendedWarranty?.endDate
                        ? serialNumberData?.extendedWarranty.isActive
                          ? t("Patient-List-Warranty-Info.Valid")
                          : t("Patient-List-Warranty-Info.Expired")
                        : "-"}
                    </p>
                  </div>
                )}
                {serialNumberData?.extendedWarranty?.endDate && (
                  <div className="search-implant-page__implant-info-box__row">
                    <p>{t("Patient-List-Implant-Card.EndDate")}:</p>
                    <p>
                      <DateRenderer
                        date={serialNumberData?.extendedWarranty?.endDate}
                      />
                    </p>
                  </div>
                )}
              </div>
            )}

            {/* {eSerialNumberData?.result?.status === "true" &&
          searchByESNumber === true && (
            <div className="search-implant-page__implant-info-box">
              <div className="search-implant-page__implant-info-box__row">
                <p>{t("Patient-List-Implant-Card.SerialNumber")}:</p>
                <p>{eSerialNumberData?.result?.serial}</p>
              </div>

              <div className="search-implant-page__implant-info-box__row">
                <p>{t("Patient-List-Implant-Card.Reference")}:</p>
                <p>{eSerialNumberData?.result?.catalog?.reference}</p>
              </div>

              <div className="search-implant-page__implant-info-box__row">
                <p>{t("Patient-List-Implant-Card.Type")}:</p>
                <p>{eSerialNumberData?.result?.catalog?.type_id}</p>
              </div>

              <div className="search-implant-page__implant-info-box__row">
                <p>{t("Patient-List-Implant-Card.Projection")}:</p>
                <p>{eSerialNumberData?.result?.catalog?.projection}</p>
                <p>cm</p>
              </div>

              <div className="search-implant-page__implant-info-box__row">
                <p>{t("Patient-List-Implant-Card.Texture")}:</p>
                <p>{eSerialNumberData?.result?.catalog?.texture_id}</p>
              </div>

              <div className="search-implant-page__implant-info-box__row">
                <p>{t("Patient-List-Implant-Card.Base")}:</p>
                <p>{eSerialNumberData?.result?.catalog?.base}</p>
                <p>cm</p>
              </div>

              <div className="search-implant-page__implant-info-box__row">
                <p>{t("Patient-List-Implant-Card.Volume")}:</p>
                <p>{eSerialNumberData?.result?.catalog?.volume}</p>
                <p>cc</p>
              </div>
            </div>
          )} */}

            <div className="fifty-fifty-layout__btn-container">
              <LinkSimple back label={t("Global.Back")} to="/doctor" white />
            </div>
          </div>
        </FullPageLayout>
      </EnsureToken>
    </>
  );
};

export default DoctorSearchImplantPage;
