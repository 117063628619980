import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  modal: false,
  modal_type: "", //general or fullForm
  registrationTicketForm: {
    fullName: "",
    patientInitials: "",
    type: "",
    surgeryType: "",
    email: "",
    implantL: "",
    implantR: "",
    description: "",
    code_left: "",
    code_right: "",
    surgery_date: "",
    surgery_country: "",
    country_residence: "",
  }
}


export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setSupportModalOn: (state, action) => {
      return {
        ...state,
        modal: true,
        modal_type: action.payload
      }
    },
    setSupportModalOff: (state, action) => {
      return {
        ...state,
        modal: false,
        registrationTicketForm: initialState.registrationTicketForm
      }
    },
  },
});


// Action creators are generated for each case reducer function
export const {setSupportModalOn, setSupportModalOff} = supportSlice.actions
export default supportSlice.reducer
