import React from "react";
import ButtonFilled from "./ButtonFilled";
import { ChevronRight } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function ButtonNext(props) {
  const { t } = useTranslation();

  return (
    <>
      <ButtonFilled
        append={<ChevronRight size="12" />}
        title={t("Global.Next")}
        colorScheme="true"
        {...props}
      />
    </>
  );
}
