import React, {createContext, useContext, useEffect, useState} from "react";
import EstaAPIKit from "../data/EstaAPIKit";
import {AccountContext} from "./AccountContext";
import {useNavigate} from "react-router-dom";

export const StaticFormContext = createContext({});

export const StaticFormContextProvider = ({children}) => {
  const estaAPIKit = new EstaAPIKit();
  const navigate = useNavigate();
  const {gigyaCredentialsLoaded} = useContext(AccountContext);
  const [hasStartedRegistration, setHasStartedRegistration] = useState(false);
  const [incisionKindList, setIncisionKindList] = useState(null);
  const [country, setCountry] = useState(null);
  const [patientFirstName, setPatientFirstName] = useState("");
  const [patientLastName, setPatientLastName] = useState("");
  const [patientInitials, setPatientInitials] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [patientCountry, setPatientCountry] = useState("");
  const [patientSSN, setPatientSSN] = useState("");
  const [patientPhone, setPatientPhone] = useState("");

  const [surgeryKindList, setSurgeryKindList] = useState(null);
  const [breastPlacementList, setBreastPlacementList] = useState(null);
  const [stageList, setStageList] = useState(null);
  const [imageSource, setImageSource] = useState(null);
  const [detectedLeftSerialNumber, setDetectedLeftSerialNumber] = useState("");
  const [detectedRightSerialNumber, setDetectedRightSerialNumber] =
    useState("");
  const [isDetected, setIsDetected] = useState(false);
  const [paymentValid, setPaymentValid] = useState(false);
  const [serialNumberLeft, setSerialNumberLeft] = useState("");
  const [serialNumberRight, setSerialNumberRight] = useState("");
  const [leftVC, setLeftVC] = useState("");
  const [rightVC, setRightVC] = useState("");
  const [isScanned, setIsScanned] = useState("");
  const [isSuccessScanned, setIsSuccessScanned] = useState(false);
  const [isSuccessNFC, setIsSuccessNFC] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [leftErrorDetail, setLeftErrorDetail] = useState("");
  const [rightErrorDetail, setRightErrorDetail] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [registerImplantsFor, setRegisterImplantsFor] = useState("");
  const [currentWizard, setCurrentWizard] = useState("breast");
  const [implantCardType, setImplantCardType] = useState(undefined);
  const [codePayment, setCodePayment] = useState(null);

  function resetImplantForm() {
    setSerialNumberLeft("");
    setSerialNumberRight("");
    setLeftVC("");
    setRightVC("");
    setIsScanned("");
    setMsgError("");
  }

  function clearFormContext() {
    setPatientFirstName("");
    setPatientLastName("");
    setPatientInitials("");
    setPatientEmail("");
    setPatientCountry("");
    setPatientSSN("");
    setPatientPhone("");
    setImageSource(null);
    setDetectedLeftSerialNumber("");
    setDetectedRightSerialNumber("");
    setIsDetected(false);
    setPaymentValid(false);
    setSerialNumberLeft("");
    setSerialNumberRight("");
    setLeftVC("");
    setRightVC("");
    setIsScanned("");
    setMsgError("");
    setLeftErrorDetail("");
    setRightErrorDetail("");
    setTransactionId("");
    setRegisterImplantsFor("both");
    setCurrentWizard("breast");

    setImplantCardType(undefined);
  }

  function preloadStaticFormOptions() {
    estaAPIKit
      .getIncisionKinds()
      .then((res) => res.json())
      .then((data) => setIncisionKindList(data))
      .catch((error) => console.log("Could not fetch incision kinds", error));

    estaAPIKit
      .getSurgeryKinds()
      .then((res) => res.json())
      .then((data) => setSurgeryKindList(data))
      .catch((error) => console.log("Could not fetch surgery kinds", error));

    estaAPIKit
      .getBreastsPlacement()
      .then((res) => res.json())
      .then((data) => {
        const newList = data.map((item, i) => {
          item.image = `./implant-placement-${i}.png`;
          return item;
        });
        setBreastPlacementList(newList);
      })
      .catch((error) =>
        console.log("Could not fetch breast placements", error)
      );

    estaAPIKit
      .getStages()
      .then((res) => res.json())
      .then((data) => setStageList(data))
      .catch((error) => console.log("Could not fetch stages", error));
  }

  function getImplantSideFromPreRegistrationPayload(payload) {
    return !!payload.implantL && !!payload.implantR
      ? "both"
      : !!payload.implantL
      ? "left"
      : "right";
  }

  function loadFormDataFromPreRegistrationPayload(payload) {
    setRegisterImplantsFor(getImplantSideFromPreRegistrationPayload(payload));
    setSerialNumberLeft(payload.implantL);
    setSerialNumberRight(payload.implantR);
    setLeftVC(payload.validationL);
    setRightVC(payload.validationR);
    setIsScanned(payload.isScanned);
    setCurrentWizard(payload.implant_type === "B" ? "breast" : "gluteal");
  }

  function loadPatientFromClinic(id, master_id, getCountryByCode) {
    estaAPIKit
      .getPatientFromClinic(id, master_id)
      .then((data) => data.json())
      .then((data) => {
        const patient_info = data?.message?.info;
        if (patient_info) {
          patient_info?.first_name &&
            setPatientFirstName(patient_info.first_name);
          patient_info?.last_name && setPatientLastName(patient_info.last_name);
          patient_info?.last_name &&
            patient_info?.first_name &&
            setPatientInitials(
              `${patient_info.first_name.charAt(
                0
              )}${patient_info.last_name.charAt(0)}`
            );
          patient_info?.email && setPatientEmail(patient_info.email);
          patient_info?.country &&
            setPatientCountry([getCountryByCode(patient_info.country)]);
        }
      });
  }

  function shouldRenderRightForm() {
    return typeof registerImplantsFor === "string" &&  registerImplantsFor === "right" || registerImplantsFor === "both";
  }

  function shouldRenderLeftForm() {
    return typeof registerImplantsFor === "string" && registerImplantsFor === "left" || registerImplantsFor === "both";
  }

  function goToStepAfterRegistration(data) {
    if (data.canBuyExtendedWarranty && data.implantsRegisteredApplyWarranty) {
      navigate("/warranty");
    } else if (data.autoExtendedWarranty) {
      navigate('/registration/warranty-extended'); //one time
    } else {
      navigate("/registration/success");
    }
  }

  const isImplantCardTypeValid = () => implantCardType !== undefined;

  useEffect(() => {
    if (gigyaCredentialsLoaded) {
      preloadStaticFormOptions();
    }
  }, [gigyaCredentialsLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (patientCountry) {
      setCountry(patientCountry[0]?.id);
    } else {
      setCountry(null);
    }
  }, [patientCountry]);

  return (
    <StaticFormContext.Provider
      value={{
        patientInitials,
        patientFirstName,
        patientLastName,
        patientEmail,
        patientCountry,
        patientSSN,
        patientPhone,
        country,
        setPatientFirstName,
        setPatientLastName,
        setPatientInitials,
        setPatientEmail,
        setPatientCountry,
        setPatientSSN,
        setPatientPhone,
        incisionKindList,
        setIncisionKindList,
        surgeryKindList,
        setSurgeryKindList,
        breastPlacementList,
        setBreastPlacementList,
        stageList,
        setStageList,
        imageSource,
        setImageSource,
        detectedLeftSerialNumber,
        setDetectedLeftSerialNumber,
        detectedRightSerialNumber,
        setDetectedRightSerialNumber,
        isDetected,
        setIsDetected,
        paymentValid,
        setPaymentValid,
        serialNumberLeft,
        setSerialNumberLeft,
        serialNumberRight,
        setSerialNumberRight,
        leftVC,
        setLeftVC,
        rightVC,
        setRightVC,
        isScanned,
        setIsScanned,
        setIsSuccessScanned,
        isSuccessScanned,
        msgError,
        leftErrorDetail,
        setLeftErrorDetail,
        rightErrorDetail,
        setRightErrorDetail,
        setMsgError,
        transactionId,
        setTransactionId,
        resetImplantForm,
        implantCardType,
        setImplantCardType,
        isImplantCardTypeValid,
        registerImplantsFor,
        setRegisterImplantsFor,
        shouldRenderLeftForm,
        shouldRenderRightForm,
        currentWizard,
        setCurrentWizard,
        hasStartedRegistration,
        setHasStartedRegistration,
        preloadStaticFormOptions,
        loadPatientFromClinic,
        loadFormDataFromPreRegistrationPayload,
        goToStepAfterRegistration,
        clearFormContext,
        setCodePayment,
        codePayment,
        setIsSuccessNFC,
        isSuccessNFC
      }}
    >
      {children}
    </StaticFormContext.Provider>
  );
};
