import React, { useContext, useEffect, useState } from "react";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { SerialNumber } from "../../components/library/FormComponents/SerialComponent";
import "./style.module.scss";
import LinkAction from "../../components/library/Link/LinkAction";
import { setSupportModalOn } from "../../store/slices/supportSlice";
import { useDispatch, useSelector } from "react-redux";
import CreateTicketModule from "../../components/library/PopUpModules/CreateTicketModule";
import DatePicker from "../../components/library/DatePicker/Datepicker";
import ButtonFilled from "../../components/library/Button/ButtonFilled";
import * as Yup from "yup";
import LinkBack from "../../components/library/Link/LinkBack";
import EstaAPIKit from "../../data/EstaAPIKit";
import { UserContext } from "../../contexts/UserContext";
import { AlertMessage } from "../../components/library/AlertMessage";
import { setAlert } from "../../store/slices/uiSlice";
import { RadioButton } from "../../components/library/FormComponents/RadioButton";
import { InputComponent } from "../../components/library/FormComponents/Input";
import { DatePickerComponent } from "../../components/library/FormComponents/DatePicker";
import { TextAreaComponent } from "../../components/library/FormComponents/TextArea";
import { format } from "date-fns";
import GigyaKit from "../../data/GigyaKit";
import { RequiredText } from "../../components/library/Form/RequiredText";

export const DisposalInformation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gigyaKit = new GigyaKit();
  const estaApiKit = new EstaAPIKit();
  const [serialNumberType, setSerialNumberType] = useState("SN");
  const [reset, setReset] = useState(false);

  const { me } = useContext(UserContext);

  const { modal, modal_type } = useSelector((state) => state.supportSlice);

  const serialFormComponent = (formik, t, side) => {
    let SNSide = `serial.SN${side}`;
    let VCSide = `serial.VC${side}`;

    return (
      <Grid container>
        <Grid item xs={12} md={5} display="flex" alignItems="center">
          <Typography variant="body1" fontSize={16} fontWeight={300}>
            {formik.values.typeSerialNumber === "SN"
              ? t("Implant-Registration.SerialNumber", { lng: "en" })
              : t("Implant-Registration.ESerialNumber", { lng: "en" })}
            *
          </Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          {formik.values.typeSerialNumber === "SN" ? (
            <SerialNumber
              SN
              serial={SNSide}
              vc={VCSide}
              side={side}
              formik={formik}
            />
          ) : (
            <SerialNumber serial={SNSide} side={side} formik={formik} />
          )}
        </Grid>
      </Grid>
    );
  };

  const disposalForm = useFormik({
    initialValues: {
      typeOfImplant: "Breast",
      typeSerialNumber: "SN",
      disposalDate: new Date(),
      serial: {
        SNLeft: "",
        VCLeft: "",
        isValidLeft: false,
      },
      disposalReason: "",
    },
    validationSchema: Yup.object({
      disposalDate: Yup.string().required(t("Required.Field", { lng: "en" })),
      disposalReason: Yup.string().required(t("Required.Field", { lng: "en" })),
    }),
    onSubmit: async (values) => {
      // Request the password first
      await gigyaKit.requestPasswordConfirmation();

      let payload = {
        source: 0,
        typeOfImplant: "B",
        disposalDate: format(new Date(values.disposalDate), "MM/dd/yyyy"),
        serialNumber: values.serial.SNLeft,
        validationCode: values.serial.VCLeft,
        disposalReason: values.disposalReason,
        user: {
          email: me.email,
        },
      };

      estaApiKit
        .disposalDevice(payload)
        .then((response) => response.json())
        .then((data) => {
          if (
            (data && data.message.error_code === 400) ||
            data.message.error_code === 404
          ) {
            dispatch(
              setAlert({
                alertMessage: {
                  open: true,
                  message: data.message.error_message,
                  success: "error",
                },
              }),
            );
          } else if (data && data.message.error_code === 409) {
            dispatch(
              setAlert({
                alertMessage: {
                  open: true,
                  message: data.message.error_message,
                  success: "warning",
                },
              }),
            );
          } else if (data && data.status === 200) {
            dispatch(
              setAlert({
                alertMessage: {
                  open: true,
                  message: data?.message,
                  success: "success",
                },
              }),
            );
            resetForm();
          }
        });
    },
  });

  const handleSerialNumberTypeChange = ({ target: { value } }) => {
    setSerialNumberType(value);
  };

  const createTicket = () => {
    dispatch(setSupportModalOn("general"));
    window.scrollTo(0, 0);
  };

  function handleDateSelected(selectedDate) {
    disposalForm.setFieldValue(
      "disposalDate",
      format(new Date(selectedDate), "MM/dd/yyyy"),
    );
  }

  const resetForm = () => {
    setReset(true);
    setSerialNumberType("SN");
    disposalForm.resetForm();
    setReset(false);
  };

  const isValidFieldsSerial = () => {
    const value = disposalForm.values.serial.isValidLeft;
    return !value;
  };

  const optionsSerialType = [{ label: "SN" }, { label: "ESN" }];

  return (
    <>
      <Helmet>
        <title>Disposal information | MotivaImagine®</title>
        <meta name="pageType" content="Disposal information Form" />
      </Helmet>
      <FiftyFiftyLayout
        backgroundImgPath="fifty-fifty-bg-5"
        title={t("Device.Tracking.Title")}
        subtitle={t("My-Info.ReportADisposal")}
        USTracking={true}
      >
        <div className="pre-next-btns pre-next-btns-top">
          <LinkBack linkBack="/" />
        </div>
        <Box
          sx={{ width: { xs: "calc(100% - 50px)", lg: "calc(100% - 300px)" } }}
        >
          <form onSubmit={disposalForm.handleSubmit}>
            {modal && modal_type === "general" && (
              <CreateTicketModule general />
            )}
            <Box mt={6}>
              <Box mb={4}>
                <AlertMessage />
              </Box>

              <Box>
                <InputComponent
                  formik={disposalForm}
                  title={t("Device.Tracking.ImplantTypeLabel")}
                  name="typeOfImplant"
                  isDisabled={true}
                />
              </Box>

              <Box mt={2}>
                <RadioButton
                  formik={disposalForm}
                  title={t("Device.Tracking.RegisterDisposalWith")}
                  name="typeSerialNumber"
                  options={optionsSerialType}
                />
              </Box>

              <Box mt={2}>{serialFormComponent(disposalForm, t, "Left")}</Box>

              <Box mt={2}>
                <DatePickerComponent
                  formik={disposalForm}
                  title={t("Device.Tracking.DisposalDate")}
                  name="disposalDate"
                  onChange={handleDateSelected}
                  reset={false}
                  required
                />
              </Box>

              <Box mt={1}>
                <TextAreaComponent
                  formik={disposalForm}
                  title={t("Device.Tracking.DisposalReason")}
                  name="disposalReason"
                  rows={6}
                  required
                />
              </Box>

              <RequiredText />

              <Box mt={8} display="flex" justifyContent="center">
                <LinkAction
                  onClick={() => createTicket()}
                  label={t("Global.HavingTrouble", { lng: "en" })}
                />
              </Box>

              <Box my={8} display="flex" justifyContent="center">
                <ButtonFilled
                  inline
                  size={"sm"}
                  type="submit"
                  title={t("Global.Submit", { lng: "en" })}
                  disabled={isValidFieldsSerial()}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </FiftyFiftyLayout>
    </>
  );
};
