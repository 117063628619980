import {configureStore} from '@reduxjs/toolkit';
import uiSlice from "./slices/uiSlice";
import preRegisterSlice from './slices/preRegisterSlice';
import registrationSlice from './slices/registrationSlice';
import supportSlice from "./slices/supportSlice";
import featureFlagSlice from "./slices/featureFlagSlice";
import explantSlice from "./slices/explantSlice";

export const store = configureStore({
    reducer: {
      uiSlice,
      preRegisterSlice,
      registrationSlice,
      supportSlice,
      featureFlagSlice,
      explantSlice
    },
});
