import React, { createContext, useEffect, useState } from "react";
import GigyaKit from "../data/GigyaKit";

import EstaAPIKit, {
  InvalidSessionStatusError,
  TokenRequiredForOperationError,
} from "../data/EstaAPIKit";
import { useNavigate } from "react-router-dom";

export const AccountContext = createContext({});

export const AccountContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const [gigyaCredentialsLoaded, setGigyaCredentialsLoaded] = useState(false);
  const [accountInfo, setAccountInfo] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isAccountInfoLoading, setIsAccountInfoLoading] = useState(true);
  const [idToken, setIdToken] = useState(null);
  const [accountReviewState, setAccountReviewState] = useState("initial");

  const gigyaKit = new GigyaKit();

  const apiBridge = {
    get(target, prop) {
      if (
        prop in target &&
        typeof target[prop] === "function" &&
        target[prop].constructor.name === "AsyncFunction"
      ) {
        return async function (...args) {
          try {
            return Promise.resolve(target[prop].apply(target, args));
          } catch (error) {
            if (
              error instanceof InvalidSessionStatusError ||
              error instanceof TokenRequiredForOperationError
            ) {
              return logOut();
            } else {
              return Promise.reject(error);
            }
          }
        };
      } else {
        return target?.[prop];
      }
    },
  };

  const estaAPIKit = new Proxy(new EstaAPIKit(), apiBridge);

  async function postGigyaCredential() {
    setAccountReviewState("loading");

    try {
      const apiResponse = await estaAPIKit.postUIDAndIdToken(
        idToken,
        accountInfo.UID,
        accountInfo.profile.email,
      );

      if (apiResponse.error === "doctor_disabled") {
        setAccountReviewState("required");
      } else {
        estaAPIKit.setBearerFromResponse(apiResponse);
        setGigyaCredentialsLoaded(true);
        setAccountReviewState("OK");
      }
    } catch (error) {
      throw error;
    }
  }

  function updateAccountInfo() {
    gigyaKit
      .getAccountInfo()
      .then((data) => {
        if (data.errorCode === 0) {
          setIsAccountInfoLoading(false);
          setAccountInfo(data);
          gigyaKit.getJWT().then((data) => {
            setIdToken(data.id_token);
          });
        }
        if (data.errorCode === 403005) {
          setIsAccountInfoLoading(false);
          setAccountInfo(null);
          setIdToken(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function logOut(useRedirect = true) {
    estaAPIKit.deleteBearer();
    setIdToken(null);
    setAccountReviewState("initial");
    await gigyaKit.logout();
    updateAccountInfo();

    if (useRedirect) {
      window.location.href = process.env.REACT_APP_DEPLOYED_FOLDER;
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateAccountInfo(), []);

  useEffect(() => {
    if (accountInfo && idToken) {
      postGigyaCredential();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken]);

  return (
    <AccountContext.Provider
      value={{
        accountInfo,
        updateAccountInfo,
        isAccountInfoLoading,
        idToken,
        accountReviewState,
        gigyaCredentialsLoaded,
        estaAPIKit,
        logOut,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
