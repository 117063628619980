import React from "react";
import ButtonFilled from "./ButtonFilled";
import { useTranslation } from "react-i18next";

export default function ButtonProceed(props) {
  const { t } = useTranslation();

  return (
    <>
      <div className="button-proceed col-12 p-0">
        <ButtonFilled
          title={t("Global.ProceedWithThisImage")}
          colorScheme="outlinedPurple"
          {...props}
        />
      </div>
    </>
  );
}
