import React from "react";
import { useTranslation } from "react-i18next";

export default function WarrantyDescription({ period }) {
  const { t } = useTranslation();
  const getDescriptionForPeriod = (period) => {
    switch (period) {
      case 2:
        return t("WarrantyInfo.2YWarrantyTitle");
      case 5:
        return t("WarrantyInfo.5YWarrantyTitle");
      default:
        return "--";
    }
  };

  return <span>{getDescriptionForPeriod(period)}</span>;
}
