import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {Box} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useDispatch, useSelector} from "react-redux";

import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import LinkAction from "../../components/library/Link/LinkAction";
import {setSupportModalOn} from "../../store/slices/supportSlice";
import {setAlert} from "../../store/slices/uiSlice";

import CreateTicketModule from "../../components/library/PopUpModules/CreateTicketModule";
import ButtonFilled from "../../components/library/Button/ButtonFilled";
import LinkBack from "../../components/library/Link/LinkBack";
import EstaAPIKit from "../../data/EstaAPIKit";
import {AlertMessage} from "../../components/library/AlertMessage";
import {PatientInformationForm} from "../../components/library/Forms/PatientInformation";
import {ExplantedImplantInformation} from "../../components/library/Forms/ExplantedImplantInformation";
import {explantFormSchema} from "../../components/library/Forms/ValidationSchema";
import {format} from "date-fns";
import {cleanExplantInformation} from "../../store/slices/explantSlice";
import {isNull} from "lodash";
import GigyaKit from "../../data/GigyaKit";
import {RequiredText} from "../../components/library/Form/RequiredText";
import AddressModal from "../../components/library/PopUpModules/AddressModal";
import PhoneNumberModal from "../../components/library/PopUpModules/PhoneNumberModal";

export const ExplantPage = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const gigyaKit = new GigyaKit();
  const estaApiKit = new EstaAPIKit();
  const { modal, modal_type } = useSelector((state) => state.supportSlice);
  const explantInformation = useSelector((state) => state.explantSlice);
  const preRegistration = useSelector((state) => state.preRegisterSlice);

  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false);

  const explantForm = useFormik({
    initialValues: {
      typeOfImplant: "Breast",
      typeSerialNumber: "SN/ESN",
      side: 1, //1 = left || 2 = right
      serial: {
        lot: "",
        SNLeft: "",
        VCLeft: "",
        isValidLeft: false,
        SNRight: "",
        VCRight: "",
        isValidRight: false,
      },
      implantInfo: null,
      explantDate: new Date(),
      originalImplantingPhysician: "",
      originalImplantingDate: !isNull(explantInformation?.originalImplantingDate)
        ? new Date(explantInformation?.originalImplantingDate)
        : new Date(),
      deviceReturned: false,
      reasonForRemovalOption: false,
      reasonForRemoval: "",
      patientInformation: {
        firstName: "",
        lastName: "",
        email: "",
        country: null,
        phone: "",
        SSN: "",
        address: null,
      },
      isValid: false,
    },
    validationSchema: explantFormSchema(t),
    onSubmit: async (values) => {
      // Request the password first
      await gigyaKit.requestPasswordConfirmation();

      let isLot = values.typeSerialNumber === "LOT";
      let payload = {
        source: 0,
        type: "B",
        serialNumber: isLot
          ? values.serial.lot
          : values.side === 1
            ? values.serial.SNLeft
            : values.side === 2
              ? values.serial.SNRight
              : "",
        implantSide: values.side === 1 ? "L" : values.side === 2 && "R",
        explantDate: format(new Date(values.explantDate), "MM/dd/yyyy"),
        flagExistOriginalInfo: false,
        deviceReturned: values.deviceReturned,
        deviceContributorRemoval: values.reasonForRemovalOption,
        reason: values.reasonForRemoval,
        temporaryExplant: isLot ? 1 : 0,
        patientInfo: {
          ...values.patientInformation,
        },
        originalImplantPhysician: values.originalImplantingPhysician,
        originalImplantDate: format(
          new Date(values.originalImplantingDate),
          "MM/dd/yyyy",
        ),
      };

      if (isLot) {
        payload = {
          ...payload,
          explantingSurgeon: {
            email: preRegistration?.me?.email,
          },
        };
      }

      estaApiKit
        .explant(payload)
        .then((response) => response.json())
        .then((data) => {
          if (data?.message?.error_code === 409) {
            dispatch(
              setAlert({
                alertMessage: {
                  open: true,
                  message: data.message.error_message,
                  success: "warning",
                },
              }),
            );
          } else if (data?.status === 200) {
            dispatch(
              setAlert({
                alertMessage: {
                  open: true,
                  message: data?.message,
                  success: "success",
                },
              }),
              explantForm.resetForm());
          } else {
            dispatch(
              setAlert({
                alertMessage: {
                  open: true,
                  message:
                    data?.error ||
                    data?.message?.error_message ||
                    data?.message,
                  success: "error",
                },
              })
            )
            ;
          }
          window.scrollTo(0, 0);
        });
    },
  });


  useEffect(() => {
    explantForm.setFieldValue("side", explantInformation?.side);
    explantForm.setFieldValue(
      "originalImplantingDate",
      !isNull(explantInformation?.originalImplantingDate)
        ? new Date(explantInformation?.originalImplantingDate)
        : new Date(),
    );

    explantForm.setFieldValue(
      "serial.SNLeft",
      explantInformation?.serial.SNLeft,
    );
    explantForm.setFieldValue(
      "serial.VCLeft",
      explantInformation?.serial.VCLeft,
    );
    explantForm.setFieldValue(
      "serial.SNRight",
      explantInformation?.serial.SNRight,
    );
    explantForm.setFieldValue(
      "serial.VCRight",
      explantInformation?.serial.VCRight,
    );

    explantForm.setFieldValue(
      "patientInformation.firstName",
      explantInformation?.patientInformation.firstName,
    );
    explantForm.setFieldValue(
      "patientInformation.lastName",
      explantInformation?.patientInformation.lastName,
    );
    explantForm.setFieldValue(
      "patientInformation.email",
      explantInformation?.patientInformation.email,
    );
    explantForm.setFieldValue(
      "patientInformation.country",
      explantInformation?.patientInformation.country,
    );
    explantForm.setFieldValue(
      "patientInformation.phone",
      explantInformation?.patientInformation.phone,
    );
    explantForm.setFieldValue(
      "patientInformation.SSN",
      explantInformation?.patientInformation.SSN,
    );

    return () => {
      explantForm.resetForm();
      dispatch(cleanExplantInformation());
    };
  }, []);

  // Extract the originalImplantingDate and originalImplantingPhysician from the implantInfo
  useEffect(() => {
    if (explantForm.values.implantInfo?.originalRegisterInfo) {
      explantForm.setFieldValue(
        "originalImplantingDate",
        new Date(
          explantForm.values.implantInfo.originalRegisterInfo.originalImplantDate,
        ),
      );
      explantForm.setFieldValue(
        "originalImplantingPhysician",
        explantForm.values.implantInfo.originalRegisterInfo
          .originalImplantPhysianName,
      );
    } else {
      explantForm.setFieldValue("originalImplantingDate", new Date());
      explantForm.setFieldValue("originalImplantingPhysician", "");
    }
  }, [explantForm.values?.implantInfo]);

  const createTicket = () => {
    dispatch(setSupportModalOn("general"));
    window.scrollTo(0, 0);
  };

  const isValidFieldsSerial = () => {
    const {typeSerialNumber, serial, side} = explantForm.values;
    const {lot, SNLeft, SNRight, isValidLeft, isValidRight} = serial;

    if (typeSerialNumber === "LOT") {
      return lot.trim() !== "";
    }

    if (SNLeft && SNRight) {
      return isValidLeft && isValidRight;
    } else if (SNLeft) {
      return isValidLeft;
    } else if (SNRight) {
      return isValidRight;
    }

    return false;
  };


  // Save the address from the address modal into the form
  const onSubmitAddress = async (address) => {
    await explantForm.setValues({
      ...explantForm.values,
      patientInformation: {
        ...explantForm.values.patientInformation,
        address: address,
      },
    });
  };

  const onSubmitPhoneNumber = async (phoneNumber) => {
    await explantForm.setValues({
      ...explantForm.values,
      patientInformation: {
        ...explantForm.values.patientInformation,
        phone: phoneNumber,
      },
    });
  };

  const addresses = explantForm.values?.fetchedPatient?.address ?? [];
  const phoneNumbers =
    explantForm.values?.fetchedPatient?.numberPhones?.map(
      (obj) => obj.number,
    ) || [];

  return (
    <>
      <Helmet>
        <title>Explant information | MotivaImagine®</title>
        <meta name="pageType" content="Explant information Form" />
      </Helmet>
      <FiftyFiftyLayout
        backgroundImgPath="fifty-fifty-bg-4"
        title={t("Device.Tracking.Title")}
        subtitle={t("My-Info.ReportAnExplant")}
        USTracking={true}
      >
        {showAddressModal && (
          <AddressModal
            initialValues={explantForm.values.patientInformation.address}
            onClose={() => setShowAddressModal(false)}
            onSubmit={onSubmitAddress}
            addresses={addresses}
          />
        )}

        {showPhoneNumberModal && (
          <PhoneNumberModal
            initialValue={explantForm.values.patientInformation.phone}
            onClose={() => setShowPhoneNumberModal(false)}
            onSubmit={onSubmitPhoneNumber}
            phoneNumbers={phoneNumbers}
          />
        )}

        <div className="pre-next-btns pre-next-btns-top">
          <LinkBack linkBack="/" />
        </div>

        <Box
          sx={{ width: { xs: "calc(100% - 50px)", lg: "calc(100% - 300px)" } }}
        >
          <form onSubmit={explantForm.handleSubmit}>
            {modal && modal_type === "general" && (
              <CreateTicketModule general />
            )}
            <Box mt={6}>
              <Box mb={4}>
                <AlertMessage />
              </Box>
              <Box>
                <ExplantedImplantInformation formik={explantForm} />
              </Box>
              <Box mt={2}>
                <PatientInformationForm
                  formik={explantForm}
                  setShowAddressModal={setShowAddressModal}
                  setShowPhoneNumberModal={setShowPhoneNumberModal}
                />
              </Box>

              <RequiredText />

              <Box mt={8} display="flex" justifyContent="center">
                <LinkAction
                  onClick={() => createTicket()}
                  label={t("Global.HavingTrouble", { lng: "en" })}
                />
              </Box>

              <Box my={8} display="flex" justifyContent="center">
                <ButtonFilled
                  inline
                  size={"sm"}
                  type="submit"
                  title={t("Global.Submit", { lng: "en" })}
                  disabled={!isValidFieldsSerial()}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </FiftyFiftyLayout>
    </>
  );
};
