import React, {useEffect} from "react";
import "./TermsAndConditionsModule.scss";
import { useTranslation } from "react-i18next";

const TermsAndConditionsModule = ({ setShowTermsAndConditions }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="module">
      <div className="module__box">
        <p
          className="module__box__close-btn"
          onClick={() => setShowTermsAndConditions(false)}
        >
          X
        </p>
        <iframe
          title="terms and conditions"
          src={`https://docs.google.com/gview?url=${t("Global.WarrantyTermsAndConditions")}&embedded=true`} //"https://cdn-mh1.motiva.health/wp-content/uploads/2020/09/26044308/motiva-warranties-program2020-en.pdf"
          frameBorder="0"
        />
        <div className="module__box__btn-container">
          <button
            className="ok-btn submit"
            onClick={() => setShowTermsAndConditions(false)}
          >
            {t("Global.Ok")}
          </button>

          <a
            className="module__box__btn-container__download-btn"
            href={t("Global.WarrantyTermsAndConditions")}
            download="FileName"
            rel="noopener"
            // rel noopener has been added to avoid any issues
            // eslint-disable-next-line react/jsx-no-target-blank  
            target="_blank"
          >
            {t("Global.Download")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModule;
