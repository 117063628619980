import "./Warranty.scss";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useContext } from "react";

import { StaticFormContext } from "../../contexts/StaticFormContext";
import DateRenderer from "../../components/library/DateRenderer";
import EnsureToken from "../../components/library/EnsureToken";
import EstaAPIKit from "../../data/EstaAPIKit";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import TermsAndConditionsModule from "../../components/library/PopUpModules/TermsAndConditionsModule";
import WarrantyOffer from "../../components/library/Warranty/WarrantyOffer";
import SpinnerSmall from "../../components/library/Spinner/SpinnerSmall";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { UserContext } from "../../contexts/UserContext";

export default function WarrantyOptionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentWizard } = useContext(StaticFormContext);
  const estaApiKit = new EstaAPIKit();
  const [warrantyDetailData, setWarrantyDetailData] = useState(null);
  const [showModule, setShowModule] = useState(false);
  const preregistration = useSelector((state) => state.preRegisterSlice);
  const { me } = useContext(UserContext);

  const isUSPatient = me.userCountry === "US";

  useEffect(() => {
    if (isUSPatient) {
      navigate("/my-implants/information");
      return;
    }

    if (currentWizard === "gluteal") {
      navigate("/my-implants/information");
    }
    estaApiKit
      .getWarrantyDetails()
      .then((res) => res.json())
      .then((data) => {
        setWarrantyDetailData(data);
        if (!data.canBuyExtendedWarranty) {
          navigate("/my-implants/information");
        }
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function hasWarrantyExtended() {
    return warrantyDetailData && warrantyDetailData.warrantyExtended;
  }

  function getTitle() {
    if (hasWarrantyExtended()) {
      return t("Extend-Warranty-Option.Has-End-Date-Warranty.Title");
    } else {
      return t("Extend-Warranty-Option.Title");
    }
  }

  function singleImplantExtensionAvailable() {
    return (
      warrantyDetailData?.implants?.filter((i) => i["can_purchase"]).length ===
      1
    );
  }
  function extensionOfSingleImplantExtensionAvailable() {
    return warrantyDetailData?.implants?.find((i) => i["can_purchase"]).period;
  }

  function warrantyExtensionAvailableTranslated() {
    let extension = extensionOfSingleImplantExtensionAvailable();
    switch (extension) {
      case 2:
        return t("WarrantyInfo.ElegibilityExtension2Y");
      case 5:
        return t("WarrantyInfo.ElegibilityExtension5Y");
      default:
        return "";
    }
  }

  function getDescription() {
    if (hasWarrantyExtended()) {
      return (
        <>
          {`${t("Extend-Warranty-Option.Has-End-Date-Warranty.Description")}`}{" "}
          <DateRenderer date={warrantyDetailData.endDateWarranty} />{" "}
        </>
      );
    } else {
      return (
        <div className="description">
          <div className="card-list">
            {warrantyDetailData &&
              warrantyDetailData["implants"] instanceof Array && (
                <WarrantyOffer implants={warrantyDetailData["implants"]} />
              )}
          </div>
          <p
            className="terms-and-conditions"
            onClick={() => setShowModule(!showModule)}
          >
            {warrantyDetailData &&
              t("Extend-Warranty-Option.SeeFullTermsAndConditions")}
          </p>
          {singleImplantExtensionAvailable() && (
            <div>
              {t("WarrantyInfo.ElegibilityExtensionPrompt")}
              &nbsp;
              <span className="font-black">
                {warrantyExtensionAvailableTranslated()}
              </span>
              ?
            </div>
          )}
        </div>
      );
    }
  }

  function getButtonList() {
    const buttonList = [];

    if (warrantyDetailData.canBuyExtendedWarranty) {
      buttonList.push({
        title: t("Extend-Warranty-Option.PurchaseExtendedWarranty"),
        nextLink: "/warranty/payment",
      });
    }
    if (hasWarrantyExtended()) {
      buttonList.push({
        title:  preregistration?.me?.isMIACustomer ? t("Implant-Registration.Devices") : t("Extend-Warranty-Option.To-My-Implants"),
        nextLink: "/my-implants/information",
      });
    }
    return buttonList;
  }

  function getSecondaryButtonList() {
    const buttonList = [];
    buttonList.push({
      title: t("Global.SkipForNow"),
      nextLink: "/my-implants",
    });

    return buttonList;
  }

  return (
    <>
      <Helmet>
        <title>Warranty Details | MotivaImagine®</title>
        <meta name="pageType" content="Extended Warranty" />
      </Helmet>

      <EnsureToken>
        {showModule && (
          <TermsAndConditionsModule setShowTermsAndConditions={setShowModule} />
        )}
        {warrantyDetailData ? (
          <FullPageLayout
            backgroundImgPath="homepage-bg-5"
            position="right"
            title={warrantyDetailData ? getTitle() : t("Global.Loading")}
            description={warrantyDetailData && getDescription()}
            buttonList={warrantyDetailData ? getButtonList() : []}
            secondaryButtonList={
              warrantyDetailData ? getSecondaryButtonList() : []
            }
          />
        ) : (
          <FullPageLayout
            backgroundImgPath="homepage-bg-5"
            position="right"
            title={
              <div className={"spin"}>
                <SpinnerSmall />
              </div>
            }
            buttonList={[]}
          />
        )}
      </EnsureToken>
    </>

  );
}
