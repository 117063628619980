import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import "./ImplantRegistrationSuccess.scss";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Helmet} from 'react-helmet-async';


const ImplantRegistrationWarrantyExtended = () => {
  const {t} = useTranslation();
  const registration = useSelector((state) => state.registrationSlice?.registrationResult);
  const [warrantyYears, setWarrantyYears] = useState([])

  useEffect(() => {
    let warrantyYearsTemp = [];
    if(registration.devices){
      for (let i = 0; i < registration.devices?.implants?.length; i++) {
        warrantyYearsTemp.push(registration.devices?.implants[i].warranty.years)
      }
    }

    setWarrantyYears(warrantyYearsTemp);
  }, [registration])

  return (
    <>
        <Helmet>
          <title>Implant Registration Success | MotivaImagine®</title>
          <meta name="pageType" content="Implant Registration" />
        </Helmet>
        <FullPageLayout
          backgroundImgPath="homepage-bg-6"
          position="right"
          buttonList={[]}
        >
          <h1 className="thanks-for-visiting-page__title">
            {t("ImplantRegistration.SuccessWarrantyExtended.Title")}
          </h1>
          <h1 className="thanks-for-visiting-page__subtitle">
            {t("ImplantRegistration.SuccessWarrantyExtended.Description", {
              years: warrantyYears && Math.max(...warrantyYears)
            })}
          </h1>
          <Link to="/my-implants" className="go-back-to-browsing-btn">
            {t("Global.GoToHome")}
          </Link>
        </FullPageLayout>
    </>
  );
};

export default ImplantRegistrationWarrantyExtended;
