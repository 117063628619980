import React from "react";
import { format } from "date-fns";
import { isBoolean } from "lodash";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { preRegistrationInformation } from "../../../store/slices/preRegisterSlice";
import { DatePickerComponent } from "../FormComponents/DatePicker";
import { InputComponent } from "../FormComponents/Input";

export const PreRegisterSurgeryInformationForm = ({ disabled = true }) => {
  const { t } = useTranslation();
  const preRegistration = useSelector((state) => state.preRegisterSlice);
  const dispatch = useDispatch();

  function handleDateSelected(selectedDate) {
    dispatch(
      preRegistrationInformation({
        ...preRegistration,
        surgeryDate: format(selectedDate, "MM/dd/yyyy"),
      }),
    );
  }

  function validationSurgeon() {
    if (
      isBoolean(preRegistration?.me?.isDoctor) &&
      preRegistration?.me?.isDoctor === true
    ) {
      if (Array.isArray(preRegistration?.me.surgeon)) {
        return preRegistration?.me.surgeon;
      }
    }
    if (Array.isArray(preRegistration?.surgeon)) {
      return preRegistration?.surgeon;
    }
  }

  return (
    <>
      <Box mt={2}>
        <InputComponent
          title={t("Global.Surgeon")}
          value={validationSurgeon()[0].label || ""}
          isDisabled={true}
          handleChange={validationSurgeon}
          id="preRegistration-surgeon"
        />
      </Box>

      <Box mt={2}>
        <DatePickerComponent
          title={t("Surgery-Information.SelectDate")}
          value={
            isBoolean(preRegistration?.me?.isDoctor)
              ? new Date()
              : new Date(preRegistration?.surgeryDate)
          }
          onChange={handleDateSelected}
          reset={false}
          id="preRegistration-surgeryDate"
          required
        />
      </Box>
    </>
  );
};
