import "./ImplantRegistrationPage.scss";

import {Navigate, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {StaticFormContext} from "../../contexts/StaticFormContext";
import {UserContext} from "../../contexts/UserContext";
import ButtonFilled from "../../components/library/Button/ButtonFilled";
import CreateTicketModule from "../../components/library/PopUpModules/CreateTicketModule";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import LinkAction from "../../components/library/Link/LinkAction";
import LinkBack from "../../components/library/Link/LinkBack";
import Registration from "../../components/library/Registration.jsx";
import {ImplantsFormSN} from "../../components/library/Form/ImplantsFormSN";
import {ImplantCard} from "../../components/library/ImplantCardView/ImplantCardView";
import {useScrollPosition} from "../../hooks/useScrollPosition";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import {useDispatch, useSelector} from "react-redux";
import {setSupportModalOn} from "../../store/slices/supportSlice";
import {DevicesForm} from "../../components/library/Form/DevicesForm";
import {preRegistrationInformation} from "../../store/slices/preRegisterSlice";
import {Helmet} from 'react-helmet-async';

export default function ImplantRegistrationPage() {

  const register = useSelector((state) => state.registrationSlice?.selectionCard);
  const preRegistration = useSelector((state) => state.preRegisterSlice);

  const [isDevicesValid, setIsDevicesValid] = useState(false);
  const {breastImplants, glutealImplants, me} = useContext(UserContext);
  const {
    serialNumberLeft,
    serialNumberRight,
    leftVC,
    rightVC
  } = useContext(StaticFormContext);

  const {modal, modal_type} = useSelector((state) => state.supportSlice);
  const [isFormValid, setIsFormValid] = useState(false);
  const [nextLink, setNextLink] = useState(null);
  const [lang, setLang] = useState(me?.country?.[0]);
  const navigate = useNavigate();

  const position = useScrollPosition();
  const isMobile = useMediaQuery('(max-width: 567px)');

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    registerImplantsFor,
    currentWizard,
    shouldRenderLeftForm,
    shouldRenderRightForm,
    implantCardType,
  } = useContext(StaticFormContext);
  const { t } = useTranslation();

  function handleOnOK() {
    const validForms = validateForms();
    if (validForms) {
      setNextLink(getNextPath());
    }
  }

  function validateForms() {
    let valid = false;
    if (registerImplantsFor === "both") {
      if (currentWizard === "breast") {
        const left_valid = isImplantValid(breastImplants, "L");
        const right_valid = isImplantValid(breastImplants, "R");

        valid = left_valid && right_valid;
      }
      if (currentWizard === "gluteal") {
        valid =
          isImplantValid(glutealImplants, "L") &&
          isImplantValid(glutealImplants, "R");
      }
    } else if (registerImplantsFor === "left") {
      if (currentWizard === "breast") {
        valid = isImplantValid(breastImplants, "L");
      }
      if (currentWizard === "gluteal") {
        valid = isImplantValid(glutealImplants, "L");
      }
    } else if (registerImplantsFor === "right") {
      if (currentWizard === "breast") {
        valid = isImplantValid(breastImplants, "R");
      }
      if (currentWizard === "gluteal") {
        valid = isImplantValid(glutealImplants, "R");
      }
    }
    return valid;
  }

  function isImplantValid(data, placement) {
    //validates result.status of an implant with what is already in data
    const result_status = data[placement].status;
    if (result_status) {
      if (result_status === "false") {
        return false;
      }
      if (result_status === "true") {
        return true;
      }
    } else {
      return false;
    }
  }

  function isValidWithMia (){
    let valid = true;
    if(isFormValid && (preRegistration?.implantsValid?.implantL === true && preRegistration?.implantsValid?.implantR === false) || (preRegistration?.implantsValid?.implantL === false && preRegistration?.implantsValid?.implantR === true)){
      valid = true;
    }else if(isFormValid && !isDevicesValid && (preRegistration?.implantsValid?.implantL === true || preRegistration?.implantsValid?.implantR === true)){
      valid = true;
    }else if(isFormValid && isDevicesValid && (preRegistration?.implantsValid?.implantL === true || preRegistration?.implantsValid?.implantR === true)){
      valid = false;
    }else if(isFormValid && !(preRegistration?.implantsValid?.implantL === true || preRegistration?.implantsValid?.implantR === true)){
      valid = false;
    }
    return valid;
  }

  useEffect(() => {
      dispatch(preRegistrationInformation({
        ...preRegistration,
        implantsInformation: {
          ...preRegistration.implantsInformation,
          tools: {
            injector: preRegistration.implantsInformation?.tools?.injector && !preRegistration?.isMiaImplants ? null : preRegistration.implantsInformation?.tools?.injector,
            balloon: preRegistration.implantsInformation?.tools?.balloon && !preRegistration?.isMiaImplants ? null : preRegistration.implantsInformation?.tools?.balloon,
          }
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      serialNumberLeft, serialNumberRight, leftVC, rightVC, preRegistration?.isMiaImplants
    ]
  );

  function createTicket() {
    dispatch(setSupportModalOn('fullForm'));
    window.scrollTo(0, 0);
  }

  function getNextPath() {

    if(preRegistration.isMiaImplants){
      return "/registration/surgery-information"
    }

    if (currentWizard === "breast") {
      return "/registration/incision-type-selection";
    } else if (currentWizard === "gluteal") {
      return "/registration/surgery-information";
    }
  }

  useEffect(() => {
    if (me?.country_id === undefined) {
      navigate("/registration/implant-type");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (me) {
      const { country_id } = me;
      setLang(country_id);
    }
  }, [me]);

  if (nextLink) return <Navigate push to={nextLink} />;
  return (
    <>
      <Helmet>
        <title>Implant Information SN | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>

      <Registration step={5}>
        <div className="pre-next-btns pre-next-btns-top">
          <LinkBack linkBack={"/registration/implant-register-method"}/>
        </div>


        {(modal && modal_type === 'fullForm') && (
          <CreateTicketModule
            ticketForSN
            leftSideImplant={shouldRenderLeftForm()}
            rightSideImplant={shouldRenderRightForm()}
          />
        )}


        <FiftyFiftyLayout
          backgroundImgPath="fifty-fifty-bg-1"
          title={t("Implant-Registration.Title")}
          subtitle={t("Implant-Registration.Description")}
        >
          <div className={`fifty-fifty-layout__btn-container--middle`} style={{height: '200px'}}>
            <div className={`col-md-12 ${position >= 330 && isMobile ? 'cardFloat' : ''}`}>
              <ImplantCard
                sn
                isBreast={currentWizard === "breast"}
                type={implantCardType}
                className="image"
                alt="Motiva ID Card"
                lang={lang}
                sectionCard={
                register.snLeft ? 'SNLeft' : register.snRight ? 'SNRight' : register.vcLeft ? 'VCLeft' : register.vcRight ? 'VCRight' : register.defaultCard && 'default'}
              />
            </div>
          </div>
          <div className="info-and-form-wrapper">
            <ImplantsFormSN setIsValid={setIsFormValid} />
          </div>

          {preRegistration.isMiaImplants &&
            <div className="info-and-form-wrapper ml-5">
              <DevicesForm setIsValid={setIsDevicesValid}/>
            </div>
          }

          <LinkAction
            to="/registration/card-type-selection"
            className="support"
            label={t("Global.ChangeCardType")}
          />
          <div className="fifty-fifty-layout__btn-container--middle">
            <LinkAction
              onClick={createTicket}
              className="support"
              label={t("Global.HavingTrouble")}
            />
          </div>
          <div className="pre-next-btns">
            <span />
            <ButtonFilled
              size={"lg"}
              inline
              disabled={isValidWithMia()}
              title={t("Global.Verify")}
              onClick={handleOnOK}
            />
          </div>
        </FiftyFiftyLayout>
      </Registration>
    </>
  );
}
