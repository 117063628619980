import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import parse from 'html-react-parser';

export const LoginSelection = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const rootPath = location.pathname === "/";

  const htmlString = rootPath ? t("Login.BannerSurgeon") : t("Login.BannerPatient");
  const reactElement = parse(htmlString);

  const replaceLink = (node) => {
    if (node.type === 'a') {
      return <Link to={node.props.href} className="underline" id="here-btn">{node.props.children}</Link>;
    }
    return node;
  }

  const reactElementWithLink = React.Children.map(reactElement, replaceLink);

  return (
    <Box display="flex" justifyContent="center" alignItems="center"
         sx={{backgroundColor: "#e5d6e7", color: "#6e0072", width: "100%", height: {xs: 45 ,md: 35}, position: "absolute", top:0}}>
      <Grid container>
        <Grid item xs={12} md={12} display="flex" justifyContent="center">
          <Typography variant="body2" fontWeight={800} sx={{fontSize: {xs: 12, md: 14} }} fontFamily="Avenir" >{reactElementWithLink}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
