import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../store/slices/uiSlice";
import { Box, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";

export const AlertMessage = () => {
  const dispatch = useDispatch();
  const iu = useSelector((state) => state.uiSlice?.alertMessage);

  useEffect(() => {
    if (iu?.open) {
      const timeoutId = setTimeout(() => {
        dispatch(
          setAlert({
            alertMessage: {
              open: false,
              message: null,
              success: null,
            },
          }),
        );
      }, 4000);
      return () => clearTimeout(timeoutId);
    }
  }, [iu?.open]);

  let color = iu?.success || "success";

  return (
    <Box sx={{ width: "100%" }}>
      <Collapse in={iu?.open || false}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                dispatch(
                  setAlert({
                    alertMessage: {
                      open: false,
                      message: null,
                      success: null,
                    },
                  }),
                );
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          severity={color}
        >
          {iu?.message}
        </Alert>
      </Collapse>
    </Box>
  );
};
