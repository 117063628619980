import React from "react";
import ButtonFilled from "./ButtonFilled";
import { useTranslation } from "react-i18next";

export default function ButtonBothSides(props) {
  const { t } = useTranslation();

  return (
    <>
      <ButtonFilled
        title={t("Global.BothSides")}
        colorScheme="purple"
        size="lg"
        {...props}
      />
    </>
  );
}
