import "./ImplantWarrantyDetail.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import DateRenderer from "../DateRenderer";
import WarrantyDescription from "./WarrantyDescription";

export default function ImplantWarrantyDetail({
  serial,
  period,
  endDateWarranty,
  side,
  hideValidUntil,
}) {
  const { t } = useTranslation();
  return (
    <div className="implantWarrantyDetail">
      <div className="itemRow">
        <div className="itemName">
          {t("WarrantyInfo.SideImplantSerialNumber", {
            side: side === "L" ? t("Global.Left") : t("Global.Right"),
          })}
        </div>
        <div className="itemDetail">{serial}</div>
      </div>
      <div className="itemRow">
        <div className="itemName">{t("WarrantyInfo.Program")}</div>
        <div className="itemDetail">
          <WarrantyDescription period={period} />
        </div>
      </div>
      <div className="itemFooter">
        {!hideValidUntil && (
          <>
            {t("WarrantyInfo.ValidUntil")}:
            <DateRenderer date={endDateWarranty} />
          </>
        )}
      </div>
    </div>
  );
}
