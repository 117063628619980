import React, { useContext, useState, useEffect } from "react";
// import CardSelectVerticalList from "../../components/library/CardSelectVertical/CardSelectVerticalList";
import LinkBack from "../../components/library/Link/LinkBack";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import Registration from "../../components/library/Registration.jsx";
import { StaticFormContext } from "../../contexts/StaticFormContext";
import { SurgeryCountrySelection } from "../../components/library/SurgeryCountrySelect/SurgeryCountrySelect.jsx";
import ButtonFilled from "../../components/library/Button/ButtonFilled";
import { CardTypeSelect } from "../../components/library/CardTypeSelect/CardTypeSelect";
import styled from "styled-components";
import {Helmet} from 'react-helmet-async';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

export default function CardSelectionPage() {
  const { isSurgeryCountryValid, me } = useContext(UserContext);
  const { registerImplantsFor, isImplantCardTypeValid } =
    useContext(StaticFormContext);
  const { t } = useTranslation();
  const [nextLink, setNextLink] = useState(null);
  const [showNextSection, setShowNextSection] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (registerImplantsFor === "") {
      navigate("/registration/implant-type");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForms = () =>
    isSurgeryCountryValid() && isImplantCardTypeValid();

  function handleOnOK() {
    setShowNextSection(false);
    const validForms = validateForms();

    if (validForms) {
      setNextLink("/registration/implant-register-method");
    } else {
      // setShowErrorModal(!validForms);
    }
    window.scrollTo(0, 0);
  }

  if (nextLink) return <Navigate to={nextLink} />;

  return (
    <>
      <Helmet>
        <title>Card Selection | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>
      <Registration step={3}>
        <FiftyFiftyLayout
          backgroundImgPath="fifty-fifty-bg-1"
          title={t(
            "question-country-perfomed",
            "On which country was your surgery performed"
          )}
        >
          <div className="fixed-image-block-size"></div>

          <div className="fifty-fifty-layout__main-btn-container">
            <FormContainer>
              <SurgeryCountrySelection setShowNextSection={setShowNextSection} />
              {(showNextSection || me?.country?.[0]) && (
                <div>
                  <h3
                    className="font-book text-muted"
                    style={{
                      fontSize: "20px",
                      textAlign: "center",
                      maxWidth: "300px",
                      margin: "2vh auto 4vh auto",
                    }}
                  >
                    {t(
                      "question-format-card",
                      "Which of these looks like your ID Registration Card?"
                    )}
                  </h3>
                  <CardTypeSelect />
                </div>
              )}
            </FormContainer>
          </div>
          <div className="pre-next-btns pre-next-btns-top">
            <LinkBack
              handleCountryReset
              linkBack="/registration/implant-side-selection"
            />
          </div>
          <div
            className="pre-next-btns"
            style={{ justifyContent: "flex-end", marginTop: "0px" }}
          >
            <ButtonFilled
              size={"lg"}
              show={showNextSection}
              inline
              disabled={!validateForms()}
              title={t("Global.Verify", "Verify")}
              onClick={handleOnOK}
            />
          </div>
        </FiftyFiftyLayout>
      </Registration>
    </>
  );
}
