import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  side: 1, //1 = left || 2 = right
  originalImplantingDate: null,
  serial: {
    SNLeft: "",
    VCLeft: "",
    SNRight: "",
    VCRight: "",
  },
  patientInformation: {
    firstName: "",
    lastName: "",
    email: "",
    country: null,
    phone: "",
    SSN: "",
    address: ""
  }
}

export const explantSlice = createSlice({
  name: 'explant',
  initialState,
  reducers: {
    setExplantInformation: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    cleanExplantInformation: (state) => {
      return {
        ...state,
        ...initialState
      }
    }
  },
});

export const {setExplantInformation, cleanExplantInformation} = explantSlice.actions
export default explantSlice.reducer
