import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { StaticFormContext } from "../../../contexts/StaticFormContext";
import { UserContext } from "../../../contexts/UserContext";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import Spinner from "../Spinner/Spinner";
import ButtonOpenTicket from "../Button/ButtonOpenTicket";
import { Logo } from "../Logo";
import "./FiftyFiftyLayout.scss";
import { AccountComponent } from "../AccountComponent";
import { Box } from "@mui/material";

const FiftyFiftyLayout = ({
  children,
  backgroundImgPath,
  title,
  subtitle,
  className,
  USTracking,
}) => {
  const { setSerialNumberLeft, setSerialNumberRight, setLeftVC, setRightVC } =
    useContext(StaticFormContext);

  const { isMeLoaded } = useContext(UserContext);

  const resetSerialNumber = () => {
    setSerialNumberLeft("");
    setSerialNumberRight("");
    setLeftVC("");
    setRightVC("");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          zIndex: 9,
          width: "100%",
          position: "absolute",
          paddingRight: { xs: 2, md: 8 },
        }}
        mt={6}
      >
        <Box
          display="flex"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <AccountComponent />
          </Box>
          <Box>
            <ButtonOpenTicket />
          </Box>
          <Box ml={{ xs: 1.5, md: 1 }} mt={{ xs: 1.1, md: 0 }}>
            <LanguageSelector />
          </Box>
        </Box>
      </Box>

      {!isMeLoaded() ? (
        <Spinner />
      ) : (
        <div className={`${className} fifty-fifty-layout`}>
          <div
            className={
              USTracking === true
                ? "fifty-fifty-layout__us"
                : "fifty-fifty-layout__content"
            }
          >
            <Link
              onClick={resetSerialNumber}
              to="/"
              className="fifty-fifty-layout__content__logo"
            >
              <Logo type="isotype" />
            </Link>

            {title && (
              <h3 className="fifty-fifty-layout__content__title font-book text-muted">
                {title}
              </h3>
            )}

            {subtitle && (
              <h6 className="fifty-fifty-layout__content__subtitle text-muted">
                {subtitle}
              </h6>
            )}

            {children}
          </div>

          <div
            className={`fifty-fifty-layout__background ${backgroundImgPath}`}
          />
        </div>
      )}
    </>
  );
};

export default FiftyFiftyLayout;
