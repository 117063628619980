import React, { useContext, useEffect } from "react";
import { useCountries } from "../../../hooks/useCountries.jsx";
import SelectComponent from "../../library/Select/Select";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../contexts/UserContext";
import { StaticFormContext } from "../../../contexts/StaticFormContext.js";

export const SurgeryCountrySelection = ({ setShowNextSection }) => {
  const { t } = useTranslation();
  const { countryList } = useCountries();
  const { me, setMe, isDoctor } = useContext(UserContext);
  const { setImplantCardType } = useContext(StaticFormContext);

  const handleCountrySelected = (selectedItem) => {
    setImplantCardType(undefined);
    if (selectedItem && selectedItem[0]) {
      setShowNextSection(true);
      setMe((prevState) => ({ ...prevState, country_id: selectedItem[0].id }));
    } else {
      setShowNextSection(false);
      setMe((prevState) => ({ ...prevState, country_id: null }));
    }
  };

  const handleCountryClear = () => {
    handleCountrySelected(null);
  };

  useEffect(() => {
    handleCountryClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectComponent
      placeholder={
        isDoctor()
          ? t("Surgery-Information.Country")
          : t("Surgery-Information.WhereDidYouHaveYourSurgery")
      }
      options={countryList.map((o) => ({ ...o, label: t(o.label) }))} // sends the translated label on the options
      handleOnChange={handleCountrySelected}
      handleOnClear={handleCountryClear}
      fixedValue
      value={me?.country}
      required
    />
  );
};
