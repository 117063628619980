import React, {useContext, useState, useEffect} from "react";
import "./MyImplantView.scss";
import {useTranslation} from "react-i18next";
import ButtonFilled from "../../components/library/Button/ButtonFilled";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import {UserContext} from "../../contexts/UserContext";
import PreRegistrationModal from "../../components/library/PopUpModules/PreRegistrationModal";
import {AccountContext} from "../../contexts/AccountContext";
import {Helmet} from 'react-helmet-async';
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {StaticFormContext} from "../../contexts/StaticFormContext";
import {useNavigate} from "react-router-dom";
import RegistrationNFCModal from "../../components/library/PopUpModules/RegistrationNFCModal";

export default function ImplantTypePage() {
  const {t} = useTranslation();
  const {me, hasPreRegistrations, loadPreregistrationInformation, isLoading} = useContext(UserContext);
  const {logOut} = useContext(AccountContext);
  const [showPreRegistrationModal, setShowPreRegistrationModal] =
    useState(true);
  const hidePreRegistration = () => setShowPreRegistrationModal(false);

  const [showRegistrationNFCModal, setRegistrationNFCModal] =
    useState(true);
  const hideRegistrationNFCModal = () => setRegistrationNFCModal(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      logOut();
    }, 1800000); // testing 10000 = 10s prod 1800000 = 30min
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preregistration = useSelector(state => state.preRegisterSlice);
  let isMia = !isEmpty(preregistration?.implantsInformation?.tools?.injector) && !isEmpty(preregistration?.implantsInformation?.tools?.balloon);

  useEffect(() => {
    if(me?.notifications?.preregistrations && me?.notifications?.preregistrations.length >= 0){
      loadPreregistrationInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.notifications?.preregistrations]);


  const RegistrationNFC =()=>{
    let dataNFC = JSON.parse(localStorage.getItem('nfc'));
    return dataNFC?.type ? true : false;
  }

  return (
    <>
      <Helmet>
        <title>Home | MotivaImagine®</title>
        <meta name="pageType" content="Home" />
      </Helmet>

      <FullPageLayout
        backgroundImgPath="homepage-bg-3"
        position="right"
        title={preregistration?.me?.isMIACustomer ? t("Implant-Registration.Devices") : t("My-Implants.Title")}
        subtitle={t("My-Implants.Description")}
        buttonList={[
          me?.userType === 1 // 0: No Implants,  1: Has Implants
            ? {
              title: preregistration?.me?.isMIACustomer ? t("MyDevices.SeeMyDevicesInformation") : t("MyImplants.SeeMyImplantsInformation"),
              nextLink: "/my-implants/information",
            }
            : {
              title: t("Global.AddImplants"),
              nextLink: "/registration/preimplant-registration-yes-no",
            },
        ]}
      >
        {me?.canBuyExtendedWarranty && me?.userCountry !== "US" && (
          <ButtonFilled
            title={t("MyImplants.ExtendYourWarranty")}
            nextLink="/warranty"
            className="extend-warranty-btn"
          />
        )}
        {isLoading ? '' : (hasPreRegistrations() && !isMia) && (
          <ButtonFilled
            title={t("MyImplants.CompletePreRegistration")}
            nextLink="/registration/full"
            className="complete-pre-registration-btn"
            onClick={() => loadPreregistrationInformation()}
          />
        )}
        {hasPreRegistrations() && showPreRegistrationModal && (
          <PreRegistrationModal onClose={hidePreRegistration} isMia={isMia}/>
        )}
        {RegistrationNFC() && showRegistrationNFCModal && <RegistrationNFCModal onClose={hideRegistrationNFCModal} />}

      </FullPageLayout>
    </>
  );
}
