import React from "react";
import { Link } from 'react-router-dom';
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import "./MyImplantsInformationThanksYouForVisitingPage.scss";
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet-async";

const MyImplantsInformationThanksYouForVisitingPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Thank you for visiting | MotivaImagine®</title>
        <meta name="pageType" content="Thank you for visiting patient" />
      </Helmet>
      <FullPageLayout
        backgroundImgPath="homepage-bg-6"
        position="right"
        buttonList={[]}
      >
        <h1 className="thanks-for-visiting-page__title color-coffee">
          {t("Thank-You-For-Visiting")}
        </h1>
        <h1 className="thanks-for-visiting-page__subtitle color-coffee">
          {t(
            "Come-Back-To-Complete-The-Process-When-You-Are-Ready-To-Register-Your-New-Motiva-Implants-Or-To-Extend-Your-Warranty"
          )}
        </h1>
        <h1 className="thanks-for-visiting-page__subtitle color-coffee">
          {t("Keep-Learning-About-The-Motiva-Solutions")}
        </h1>


        <Link
          to="/my-implants"
          className="go-back-to-browsing-btn"
        >
          {t("Global.GoToHome")}
        </Link>

        <a
          className="go-back-to-browsing-btn"
          href="https://motiva.health/patients/"
        >
          {t("Global.GoToWebsite")}
        </a>
      </FullPageLayout>
    </>
  );
};

export default MyImplantsInformationThanksYouForVisitingPage;
