import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ButtonFilled from "../Button/ButtonFilled";
import LinkSimple from "../Link/LinkSimple";
import Modal from "./Modal";
import EstaAPIKit from "../../../data/EstaAPIKit";
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {StaticFormContext} from "../../../contexts/StaticFormContext";
import {UserContext} from "../../../contexts/UserContext";
import {delay} from "../../../utils";
import {isEmpty, isNull} from "lodash";
import SpinnerSmall from "../Spinner/SpinnerSmall";

const RegistrationNFCModal = ({onClose, processNFCRegister}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const estaAPIKit = new EstaAPIKit();
  const [dataRegistration, setDataRegistration] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    setCurrentWizard,
    setHasStartedRegistration,
    setRegisterImplantsFor,
    setSerialNumberLeft,
    setLeftVC,
    setSerialNumberRight,
    setRightVC,
    setIsSuccessNFC
  } =
    useContext(StaticFormContext);
  const {setMe} = useContext(UserContext);

  useEffect(() => {
    let dataNFC = JSON.parse(localStorage.getItem('nfc'));

    const implantLeft = dataNFC.serialLeft?.split(',');
    const implantRight = dataNFC.serialRight?.split(',');

    const fetchData = async () => {
      setIsLoading(true)
      const deviceSerials = [
        !isNull(dataNFC.serialLeft) ? implantLeft[0] : null,
        !isNull(dataNFC.serialRight) ? implantRight[0] : null
      ].filter(serial => serial !== null);

      const deviceInfoArray = [];

      for (const serial of deviceSerials) {
        const response = await estaAPIKit.getDeviceInfo(serial);
        const data = await response.json();
        deviceInfoArray.push(data);
      }

      setDataRegistration(deviceInfoArray);
      setIsLoading(false)
    };

    fetchData();
  }, []);

  const ProcessRegistration = async () => {

    let dataNFC = JSON.parse(localStorage.getItem('nfc'));
    setHasStartedRegistration(true);
    setCurrentWizard('breast');
    setRegisterImplantsFor('both')
    setMe((prev) => ({...prev, country_id: 157}));

    navigate("/registration/implant-registration-with-sn");
    await delay(200);

    const implantLeft = dataNFC.serialLeft?.split(',');
    const implantRight = dataNFC.serialRight?.split(',');

    if (dataNFC?.serialLeft) {
      setSerialNumberLeft(implantLeft[0]);
      setLeftVC(implantLeft[1]);
    }

    if (dataNFC?.serialRight) {
      setSerialNumberRight(implantRight[0]);
      setRightVC(implantRight[1]);
    }
    setIsSuccessNFC(true)
  }

  return (
    <Modal title={t("Global.AddImplants")} onClose={onClose} overridesBox="module__fullbox">
      {isLoading ? <SpinnerSmall/>
        :
        <>
          <Grid container>
            {dataRegistration.map((data, index) => (
              <Grid key={index} item md={12} mt={1}>
                <Card elevation={0}>
                  <CardContent sx={{
                    border: 'solid 1px #e9e9e9',
                    borderRadius: 2,
                    textAlign: 'left',
                    paddingBottom: {xs: '11px !important', md: '16px !important'}
                  }}>
                    <Grid container spacing={2}>
                      <Grid item
                            md={12}>{index === 0 ? t("Implant-Registration.LeftImplant") : t("Implant-Registration.RightImplant")}</Grid>
                      <Grid item xs={12} md={5}>
                        <Box fontSize={13}>{t("Patient-List-Implant-Card.Family")}: {data.family}</Box>
                        <Box fontSize={13}>{t("Patient-List-Implant-Card.Projection")}: {data.projection}</Box>
                        <Box fontSize={13}>{t("Patient-List-Implant-Card.Reference")}: {data.reference}</Box>
                      </Grid>
                      <Grid item xs={12} md={7} sx={{paddingTop: {xs: '0px !important'}}}>
                        <Box fontSize={13}>{t("Patient-List-Implant-Card.SerialNumber")}: {data.serialNumber}</Box>
                        <Box fontSize={13}>{t("Patient-List-Implant-Card.Texture")}: {data.texture}</Box>
                        <Box fontSize={13}>{t("Patient-List-Implant-Card.Volume")}: {data.volume}</Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      }

      <ButtonFilled
        title={t("Global.Process")}
        onClick={() => ProcessRegistration()}
      />
    </Modal>
  );
};

export default RegistrationNFCModal;
