import React from "react";
import "./Modal.scss";

const Modal = ({ title, children, onClose, overridesBox }) => {
  return (
    <div className="module">
      <div className={`module__box ${overridesBox}`}>
        <p className="module__box__close-btn" onClick={() => onClose()}>
          X
        </p>
        <h1>{title}</h1>
        {children}
      </div>
    </div>
  );
};

export default Modal;
