import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { StaticFormContext } from "../../../contexts/StaticFormContext.js";
import { UserContext } from "../../../contexts/UserContext.js";

import { ImplantCard } from "../ImplantCardView/ImplantCardView";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const CardButtonContainer = styled.label`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const RadioButton = styled.input.attrs({ type: "radio" })`
  flex-shrink: 1;
  display: inline-block;
  width: max-content !important;
`;

const CardButton = ({ onChange, lang, currentWizard, ...props }) => {
  return (
    <CardButtonContainer>
      <RadioButton
        style={{ marginBottom: "17px" }}
        checked={props.selected}
        onChange={onChange}
        name="cartType"
        value={props.type}
      />
      <ImplantCard
        type={props.type}
        lang={lang}
        isBreast={currentWizard === "breast"}
      />
    </CardButtonContainer>
  );
};

export const CardTypeSelect = () => {
  const defaultAmountOfCardsToShow = 2;
  const { me } = useContext(UserContext);
  const { implantCardType, setImplantCardType, currentWizard } =
    useContext(StaticFormContext);
  const [cardsOrder, setCardsOrder] = useState([0, 1, 2]);
  const [lang, setLang] = useState(me?.country?.[0]);

  const cardsOrderForCountry = (countryCode) =>
    countryCode === 190
      ? [0, 1] // Brazil
      : [0, 1];

  const handleSelectCardType = ({ target }) => {
    const { value } = target;
    setImplantCardType(parseInt(value));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (me) {
      const { country_id } = me;
      setLang(country_id);
      setCardsOrder(cardsOrderForCountry(country_id));
    }
  }, [me]);

  return (
    <Container>
      {cardsOrder.slice(0, defaultAmountOfCardsToShow).map((i) => (
        <CardButton
          key={i}
          type={i}
          selected={implantCardType === i}
          onChange={handleSelectCardType}
          lang={lang}
          currentWizard={currentWizard}
        />
      ))}
    </Container>
  );
};
