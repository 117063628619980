import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import FormESN from "../../library/Form/FormESN";
import { StaticFormContext } from "../../../contexts/StaticFormContext";
import { useSelector } from "react-redux";

const ImplantForm = ({
  title,
  placement,
  setIsImplantValid,
  showCardClue,
  leftError,
  rightError,
  setRightError,
  setLeftError,
  handleValidation,
  setHandleValidation,
  isLeftRender,
}) => {
  return (
    <>
      <div
        className={`${
          placement === "right" && "pt-1"
        }  font-black d-inline mt-4 mb-3`}
      >
        <span className="pr-3">{title}</span>
      </div>
      <div className="form-wrapper-wrapper">
        <FormESN
          setIsImplantValid={setIsImplantValid}
          showCardClue={showCardClue}
          placement={placement}
          leftError={leftError}
          rightError={rightError}
          setRightError={setRightError}
          setLeftError={setLeftError}
          handleValidation={handleValidation}
          setHandleValidation={setHandleValidation}
          isLeftRender={isLeftRender}
          required
        />
      </div>
    </>
  );
};

export const ImplantsFormESN = ({ setIsValid, showCardClue, showValid }) => {
  const { t } = useTranslation();
  const [isInconsistent, setIsInconsistent] = useState(false);
  const [isLeftImplantValid, setIsLeftImplantValid] = useState(false);
  const [isRightImplantValid, setIsRightImplantValid] = useState(false);
  const [duplicatedSN, setDuplicateSN] = useState(false);
  const [leftError, setLeftError] = useState("");
  const [rightError, setRightError] = useState("");
  const [handleValidation, setHandleValidation] = useState(false);
  const {
    serialNumberLeft,
    serialNumberRight,
    shouldRenderLeftForm,
    shouldRenderRightForm,
  } = useContext(StaticFormContext);
  const preRegistration = useSelector((state) => state.preRegisterSlice);

  const preValidation = () =>
    checkIfAllInputsContainValue() && checkIfInputsAreNotDuplicated();

  const checkIfInputsAreNotDuplicated = () =>
    serialNumberLeft === "" || serialNumberLeft !== serialNumberRight;

  const checkIfAllInputsContainValue = () =>
    (!shouldRenderLeftForm() ||
      (serialNumberLeft && serialNumberLeft.trim().length === 15)) &&
    (!shouldRenderRightForm() ||
      (serialNumberRight && serialNumberRight.trim().length === 15));

  function isFormValid() {
    return (
      checkIfAllInputsContainValue() &&
      checkIfInputsAreNotDuplicated() &&
      (!shouldRenderLeftForm() || isLeftImplantValid) &&
      (!shouldRenderRightForm() || isRightImplantValid)
    );
  }

  useEffect(() => {
    //if something changes on the inputs disable next
    const readyForValidation = preValidation();
    setHandleValidation(readyForValidation);
  }, [serialNumberLeft, serialNumberRight]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsValid(isFormValid());
    setDuplicateSN(
      checkIfAllInputsContainValue() && !checkIfInputsAreNotDuplicated(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    serialNumberLeft,
    serialNumberRight,
    isLeftImplantValid,
    isRightImplantValid,
  ]);

  useEffect(() => {
    let implantL = preRegistration?.implantsValid?.implantL;
    let implantR = preRegistration?.implantsValid?.implantR;

    if (
      (implantL === true && implantR === false) ||
      (implantL === false && implantR === true)
    ) {
      setIsInconsistent(true);
    } else {
      setIsInconsistent(false);
    }
  }, [
    preRegistration?.implantsValid?.implantL,
    preRegistration?.implantsValid?.implantR,
  ]);

  return (
    <>
      {shouldRenderLeftForm() && (
        <ImplantForm
          showCardClue={showCardClue}
          title={t("Implant-Registration.LeftImplant")}
          placement="left"
          setIsImplantValid={setIsLeftImplantValid}
          leftError={leftError}
          rightError={leftError}
          setRightError={setLeftError}
          setLeftError={setLeftError}
          handleValidation={handleValidation}
          setHandleValidation={setHandleValidation}
          isLeftRender={true}
        />
      )}

      {shouldRenderRightForm() && (
        <ImplantForm
          showCardClue={showCardClue}
          title={t("Implant-Registration.RightImplant")}
          placement="right"
          setIsImplantValid={setIsRightImplantValid}
          leftError={rightError}
          rightError={rightError}
          setRightError={setRightError}
          setLeftError={setRightError}
          handleValidation={handleValidation}
          setHandleValidation={setHandleValidation}
        />
      )}
      {duplicatedSN && (
        <div className="validation-message">
          <p>
            {preRegistration.isESN
              ? t("ImplantRegistrationError.SameESN")
              : t("ImplantRegistrationError.SameSN")}
          </p>
        </div>
      )}
      {isInconsistent && (
        <>
          <div className="validation-message">
            <p className="ml-1">
              {t("ImplantRegistrationError.InconsistentType")}
            </p>
          </div>
        </>
      )}
    </>
  );
};
