import React, {useState, useCallback, useRef} from 'react';
import {Box, Typography} from "@material-ui/core";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {DialogComponent} from "../Dialog";
import {isEmpty} from "lodash";
import {Scanner} from "../Icons/Scanner";
import Webcam from "react-webcam";


export default function ScannerCode({ extract, type, title}) {

  const [openScan, setScan] = useState(false);
  const webcamRef = useRef(null);

  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();

      const regex = new RegExp(`\\bdata:image/png;base64,\\b`, 'g');
      const imageBase64 = imageSrc.replace(regex, '');

      extract(imageBase64);
      handleCloseScan();

    },
    [webcamRef]
  );

  const handleCloseScan = (type) => {
    setScan(false);
  };
  const handleOpenScan = () => {
    setScan(true);
  }

  const content =()=> (
    <>
      <Box mb={4}>
        <Typography variant="h6">
          {title}
        </Typography>
      </Box>

      <Webcam
        width={400}
        height={300}
        ref={webcamRef}
        screenshotQuality={0.5}
        screenshotFormat='image/png'
      />
      <button onClick={capture}>Capture photo</button>
    </>
  )

  return (
    <>
      <Box onClick={()=>handleOpenScan()}>
        <Scanner />
      </Box>

      {openScan &&
        <DialogComponent open={openScan} close={handleCloseScan} content={content()}></DialogComponent>
      }
    </>
  );
};
