import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./PreImplantRegistrationPage.scss";
import "../../components/library/BackgroundImage/BackgroundImage.scss";
import { UserContext } from "../../contexts/UserContext";
import EstaAPIKit from "../../data/EstaAPIKit";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";

export default function PreImplantRegistrationPage() {
  const { setMe, me } = useContext(UserContext);
  const estaApiKit = new EstaAPIKit();
  const { setUserError } = useContext(UserContext);
  const { t } = useTranslation();

  function handleHasMotivaBreastImplants() {
    const newState = { ...me, hasMotiva: true };
    setMe(newState);
    putMe(newState);
  }

  function handleHasOtherBrandImplants() {
    const newState = { ...me, hasMotiva: false };
    setMe(newState);
    putMe(newState);
  }

  function handleDoesNotKnow() {
    const newState = { ...me, hasMotiva: false };
    setMe(newState);
    putMe(newState);
  }

  function putMe(state) {
    estaApiKit
      .putMe(state)
      .then(res => {
        estaApiKit
          .getMe()
          .then(res => res.json())
          .then(data => console.log(data));
      })
      .catch(error => setUserError("error"));
  }

  return (
    <FullPageLayout
      backgroundImgPath="homepage-bg-1"
      position="center"
      description={t("Preimplant-Registration-Page-Two.Title")}
      buttonList={[
        {
          title: t("Global.Motiva"),
          onClick: handleHasMotivaBreastImplants,
          nextLink: "/registration/preimplant-registration-three",
        },
        {
          title: t("Global.OtherBrand"),
          onClick: handleHasOtherBrandImplants,
          nextLink: "/my-implants/thank-you-for-visiting",
        },
        {
          title: t("Global.IDontKnow"),
          onClick: handleDoesNotKnow,
          nextLink: "/my-implants/thank-you-for-visiting",
        },
      ]}
    />
  );
}
