import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./PreImplantRegistrationPage.scss";
import "../../components/library/BackgroundImage/BackgroundImage.scss";
import { UserContext } from "../../contexts/UserContext";
import EstaAPIKit from "../../data/EstaAPIKit";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import {Helmet} from "react-helmet-async";
import {AccountContext} from "../../contexts/AccountContext";
import {useTagManager} from "../../hooks/useTagManager";
import {isUndefined} from "lodash";

export default function PreImplantRegistrationPage() {
  const { t } = useTranslation();
  const estaApiKit = new EstaAPIKit();
  const { setMe, me } = useContext(UserContext);
  const { setUserError } = useContext(UserContext);

  const {accountInfo} = useContext(AccountContext);
  const gtm = useTagManager();

  const handleHasMotivaBreastImplants = () => {
    const newState = { ...me, hasMotiva: true, hasBreastImplants: true };
    setMe(newState);
    putMe(newState);

    gtm({
      category: 'form',
      action: 'patient-form-submit _step_3',
      event: 'interaction',
      segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
    });
  };

  const handleHasOtherBrandImplants = () => {
    const newState = { ...me, hasMotiva: false, hasBreastImplants: false };
    setMe(newState);
    putMe(newState);
  };

  function putMe(state) {
    estaApiKit
      .putMe(state)
      .then((res) => {
        estaApiKit.getMe().then((res) => res.json());
      })
      .catch((error) => setUserError("error"));
  }

  return (
    <>
      <Helmet>
        <title>Patient register implant | MotivaImagine®</title>
        <meta name="pageType" content="Patient Implant Registration Page" />
      </Helmet>
      <FullPageLayout
        backgroundImgPath="homepage-bg-5"
        position="center"
        description={t("Preimplant-Registration-Page-YesNo.Title")}
        buttonList={[
          {
            title: t("Global.YesRegister"),
            onClick: handleHasMotivaBreastImplants,
            nextLink: "/registration/implant-type",
          },
          {
            title: t("No"),
            onClick: handleHasOtherBrandImplants,
            nextLink: "/my-implants/thank-you-for-visiting",
          },
        ]}
      />
    </>
  );
}
