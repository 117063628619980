import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export const TextAreaComponent = (props) => {
  const { formik, title, name, isDisabled = false, rows, required } = props;

  const meta = formik?.getFieldMeta(name);

  return (
    <Grid container>
      <Grid item xs={12} md={5} display="flex" alignItems="center">
        <Typography variant="body1" fontSize={16} fontWeight={300}>
          {title}
          {required && "*"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <textarea
          name={name}
          className="form-control"
          rows={rows}
          style={{ width: "100%" }}
          value={meta.value}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          disabled={isDisabled}
        ></textarea>
        <Box mt={1}>
          {meta?.error && meta?.touched ? (
            <Typography variant="subtitle2" color="red">
              {meta.error}
            </Typography>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};
