import React,{useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getFeatures} from "../../../store/slices/featureFlagSlice";


function getFavicon() {
  return document.querySelector("link[rel~='icon']");
}

export const Logo = ({type}) => {
  const featureFlag = useSelector((state) => state.featureFlagSlice.features);
  const dispatch = useDispatch();

  const logoImagineColor = `${process.env.PUBLIC_URL}/logo/imagine/logo-color.png`;
  const isoTypeImagineColor = `${process.env.PUBLIC_URL}/logo/imagine/isotype-color.svg`;

  const logoESTAColor = `${process.env.PUBLIC_URL}/logo/establishment/logo-color.png`;
  const isoTypeESTAColor = `${process.env.PUBLIC_URL}/logo/establishment/isotype-color.png`;

  let isFeatureStatus = () => (featureFlag && featureFlag.filter((feat)=> feat.feature === "esta_branded")[0].status);

  useEffect(() => {
    dispatch(getFeatures());
  }, []);

  useEffect(() => {
    const favicon = getFavicon();
    favicon.href = isFeatureStatus() === 0 ? '/favicon.ico' : '/favicon-esta.ico';
  }, [featureFlag]);


  return (
    <>
      {type === "logo" && (isFeatureStatus() === 0 ? <img src={logoImagineColor} width={200} alt="logo-imagine"/> : <img src={logoESTAColor} width={250} alt="logo-establishment"/>)}
      {type === "isotype" && (isFeatureStatus() === 0 ? <img src={isoTypeImagineColor} width={70} alt="isotype-imagine"/> : <img src={isoTypeESTAColor} width={80} alt="isotype-establishment"/>)}
    </>
  );
}
