import React, { useState, useEffect } from "react";
import CardSelectVerticalItem from "./CardSelectVerticalItem";

export default function CardSelectVerticalList(props) {
  const { verticalItems, showImage, showRadioBtn, handleOnChange } = props;

  //removed removeUndefined since its not defined and prevented cards from being rendered
  //if(removeUndefined && verticalItem.title !== "Undefined")
  const [isSelected, setIsSelected] = useState(null);

  useEffect(() => {
    if (handleOnChange) {
      handleOnChange(isSelected);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  return (
    <div>
      {verticalItems.map((verticalItem) => {
        if (verticalItem.title !== "Undefined") {
          return (
            <CardSelectVerticalItem
              key={`card-select-vertical-item-${verticalItem.id}`}
              id={verticalItem.id}
              isSelected={isSelected}
              setIsSelected={setIsSelected}
              title={verticalItem.title}
              description={
                verticalItem.description ||
                "The quick brown fox jumps over the lazy dog."
              }
              image={verticalItem.image}
              showImage={showImage}
              showRadioBtn={showRadioBtn}
              // onClick={history.push("/registration/surgery-indication")}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}
