import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonFilled from "../Button/ButtonFilled";
import Modal from "./Modal";
import { InputComponent } from "../FormComponents/Input";
import { Box } from "@mui/material";
import { CountryAutocomplete } from "../FormComponents/CountryAutocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import LinkSimple from "../Link/LinkSimple";
import { RequiredText } from "../Form/RequiredText";
import { formatFullAddress } from "../../../utils";
import { useCountries } from "../../../hooks/useCountries";
import ButtonComponent from "../Button/ButtonComponent";
import styles from "./AddressModal.module.scss";

const AddressModal = ({ initialValues, onClose, onSubmit, addresses = [] }) => {
  const { t } = useTranslation();
  const { countryList } = useCountries();

  const hasAddresses = addresses && addresses.length > 0;
  const [showFormScreen, setShowFormScreen] = useState(!hasAddresses);

  const requiredText = t("Required.Field");

  const formik = useFormik({
    initialValues: initialValues ?? {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      country: null,
    },
    validationSchema: Yup.object().shape({
      addressLine1: Yup.string().required(requiredText),
      addressLine2: Yup.string().notRequired(),
      city: Yup.string().required(requiredText),
      state: Yup.string().required(requiredText),
      postalCode: Yup.string().required(requiredText),
      country: Yup.string().required(requiredText).typeError(requiredText),
    }),
    onSubmit: async (values) => {
      await onSubmit(values);
      onClose();
    },
  });

  const handleClickAddress = async (address) => {
    await onSubmit(address);
    onClose();
  };

  return (
    <Modal title={t("Global.Address")} onClose={onClose}>
      <form onSubmit={formik.handleSubmit} className="ignore-modal-style">
        {hasAddresses && !showFormScreen && (
          <Box sx={{ mb: 8 }}>
            <p className="ignore-modal-style">
              {t("AddressModal.SelectOneOfTheFollowing")}
            </p>

            {addresses.map((address, index) => (
              <ButtonComponent
                key={index}
                title={formatFullAddress(address, countryList)}
                colorScheme="outlinedPurple"
                type="button"
                onClick={() => handleClickAddress(address)}
                className={styles.addressButton}
              />
            )) ?? null}

            <button
              type="button"
              className={styles.addNewAddressButton}
              onClick={() => setShowFormScreen(true)}
            >
              {t("AddressModal.OrAddNewAddress")}
            </button>
          </Box>
        )}

        {showFormScreen && (
          <Box sx={{ mb: 4 }}>
            <Box mt={2}>
              <InputComponent
                formik={formik}
                title={t("Global.AddressLine1")}
                name="addressLine1"
                required
              />
            </Box>

            <Box mt={2}>
              <InputComponent
                formik={formik}
                title={t("Global.AddressLine2")}
                name="addressLine2"
              />
            </Box>

            <Box mt={2}>
              <InputComponent
                formik={formik}
                title={t("Global.City")}
                name="city"
                required
              />
            </Box>

            <Box mt={2}>
              <InputComponent
                formik={formik}
                title={t("Global.State")}
                name="state"
                required
              />
            </Box>

            <Box mt={2}>
              <InputComponent
                formik={formik}
                title={t("Global.PostalCode")}
                name="postalCode"
                required
              />
            </Box>

            <Box mt={2}>
              <CountryAutocomplete
                formik={formik}
                name="country"
                title={t("Surgery-Information.Country")}
                required
              />
            </Box>

            <RequiredText />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {showFormScreen && (
            <ButtonFilled title={t("Global.Save")} type="submit" />
          )}

          <LinkSimple
            cancel
            onClick={onClose}
            to="#"
            label={t("Global.Cancel")}
          />
        </Box>
      </form>
    </Modal>
  );
};

export default AddressModal;
