import React, {useContext} from "react";
import "./NotFound.scss";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AccountContext} from "../../contexts/AccountContext";
import {Helmet} from 'react-helmet-async';

export default function NotFound() {
  const { t } = useTranslation();
  const { accountInfo } = useContext(AccountContext);
  return (
    <>
      <Helmet>
        <title>MotivaImagine® | Not Found</title>
      </Helmet>
      <div className="background__page">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-7"></div>
            <div className="col-md-12 col-lg-5 vh-100 d-flex align-items-center justify-content-center">
              <div>
                <h1 className="fs-100 color-coffee">
                  404
                </h1>
                <h1 className="error-page__title color-coffee">
                  {t("ErrorPage.404.title")}
                </h1>
                <h1 className="error-page__subtitle color-coffee my-4">
                  {t(
                    "ErrorPage.404.description"
                  )}
                </h1>

                <div className="w-100 d-flex justify-content-center">
                  <Link
                    to={!accountInfo ? "/" : accountInfo?.data?.userType === "Surgeon" ? "/doctor" : "/my-implants"}
                    className="go-back-to-browsing-btn"
                  >
                    {t("Global.GoToHome")}
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
