import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useCountries } from "../../../../hooks/useCountries";
import { InputComponent } from "../../FormComponents/Input";
import EstaAPIKit from "../../../../data/EstaAPIKit";
import { CountryAutocomplete } from "../../FormComponents/CountryAutocomplete";
import { formatFullAddress } from "../../../../utils";

export const PatientInformationForm = ({
  formik,
  setShowAddressModal,
  setShowPhoneNumberModal,
}) => {
  const { t } = useTranslation();
  const { countryList } = useCountries();
  const estaAPIKit = new EstaAPIKit();
  const [isLoading, setIsLoading] = useState(false);

  const address = formik.getFieldMeta("patientInformation.address").value;
  let fullAddress = address ? formatFullAddress(address, countryList) : "";

  const phoneNumber = formik.getFieldMeta("patientInformation.phone").value;

  // Populate fields using the fetched patient data
  const fetchedPatient = formik.values?.fetchedPatient;
  useEffect(() => {
    formik.setFieldValue(
      "patientInformation.firstName",
      fetchedPatient?.firstName ?? "",
    );
    formik.setFieldTouched("patientInformation.firstName", false, false);

    formik.setFieldValue(
      "patientInformation.lastName",
      fetchedPatient?.lastName ?? "",
    );
    formik.setFieldTouched("patientInformation.lastName", false, false);

    const countryId = countryList.find(
      (country) => country.label === fetchedPatient?.country,
    )?.id;
    formik.setFieldValue("patientInformation.country", countryId ?? "");
    formik.setFieldTouched("patientInformation.country", false, false);

    formik.setFieldValue(
      "patientInformation.SSN",
      fetchedPatient?.SSNumber ?? "",
    );
    formik.setFieldTouched("patientInformation.SSN", false, false);

    const phoneNumber = fetchedPatient?.numberPhones?.[0]?.number;
    formik.setFieldValue("patientInformation.phone", phoneNumber ?? "");
    formik.setFieldTouched("patientInformation.phone", false, false);

    const address = fetchedPatient?.address?.[0];
    if (address) {
      formik.setFieldValue(
        "patientInformation.address.addressLine1",
        address?.addressLine1 ?? "",
      );
      formik.setFieldValue(
        "patientInformation.address.addressLine2",
        address?.addressLine2 ?? "",
      );
      formik.setFieldValue(
        "patientInformation.address.city",
        address?.city ?? "",
      );
      formik.setFieldValue(
        "patientInformation.address.state",
        address?.state ?? "",
      );
      formik.setFieldValue(
        "patientInformation.address.postalCode",
        address?.postalCode ?? "",
      );
      formik.setFieldValue(
        "patientInformation.address.country",
        parseInt(address?.country ?? 0),
      );
    } else {
      formik.setFieldValue("patientInformation.address", null);
    }
  }, [fetchedPatient]);

  return (
    <>
      <Box mb={6}>
        <h3>{t("PreRegistration.PatientSummary", { lng: "en" })}</h3>
      </Box>
      <Box>
        <InputComponent
          formik={formik}
          title={t("PreRegistration.PatientEmail", { lng: "en" })}
          name="patientInformation.email"
          isLoading={isLoading}
          isDisabled={isLoading}
          t={t}
          handleBlur={(e) => {
            formik.handleBlur(e);

            const meta = formik.getFieldMeta("patientInformation.email");
            if (!meta.error) {
              setIsLoading(true);
              estaAPIKit
                .getPatientInformation(meta.value)
                .then((response) => response.json())
                .then((data) => {
                  setIsLoading(false);
                  const isFound = data.status === 200;

                  if (isFound) {
                    const fetchedPatient = data.data;

                    fetchedPatient.address = fetchedPatient.address.map(
                      (address) => ({
                        addressLine1: address.address_line_1,
                        addressLine2: address.address_line_2,
                        city: address.city,
                        state: address.state,
                        postalCode: address.postal_code,
                        country: parseInt(address.country),
                      }),
                    );

                    formik.setFieldValue("fetchedPatient", fetchedPatient);
                  } else {
                    formik.setFieldValue("fetchedPatient", undefined);
                  }
                });
            }
          }}
          required
        />
      </Box>
      <Box mt={2}>
        <InputComponent
          formik={formik}
          title={t("PreRegistration.PatientFirstName", { lng: "en" })}
          name="patientInformation.firstName"
          required
        />
      </Box>
      <Box mt={2}>
        <InputComponent
          formik={formik}
          title={t("PreRegistration.PatientLastName", { lng: "en" })}
          name="patientInformation.lastName"
          required
        />
      </Box>
      <Box mt={2}>
        <InputComponent
          formik={formik}
          title={t("Global.SSN")}
          name="patientInformation.SSN"
        />
      </Box>
      <Box mt={2}>
        <InputComponent
          formik={formik}
          title={t("Global.Address")}
          value={fullAddress}
          handleFocus={(e) => {
            e.preventDefault();
            e.target.blur();
            setShowAddressModal(true);
          }}
          handleChange={() => {}}
          handleBlur={() => {}}
        />
      </Box>
      <Box mt={2}>
        <InputComponent
          formik={formik}
          title={t("Global.Phone")}
          value={phoneNumber}
          handleFocus={(e) => {
            e.preventDefault();
            e.target.blur();
            setShowPhoneNumberModal(true);
          }}
          handleChange={() => {}}
          handleBlur={() => {}}
        />
      </Box>
      <Box mt={2} mb={3}>
        <CountryAutocomplete
          formik={formik}
          name="patientInformation.country"
        />
      </Box>
    </>
  );
};
