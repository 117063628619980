import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight, ChevronLeft } from "react-bootstrap-icons";
import "./LinkBtn.scss";

export default function LinkSimple({
  to,
  label,
  disabled,
  onClick,
  back,
  cancel,
  white,
}) {
  return (
    <Link
      to={disabled ? "#" : to}
      className={`${white ? "white-version my-link" : "my-link"} ${
        disabled && "disabled-styles"
      }`}
      onClick={disabled ? () => {} : onClick}
    >
      {/* Removes svg icons if button is 'cuncel' */}
      {!cancel ? (
        back ? (
          <>
            <ChevronLeft
              size="8"
              style={{ fill: "#805474", strokeWidth: "3" }}
            />
            {label}
          </>
        ) : (
          <>
            {label}
            <ChevronRight size="8" style={{ fill: "#805474" }} />
          </>
        )
      ) : (
        label
      )}
    </Link>
  );
}
