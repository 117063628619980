import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import styles from "./LanguageSelector.module.scss";
import {ReactComponent as Globe} from "../../../assets/langIcon2.svg";
import {Dropdown} from "react-bootstrap";
import {useTagManager} from "../../../hooks/useTagManager";
import {AccountContext} from "../../../contexts/AccountContext";
import {isNull, isUndefined} from "lodash";
import { useSearchParams } from 'react-router-dom';

export default function LanguageSelector({isLogged = false}) {
  const {t} = useTranslation();
  const [searchParams] = useSearchParams();
  const {accountInfo} = useContext(AccountContext);
  const gtm = useTagManager();

  const languages = [
    {label: t("Global.English"), lang: "en"},
    {label: t("Global.Spanish"), lang: "es"},
    {label: t("Global.Italian"), lang: "it"},
    {label: t("Global.German"), lang: "de"},
    {label: t("Global.French"), lang: "fr"},
    {label: t("Global.Swedish"), lang: "sv"},
    {label: t("Global.Danish"), lang: "da"},
    {label: t("Global.Norwegian"), lang: "nn-NO"},
    {label: t("Global.Portuguese"), lang: "pt"},
  ];

  function changeLanguage(lang) {
    i18next.changeLanguage(lang);
    window.chosenLanguage = lang;

    gtm({
      category: 'language',
      action: 'chosenLanguage',
      event: 'interaction',
      segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
    });
  }

  function renderDropdownItem(label, lang, index) {
    return (
      <Dropdown.Item
        key={index}
        onClick={() => changeLanguage(lang)}
        className={styles.dropdownItem}
      >
        {label}
      </Dropdown.Item>
    );
  }

  function renderDropdown() {
    return (
      <div>
        {languages.map((item, index) =>
          renderDropdownItem(item.label, item.lang, index)
        )}
      </div>
    );
  }

  useEffect(()=>{
    let lang = searchParams.get('lang');
    const langExists = languages.some((language) => language.lang === lang);

    if((!isNull(lang) && langExists)){
      changeLanguage(lang)
    }
  },[])

  return (
    <Dropdown>
      <Dropdown.Toggle
        as="span"
        className={styles.dropdownToggle}
        variant="success"
      >
        <Globe className={styles.iconImage}/>
      </Dropdown.Toggle>
      <Dropdown.Menu>{renderDropdown()}</Dropdown.Menu>
    </Dropdown>
  );
}
