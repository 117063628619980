import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
// import ClinicSearch from "../../components/library/Select/ClinicSearch";
import {UserContext} from "../../contexts/UserContext";
import ButtonFilled from "../../components/library/Button/ButtonFilled.jsx";
import LinkBack from "../../components/library/Link/LinkBack";
import LinkAction from "../../components/library/Link/LinkAction";
import EstaAPIKit from "../../data/EstaAPIKit";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import CreateTicketModule from "../../components/library/PopUpModules/CreateTicketModule";
import {StaticFormContext} from "../../contexts/StaticFormContext";
import {SurgeryInformationForm} from "../../components/library/Form/SurgeryInformationForm";
import Loader from "react-loader-spinner";
import Registration from "../../components/library/Registration.jsx";
import {useNavigate} from "react-router-dom";
import {openErrorDialog} from "../../store/slices/uiSlice";
import {useDispatch, useSelector} from "react-redux";
import {setSupportModalOn} from "../../store/slices/supportSlice";
import {Helmet} from 'react-helmet-async';
import {AccountContext} from "../../contexts/AccountContext";
import {useTagManager} from "../../hooks/useTagManager";
import {isUndefined} from "lodash";

export default function SurgeryInformationPage() {
  const {me, setMe, registerMeDevicesBreast} = useContext(UserContext);
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const preRegistration = useSelector((state) => state.preRegisterSlice);
  const {modal, modal_type} = useSelector((state) => state.supportSlice);

  const {accountInfo} = useContext(AccountContext);
  const gtm = useTagManager();

  const estaAPIKit = new EstaAPIKit();
  const {
    currentWizard,
    setMsgError,
    shouldRenderLeftForm,
    shouldRenderRightForm,
    goToStepAfterRegistration,
  } = useContext(StaticFormContext);
  const {t} = useTranslation();
  const dispatch = useDispatch();


  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const loaderOff = () => setIsLoading(false);
  const loaderOn = () => setIsLoading(true);

  //resets fields on load
  useEffect(() => {
    loaderOff();
    if (me?.surgeon) {
      setMe((prevState) => ({ ...prevState, surgeon: null }));
    }
    if (me?.country) {
      setMe((prevState) => ({ ...prevState, country: null }));
    }
    if (me?.surgeryDate) {
      setMe((prevState) => ({ ...prevState, surgeryDate: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (me?.country_id === undefined) {
      navigate("/registration/implant-type");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //validate all fields are filled

  function handleOnClick(e) {
    loaderOn();
    registerMeDevicesBreast()
      .then((data) => {
        currentWizard === "breast"
          ? goToStepAfterRegistration(data)
          : navigate("/registration/success");
      })
      .catch((data) => {
        data?.code && setMsgError(estaAPIKit.errorMapping(data?.code));
        dispatch(openErrorDialog({
          errorDialog: {
            open: true
          },
        }));
      });

    gtm({
      category: 'Send_request_implant',
      action: 'Submit_implant_request',
      event: 'interaction',
      segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
    });
  }

  function createTicket() {
    dispatch(setSupportModalOn('fullForm'));
    window.scrollTo(0, 0);
  }

  return (
    <>
      <Helmet>
        <title>Additional Surgery Information | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>
      {(modal && modal_type === 'fullForm') && (
        <CreateTicketModule
          ticketForSN={!me.isESN}
          ticketForESN={me.isESN}
          leftSideImplant={shouldRenderLeftForm()}
          rightSideImplant={shouldRenderRightForm()}
        />
      )}
      <Registration step={currentWizard === "breast" ? 9 : 6}>
        <FullPageLayout
          backgroundImgPath="homepage-bg-5"
          position="right"
          title={t("Surgery-Information.Title")}
          description={t("Surgery-Information.Description")}
          buttonList={[]}
        >
          <div className="surgery-information-form">
            <SurgeryInformationForm setIsValid={setIsValid} />

            <div className="fifty-fifty-layout__btn-container--middle">
              <LinkAction
                onClick={createTicket}
                label={t("Global.HavingTrouble")}
              />
            </div>

            <div className="pre-next-btns">
              {!preRegistration.isMiaImplants &&
                <div className="pre-next-btns__btn">
                  <LinkBack
                    linkBack={
                      currentWizard === "breast"
                        ? "/registration/surgery-indication"
                        : "/registration/implant-register-method"
                    }
                  />
                </div>
              }

              <div className="pre-next-btns__btn">
                {!isLoading && (
                  <>
                    <ButtonFilled
                      inline
                      title={t("Global.Submit")}
                      disabled={!isValid}
                      onClick={handleOnClick}
                    />
                  </>
                )}
                <Loader
                  type="TailSpin"
                  color="#805474"
                  height={30}
                  width={30}
                  visible={isLoading}
                />
              </div>
            </div>
          </div>
        </FullPageLayout>
      </Registration>
    </>
  );
}
