import DatePicker from "react-datepicker";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import EstaAPIKit from "../../../data/EstaAPIKit";
import "./CreateTicketModule.scss";
import SelectComponent from "../Select/Select";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../../contexts/UserContext";
import {StaticFormContext} from "../../../contexts/StaticFormContext";
import ButtonFilled from "../../../components/library/Button/ButtonFilled";
import Loader from "react-loader-spinner";
import {useDispatch, useSelector} from "react-redux";
import {setSupportModalOff} from "../../../store/slices/supportSlice";
import {useFormik} from 'formik';
import * as rdd from "react-device-detect";
import {openErrorDialog} from "../../../store/slices/uiSlice";


function padded(numb) {
  const num = String(numb);
  return num < 10 ? "0" + num : num;
}


const CreateTicketModule = ({
  general,
  paymentModule,
  ticketForSN,
  ticketForESN,
  leftSideImplant,
  rightSideImplant,
  errorBAC,
  setPaymentValid
}) => {


  const {
    patientFirstName,
    patientLastName,
    leftErrorDetail,
    rightErrorDetail,
    serialNumberLeft,
    serialNumberRight,
    leftVC,
    rightVC
  } = useContext(StaticFormContext);

  const [isValid, setIsValid] = useState(false);
  const [surgeryDate] = useState("");
  const [surgeryCountry, setSurgeryCountry] = useState("");
  const [countryResidence, setCountryResidence] = useState("");
  const [ticketNumber, setTicketNumber] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [startDate, setStartDate] = useState(surgeryDate);
  const dispatch = useDispatch();
  const preRegisterState = useSelector((state) => state.preRegisterSlice);
  //This provides from context state
  const {me, isDoctor, buildNumber} = useContext(UserContext);
  const supportState = useSelector((state) => state.supportSlice);
  let {registrationTicketForm} = supportState;

  const navigate = useNavigate();
  const {t} = useTranslation();
  const estaAPIKit = new EstaAPIKit();


  const formik = useFormik({
    initialValues: registrationTicketForm,
    onSubmit: values => {
      handleSubmit(values);
    },
  });


  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationScreen, setConfirmationScreen] = useState(false);
  const loaderOff = () => setIsLoading(false);
  const loaderOn = () => setIsLoading(true);

  const showConfirmation = () => setConfirmationScreen(true);
  const hideConfirmation = () => setConfirmationScreen(false);
  const goHome = useRef(null);
  const datePickerRef = useRef(null);

  function fetchCountries() {
    estaAPIKit
      .getCountries()
      .then((res) => res.json())
      .then((data) => {
        setCountryList(
          data.Countries.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        );
      });
  }

  useEffect(() => {
    hideConfirmation();
  }, []);

  useEffect(() => {
    //Constructor effect
    fetchCountries();
    if (me) {
      //If is possible populated user information
      let {email, surgery_country, fullName, patientInitials, country_residence} = _get_user_information();
      formik.setFieldValue("fullName", fullName);
      formik.setFieldValue("patientInitials", patientInitials);
      formik.setFieldValue("surgery_country", surgery_country);
      formik.setFieldValue("email", email);
      formik.setFieldValue("country_residence", country_residence);
    }
    //If is possible populated implant's information
    let {implantL, implantR, code_left, code_right} = _get_implants_information();
    formik.setFieldValue("implantL", implantL);
    formik.setFieldValue("implantR", implantR);
    formik.setFieldValue("code_left", code_left);
    formik.setFieldValue("code_right", code_right);

    //If is possible populated Surgery information
    let {surgery_date, surgeryType} = _get_surgery_information();
    formik.setFieldValue("surgery_date", surgery_date);
    formik.setFieldValue("surgeryType", surgeryType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);


  const _get_ticket_type = () => {
    return isDoctor()
      ? "PreRegistration"
      : paymentModule
        ? "Warranty"
        : general
          ? "General"
          : "Registration";
  }


  const _get_full_name = () => `${me.firstName} ${me.lastName}`;


  const _get_user_information = () => {
    let {email, userCountry} = me;
    return {
      fullName: _get_full_name(),
      patientInitials: `${patientFirstName.charAt(0)}${patientLastName.charAt(0)}`.toUpperCase() || "",
      email: email || "",
      surgery_country: userCountry || "",
      country_residence: countryResidence || me.userCountry || ""
    }
  }

  const _get_implants_information = () => {
    return {
      implantL: preRegisterState.implantsInformation.implantL || serialNumberLeft || "",
      implantR: preRegisterState.implantsInformation.implantR || serialNumberRight || "",
      code_left: preRegisterState.implantsInformation.validationL || leftVC || "",
      code_right: preRegisterState.implantsInformation.validationR || rightVC || "",
    }
  }

  const _get_surgery_information = () => {
    return {
      surgery_date: preRegisterState.surgeryDate || new Date(),
      surgeryType: preRegisterState?.surgeryIndication &&
        t(`API.SurgeryIndication-${preRegisterState.surgeryIndication}`) || ""
    }
  }

  const _get_error_message = () => {
    return [leftErrorDetail, rightErrorDetail]
      .filter(Boolean)
      .join(" - ")

  }

  const _get_device_Information = () => {
    return "{" +
      '"method":"' +
      (ticketForESN ? "ESN" : "") +
      (ticketForSN ? "SN" : "") +
      '","browserName":"' +
      rdd.browserName +
      '","browserVersion":"' +
      rdd.browserVersion +
      '","mobileVendor":"' +
      rdd.mobileVendor +
      '","mobileModel":"' +
      rdd.mobileModel +
      '","deviceType":"' +
      rdd.deviceType +
      '","appVersion":"' +
      buildNumber +
      '"}';
  };


  const _surgery_date_payload = () => {
    return startDate ?
      `${padded(startDate.getFullYear())}-${padded(
        startDate.getMonth() + 1
      )}-${padded(startDate.getDate())}` : ''
  };


  //Submit To Create Ticket
  function handleSubmit(data) {
    loaderOn();
    setIsValid(false);
    let {
      description,
      implantL,
      implantR,
      fullName,
      patientInitials,
      surgeryType,
      code_left,
      code_right,
      surgery_country,
      country_residence
    } = data;
    let payload = {
      type: _get_ticket_type(),
      patientInitials: patientInitials,
      surgeryType,
      fullName,
      email: _get_user_information().email,
      implants: {
        "left": {
          "serial": implantL,
          "validationCode": code_left
        },
        right: {
          "serial": implantR,
          "validationCode": code_right
        }
      },
      tools: {
          "injector": null,
          "balloon": null
        },
      description: description?.length > 0 ? description : "-",
      surgery_date: _surgery_date_payload(),
      surgery_country,
      country_residence,
      messageError: t(`${_get_error_message()}`),
      deviceInfo: _get_device_Information(),
    }
    if (isDoctor()) {
      payload = {
        surgeryType: t(`API.SurgeryIndication-${me.surgeryIndication}`),
        ...payload,
      };
    }

    estaAPIKit
      .createTicket(payload)
      .then((res) => res.json())
      .then((data) => {
        setTicketNumber(data.ticket_number ? data.ticket_number : "#");
        loaderOff();
        setIsValid(true);
        showConfirmation();
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.log(error)
        dispatch(openErrorDialog({
          errorDialog: {
            open: true
          },
          createTicket: {
            create: true
          }
        }));
        closeTicketState();
        loaderOff();
        setIsValid(true);
      });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    //if all inputs are filled turns on the send button
    setIsValid(validateTicket());
  });

  //Close Modal Ticket Form
  const closeTicketState = () => {
    dispatch(setSupportModalOff());
    paymentModule && setPaymentValid(false);
  }

  function validateImplants() {
    if (ticketForESN) {
      //both ESN
      if (formik.values.implantL && formik.values.implantR) {
        return formik.values.implantL !== '' && formik.values.implantR !== '';
      }
      //just left ESN
      if (formik.values.implantL && !formik.values.implantR) {
        return !!formik.values.implantL;
      }
      //just right ESN
      if (formik.values.implantR && !formik.values.implantL) {
        return !!formik.values.implantR;
      }
    }
    if (ticketForSN) {
      if (formik.values.implantL && formik.values.implantR) {
        //both SN and VC
        return !!(formik.values.implantL &&
          formik.values.implantL !== '' &&
          formik.values.code_left &&
          formik.values.code_left !== '' &&
          formik.values.implantR &&
          formik.values.implantR !== '' &&
          formik.values.code_right);
      }
      if (formik.values.implantL && !formik.values.implantR) {
        //just left SN and VC
        return !!(formik.values.implantL && formik.values.code_left);
      }
      if (formik.values.implantR && !formik.values.implantL) {
        //just left SN and VC
        return !!(formik.values.implantR && formik.values.code_right);
      }
    }
    // if is a ticket from a another screen
    if (!ticketForESN && !ticketForSN)
      return true
  }

  //validates corresponding inputs not empty for implants registration tickets
  function validateTicket() {
    let surgeryCountryValid = surgeryCountry && true || general;
    let countryResidenceValid = countryResidence && true || general;
    let startDateValid = formik.values.surgery_date && true || general;
    return !!(formik.values.fullName !== '' &&
      formik.values.email !== '' &&
      surgeryCountryValid &&
      countryResidenceValid &&
      formik.values.description &&
      formik.values.description.trim().length >= 10 &&
      startDateValid &&
      validateImplants());
  }

  //validates with the regex an length if the field is valid with the corresponding format ESN or SN
  function validateInput(target, isESN) {
    if (isESN) {
      return (/^[0-9\s]+$/.test(target) || target === "") && target.length <= 15;
    } else {
      return (/^[0-9-\s]+$/.test(target) || target === "") &&
        target.length <= 11;
    }
  }

  return (
    <div className="module">
      <div className="module__box">
        <p
          className="module__box__close-btn"
          onClick={() => {
            closeTicketState();
          }}
        >
          {"X"}
        </p>
        {confirmationScreen && (
          <>
            <h2>{t("ErrorModal.Confirmation.Heading")}</h2>
            <p>{t("ErrorModal.Confirmation.Ticket")}</p>
            <p>{ticketNumber}</p>
            <p>{t("ErrorModal.Confirmation.Message")}</p>
            <button
              className="submit"
              ref={goHome}
              onClick={() => {
                navigate("/");
                closeTicketState();
              }}
            >
              {t("ErrorModal.Confirmation.Button")}
            </button>
          </>
        )}
        {!confirmationScreen && (
          <>
            {paymentModule ?
              <h1>
                {t("MaintenancePage.Title")}
              </h1>
              :
              <h1>
                {isDoctor()
                  ? t("ErrorModal.Doctor.Heading")
                  : t("ErrorModal.Heading")}
              </h1>
            }
            {paymentModule ? (
              <>
                <p style={{color: errorBAC === "" ? 'red' : 'black'}}>
                  {t("ErrorModal.Payment.Description")}
                  {errorBAC &&
                    <span> : <span  style={{color: 'red'}}>{t(errorBAC)}</span></span>
                  }
                </p>
                <button
                  className="submit"
                  onClick={() => {
                    closeTicketState();
                  }}
                >
                  {t("Global.TryAgain")}
                </button>
                <p>{t("ErrorModal.ContactSupport")}</p>
              </>
            ) : !general ? (
              <p>
                {isDoctor()
                  ? t("ErrorModal.Doctor.Description")
                  : t("ErrorModal.Description")}
              </p>
            ) : (
              <></>
            )}
            <form onSubmit={formik.handleSubmit}>
              <label>
                <input
                  id="fullName"
                  type="text"
                  name="fullName"
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                />
                {t("ErrorModal.FullName") + "*"}
              </label>
              {!general && me?.isDoctor && (
                <label>
                  <input
                    type="text"
                    name="patientInitials"
                    onChange={formik.handleChange}
                    value={formik.values.patientInitials}
                  />
                  {t("ErrorModal.PatientInitials") + "*"}
                </label>
              )}
              {!general && me?.isDoctor && (
                <label>
                  <input
                    type="text"
                    name="surgeryType"
                    onChange={formik.handleChange}
                    value={formik.values.surgeryType}
                  />
                  {t("ErrorModal.SurgeryType") + "*"}
                </label>
              )}
              <label>
                <input
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {t("ErrorModal.email") + "*"}
              </label>

              {leftSideImplant && (
                <label>
                  <input
                    type="text"
                    name="implantL"
                    onChange={(e) => {
                      if (validateInput(e.target.value, ticketForESN)) {
                        return formik.handleChange(e)
                      }
                    }}
                    value={formik.values.implantL}
                  />
                  {ticketForSN && t("ErrorModal.leftSN") + "*"}
                  {ticketForESN && t("ErrorModal.leftESN") + "*"}
                </label>
              )}

              {rightSideImplant && (
                <label>
                  <input
                    type="text"
                    name="implantR"
                    onChange={(e) => {
                      if (validateInput(e.target.value, ticketForESN)) {
                        return formik.handleChange(e);
                      }
                    }}
                    value={formik.values.implantR}
                  />
                  {ticketForSN && t("ErrorModal.rightSN") + "*"}
                  {ticketForESN && t("ErrorModal.rightESN") + "*"}
                </label>
              )}

              {/* //----------------------------------------------------------------------------------------------------------------- */}
              {ticketForSN && (
                <>
                  {leftSideImplant && (
                    <label>
                      <input
                        type="text"
                        name="code_left"
                        maxLength={1}
                        onChange={(e) => {
                          if (
                            /^[0-9a-fA-F]+$/.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            return formik.handleChange(e);
                          }
                        }}
                        value={formik.values.code_left}
                      />
                      {t("ErrorModal.CodeLeft") + "*"}
                    </label>
                  )}
                  {rightSideImplant && (
                    <label>
                      <input
                        type="text"
                        name="code_right"
                        maxLength={1}
                        onChange={(e) => {
                          if (
                            /^[0-9a-fA-F]+$/.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            return formik.handleChange(e);
                          }
                        }}
                        value={formik.values.code_right}
                      />
                      {t("ErrorModal.CodeRight") + "*"}
                    </label>
                  )}
                </>
              )}
              {/* //----------------------------------------------------------------------------------------------------------------- */}

              {!general &&
                <>
                  <label className="my-date-picker">
                    <DatePicker
                      name="surgery_date"
                      required
                      maxDate={new Date()}
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setTimeout(() => datePickerRef.current.setOpen(false), 1);
                        return formik.setFieldValue("surgery_date", date);
                      }}
                      minDate={new Date(2010, 0, 1)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      ref={datePickerRef}
                      value={formik.values.surgery_date}
                    />

                    <div className="calender-icon position-relative">
                      <i className="fas fa-calendar-alt leadingInputIcon input-icon-2"/>
                      <p>{t("ErrorModul.SurgeryDate")}*</p>
                      {/* <i className="fas fa-caret-down trailingInputIcon "></i> */}
                    </div>
                  </label>
                  <label>
                    <SelectComponent
                      createTicket
                      fixedValue
                      value={formik.values.surgery_country || ""}
                      handleOnChange={(item) => {
                        setSurgeryCountry(item[0]?.label || "");
                        return formik.setFieldValue("surgery_country", item[0]?.label);
                      }}
                      options={countryList.map((o) => ({
                        ...o,
                        label: t(o.label),
                      }))}
                    />
                    {t("ErrorModal.SurgeryCountry") + "*"}
                  </label>

                  <label>
                    <SelectComponent
                      createTicket
                      fixedValue
                      value={formik.values.country_residence || ""}
                      handleOnChange={(item) => {
                        setCountryResidence(item[0]?.label || "");
                        return formik.setFieldValue("country_residence", item[0]?.label);
                      }
                      }
                      options={countryList.map((o) => ({
                        ...o,
                        label: t(o.label),
                      }))}
                    />
                    {t("ErrorModal.CountryResidence") + "*"}
                  </label>
                </>

              }


              <label>
                <textarea
                  className="text-area-box"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  rows="4"
                  placeholder={t("ErrorModal.LeaveAComment")}

                />
              </label>

              {!isLoading && (
                <div>
                  <ButtonFilled
                    className="sendTicketBtn"
                    disabled={!isValid}
                    title={t("ErrorModal.Send")}
                    type="submit"
                  />
                </div>
              )}
              <Loader
                className={"mt-3"}
                type="TailSpin"
                color="#805474"
                height={20}
                width={20}
                visible={isLoading}
              />

            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateTicketModule;
