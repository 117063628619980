import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "../../components/library/Layout/Layout";
import LinkBack from "../../components/library/Link/LinkBack";
import EstaAPIKit from "../../data/EstaAPIKit";
import { StaticFormContext } from "../../contexts/StaticFormContext";
import {Helmet} from 'react-helmet-async';

export default function WarrantyPaymentResponsePage() {
  const { setPaymentValid, setTransactionId, setCodePayment } = useContext(StaticFormContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [warrantyData, setWarrantyData] = useState(null);
  const estaAPIKit = new EstaAPIKit();
  const queryParams = new URLSearchParams(location.search);
  const { t } = useTranslation();

  useEffect(() => {
    var transactionId = getTransactionId();
    if (transactionId) setTransactionId(transactionId);

    let responseCode = getResponseCode();
    responseCode && setCodePayment(responseCode);

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getResponse() {
    return queryParams.get("response");
  }

  function getAmount() {
    return queryParams.get("amount");
  }

  function getTransactionId() {
    return queryParams.get("transactionid");
  }

  function getResponseCode() {
    return queryParams.get("response_code");
  }

  function getResponseText() {
    return queryParams.get("responsetext");
  }

  function renderMessage() {
    if (getResponse() === "1") {
      return t(
        "Payment-Response-Page.YourTransactionIsBeingProcessedDescription"
      );
    }
    if (getResponse() === "2") {
      return t(
        "Payment-Response-Page.TransactionReceivedButDeclinedDescription"
      );
    }
    if (getResponse() === "3") {
      return t("Payment-Response-Page.TransactionErrorDescription");
    }
  }

  function renderBackLink() {
    if (getResponse() !== "1") {
      return (
        <div className="pt-5 mt-5 mb-5">
          <div className="d-inline-block pt-3">
            <LinkBack linkBack="/warranty/payment" />
          </div>
        </div>
      );
    }
  }

  function fetchWarrantyData() {
    estaAPIKit
      .getWarrantyDetails()
      .then((res) => res.json())
      .then((data) => {
        setWarrantyData(data);
      });
  }

  async function postRegisterWarranty () {

    let  leftImplant = (warrantyData && warrantyData.implants && warrantyData.implants[0]) ? warrantyData.implants[0].serial : null;
    let  rightImplant = (warrantyData && warrantyData.implants && warrantyData.implants[1]) ? warrantyData.implants[1].serial : null;

    const payload = {
      implants: {
        left: leftImplant,
        right: rightImplant
      },
      lang: estaAPIKit.getUserLanguage(),
      price: getAmount(),
      transactionid: getTransactionId(),
      response_code: getResponseCode(),
      responsetext: getResponseText(),
    };
    await estaAPIKit
      .registerWarranty(payload)
      .then((res) => {
        //API
        if (res.status_code === 200) {
          return res.json();
        }
      })
      .then((_data) => {

        console.log(getResponseCode())

       if (Number(getResponseCode()) === 100) {
          navigate("/warranty/payment/confirmation");
        } else {
          setPaymentValid(true);
          navigate("/warranty/payment");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchWarrantyData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (warrantyData) {
      postRegisterWarranty();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warrantyData]);

  return (
    <>
      <Helmet>
        <title>Warranty Payment Response | MotivaImagine®'</title>
        <meta name="pageType" content="Extended Warranty" />
      </Helmet>
      <Layout>
        <h6 className="font-book text-center font-size-21 color-gray-500 pt-3">
          <div className="pt-3">{renderMessage()}</div>
        </h6>
        <div>
          <div className="pt-4">
            <p>{getResponseText()}</p>
            <p>Amount: {getAmount()}</p>
            <p>Transaction Id: {getTransactionId()}</p>
            <p>Response Code: {getResponseCode()}</p>
            <p>Response Text: {getResponseText()}</p>
          </div>
        </div>

        {renderBackLink}
      </Layout>
    </>
  );
}

/*
?response=2
&responsetext=Transaction+received+but+declined
&authcode=
&transactionid=5659501407
&avsresponse=U
&cvvresponse=P
&orderid=3000
&type=sale
&response_code=200
&merchant_defined_field_1=
&merchant_defined_field_2=
&merchant_defined_field_3=
&merchant_defined_field_4=
&merchant_defined_field_5=
&username=5920188
&time=1600679655
&amount=200.00
&hash=11d6e90af52ac409cdbf3f9282626939
*/
