import React from "react"
import { useTranslation } from "react-i18next"

const WarrantyInfo = (warranty) => {
  const { t } = useTranslation()

  function renderWarrantyInformation(translationKey, value) {
    return (
      <div className="text-muted">
        <p className="font-heavy d-inline-block pr-2">{t(translationKey)}</p>
        <p className=" d-inline-block">{value || t("Global.NotFound")}</p>
      </div>
    )
  }

  return (
    <>
      <h5 className="font-black font-size-14 border-bottom">
        {t("Patient-List-Warranty-Info.ExtendedWarrantyInfo")}
      </h5>
      <div className="font-size-16 color-gray-600">

        {renderWarrantyInformation(
          "Patient-List-Warranty-Info.MotivaProgram",
          warranty.data.program
        )}

        {renderWarrantyInformation(
          "Patient-List-Warranty-Info.Coverage",
          `${warranty.data.coverage} ${warranty.data.currency}`
        )}

        {renderWarrantyInformation(
          "Patient-List-Warranty-Info.Valid",
          `${warranty.data.startDate || ""} - ${warranty.data.endDate || ""}`
        )}
      </div>
    </>
  )
}

export default WarrantyInfo
