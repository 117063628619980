import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import "./PreImplantRegistrationPage.scss";
import "../../components/library/BackgroundImage/BackgroundImage.scss";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";

export default function PreImplantRegistrationPage() {
  const { setMe } = useContext(UserContext);

  function handleHasBreastImplants() {
    setMe(prevState => ({ ...prevState, hasBreastImplants: true }));
  }
  function handleDoesNotHaveBreastImplants() {
    setMe(prevState => ({ ...prevState, hasBreastImplants: false }));
  }

  const { t } = useTranslation();

  return (
    <FullPageLayout
      backgroundImgPath="homepage-bg-1"
      position="center"
      title={t("Preimplant-Registration-Page-One.Title-1")}
      subtitle={t("Preimplant-Registration-Page-One.Description-1")}
      description={t("Preimplant-Registration-Page-One.Title")}
      buttonList={[
        {
          title: t("Preimplant-Registration-Page-One.IHaveOneOrMoreImplants"),
          onClick: handleHasBreastImplants,
          nextLink: "/registration/preimplant-registration-two",
        },
        {
          title: t("Preimplant-Registration-Page-One.IDontHaveBreastImplants"),
          onClick: handleDoesNotHaveBreastImplants,
          nextLink: "/my-implants/thank-you-for-visiting",
        },
      ]}
    />
  );
}
