import React, { useState, useEffect } from "react"
import EstaAPIKit from "../data/EstaAPIKit"

const APIComponet = ({ title, resultHeadline, api, payload }) => {
  const [data, setData] = useState(null)
  const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const estaAPIKit = new EstaAPIKit()

  function handleApiCall() {
    if (api) {
      estaAPIKit[api](payload)
        .then((res) => {
          setResponse(res)
          setIsLoading(false)
          return res.json()
        })
        .then((data) => setData(data))
    } else {
      setData({})
      setIsLoading(false)
    }
  }

  function isError() {
    if (response && response.status !== "200") {
      return true
    }
    if (!response && api) {
      return true
    }
    return false
  }

  useEffect(() => {
    handleApiCall() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        className="row"
        style={{
          borderTop: "1px solid #BBB",
          backgroundColor: isError() ? "#e6abab" : "white",
        }}
      >
        <div className="col-md-6">
          <strong>{title}</strong>
          <pre>{JSON.stringify(payload, null, 2)}</pre>
        </div>
        <div className="col-md-6">
          <strong>{resultHeadline}</strong>
          {isLoading && <p>Loading</p>}
          {isLoading === false && (
            <>
              <span>{response && response.status}</span>
              <pre
                style={{
                  background: "#EEE",
                  maxHeight: "50px",
                  overflowY: "scroll",
                }}
              >
                {JSON.stringify(data, null, 2)}
              </pre>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default function APITester() {
  const estaAPIKit = new EstaAPIKit()

  return (
    <>
      <div className="col-md-12">
        <h1>API Tester</h1>
        <h5>{estaAPIKit.ROOT_URL}</h5>
        <APIComponet title="API" resultHeadline="Result" />
        <APIComponet title="getMe" api="getMe" />
        <APIComponet
          title="putMe"
          api="putMe"
          payload={{
            firstName: "Pelle",
            username: "pelle",
            email: "mian@willandskill.se",
          }}
        />
        <APIComponet title="getMeDevicesGluteal" api="getMeDevicesGluteal" />
        <APIComponet title="getMeDevicesBreast" api="getMeDevicesBreast" />
        <APIComponet title="getWarrantyDetails" api="getWarrantyDetails" />
        <APIComponet
          title="registerWarranty"
          api="registerWarranty"
          payload={{
            implantL: "20020156-12",
            implantR: "20020156-11",
            price: "200.00",
            transactionid: "12332132321",
            response_code: "200",
            responsetext: "Transaction Received",
          }}
        />
        <APIComponet
          title="registerMeDevicesBreast"
          api="registerMeDevicesBreast"
          payload={{
            doctor_id: 0,
            doctor_name: "Dr Test",
            country_id: 27,
            surgery_date: "6/9/18",
            implantL: "20020156-12",
            validationL: "5",
            implantR: "20020156-11",
            validationR: "7",
            implant_type: "B",
            placement_id: 1,
            incision_id: 1,
            indication_id: 1,
            lang: "es_ES",
            is_Q: false,
          }}
        />

        <APIComponet
          title="validateSerialNumber"
          api="validateSerialNumber"
          payload="20080022-14"
        />
        <APIComponet
          title="validateESerialNumber"
          api="validateESerialNumber"
          payload="986100000670790"
        />
        <APIComponet
          title="validateSerialNumberAndValidationCode"
          api="validateSerialNumberAndValidationCode"
          payload={{
            serialNumber: "20080022-14",
            code: "e",
            side: "L",
            type: "B",
          }}
        />

        <APIComponet title="getCountries" api="getCountries" />
        <APIComponet title="getIncisionKinds" api="getIncisionKinds" />
        <APIComponet title="getSurgeryKinds" api="getSurgeryKinds" />
        <APIComponet title="getBreastsPlacement" api="getBreastsPlacement" />
        <APIComponet title="getClinics" api="getClinics" payload={"Clinic"} />
        <APIComponet title="getStages" api="getStages" />
      </div>
    </>
  )
}
