import "./Form.scss";
import "./FormESN.scss";

import { useTranslation } from "react-i18next";
import MaskedInput from "react-text-mask";
import React, { useState, useContext, useEffect } from "react";

import { ImplantContext } from "../../../contexts/ImplantContext";
import { StaticFormContext } from "../../../contexts/StaticFormContext";
import { UserContext } from "../../../contexts/UserContext";
import { ImplantCard } from "../../../components/library/ImplantCardView/ImplantCardView";
import EstaAPIKit from "../../../data/EstaAPIKit";
import { SuccessValidationCheckmark } from "../../library/Icons/SuccessValidationCheckmark";
import Loader from "react-loader-spinner";
import {useDispatch, useSelector} from "react-redux";
import {preRegistrationInformation} from "../../../store/slices/preRegisterSlice";
import {Box} from "@mui/material";

export default function FormESN({
  placement,
  leftError,
  rightError,
  setRightError,
  setLeftError,
  isImplantValid,
  setIsImplantValid,
  showCardClue = true,
  handleValidation,
  setHandleValidation,
  isLeftRender, required
}) {
  const { setBreastImplants, setGlutealImplants, me } = useContext(UserContext);
  const preRegistration  = useSelector((state) => state.preRegisterSlice);
  const featureFlag = useSelector((state) => state.featureFlagSlice.features);
  const {
    currentWizard,
    serialNumberLeft,
    setSerialNumberLeft,
    serialNumberRight,
    setSerialNumberRight,
    setMsgError,
    leftErrorDetail,
    setLeftErrorDetail,
    rightErrorDetail,
    setRightErrorDetail,
    implantCardType,
    registerImplantsFor
  } = useContext(StaticFormContext);
  const { t } = useTranslation();
  const [lang, setLang] = useState(me?.country_id);
  const [isReLoadOnBlur, setIsReLoadOnBlur] = useState(null);
  const [isLeftValidate, setIsLeftValidate] = useState(false);
  const [isRightValidate, setIsRightValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { validateESerialNumber } = useContext(ImplantContext);
  const dispatch = useDispatch();
  const shortWizardFor = currentWizard === "breast" ? "B" : "G";
  const shortPlacement = placement === "left" ? "L" : "R";
  const MASK = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  // ESN 986100000664250
  useEffect(() => {
    setSerialNumberLeft("");
    setSerialNumberRight("");
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (handleValidation) {
      setHandleValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleValidation]);

  useEffect(() => {
    if (leftErrorDetail || rightErrorDetail) {
      setMsgError(leftErrorDetail + "-" + rightErrorDetail);
    }
  }, [leftErrorDetail, rightErrorDetail, setMsgError]);

  const isLeftPlacement = () => {
    return registerImplantsFor === "left";
  };

  const isRightPlacement = () => {
    return registerImplantsFor === "right";
  };

  const resetLeftPlacement = () => {
    setSerialNumberLeft("");
  };

  const resetRightPlacement = () => {
    setSerialNumberRight("");
  };

  let isFeatureStatus = () => (featureFlag && featureFlag.filter((feat)=> feat.feature === "mia_flow")[0].status);

  async function validate() {
    setIsLoading(true);

    let payload ={
      implants:{
        type: shortWizardFor,
        left:{
          serialNumber: null,
          validationCode:null,
          electronicSerial: placement === "left" ? serialNumberLeft : null
        },
        right:{
          serialNumber: null,
          validationCode:null,
          electronicSerial: placement === "right" ? serialNumberRight : null
        }
      },
      tools: {
        injector: null,
        balloon: null
      }
    }

    const result = await validateESerialNumber(payload);
    if (result?.status === "true") {
      if (shortWizardFor === "B") {
        setBreastImplants((prevState) => ({
          ...prevState,
          [shortPlacement]: result,
        }));
      } else {
        setGlutealImplants((prevState) => ({
          ...prevState,
          [shortPlacement]: result,
        }));
      }
      setIsImplantValid(true);
      placement === "left" ? setIsLeftValidate(true) : setIsRightValidate(true);

      //this feature flag enables or disables mia flow
      if(isFeatureStatus() === 1) {
        dispatch(preRegistrationInformation({
          isMiaImplants: result.isMiaImplants,
          implantsValid: {
            implantL: isLeftRender ? (result?.isMiaImplants ? true : false) : preRegistration?.implantsValid?.implantL,
            implantR: isLeftRender ? preRegistration?.implantsValid?.implantR : (result?.isMiaImplants ? true : false),
          },
          implantPlacement: result?.isMiaImplants ? 1 : 0,
          incisionKind: result?.isMiaImplants ? 4 : 0,
          surgeryIndication: result?.isMiaImplants ? 5 : 0,
        }));
      }

    } else {
      isLeftPlacement() && resetRightPlacement();
      isRightPlacement() && resetLeftPlacement();
      placement === "left"
        ? setIsLeftValidate(false)
        : setIsRightValidate(false);
      let text = "";
      switch (result?.code) {
        case ("205" || "206"):
          text = preRegistration.isESN ? `ImplantRegistrationError.ImplantNotExistentESN` : result?.description;
          if (placement === "left") {
            setLeftError(text);
            setLeftErrorDetail(text);
          }else{
            setRightError(text);
            setRightErrorDetail(text);
          }
          break;
        default:
          if (placement === "left") {
            setLeftError(result?.description);
            setLeftErrorDetail(result?.description);
          }else{
            setRightError(result?.description);
            setRightErrorDetail(result?.description);
          }
          break;
      }
      setIsImplantValid(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    const { country_id } = me;
    setLang(country_id);
  }, [me]);

  function handleOnBlur() {
    placement === "left" ? setIsLeftValidate(false) : setIsRightValidate(false);
    setLeftError("");
    setRightError("");
    let valueESN = placement === "left" ? serialNumberLeft : serialNumberRight;
    if (valueESN && valueESN.trim().length === 15) {
      validate();
    }
  }

  useEffect(()=>{
    resetRightPlacement();
    resetLeftPlacement();
    setIsLeftValidate(false)
    setIsRightValidate(false);
    setLeftError("");
    setRightError("");
    dispatch(preRegistrationInformation({
      implantsInformation: {
        ...preRegistration?.implantsInformation,
        tools: {
          injector: null,
          balloon: null,
        }
      }
    }))
  },[preRegistration?.implantsFor, preRegistration?.implantsInformation?.implant_type])

  useEffect(()=>{
    handleOnBlur();
  },[isReLoadOnBlur])

  return (
    <>
      <form className="esn-form">
        {showCardClue && (
          <ImplantCard
            style={{ marginTop: "0px" }}
            esn
            type={implantCardType}
            className="esn-form__img"
            alt="esn-img"
            left={placement === "left"}
            right={placement === "right"}
            isBreast={currentWizard === "breast"}
            lang={lang}
          />
        )}

        <div className="esn-form__input-wrapper">
          <Box sx={{width: {xs: '100%',md: '52%'}}}>
            <p className="label-for-ESerialNumber text-muted">
              {t("Implant-Registration.ESerialNumber")}
              {required && "*"}
            </p>

            <MaskedInput
              mask={MASK}
              placeholderChar={"\u2000"}
              type="text"
              className={`form-control serial-input ${
                isImplantValid ? "is-invalid" : ""
              }`}
              id={`serial-number-input-${placement}`}
              name="serial-number-input"
              render={(ref, props) => {
                return (
                  <input placeholder="000000000000000" ref={ref} {...props} />
                );
              }}
              onBlur={handleOnBlur}
              onChange={({ target }) => {
                placement === "left" ? setLeftError("") : setRightError("");
                const value = target.value.replace(/^[\s_-]|[\s_-]/gm, "");
                placement === "left" && setSerialNumberLeft(value);
                placement === "right" && setSerialNumberRight(value);
                if(value && value.length === 15){
                  setIsReLoadOnBlur(Math.floor(Math.random() * 10));
                }
              }}
              value={
                placement === "left" ? serialNumberLeft : serialNumberRight
              }
            />

            <div className="container-error">
              {placement === "left" && leftError && (
                <div className="validation-message">
                  <p>{t(leftError)}</p>
                </div>
              )}
              {placement === "right" && rightError && (
                <div className="validation-message">
                  <p>{t(rightError)}</p>
                </div>
              )}
            </div>

          </Box>
          <div
            className="successfull-validation-checkmark-2"
            style={
              leftError || rightError
                ? {
                    display: "none",
                  }
                : null
            }
          >
            {isLeftValidate && <SuccessValidationCheckmark />}
            {isRightValidate && <SuccessValidationCheckmark />}
            {isLoading && (
              <Loader
                className={"loading"}
                type="TailSpin"
                color="#805474"
                height={20}
                width={20}
                visible={isLoading}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
}
