import React from "react"

import ButtonLogOut from "../components/library/Button/ButtonLogOut"
import LayoutPreImplant from "../components/library/LayoutPreImplant/LayoutPreImplant"

export default function LogoutPage() {
  return (
    <>
      <LayoutPreImplant
      >
        <div className="pb-5 mx-5 text-center">
          <ButtonLogOut />
        </div>
      </LayoutPreImplant>
    </>
  )
}
