import "./Form.scss";

import React, {useContext, useEffect, useState} from "react";
import EstaAPIKit from "../../../data/EstaAPIKit";
import {useTranslation} from "react-i18next";
import {ImplantContext} from "../../../contexts/ImplantContext";
import {StaticFormContext} from "../../../contexts/StaticFormContext";
import {UserContext} from "../../../contexts/UserContext";
import {SuccessValidationCheckmark} from "../../library/Icons/SuccessValidationCheckmark";
import Loader from "react-loader-spinner";
import MaskedInput from "react-text-mask";
import md5 from "md5";
import {useDispatch, useSelector} from "react-redux";
import {preRegistrationInformation} from "../../../store/slices/preRegisterSlice";
import {setSelectionCard, cleanSelectionCard} from "../../../store/slices/registrationSlice";
import {Box} from "@mui/material";
import ScannerCode from "../ScannerCode";

export default function Form({
placement,
setContainsValue,
setIsImplantValid,
leftError,
rightError,
setRightError,
setLeftError,
handleValidation,
setHandleValidation,
isLeftRender, id,
required
}) {
  const { setBreastImplants, setGlutealImplants } = useContext(UserContext);
  const {
    currentWizard,
    detectedLeftSerialNumber,
    detectedRightSerialNumber,
    leftVC,
    rightVC,
    setRightVC,
    setLeftVC,
    serialNumberLeft,
    setSerialNumberLeft,
    setSerialNumberRight,
    serialNumberRight,
    setIsScanned,
    leftErrorDetail,
    setLeftErrorDetail,
    rightErrorDetail,
    setRightErrorDetail,
    setMsgError,
    registerImplantsFor,
    resetImplantForm,
    isSuccessScanned,
    setIsSuccessScanned,
    isSuccessNFC
  } = useContext(StaticFormContext);
  const { validateSerialNumberAndValidationCode } = useContext(ImplantContext);
  const preRegistration  = useSelector((state) => state.preRegisterSlice);
  const featureFlag = useSelector((state) => state.featureFlagSlice.features);
  const { t } = useTranslation();
  const estaAPIKit = new EstaAPIKit();
  const dispatch = useDispatch();
  const [isReLoadOnBlur, setIsReLoadOnBlur] = useState(null);
  const [validationRan, setValidationRan] = useState(false);
  const [isVCInvalid, setIsVCInvalid] = useState(false);
  const [isLeftValidate, setIsLeftValidate] = useState(false);
  const [isRightValidate, setIsRightValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const shortPlacement = placement === "left" ? "L" : "R";
  const shortWizardFor = currentWizard === "breast" ? "B" : "G";
  const MASK = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
  ];

  useEffect(() => {
    setSerialNumberLeft("");
    setSerialNumberRight("");
    setRightVC("");
    setLeftVC("");
    placement === "left"
      ? setContainsValue((prevState) => ({
          ...prevState,
          left: 0,
          leftSingleDigit: 0,
        }))
      : setContainsValue((prevState) => ({
          ...prevState,
          right: 0,
          rightSingleDigit: 0,
        })); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (handleValidation) {
      handleOnBlur();
      setHandleValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleValidation]);

  useEffect(() => {
    if (
      placement === "left" &&
      detectedLeftSerialNumber &&
      detectedLeftSerialNumber.length > 0
    ) {
      setSerialNumberLeft(detectedLeftSerialNumber);

      setContainsValue((prevState) => ({
        ...prevState,
        left: detectedLeftSerialNumber.length,
      }));
    } else if (
      placement === "right" &&
      detectedRightSerialNumber &&
      detectedRightSerialNumber.length > 0
    ) {
      setSerialNumberRight(detectedRightSerialNumber);

      setContainsValue((prevState) => ({
        ...prevState,
        right: detectedRightSerialNumber.length,
      }));
    }

    // generateVC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placement, detectedLeftSerialNumber, detectedRightSerialNumber]);

  useEffect(() => {
    if (leftErrorDetail || rightErrorDetail) {
      setMsgError(leftErrorDetail + "-" + rightErrorDetail);
    }
  }, [leftErrorDetail, rightErrorDetail, setMsgError]);

  const isLeftPlacement = () => {
    return registerImplantsFor === "left";
  };

  const isRightPlacement = () => {
    return registerImplantsFor === "right";
  };

  const resetLeftPlacement = () => {
    setSerialNumberLeft("");
    setLeftVC("");
  };

  const resetRightPlacement = () => {
    setSerialNumberRight("");
    setRightVC("");
  };

  function showInvalid(isVC = false) {
    return (
      validationRan &&
      !isLeftValidate &&
      !isRightValidate &&
      !isVC ^ isVCInvalid
    );
  }

  function handleOnFocus(event) {
    dispatch(setSelectionCard({
      selectionCard: {
        defaultCard: event !== 'left-SN' || event !== 'right-SN' || event !== 'left-VC' || event !== 'right-VC',
        snLeft: event === 'left-SN',
        snRight: event === 'right-SN',
        vcLeft: event === 'left-VC',
        vcRight: event === 'right-VC',
      }
    }));

    setIsScanned(0);
    setValidationRan(false);
  }

  function handleOnBlur() {
    placement === "left" ? setIsLeftValidate(false) : setIsRightValidate(false);
    setLeftError("");
    setRightError("");
    let valueSN = placement === "left" ? serialNumberLeft : serialNumberRight;
    let valueVC = placement === "left" ? leftVC : rightVC;
    // if (valueSN.trim().length === 11) {
    //   generateVC();
    // }
    if (valueSN && valueSN.trim().length === 11 && valueVC && valueVC.length === 1) {
      validate();
    }
  }

  function generateVC() {
    const hashedSN = md5(
      placement === "left"
        ? serialNumberLeft
          ? serialNumberLeft
          : ""
        : serialNumberRight
        ? serialNumberRight
        : ""
    );

    let pos = -1;
    if (
      placement === "left"
        ? serialNumberLeft?.substr(0, 4) >= 1911
        : serialNumberRight?.substr(0, 4) >= 1911
    ) {
      pos = 0;
    }

    let VC = hashedSN?.substring(pos, 1).toUpperCase();
    //Validation Code
    //console.log(VC);

    placement === "left" &&
    detectedLeftSerialNumber.length > 0 &&
    setLeftVC(VC);
    placement === "right" &&
    detectedRightSerialNumber.length > 0 &&
    setRightVC(VC);
  }

  let isFeatureStatus = () => (featureFlag && featureFlag.filter((feat)=> feat.feature === "mia_flow")[0].status);

  async function validate() {
    setIsLoading(true);
    setIsSuccessScanned(false);

    let payload ={
      implants: {
        type: shortWizardFor,
        left:{
          serialNumber: placement === "left" ? serialNumberLeft : null,
          validationCode: placement === "left" ? leftVC : null,
          electronicSerial: null
        },
        right:{
          serialNumber: placement === "right" ? serialNumberRight : null,
          validationCode: placement === "right" ? rightVC : null,
          electronicSerial: null
        }
      },
      tools: {
        injector: null,
        balloon: null
      }
    }

    let result = await validateSerialNumberAndValidationCode(payload);

    if (result?.status === "true") {
      if (shortWizardFor === "B") {
        setBreastImplants((prevState) => ({
          ...prevState,
          [shortPlacement]: result,
        }));
      } else {
        setGlutealImplants((prevState) => ({
          ...prevState,
          [shortPlacement]: result,
        }));
      }
      placement === "left" ? setLeftVC(leftVC) : setRightVC(rightVC);
      setValidationRan(true);
      placement === "left" ? setIsLeftValidate(true) : setIsRightValidate(true);
      setIsImplantValid(true);
      setIsVCInvalid(false);

      //this feature flag enables or disables mia flow
      if(isFeatureStatus() === 1){
        dispatch(preRegistrationInformation({
          isMiaImplants: result.isMiaImplants,
          implantsValid : {
            implantL: isLeftRender ? (result?.isMiaImplants ? true : false) : preRegistration?.implantsValid?.implantL,
            implantR: isLeftRender ? preRegistration?.implantsValid?.implantR : (result?.isMiaImplants ? true : false),
          },
          implantPlacement: result?.isMiaImplants ?  1 : 0,
          incisionKind: result?.isMiaImplants ? 4 : 0,
          surgeryIndication: result?.isMiaImplants ? 5 : 0,
        }));
      }

    } else {
      isLeftPlacement() && resetRightPlacement();
      isRightPlacement() && resetLeftPlacement();
      placement === "left"
        ? setIsLeftValidate(false)
        : setIsRightValidate(false);
      if (placement === "left") {
        setLeftError(result?.description);
        setLeftErrorDetail(result?.description);
      } else {
        setRightError(result?.description);
        setRightErrorDetail(result?.description);
      }
      setIsImplantValid(false);
      setIsVCInvalid(true);
    }
    setIsLoading(false);
  }

  useEffect(()=>{
    resetRightPlacement();
    resetLeftPlacement();
    setIsLeftValidate(false)
    setIsRightValidate(false);
    setLeftError("");
    setRightError("");
    dispatch(preRegistrationInformation({
      implantsInformation: {
        ...preRegistration?.implantsInformation,
        tools: {
          injector: null,
          balloon: null,
        }
      }
    }))
  },[preRegistration?.implantsFor, preRegistration?.implantsInformation?.implant_type])

  useEffect(()=>{
    handleOnBlur();
  },[isReLoadOnBlur,isSuccessScanned,isSuccessNFC]);

  const getSerial = (value) => {

    const payload ={
      image: value
    }

    estaAPIKit.extractSerialNumberByScan(payload)
      .then((res) => res.json())
      .then(response => {

        if(response.result?.validStructure) {

          if (placement === "left") {
            setSerialNumberLeft(response.result.serialNumber)
            setLeftVC(response.result.validationCode);
          }

          if (placement === "right") {
            setSerialNumberRight(response.result.serialNumber);
            setRightVC(response.result.validationCode);
          }
        }
      }).catch(error =>{
      console.error(error);
    });
  }

  return (
    <div className="sn-row">
      <form className="form-wrapper">
        <div className="form-wrapper__left-side">
          <div className="d-flex flex-md-row flex-column">
            <div className="serial-number-group">
              <p className="label-for-serialnumber text-muted">
                {t("Implant-Registration.SerialNumber")}
                {required && "*"}
              </p>
              <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Box mr={1} >
                  <ScannerCode extract={getSerial} type={"implant"} title={t('Implant-Registration.ScanLabel')}
                  />
                </Box>
                <MaskedInput
                  mask={MASK}
                  placeholderChar={"\u2000"}
                  type="text"
                  className={`form-control serial-input ${
                    showInvalid() ? "is-invalid" : ""
                  }`}
                  id={`serial-number-input-${placement}`}
                  name="serial-number-input"
                  render={(ref, props) => {
                    return (
                      <input placeholder="00000000-00" ref={ref} {...props} />
                    );
                  }}
                  onFocus={()=>handleOnFocus(`${placement}-SN`)}
                  onBlur={()=>{
                    handleOnFocus();
                    handleOnBlur();
                  }}
                  onChange={(e) => {
                    placement === "left" && setSerialNumberLeft(e.target.value);
                    placement === "right" && setSerialNumberRight(e.target.value);

                    if(e.target.value.length === 11){
                      setIsReLoadOnBlur(Math.floor(Math.random() * 10));
                    }

                    const currValue = e.target.value.length;
                    // Checks - helps to check if all input fields contains value
                    placement === "left"
                      ? setContainsValue((prevState) => ({
                          ...prevState,
                          left: currValue,
                        }))
                      : setContainsValue((prevState) => ({
                          ...prevState,
                          right: currValue,
                        }));
                  }}
                  value={
                    placement === "left" ? serialNumberLeft : serialNumberRight
                  }
                />
              </Box>
            </div>
            <div className="validation-code-group">
              <p className="label-for-serialnumber text-muted">
                {t("Implant-Registration.ValidationCode")}
                {required && "*"}
              </p>
              <input
                type="text"
                className={`form-control validation-input ${
                  showInvalid(true) ? "is-invalid" : ""
                }`}
                placeholder="X"
                maxLength={1}
                id={`validation-code-input-${placement}`}
                name="validation-code-input"
                onFocus={()=>handleOnFocus(`${placement}-VC`)}
                onBlur={()=>{
                  handleOnFocus();
                  handleOnBlur();
                }}
                onChange={(e) => {
                  setIsImplantValid(false);
                  setValidationRan(false);
                  if (
                    /^[0-9a-fA-F]+$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    placement === "left" && setLeftVC(e.target.value);
                    placement === "right" && setRightVC(e.target.value);
                  }
                  if(e.target.value.length === 1){
                    setIsReLoadOnBlur(Math.floor(Math.random() * 10));
                  }
                }}
                value={placement === "left" ? leftVC : rightVC}
              />
            </div>
            <div className="form-wrapper__right-side">
              <div
                className="successfull-validation-checkmark"
                style={
                  leftError || rightError
                    ? {
                        display: "none",
                      }
                    : null
                }
              >
                {isLeftValidate && <SuccessValidationCheckmark />}
                {isRightValidate && <SuccessValidationCheckmark />}
                {!isLeftValidate && placement === "left" && (
                  <Loader
                    className={"loading"}
                    type="TailSpin"
                    color="#805474"
                    height={20}
                    width={20}
                    visible={isLoading}
                  />
                )}
                {!isRightValidate && placement === "right" && (
                  <Loader
                    className={"loading"}
                    type="TailSpin"
                    color="#805474"
                    height={20}
                    width={20}
                    visible={isLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="container-error">
        {placement === "left" && leftError && (
          <div className="validation-message">
            <p>{t(leftError)}</p>
          </div>
        )}
        {placement === "right" && rightError && (
          <div className="validation-message">
            <p>{t(rightError)}</p>
          </div>
        )}
      </div>
    </div>
  );
}
