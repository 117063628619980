import React, {useState, useEffect, useContext} from "react";
import "./PatientListViewPage.scss";
import EstaAPIKit from "../../data/EstaAPIKit";
import PatientCard from "../../components/library/PatientCard/PatientCard";
import {useTranslation} from "react-i18next";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import LinkBack from "../../components/library/Link/LinkBack";
import Pagination from "../../components/library/Pagination/Pagination";
import {PatientListContext} from "../../contexts/PatientListContext";
import EnsureToken from "../../components/library/EnsureToken";
import SpinnerSmall from "../../components/library/Spinner/SpinnerSmall";
import {DebounceInput} from "react-debounce-input";
import {Helmet} from 'react-helmet-async';
import {Grid} from "@mui/material";

export default function PatientListView() {
  const ITEMS_PER_PAGE = 5;
  const estaAPIKit = new EstaAPIKit();
  const {t} = useTranslation();
  const {patientList, setPatientList} = useContext(PatientListContext);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, inputValue]);

  function getDoctorMePatients(params) {
    return estaAPIKit.getDoctorMePatients(params);
  }

  async function getPatients() {
    setPatientList(null);
    setIsLoading(true);
    const result = await getDoctorMePatients({
      total_items: ITEMS_PER_PAGE,
      page: currentPage,
      search: inputValue,
    });
    if (result?.code === 200) {
      const data = result?.message?.data;
      const patientList = data.map((item, index) => {
        return {...item, id: index};
      });
      setTotalPages(result?.message?.last_page);
      setPatientList(patientList);
    } else {
      setPatientList([]);
    }
    setIsLoading(false);
  }

  const renderPatientList = () =>
    patientList &&
    patientList.map((patientData) => (
      <PatientCard
        patientData={patientData}
        patientIndex={patientData.id}
        key={patientData.id}
      />
    ));

  return (
    <>
      <Helmet>
        <title>My Patients | MotivaImagine®</title>
        <meta name="pageType" content="Patient List View"/>
      </Helmet>

      <EnsureToken>
        <div className="pre-next-btns pre-next-btns-top">
          <LinkBack linkBack="/"/>
        </div>
        <FiftyFiftyLayout
          backgroundImgPath="fifty-fifty-bg-1"
          title={t("Patient-List.Title")}
        >
          <DebounceInput
            debounceTimeout={800}
            className="patient-list-view__search"
            type="text"
            placeholder={t("Global.Search-Here")}
            onChange={(e) => {
              setCurrentPage(1);
              setInputValue(e.target.value);
            }}
            value={inputValue}
          />
          <Grid container>
            <Grid item xs={12} md={12}>
              <div className="patient-list-view__patientList-container">
                {patientList?.length === 0 && (
                  <div className="patient-list-view__no-patients">
                    <p>{t("Global.NoResults")}</p>
                  </div>
                )}
                {isLoading && (
                  <div className={"patient-list-view__loader"}>
                    <SpinnerSmall/>
                  </div>
                )}
                {patientList && (
                  <div className="fifty-fifty-layout__overflow-scroll-container">
                    <div className="justify-content-center">
                      {renderPatientList()}
                    </div>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <Pagination
                hidden={patientList?.length === 0 && !isLoading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </Grid>
          </Grid>
          {/* {userError && <ErrorComponent userError={userError} />} */}
        </FiftyFiftyLayout>
      </EnsureToken>
    </>
  );
}
