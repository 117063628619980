import React, { useContext, useState, useEffect } from "react";
import LinkBack from "../../components/library/Link/LinkBack";
import { UserContext } from "../../contexts/UserContext";
import { StaticFormContext } from "../../contexts/StaticFormContext";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import "./SurgeryIndicationPage.scss";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import Registration from "../../components/library/Registration.jsx";
import {Helmet} from 'react-helmet-async';

export default function SurgeryIndicationPage() {
  const { me, setMe } = useContext(UserContext);
  const { surgeryKindList } = useContext(StaticFormContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showLeftOrRight, setShowLeftOrRight] = useState(true);
  const [nextLink, setNextLink] = useState(null);

  function handleOnChange(selectedItem) {
    setMe((prevState) => ({ ...prevState, surgeryIndication: selectedItem }));
  }

  function goNext() {
    setNextLink("/registration/surgery-information");
  }

  useEffect(() => {
    if (me?.country_id === undefined) {
       navigate("/registration/implant-type");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (nextLink) return <Navigate push to={nextLink} />;

  return (
    <>
      <Helmet>
        <title>Surgery Type | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>
      <Registration step={8}>
        <FiftyFiftyLayout
          backgroundImgPath="fifty-fifty-bg-1"
          title={t("Surgery-Indication.Title")}
          // subtitle={t("Implant-Registration.Description")}
        >
          <div className="pre-next-btns pre-next-btns-top">
            <LinkBack linkBack="/registration/implant-placement" />
          </div>
          <div className="toggle-btn-container">
            <p
              onClick={() => setShowLeftOrRight(true)}
              className={showLeftOrRight ? "while-active" : "while-not-active"}
            >
              {t("SurgeryIndication.Augmentation")}
            </p>
            <p
              onClick={() => setShowLeftOrRight(false)}
              className={!showLeftOrRight ? "while-active" : "while-not-active"}
            >
              {t("SurgeryIndication.Reconstruction")}
            </p>
          </div>
          {showLeftOrRight &&
            surgeryKindList &&
            surgeryKindList.slice(0, 3).map(
              (surgeryKind) =>
                surgeryKind.title !== "Undefined" && (
                  <label
                    key={surgeryKind.id}
                    onClick={() => {
                      handleOnChange(surgeryKind.id);
                      goNext();
                    }}
                    className="implant-register-with__description-container__card label-card implant-card"
                  >
                    <div>
                      <h5 className="vertical-list-item__title">
                        {t(`API.SurgeryIndication-${surgeryKind.id}`)}
                      </h5>
                      <p className="vertical-list-item__text text-muted">
                        {t(`API.SurgeryIndication-${surgeryKind.id}-description`)}
                      </p>
                    </div>
                    <input type="radio" name="radio2" />
                    <span className="radio-btn-card"></span>
                  </label>
                )
            )}
          {!showLeftOrRight &&
            surgeryKindList &&
            surgeryKindList.slice(3, 5).map(
              (surgeryKind) =>
                surgeryKind.title !== "Undefined" && (
                  <label
                    key={surgeryKind.id}
                    onClick={() => {
                      handleOnChange(surgeryKind.id);
                      goNext();
                    }}
                    className="implant-register-with__description-container__card label-card implant-card"
                  >
                    <div>
                      <h5 className="vertical-list-item__title">
                        {t(`API.SurgeryIndication-${surgeryKind.id}`)}
                      </h5>
                      <p className="vertical-list-item__text text-muted">
                        {t(`API.SurgeryIndication-${surgeryKind.id}-description`)}
                      </p>
                    </div>
                    <input type="radio" name="radio2" />
                    <span className="radio-btn-card"></span>
                  </label>
                )
            )}
        </FiftyFiftyLayout>
      </Registration>
    </>
  );
}
