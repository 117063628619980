import React from "react";
import TagManager from "react-gtm-module";

export const useTagManager = () => {
  return (args) => {
    TagManager.dataLayer({
      dataLayer: {
        ...args
      }
    });
  };
};
