import React from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";

export const AutoComplete = ({
  formik,
  options,
  title,
  name,
  placeholder,
  handleChange,
  value,
  id,
  isOptionEqualToValue = undefined,
  getOptionLabel = undefined,
  required = false,
}) => {
  const meta = formik?.getFieldMeta(name);

  return (
    <Grid container>
      <Grid item xs={12} md={5} display="flex" alignItems="center">
        <Typography as="label" variant="body1" fontSize={16} fontWeight={300}>
          {title}
          {required && "*"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Autocomplete
          disablePortal
          options={options}
          value={typeof name === "undefined" ? value : meta.value}
          onChange={
            handleChange ??
            ((e, value) => formik.setFieldValue(name, value || null))
          }
          onPaste={(event) => {
            event.preventDefault();
          }}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              id={id}
              fullWidth
              name={name && name}
              placeholder={placeholder}
              error={
                formik && Boolean(formik.touched[name] && formik.errors[name])
              }
              FormHelperTextProps={{
                style: {
                  textAlign: "left",
                  margin: 0,
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "red",
                  letterSpacing: "0.00714em",
                },
              }}
              helperText={formik && formik.touched[name] && formik.errors[name]}
              onBlur={formik && formik.handleBlur}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#f2f2f2 !important",
                  borderRadius: "10px !important",
                  height: "50px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  borderRadius: "10px !important",
                },
                "& .MuiOutlinedInput-input": {
                  height: "19px",
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
