// import "date-fns";
import React, {useRef, useState, useEffect} from "react";
import "./DatePicker.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box} from "@mui/material";

export default function DatePickerBox({value, placeholder, handleOnChange, disabled = false, tooltip, removeTitle, resetAutomate}) {
  const [startDate, setStartDate] = useState(value);
  const {t} = useTranslation();
  const ref = useRef();
  const preRegistration = useSelector((state) => state.preRegisterSlice);

  useEffect(
    () => {
      if (startDate !== value) handleOnChange(startDate);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate]
  );

  useEffect(
    () => {
      if (value && !startDate) setStartDate(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  useEffect(
    () => {
      if(resetAutomate === true){
        setStartDate(new Date());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetAutomate]
  );

  const NonEditableInput = React.forwardRef(({value, onClick}, ref) => (
    <input
      readOnly
      type="text"
      placeholder={placeholder}
      className={`non-editable-input`}
      onClick={onClick}
      value={value}
      ref={ref}
    />
  ));

  const locale = {
    localize: {
      month: (n) =>
        [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ].map((m) => t(`Global.${m}`))[n],
      day: (n) =>
        ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((d) =>
          t(`Global.${d}`)
        )[n],
    },
    formatLong: {},
  };

  let typeTitle = preRegistration.isMiaImplants ? 'Procedure' : 'Surgery';

  return (
    <div className="position-relative">
      {removeTitle ? "" : <Box display={tooltip ? "flex" : "inline-block"} sx={{height: 40}}>
        <p
          className="font-black my-1">{placeholder ? placeholder : t(`${typeTitle}-Information.${typeTitle}Date`)}</p>
        {tooltip}
      </Box>
      }
      <label className="datepicker-wrap">
        <DatePicker
          ref={ref}
          disabled={disabled}
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);

            // When the value is set, it calls setOpen(false) properly, but another function is calling setOpen(true)
            // opening the datepicker again, at this point we don't know why this is happening, for which the best
            // solution is to invke setOpen(false) after this has happened, this is a workaround for a possible bug within the module
            setTimeout(() => ref.current.setOpen(false), 1);
          }}
          locale={locale}
          minDate={new Date(2010, 0, 1)}
          maxDate={new Date()}
          className={`datepicker`}
          customInput={<NonEditableInput/>}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          inline={false}
          shouldCloseOnSelect={true}
        />
        {/*
        <div className="iconWrapper position-relative">
          <i className="fas fa-calendar-alt leadingInputIcon input-icon-2"></i>
        </div>
        */}
      </label>
    </div>
  );
}
