import React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

export default function ButtonComponent({
  prepend,
  title,
  subtitle,
  append,
  fullWidth,
  inline,
  size,
  colorScheme,
  onClick,
  nextLink,
  disabled,
  id,
  show = true,
  type = undefined,
  ...props
}) {
  const getSize = () => {
    if (size === "lg") {
      return "btn-lg";
    }
    return "";
  };

  const getColorScheme = () => {
    if (colorScheme === "purple") {
      return "btn-primary";
    }
    if (colorScheme === "outlinedPurple") {
      return "btn-outline-primary";
    }
    if (colorScheme === "gray") {
      return "btn-outline-gray";
    }
    if (colorScheme === "white") {
      return "btn-outline-white";
    }
    if (colorScheme === "light-purple") {
      return "btn-light-purple";
    }
    return "btn-primary";
  };

  function handleOnClick() {
    if (onClick && !disabled) {
      onClick();
    }
  }

  function renderButton() {
    return (
      show && (
        <button
          onClick={() => {
            handleOnClick();
          }}
          className={`button-component
        btn
        ${getColorScheme()}
        ${fullWidth ? "btn-block" : ""}
        ${inline ? "btn-inline" : ""}
        ${getSize()}
        ${props.className || ""}
        `}
          disabled={disabled}
          type={type}
        >
          <p className={`m-0 text-wrap`}>
            {prepend && <span className="previous">{prepend}</span>} {title}{" "}
            <span className="subtitle">{subtitle}</span> {append}
          </p>
        </button>
      )
    );
  }

  function renderComponent() {
    if (nextLink) {
      return <Link to={!disabled && nextLink}>{renderButton()}</Link>;
    } else {
      return renderButton();
    }
  }

  return renderComponent();
}
