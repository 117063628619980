import styled from "styled-components";
import {
  OLD_FIRST_CARD,
  OLD_POINTED_FIRST_CARD,
  OLD_CARD_SN_DA,
  OLD_CARD_SN_DE,
  OLD_CARD_SN_EN,
  OLD_CARD_SN_ES,
  OLD_CARD_SN_FR,
  OLD_CARD_SN_IT,
  OLD_CARD_SN_NO,
  OLD_CARD_SN_PT,
  OLD_CARD_SN_SV,
  OLD_ESN_LEFT,
  OLD_ESN_RIGHT,
  GLOBAL_BREAST_SN_ESN,
  GLOBAL_BREAST_ESN_LEFT,
  GLOBAL_BREAST_ESN_RIGHT,
  GLOBAL_BREAST_NEW_CARD_2022,
  GLOBAL_GLUTEAL_SN_ESN,
  GLOBAL_GLUTEAL_ESN_LEFT,
  GLOBAL_GLUTEAL_ESN_RIGHT,
  GLOBAL_GLUTEAL_NEW_CARD_2022,
  BRAZIL_BREAST_SN_ESN,
  BRAZIL_BREAST_ESN_LEFT,
  BRAZIL_BREAST_ESN_RIGHT,
  BRAZIL_BREAST_NEW_CARD_2022,
  BRAZIL_GLUTEAL_SN_ESN,
  BRAZIL_GLUTEAL_ESN_LEFT,
  BRAZIL_GLUTEAL_ESN_RIGHT,
  BRAZIL_GLUTEAL_NEW_CARD_2022
} from "../../../assets/ImplantCards/index";
import { getLanguage } from "../../../utils/index";

let GLOBAL_BREAST_CP_EN = require(`../../../assets/ImplantCards/Global_Breast/EN/CP-SN-CV.svg`);
let GLOBAL_GLUTEAL_CP_EN = require(`../../../assets/ImplantCards/Global_Gluteal/EN/CP-SN-CV.svg`);
let BRAZIL_BREAST_CP_EN = require(`../../../assets/ImplantCards/Brazil_Breast/EN/CP-SN-CV.svg`);
let BRAZIL_GLUTEAL_CP_EN = require(`../../../assets/ImplantCards/Brazil_Gluteal/EN/CP-SN-CV.svg`);

// Country: (brazil or global mapping)
// Surgery types: (breast or implants)

const BRAZIL_LANG_ID = 190;

const cardCatalog = {
  SN: "SN",
  OLD_SN: "OLD_SN",
  SN_ESN: "SN_ESN",
  ESN_LEFT: "ESN_LEFT",
  ESN_RIGHT: "ESN_RIGHT",
  OLD_ESN_LEFT: "OLD_ESN_LEFT",
  OLD_ESN_RIGHT: "OLD_ESN_RIGHT",
  NEW_CARD_2022: "NEW_CARD_2022",
  OLD_CARD: "OLD_CARD",
  POINTED_OLD_CARD: "POINTED_OLD_CARD",
};

const cardSet = {
  GLOBAL_BREAST_OLD_CARD: "GLOBAL_BREAST_OLD_CARD",
  GLOBAL_BREAST_POINTED_OLD_CARD: "GLOBAL_BREAST_POINTED_OLD_CARD",
  GLOBAL_BREAST_OLD_SN_DA: "GLOBAL_BREAST_OLD_SN_da",
  GLOBAL_BREAST_OLD_SN_DE: "GLOBAL_BREAST_OLD_SN_de",
  GLOBAL_BREAST_OLD_SN_EN: "GLOBAL_BREAST_OLD_SN_en",
  GLOBAL_BREAST_OLD_SN_ES: "GLOBAL_BREAST_OLD_SN_es",
  GLOBAL_BREAST_OLD_SN_FR: "GLOBAL_BREAST_OLD_SN_fr",
  GLOBAL_BREAST_OLD_SN_IT: "GLOBAL_BREAST_OLD_SN_it",
  GLOBAL_BREAST_OLD_SN_NO: "GLOBAL_BREAST_OLD_SN_no",
  GLOBAL_BREAST_OLD_SN_PT: "GLOBAL_BREAST_OLD_SN_pt",
  GLOBAL_BREAST_OLD_SN_SV: "GLOBAL_BREAST_OLD_SN_sv",
  GLOBAL_BREAST_OLD_ESN_LEFT: "GLOBAL_BREAST_OLD_ESN_LEFT",
  GLOBAL_BREAST_OLD_ESN_RIGHT: "GLOBAL_BREAST_OLD_ESN_RIGHT",
  GLOBAL_BREAST_SN_DA: "GLOBAL_BREAST_SN_da",
  GLOBAL_BREAST_SN_DE: "GLOBAL_BREAST_SN_de",
  GLOBAL_BREAST_SN_EN: "GLOBAL_BREAST_SN_en",
  GLOBAL_BREAST_SN_ES: "GLOBAL_BREAST_SN_es",
  GLOBAL_BREAST_SN_FR: "GLOBAL_BREAST_SN_fr",
  GLOBAL_BREAST_SN_IT: "GLOBAL_BREAST_SN_it",
  GLOBAL_BREAST_SN_NO: "GLOBAL_BREAST_SN_no",
  GLOBAL_BREAST_SN_PT: "GLOBAL_BREAST_SN_pt",
  GLOBAL_BREAST_SN_SV: "GLOBAL_BREAST_SN_sv",
  GLOBAL_BREAST_SN_ESN: "GLOBAL_BREAST_SN_ESN",
  GLOBAL_BREAST_ESN_LEFT: "GLOBAL_BREAST_ESN_LEFT",
  GLOBAL_BREAST_ESN_RIGHT: "GLOBAL_BREAST_ESN_RIGHT",
  GLOBAL_BREAST_NEW_CARD_2022: "GLOBAL_BREAST_NEW_CARD_2022",

  GLOBAL_GLUTEAL_OLD_CARD: "GLOBAL_GLUTEAL_OLD_CARD",
  GLOBAL_GLUTEAL_POINTED_OLD_CARD: "GLOBAL_GLUTEAL_POINTED_OLD_CARD",
  GLOBAL_GLUTEAL_OLD_SN_DA: "GLOBAL_GLUTEAL_OLD_SN_da",
  GLOBAL_GLUTEAL_OLD_SN_DE: "GLOBAL_GLUTEAL_OLD_SN_de",
  GLOBAL_GLUTEAL_OLD_SN_EN: "GLOBAL_GLUTEAL_OLD_SN_en",
  GLOBAL_GLUTEAL_OLD_SN_ES: "GLOBAL_GLUTEAL_OLD_SN_es",
  GLOBAL_GLUTEAL_OLD_SN_FR: "GLOBAL_GLUTEAL_OLD_SN_fr",
  GLOBAL_GLUTEAL_OLD_SN_IT: "GLOBAL_GLUTEAL_OLD_SN_it",
  GLOBAL_GLUTEAL_OLD_SN_NO: "GLOBAL_GLUTEAL_OLD_SN_no",
  GLOBAL_GLUTEAL_OLD_SN_PT: "GLOBAL_GLUTEAL_OLD_SN_pt",
  GLOBAL_GLUTEAL_OLD_SN_SV: "GLOBAL_GLUTEAL_OLD_SN_sv",
  GLOBAL_GLUTEAL_OLD_ESN_LEFT: "GLOBAL_GLUTEAL_OLD_ESN_LEFT",
  GLOBAL_GLUTEAL_OLD_ESN_RIGHT: "GLOBAL_GLUTEAL_OLD_ESN_RIGHT",
  GLOBAL_GLUTEAL_SN_DA: "GLOBAL_GLUTEAL_SN_da",
  GLOBAL_GLUTEAL_SN_DE: "GLOBAL_GLUTEAL_SN_de",
  GLOBAL_GLUTEAL_SN_EN: "GLOBAL_GLUTEAL_SN_en",
  GLOBAL_GLUTEAL_SN_ES: "GLOBAL_GLUTEAL_SN_es",
  GLOBAL_GLUTEAL_SN_FR: "GLOBAL_GLUTEAL_SN_fr",
  GLOBAL_GLUTEAL_SN_IT: "GLOBAL_GLUTEAL_SN_it",
  GLOBAL_GLUTEAL_SN_NO: "GLOBAL_GLUTEAL_SN_no",
  GLOBAL_GLUTEAL_SN_PT: "GLOBAL_GLUTEAL_SN_pt",
  GLOBAL_GLUTEAL_SN_SV: "GLOBAL_GLUTEAL_SN_sv",
  GLOBAL_GLUTEAL_SN_ESN: "GLOBAL_GLUTEAL_SN_ESN",
  GLOBAL_GLUTEAL_ESN_LEFT: "GLOBAL_GLUTEAL_ESN_LEFT",
  GLOBAL_GLUTEAL_ESN_RIGHT: "GLOBAL_GLUTEAL_ESN_RIGHT",
  GLOBAL_GLUTEAL_NEW_CARD_2022: "GLOBAL_GLUTEAL_NEW_CARD_2022",

  BRAZIL_BREAST_OLD_CARD: "BRAZIL_BREAST_OLD_CARD",
  BRAZIL_BREAST_POINTED_OLD_CARD: "BRAZIL_BREAST_POINTED_OLD_CARD",
  BRAZIL_BREAST_OLD_SN_DA: "BRAZIL_BREAST_OLD_SN_da",
  BRAZIL_BREAST_OLD_SN_DE: "BRAZIL_BREAST_OLD_SN_de",
  BRAZIL_BREAST_OLD_SN_EN: "BRAZIL_BREAST_OLD_SN_en",
  BRAZIL_BREAST_OLD_SN_ES: "BRAZIL_BREAST_OLD_SN_es",
  BRAZIL_BREAST_OLD_SN_FR: "BRAZIL_BREAST_OLD_SN_fr",
  BRAZIL_BREAST_OLD_SN_IT: "BRAZIL_BREAST_OLD_SN_it",
  BRAZIL_BREAST_OLD_SN_NO: "BRAZIL_BREAST_OLD_SN_no",
  BRAZIL_BREAST_OLD_SN_PT: "BRAZIL_BREAST_OLD_SN_pt",
  BRAZIL_BREAST_OLD_SN_SV: "BRAZIL_BREAST_OLD_SN_sv",
  BRAZIL_BREAST_OLD_ESN_LEFT: "BRAZIL_BREAST_OLD_ESN_LEFT",
  BRAZIL_BREAST_OLD_ESN_RIGHT: "BRAZIL_BREAST_OLD_ESN_RIGHT",
  BRAZIL_BREAST_SN_DA: "BRAZIL_BREAST_SN_da",
  BRAZIL_BREAST_SN_DE: "BRAZIL_BREAST_SN_de",
  BRAZIL_BREAST_SN_EN: "BRAZIL_BREAST_SN_en",
  BRAZIL_BREAST_SN_ES: "BRAZIL_BREAST_SN_es",
  BRAZIL_BREAST_SN_FR: "BRAZIL_BREAST_SN_fr",
  BRAZIL_BREAST_SN_IT: "BRAZIL_BREAST_SN_it",
  BRAZIL_BREAST_SN_NO: "BRAZIL_BREAST_SN_no",
  BRAZIL_BREAST_SN_PT: "BRAZIL_BREAST_SN_pt",
  BRAZIL_BREAST_SN_SV: "BRAZIL_BREAST_SN_sv",
  BRAZIL_BREAST_SN_ESN: "BRAZIL_BREAST_SN_ESN",
  BRAZIL_BREAST_ESN_LEFT: "BRAZIL_BREAST_ESN_LEFT",
  BRAZIL_BREAST_ESN_RIGHT: "BRAZIL_BREAST_ESN_RIGHT",
  BRAZIL_BREAST_NEW_CARD_2022: "BRAZIL_BREAST_NEW_CARD_2022",

  BRAZIL_GLUTEAL_OLD_CARD: "BRAZIL_GLUTEAL_OLD_CARD",
  BRAZIL_GLUTEAL_POINTED_OLD_CARD: "BRAZIL_GLUTEAL_POINTED_OLD_CARD",
  BRAZIL_GLUTEAL_OLD_SN_DA: "BRAZIL_GLUTEAL_OLD_SN_da",
  BRAZIL_GLUTEAL_OLD_SN_DE: "BRAZIL_GLUTEAL_OLD_SN_de",
  BRAZIL_GLUTEAL_OLD_SN_EN: "BRAZIL_GLUTEAL_OLD_SN_en",
  BRAZIL_GLUTEAL_OLD_SN_ES: "BRAZIL_GLUTEAL_OLD_SN_es",
  BRAZIL_GLUTEAL_OLD_SN_FR: "BRAZIL_GLUTEAL_OLD_SN_fr",
  BRAZIL_GLUTEAL_OLD_SN_IT: "BRAZIL_GLUTEAL_OLD_SN_it",
  BRAZIL_GLUTEAL_OLD_SN_NO: "BRAZIL_GLUTEAL_OLD_SN_no",
  BRAZIL_GLUTEAL_OLD_SN_PT: "BRAZIL_GLUTEAL_OLD_SN_pt",
  BRAZIL_GLUTEAL_OLD_SN_SV: "BRAZIL_GLUTEAL_OLD_SN_sv",
  BRAZIL_GLUTEAL_OLD_ESN_LEFT: "BRAZIL_GLUTEAL_OLD_ESN_LEFT",
  BRAZIL_GLUTEAL_OLD_ESN_RIGHT: "BRAZIL_GLUTEAL_OLD_ESN_RIGHT",
  BRAZIL_GLUTEAL_SN_DA: "BRAZIL_GLUTEAL_SN_da",
  BRAZIL_GLUTEAL_SN_DE: "BRAZIL_GLUTEAL_SN_de",
  BRAZIL_GLUTEAL_SN_EN: "BRAZIL_GLUTEAL_SN_en",
  BRAZIL_GLUTEAL_SN_ES: "BRAZIL_GLUTEAL_SN_es",
  BRAZIL_GLUTEAL_SN_FR: "BRAZIL_GLUTEAL_SN_fr",
  BRAZIL_GLUTEAL_SN_IT: "BRAZIL_GLUTEAL_SN_it",
  BRAZIL_GLUTEAL_SN_NO: "BRAZIL_GLUTEAL_SN_no",
  BRAZIL_GLUTEAL_SN_PT: "BRAZIL_GLUTEAL_SN_pt",
  BRAZIL_GLUTEAL_SN_SV: "BRAZIL_GLUTEAL_SN_sv",
  BRAZIL_GLUTEAL_SN_ESN: "BRAZIL_GLUTEAL_SN_ESN",
  BRAZIL_GLUTEAL_ESN_LEFT: "BRAZIL_GLUTEAL_ESN_LEFT",
  BRAZIL_GLUTEAL_ESN_RIGHT: "BRAZIL_GLUTEAL_ESN_RIGHT",
  BRAZIL_GLUTEAL_NEW_CARD_2022: "BRAZIL_GLUTEAL_NEW_CARD_2022",
};

const cardType = {
  BREAST: "BREAST",
  GLUTEAL: "GLUTEAL",
};

const countrySelections = {
  GLOBAL: "GLOBAL",
  BRAZIL: "BRAZIL",
};

const renderCardSN = (sectionCard, group, lang)=>{

  let COMPLETE_SN = require(`../../../assets/ImplantCards/${group}/${lang}/CP-SN-CV.svg`);
  let SN_LEFT = require(`../../../assets/ImplantCards/${group}/SN-Left.svg`);
  let SN_RIGHT = require(`../../../assets/ImplantCards/${group}/SN-Right.svg`);
  let VC_LEFT = require(`../../../assets/ImplantCards/${group}/${lang}/VC-Left.svg`);
  let VC_RIGHT = require(`../../../assets/ImplantCards/${group}/${lang}/VC-Right.svg`);

  return sectionCard === 'SNLeft' ? SN_LEFT : sectionCard === 'SNRight' ? SN_RIGHT : sectionCard === 'VCLeft' ?
    VC_LEFT : sectionCard === 'VCRight' ? VC_RIGHT: sectionCard === 'default' && COMPLETE_SN
}

const getSNCardByLang = ({ lang, isBreast, type, sectionCard }) => {
  const isOldCard = type === 1;
  const isGlobal = lang !== BRAZIL_LANG_ID;
  const countryRegionText = isGlobal
    ? countrySelections.GLOBAL
    : countrySelections.BRAZIL;
  const cartTypeText = isBreast ? cardType.BREAST : cardType.GLUTEAL;
  const cardId = `${countryRegionText}_${cartTypeText}_${!isOldCard ? cardCatalog.SN : cardCatalog.OLD_SN}_${getLanguage()}`;
  if (isGlobal) {
    // GLOBAL (Rest of countries)
    if (isBreast) {
      // GLOBAL BREAST
      switch (cardId) {
        case cardSet.GLOBAL_BREAST_SN_DA:
          return renderCardSN(sectionCard,'Global_Breast','DA');
        case cardSet.GLOBAL_BREAST_SN_DE:
          return renderCardSN(sectionCard,'Global_Breast','DE');
        case cardSet.GLOBAL_BREAST_SN_EN:
          return renderCardSN(sectionCard,'Global_Breast','EN');
        case cardSet.GLOBAL_BREAST_SN_ES:
          return renderCardSN(sectionCard,'Global_Breast','ES');
        case cardSet.GLOBAL_BREAST_SN_FR:
          return renderCardSN(sectionCard,'Global_Breast','FR');
        case cardSet.GLOBAL_BREAST_SN_IT:
          return renderCardSN(sectionCard,'Global_Breast','IT');
        case cardSet.GLOBAL_BREAST_SN_NO:
          return renderCardSN(sectionCard,'Global_Breast','NO');
        case cardSet.GLOBAL_BREAST_SN_PT:
          return renderCardSN(sectionCard,'Global_Breast','PT');
        case cardSet.GLOBAL_BREAST_SN_SV:
          return renderCardSN(sectionCard,'Global_Breast','SV');

        case cardSet.GLOBAL_BREAST_OLD_SN_DA:
          return OLD_CARD_SN_DA;
        case cardSet.GLOBAL_BREAST_OLD_SN_DE:
          return OLD_CARD_SN_DE;
        case cardSet.GLOBAL_BREAST_OLD_SN_EN:
          return OLD_CARD_SN_EN;
        case cardSet.GLOBAL_BREAST_OLD_SN_ES:
          return OLD_CARD_SN_ES;
        case cardSet.GLOBAL_BREAST_OLD_SN_FR:
          return OLD_CARD_SN_FR;
        case cardSet.GLOBAL_BREAST_OLD_SN_IT:
          return OLD_CARD_SN_IT;
        case cardSet.GLOBAL_BREAST_OLD_SN_NO:
          return OLD_CARD_SN_NO;
        case cardSet.GLOBAL_BREAST_OLD_SN_PT:
          return OLD_CARD_SN_PT;
        case cardSet.GLOBAL_BREAST_OLD_SN_SV:
          return OLD_CARD_SN_SV;
        default:
          return GLOBAL_BREAST_CP_EN;
      }
    } else {
      switch (cardId) {
        case cardSet.GLOBAL_GLUTEAL_SN_ESN:
          return GLOBAL_GLUTEAL_SN_ESN;
        case cardSet.GLOBAL_GLUTEAL_SN_DA:
          return renderCardSN(sectionCard,'Global_Gluteal','DA');
        case cardSet.GLOBAL_GLUTEAL_SN_DE:
          return renderCardSN(sectionCard,'Global_Gluteal','DE');
        case cardSet.GLOBAL_GLUTEAL_SN_EN:
          return renderCardSN(sectionCard,'Global_Gluteal','EN');
        case cardSet.GLOBAL_GLUTEAL_SN_ES:
          return renderCardSN(sectionCard,'Global_Gluteal','ES');
        case cardSet.GLOBAL_GLUTEAL_SN_FR:
          return renderCardSN(sectionCard,'Global_Gluteal','FR');
        case cardSet.GLOBAL_GLUTEAL_SN_IT:
          return renderCardSN(sectionCard,'Global_Gluteal','IT');
        case cardSet.GLOBAL_GLUTEAL_SN_NO:
          return renderCardSN(sectionCard,'Global_Gluteal','NO');
        case cardSet.GLOBAL_GLUTEAL_SN_PT:
          return renderCardSN(sectionCard,'Global_Gluteal','PT');
        case cardSet.GLOBAL_GLUTEAL_SN_SV:
          return renderCardSN(sectionCard,'Global_Gluteal','SV');
        case cardSet.GLOBAL_GLUTEAL_OLD_SN_DA:
          return OLD_CARD_SN_DA;
        case cardSet.GLOBAL_GLUTEAL_OLD_SN_DE:
          return OLD_CARD_SN_DE;
        case cardSet.GLOBAL_GLUTEAL_OLD_SN_EN:
          return OLD_CARD_SN_EN;
        case cardSet.GLOBAL_GLUTEAL_OLD_SN_ES:
          return OLD_CARD_SN_ES;
        case cardSet.GLOBAL_GLUTEAL_OLD_SN_FR:
          return OLD_CARD_SN_FR;
        case cardSet.GLOBAL_GLUTEAL_OLD_SN_IT:
          return OLD_CARD_SN_IT;
        case cardSet.GLOBAL_GLUTEAL_OLD_SN_NO:
          return OLD_CARD_SN_NO;
        case cardSet.GLOBAL_GLUTEAL_OLD_SN_PT:
          return OLD_CARD_SN_PT;
        case cardSet.GLOBAL_GLUTEAL_OLD_SN_SV:
          return OLD_CARD_SN_SV;
        case cardSet.GLOBAL_GLUTEAL_ESN_LEFT:
          return GLOBAL_GLUTEAL_ESN_LEFT;
        case cardSet.GLOBAL_GLUTEAL_ESN_RIGHT:
          return GLOBAL_GLUTEAL_ESN_RIGHT;
        default:
          return GLOBAL_GLUTEAL_CP_EN;
      }
    }
  } else {
    // BRAZIL
    if (isBreast) {
      // BRAZIL BREAST
      switch (cardId) {
        case cardSet.BRAZIL_BREAST_SN_ESN:
          return BRAZIL_BREAST_SN_ESN;
        case cardSet.BRAZIL_BREAST_SN_DA:
          return renderCardSN(sectionCard,'Brazil_Breast','DA');
        case cardSet.BRAZIL_BREAST_SN_DE:
          return renderCardSN(sectionCard,'Brazil_Breast','DE');
        case cardSet.BRAZIL_BREAST_SN_EN:
          return renderCardSN(sectionCard,'Brazil_Breast','EN');
        case cardSet.BRAZIL_BREAST_SN_ES:
          return renderCardSN(sectionCard,'Brazil_Breast','ES');
        case cardSet.BRAZIL_BREAST_SN_FR:
          return renderCardSN(sectionCard,'Brazil_Breast','FR');
        case cardSet.BRAZIL_BREAST_SN_IT:
          return renderCardSN(sectionCard,'Brazil_Breast','IT');
        case cardSet.BRAZIL_BREAST_SN_NO:
          return renderCardSN(sectionCard,'Brazil_Breast','NO');
        case cardSet.BRAZIL_BREAST_SN_PT:
          return renderCardSN(sectionCard,'Brazil_Breast','PT');
        case cardSet.BRAZIL_BREAST_SN_SV:
          return renderCardSN(sectionCard,'Brazil_Breast','SV');
        case cardSet.BRAZIL_BREAST_OLD_SN_DA:
          return OLD_CARD_SN_DA;
        case cardSet.BRAZIL_BREAST_OLD_SN_DE:
          return OLD_CARD_SN_DE;
        case cardSet.BRAZIL_BREAST_OLD_SN_EN:
          return OLD_CARD_SN_EN;
        case cardSet.BRAZIL_BREAST_OLD_SN_ES:
          return OLD_CARD_SN_ES;
        case cardSet.BRAZIL_BREAST_OLD_SN_FR:
          return OLD_CARD_SN_FR;
        case cardSet.BRAZIL_BREAST_OLD_SN_IT:
          return OLD_CARD_SN_IT;
        case cardSet.BRAZIL_BREAST_OLD_SN_NO:
          return OLD_CARD_SN_NO;
        case cardSet.BRAZIL_BREAST_OLD_SN_PT:
          return OLD_CARD_SN_PT;
        case cardSet.BRAZIL_BREAST_OLD_SN_SV:
          return OLD_CARD_SN_SV;
        case cardSet.BRAZIL_BREAST_ESN_LEFT:
          return BRAZIL_BREAST_ESN_LEFT;
        case cardSet.BRAZIL_BREAST_ESN_RIGHT:
          return BRAZIL_BREAST_ESN_RIGHT;
        default:
          return BRAZIL_BREAST_CP_EN;
      }
    } else {
      // BRAZIL GLUTEAL
      switch (cardId) {
        case cardSet.BRAZIL_GLUTEAL_SN_ESN:
          return BRAZIL_GLUTEAL_SN_ESN;
        case cardSet.BRAZIL_GLUTEAL_SN_DA:
          return renderCardSN(sectionCard,'Brazil_Gluteal','DA');
        case cardSet.BRAZIL_GLUTEAL_SN_DE:
          return renderCardSN(sectionCard,'Brazil_Gluteal','DE');
        case cardSet.BRAZIL_GLUTEAL_SN_EN:
          return renderCardSN(sectionCard,'Brazil_Gluteal','EN');
        case cardSet.BRAZIL_GLUTEAL_SN_ES:
          return renderCardSN(sectionCard,'Brazil_Gluteal','ES');
        case cardSet.BRAZIL_GLUTEAL_SN_FR:
          return renderCardSN(sectionCard,'Brazil_Gluteal','FR');
        case cardSet.BRAZIL_GLUTEAL_SN_IT:
          return renderCardSN(sectionCard,'Brazil_Gluteal','IT');
        case cardSet.BRAZIL_GLUTEAL_SN_NO:
          return renderCardSN(sectionCard,'Brazil_Gluteal','NO');
        case cardSet.BRAZIL_GLUTEAL_SN_PT:
          return renderCardSN(sectionCard,'Brazil_Gluteal','PT');
        case cardSet.BRAZIL_GLUTEAL_SN_SV:
          return renderCardSN(sectionCard,'Brazil_Gluteal','SV');
        case cardSet.BRAZIL_GLUTEAL_OLD_SN_DA:
          return OLD_CARD_SN_DA;
        case cardSet.BRAZIL_GLUTEAL_OLD_SN_DE:
          return OLD_CARD_SN_DE;
        case cardSet.BRAZIL_GLUTEAL_OLD_SN_EN:
          return OLD_CARD_SN_EN;
        case cardSet.BRAZIL_GLUTEAL_OLD_SN_ES:
          return OLD_CARD_SN_ES;
        case cardSet.BRAZIL_GLUTEAL_OLD_SN_FR:
          return OLD_CARD_SN_FR;
        case cardSet.BRAZIL_GLUTEAL_OLD_SN_IT:
          return OLD_CARD_SN_IT;
        case cardSet.BRAZIL_GLUTEAL_OLD_SN_NO:
          return OLD_CARD_SN_NO;
        case cardSet.BRAZIL_GLUTEAL_OLD_SN_PT:
          return OLD_CARD_SN_PT;
        case cardSet.BRAZIL_GLUTEAL_OLD_SN_SV:
          return OLD_CARD_SN_SV;
        case cardSet.BRAZIL_GLUTEAL_ESN_LEFT:
          return BRAZIL_GLUTEAL_ESN_LEFT;
        case cardSet.BRAZIL_GLUTEAL_ESN_RIGHT:
          return BRAZIL_GLUTEAL_ESN_RIGHT;
        default:
          return BRAZIL_GLUTEAL_CP_EN;
      }
    }
  }
};

const getCardTypeByLang = ({ lang, type, isBreast, left, right, esnorsn }) => {
  const isGlobal = lang !== BRAZIL_LANG_ID;
  const countryRegionText = isGlobal
    ? countrySelections.GLOBAL
    : countrySelections.BRAZIL;
  const cartTypeText = isBreast ? cardType.BREAST : cardType.GLUTEAL;
  const resolveCardType = (type) => {
    const isOldCard = type === 1;
    if (esnorsn && type === 1) return cardCatalog.POINTED_OLD_CARD;
    if (esnorsn && type === 0) return cardCatalog.SN_ESN;
    if (left)
      return !isOldCard ? cardCatalog.ESN_LEFT : cardCatalog.OLD_ESN_LEFT;
    if (right)
      return !isOldCard ? cardCatalog.ESN_RIGHT : cardCatalog.OLD_ESN_RIGHT;
    switch (type) {
      case 0:
        return cardCatalog.NEW_CARD_2022;
      case 1:
        return cardCatalog.OLD_CARD;
      default:
        return cardCatalog.SN_ESN;
    }
  };
  const cartType = resolveCardType(type);
  const cardId = `${countryRegionText}_${cartTypeText}_${cartType}`;
  if (isGlobal) {
    // GLOBAL (Rest of countries)
    if (isBreast) {
      // GLOBAL BREAST
      switch (cardId) {
        case cardSet.GLOBAL_BREAST_OLD_CARD:
          return OLD_FIRST_CARD;
        case cardSet.GLOBAL_BREAST_POINTED_OLD_CARD:
          return OLD_POINTED_FIRST_CARD;
        case cardSet.GLOBAL_BREAST_NEW_CARD_2022:
          return GLOBAL_BREAST_NEW_CARD_2022;
        case cardSet.GLOBAL_BREAST_SN_ESN:
          return GLOBAL_BREAST_SN_ESN;
        case cardSet.GLOBAL_BREAST_ESN_LEFT:
          return GLOBAL_BREAST_ESN_LEFT;
        case cardSet.GLOBAL_BREAST_ESN_RIGHT:
          return GLOBAL_BREAST_ESN_RIGHT;
        case cardSet.GLOBAL_BREAST_OLD_ESN_LEFT:
          return OLD_ESN_LEFT;
        case cardSet.GLOBAL_BREAST_OLD_ESN_RIGHT:
          return OLD_ESN_RIGHT;
        default:
          return GLOBAL_BREAST_SN_ESN;
      }
    } else {
      switch (cardId) {
        case cardSet.GLOBAL_GLUTEAL_OLD_CARD:
          return OLD_FIRST_CARD;
        case cardSet.GLOBAL_GLUTEAL_POINTED_OLD_CARD:
          return OLD_POINTED_FIRST_CARD;
        case cardSet.GLOBAL_GLUTEAL_NEW_CARD_2022:
          return GLOBAL_GLUTEAL_NEW_CARD_2022;
        case cardSet.GLOBAL_GLUTEAL_SN_ESN:
          return GLOBAL_GLUTEAL_SN_ESN;
        case cardSet.GLOBAL_GLUTEAL_ESN_LEFT:
          return GLOBAL_GLUTEAL_ESN_LEFT;
        case cardSet.GLOBAL_GLUTEAL_ESN_RIGHT:
          return GLOBAL_GLUTEAL_ESN_RIGHT;
        case cardSet.GLOBAL_GLUTEAL_OLD_ESN_LEFT:
          return OLD_ESN_LEFT;
        case cardSet.GLOBAL_GLUTEAL_OLD_ESN_RIGHT:
          return OLD_ESN_RIGHT;
        default:
          return GLOBAL_GLUTEAL_SN_ESN;
      }
    }
  } else {
    // BRAZIL
    if (isBreast) {
      // BRAZIL BREAST
      switch (cardId) {
        case cardSet.BRAZIL_BREAST_OLD_CARD:
          return OLD_FIRST_CARD;
        case cardSet.BRAZIL_BREAST_POINTED_OLD_CARD:
          return OLD_POINTED_FIRST_CARD;
        case cardSet.BRAZIL_BREAST_NEW_CARD_2022:
          return BRAZIL_BREAST_NEW_CARD_2022;
        case cardSet.BRAZIL_BREAST_SN_ESN:
          return BRAZIL_BREAST_SN_ESN;
        case cardSet.BRAZIL_BREAST_ESN_LEFT:
          return BRAZIL_BREAST_ESN_LEFT;
        case cardSet.BRAZIL_BREAST_ESN_RIGHT:
          return BRAZIL_BREAST_ESN_RIGHT;
        case cardSet.BRAZIL_BREAST_OLD_ESN_LEFT:
          return OLD_ESN_LEFT;
        case cardSet.BRAZIL_BREAST_OLD_ESN_RIGHT:
          return OLD_ESN_RIGHT;
        default:
          return BRAZIL_BREAST_SN_ESN;
      }
    } else {
      // BRAZIL GLUTEAL
      switch (cardId) {
        case cardSet.BRAZIL_GLUTEAL_OLD_CARD:
          return OLD_FIRST_CARD;
        case cardSet.BRAZIL_GLUTEAL_POINTED_OLD_CARD:
          return OLD_POINTED_FIRST_CARD;
        case cardSet.BRAZIL_GLUTEAL_NEW_CARD_2022:
          return BRAZIL_GLUTEAL_NEW_CARD_2022;
        case cardSet.BRAZIL_GLUTEAL_SN_ESN:
          return BRAZIL_GLUTEAL_SN_ESN;
        case cardSet.BRAZIL_GLUTEAL_ESN_LEFT:
          return BRAZIL_GLUTEAL_ESN_LEFT;
        case cardSet.BRAZIL_GLUTEAL_ESN_RIGHT:
          return BRAZIL_GLUTEAL_ESN_RIGHT;
        case cardSet.BRAZIL_GLUTEAL_OLD_ESN_LEFT:
          return OLD_ESN_LEFT;
        case cardSet.BRAZIL_GLUTEAL_OLD_ESN_RIGHT:
          return OLD_ESN_RIGHT;
        default:
          return BRAZIL_GLUTEAL_SN_ESN;
      }
    }
  }
};

const resolveCard = (options) => {
  // TODO - Separete tenary
  const temp = options.esnorsn
    ? getCardTypeByLang(options)
    : !options.esn && !options.sn
    ? getCardTypeByLang(options)
    : options.esn
    ? getCardTypeByLang(options)
    : getSNCardByLang(options);
  return temp;
};

export const ImplantCard = styled.div`
  --selection-color: hsl(316, 21%, 42%);
  background-image: url(${({
    type,
    esnorsn,
    left,
    right,
    esn,
    sn,
    isBreast = true,
    lang,
    sectionCard
  }) =>
    resolveCard({
      type,
      esnorsn,
      left,
      right,
      esn,
      sn,
      isBreast,
      lang,
      sectionCard
    })});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 250px;
  height: auto;
  min-height: 20vh;
  position: relative;
  @media screen and (min-width: 500px) {
    min-width: 400px;
  }
`;
