import React, {createContext, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {preRegistrationInformation} from '../store/slices/preRegisterSlice'

import {parse as parseDate} from "date-fns";
import {AccountContext} from "./AccountContext";
import {StaticFormContext} from "./StaticFormContext";
import {useCountries} from "../hooks/useCountries";
import {useQueryString} from "../hooks/useQueryString";
import {setRegistrationResult} from "../store/slices/registrationSlice";


export const UserContext = createContext({});

export const UserContextProvider = ({children}) => {
  const {getQueryParam} = useQueryString();
  const preRegistration = useSelector((state) => state.preRegisterSlice);
  const {getCountryById, getCountryByCode, countriesLoaded} = useCountries();
  const {estaAPIKit, gigyaCredentialsLoaded} = useContext(AccountContext);
  const dispatch = useDispatch();


  const {
    patientFirstName,
    patientLastName,
    patientEmail,
    patientCountry,
    country,
    loadPatientFromClinic,
    currentWizard,
    serialNumberLeft,
    serialNumberRight,
    leftVC,
    rightVC,
    isScanned,
    loadFormDataFromPreRegistrationPayload,
    clearFormContext,
  } = useContext(StaticFormContext);
  const [me, setMe] = useState(null);
  const [breastImplants, setBreastImplants] = useState({});
  const [glutealImplants, setGlutealImplants] = useState({});
  const [isMeLoading, setIsMeLoading] = useState(false);
  const [userError, setUserError] = useState(null);

  const [
    isPreRegistrationInformationLoaded,
    setIsPreRegistrationInformationLoaded,
  ] = useState(false);

  const [
    isLoading,
    setIsLoading,
  ] = useState(false);

  const isMeLoaded = () => me && !isMeLoading;

  const isSurgeryCountryValid = () => {
    return me?.country_id;
  };

  async function getMe() {
    setIsMeLoading(true);
    await estaAPIKit
      .getMe()
      .then((res) => res.json())
      .then(fillInSurgeon)
      .then((data) => {
        setMe(data);
        dispatch(preRegistrationInformation({
          me: data
        }));
      })
      .catch((error) =>
        console.log("Error getting user information from Esta API", error)
      );
    setIsMeLoading(false);
  }

  function loadPreregistrationInformation() {
    const preRegistrationId = me?.notifications?.preregistrations?.[0]?.id;
    setIsLoading(true);
    return estaAPIKit
      .preRegistration(preRegistrationId)
      .then((res) => res.json())
      .then((data) => {
        loadFormDataFromPreRegistrationPayload(data);
        setMe((me) => ({
          ...me,
          surgeryDate: parseDate(data.surgery_date, "MM/dd/yyyy", new Date()),
          isESN: data.isQ,
          country: [getCountryById(data.country_id)],
        }));
        setIsPreRegistrationInformationLoaded(true);

        setIsLoading(false);

        dispatch(preRegistrationInformation({
          preRegistrationId,
          implantPlacement: data.placement_id,
          incisionKind: data.incision_id,
          surgeryDate: data.surgery_date,
          surgeryIndication: data.indication_id,
          surgeon: [
            {
              id: data.doctor_id,
              value: data.doctor_id,
              label: data.doctor_name,
            },
          ],
          isQ: data.isQ,
          isESN: data.isQ,
          country: [getCountryById(data.country_id)],
          implantsInformation: {
            ...data,
            tools:{
              injector: data.injector,
              balloon: data.balloon
            }
          },
          implantsFor: typeof data.implantL === 'string' && typeof data.implantR === 'string'
            ? "both" : typeof data.implantL === 'string' ? "left" : "right",
          isMiaImplants: (data.injector && data.balloon) ? true : false
        }));
      }).finally(()=>setIsLoading(false));
  }

  function fillInSurgeon(data) {
    const doctor_id = data.doctor_id ? data.doctor_id : 0;
    return {
      ...data,
      surgeon: data.isDoctor
        ? [
          {
            id: doctor_id,
            value: doctor_id,
            label: `${data.firstName} ${data.lastName}`,
          },
        ]
        : [],
    };
  }

  function getImplant(implantType, side) {
    if (implantType === "B") {
      return getImplantOfType(breastImplants, side);
    } else if (implantType === "G") {
      return getImplantOfType(glutealImplants, side);
    }
  }

  function getImplantOfType(implants, side) {
    if (implants && implants[side] && implants[side]) {
      if (me.isESN) {
        return implants[side].electronic_serial;
      } else if (implants[side].serial) {
        return implants[side].serial;
      }
      return "";
    }
    return "";
  }

  function getImplantSerialNumber(implantType, side) {
    const implantFromUserContext = getImplant(implantType, side);
    const implantFromFormContext =
      side === "L" ? serialNumberLeft : serialNumberRight;

    return implantFromUserContext // If there is an implant in user context, use that one
      ? implantFromUserContext
      : me.preRegistrationId && implantFromFormContext // If there is not, check if we're in a preregistration process and allow to check for implant in the form context
        ? implantFromFormContext
        : ""; // If neither send empty
  }

  async function getMePayloadForRegistration() {
    const lang = estaAPIKit.getUserLanguage();
    const implantType = currentWizard === "breast" ? "B" : "G";

    let payload = {
      doctor: {
        doctorId: me.surgeon && me.surgeon.length > 0 ? me.surgeon[0].id : 0,
      },
      surgeryDate: me.surgeryDate.toLocaleDateString("en-US"),
      countryId: me.country_id
        ? me.country_id
        : country
          ? country
          : null || me?.country?.[0]?.id,
      placementId: me.implantPlacement ? me.implantPlacement : preRegistration?.implantPlacement,
      incisionId: me.incisionKind ? me.incisionKind : preRegistration?.incisionKind,
      indicationId: me.surgeryIndication ? me.surgeryIndication : preRegistration?.surgeryIndication,
      lang,
      isScanned: isScanned ? isScanned : 0,
      devices:{
        implants: {
          type: implantType || 0,
          left: {
            serialNumber: !me.isQ ? getImplantSerialNumber(implantType, "L") : null,
            validationCode: !me.isQ ? leftVC : null,
            electronicSerial: me.isQ ? getImplantSerialNumber(implantType, "L") : null

          },
          right: {
            serialNumber: !me.isQ ? getImplantSerialNumber(implantType, "R") : null,
            validationCode: !me.isQ ? rightVC : null,
            electronicSerial: me.isQ ?getImplantSerialNumber(implantType, "R") : null,
          }
        },
        tools: {
          injector: preRegistration?.implantsInformation?.tools.injector ? preRegistration?.implantsInformation?.tools.injector : null,
          balloon: preRegistration?.implantsInformation?.tools.balloon ? preRegistration?.implantsInformation?.tools.balloon : null
        }
      }
    };

    if (me.surgeon && me.surgeon.length > 0 && me.surgeon[0].label) {
      payload.doctor.doctorName = me.surgeon[0].label;
      payload.doctor.doctorId = me.surgeon[0].value;
    }

    if (me.preRegistrationId) {
      payload.preRegistrationId = me.preRegistrationId;
    }

    if (isDoctor()) {
      payload = {
        ...payload,
        patientFirstName: patientFirstName,
        patientLastName: patientLastName,
        patientCountry: patientCountry?.[0].value,
        patientEmail: patientEmail,
      };
    }
    return payload;
  }

  async function getMePayloadForPreRegistration(isDevice= false) {
    const lang = estaAPIKit.getUserLanguage();
    const implantType = preRegistration?.implantsInformation?.implant_type;

    let payload = {
      doctor:{
        doctorId: preRegistration?.me?.surgeon && preRegistration?.me?.surgeon.length > 0 ? preRegistration?.me?.surgeon[0].id : preRegistration?.surgeon[0].id,
      },
      surgeryDate: preRegistration?.surgeryDate === null ? new Date().toLocaleDateString("en-US") : preRegistration?.surgeryDate,
      countryId: preRegistration?.country[0].id,
      placementId: preRegistration?.implantPlacement || 0,
      incisionId: preRegistration?.incisionKind || 0,
      indicationId: preRegistration?.surgeryIndication || 0,
      lang,
      isScanned: isScanned ? isScanned : 0,

    };

    if (preRegistration?.me?.surgeon && preRegistration?.me?.surgeon.length > 0 || (preRegistration?.surgeon && preRegistration?.surgeon.length > 0)) {
      payload.doctor.doctorName = preRegistration?.me?.surgeon && preRegistration?.me?.surgeon.length > 0 ? preRegistration?.me?.surgeon[0].label : preRegistration?.surgeon[0].label;
    }

    if (preRegistration?.preRegistrationId) {
      payload.preRegistrationId = preRegistration?.preRegistrationId;
    }

    const devices = {
      implants: {
        type: implantType || 0,
        left: {
          serialNumber: preRegistration?.implantsInformation?.validationL ? preRegistration?.implantsInformation?.implantL : null,
          validationCode: preRegistration?.implantsInformation?.validationL ? preRegistration?.implantsInformation?.validationL : null,
          electronicSerial: !preRegistration?.implantsInformation?.validationL ? preRegistration?.implantsInformation?.implantL : null
        },
        right: {
          serialNumber: preRegistration?.implantsInformation?.validationR ? preRegistration?.implantsInformation?.implantR : null,
          validationCode: preRegistration?.implantsInformation?.validationR ? preRegistration?.implantsInformation?.validationR : null,
          electronicSerial: !preRegistration?.implantsInformation?.validationR ? preRegistration?.implantsInformation?.implantR : null,
        }
      },
      tools: {
        injector: preRegistration?.implantsInformation?.tools.injector ? preRegistration?.implantsInformation?.tools.injector : null,
        balloon: preRegistration?.implantsInformation?.tools.balloon ? preRegistration?.implantsInformation?.tools.balloon : null
      }
    };

    if (preRegistration?.me?.surgeon && preRegistration?.me?.surgeon.length > 0 || (preRegistration?.surgeon && preRegistration?.surgeon.length > 0)) {
      payload.doctor.doctorName = preRegistration?.me?.surgeon && preRegistration?.me?.surgeon.length > 0 ? preRegistration?.me?.surgeon[0].label : preRegistration?.surgeon[0].label;
    }

    if (isDevice) {
      payload = {
        ...payload,
        devices
      };
    }else{
      payload = {
        ...payload,
        ...devices
      };
    }

    if (isDoctor()) {
      payload = {
        ...payload,
        patientFirstName: preRegistration?.patientSummary?.patientFirstName,
        patientLastName: preRegistration?.patientSummary?.patientLastName,
        patientCountry: preRegistration?.country?.[0].value,
        patientEmail: preRegistration?.patientSummary?.patientEmail,
        SSN: preRegistration?.patientSummary?.patientSSN,
        phone: preRegistration?.patientSummary?.patientPhone,
      };
    }

    return payload;
  }

  function isDoctor() {
    return !!me?.isDoctor;
  }

  function hasPreRegistrations() {
    return !!me?.notifications?.preregistrations?.length;
  }

  function registerMeDevicesBreast() {
    return estaAPIKit
      .registerMeDevicesBreast(getMePayloadForRegistration())
      .then((data) => {
        dispatch(setRegistrationResult(data.message))
        clearFormContext();
        return updateMeWarranty(data.message);
      });
  }

  function preRegisterReject() {
    const preRegistrationId = me?.notifications?.preregistrations?.[0]?.id;
    return estaAPIKit.preRegisterReject(preRegistrationId).then(getMe);
  }

  async function preRegisterMeDevicesBreastPatient() {
    let payload = await getMePayloadForPreRegistration(true);
    return estaAPIKit
      .registerMeDevicesBreast(payload)
      .then((data) => {
        dispatch(setRegistrationResult(data.message))
        clearFormContext();
        return updateMeWarranty(data.message);
      });
  }

  function preRegisterMeDevicesBreastDoctor() {
    return estaAPIKit
      .preRegister(getMePayloadForPreRegistration())
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status === true) {
          return Promise.reject(data);
        }
      })
      .then(clearFormContext)
      .then(updateMeWarranty);
  }

  function updateMeWarranty(response) {
    //updates and redirects locally and redirects to warranty if applies
    setIsMeLoading(true);
    return estaAPIKit
      .getMe()
      .then((res) => res.json())
      .then(fillInSurgeon)
      .then((data) => {
        setMe(data);
        setIsMeLoading(false);
        dispatch(preRegistrationInformation({
          me: data
        }));
        return {
          ...data,
          autoExtendedWarranty: response?.autoExtendedWarranty,
          implants: response?.devices?.implants,
          implantsRegisteredApplyWarranty: response?.implantsRegisteredApplyWarranty
        };
      })
      .catch((error) => {
        //alert("Error getting user information from Esta API");
        console.log(error);
      });
  }

  useEffect(() => {
    gigyaCredentialsLoaded && getMe();
  }, [gigyaCredentialsLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isPreRegistrationInformationLoaded && hasPreRegistrations()) {
      if (me && countriesLoaded) loadPreregistrationInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, countriesLoaded]);

  useEffect(() => {
    if (isDoctor() && countriesLoaded) {
      const id = getQueryParam("id");
      const master_id = getQueryParam("master_id");
      if (id && master_id)
        loadPatientFromClinic(id, master_id, getCountryByCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, countriesLoaded]);

  return (
    <UserContext.Provider
      value={{
        me,
        userError,
        estaAPIKit,
        isMeLoading,
        breastImplants,
        glutealImplants,
        setMe,
        setBreastImplants,
        setGlutealImplants,
        setUserError,
        isMeLoaded,
        isSurgeryCountryValid,
        isDoctor,
        hasPreRegistrations,
        isPreRegistrationInformationLoaded,
        loadPreregistrationInformation,
        getMePayloadForRegistration,
        preRegisterReject,
        registerMeDevicesBreast,
        preRegisterMeDevicesBreastDoctor,
        preRegisterMeDevicesBreastPatient,
        updateMeWarranty,
        isLoading
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
