import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonFilled from "../Button/ButtonFilled";
import Modal from "./Modal";
import { InputComponent } from "../FormComponents/Input";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import LinkSimple from "../Link/LinkSimple";
import ButtonComponent from "../Button/ButtonComponent";
import styles from "./AddressModal.module.scss";

const PhoneNumberModal = ({
  initialValue,
  onClose,
  onSubmit,
  phoneNumbers = [],
}) => {
  const { t } = useTranslation();
  const hasNumbers = phoneNumbers && phoneNumbers.length > 0;
  const [showFormScreen, setShowFormScreen] = useState(!hasNumbers);

  const formik = useFormik({
    initialValues: {
      phoneNumber: initialValue,
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string(),
    }),
    onSubmit: async (values) => {
      await onSubmit(values.phoneNumber);
      onClose();
    },
  });

  const handleClickNumber = async (phoneNumber) => {
    await onSubmit(phoneNumber.toString());
    onClose();
  };

  return (
    <Modal title={t("Global.Phone")} onClose={onClose}>
      <form onSubmit={formik.handleSubmit} className="ignore-modal-style">
        {hasNumbers && !showFormScreen && (
          <Box sx={{ mb: 8 }}>
            <p className="ignore-modal-style">
              {t("PhoneNumberModal.SelectOneOfTheFollowing")}
            </p>

            {phoneNumbers.map((phoneNumber, index) => (
              <ButtonComponent
                key={index}
                title={phoneNumber}
                colorScheme="outlinedPurple"
                type="button"
                onClick={() => handleClickNumber(phoneNumber)}
                className={styles.addressButton}
              />
            )) ?? null}

            <button
              type="button"
              className={styles.addNewAddressButton}
              onClick={() => setShowFormScreen(true)}
            >
              {t("PhoneNumberModal.OrAddNewPhone")}
            </button>
          </Box>
        )}

        {showFormScreen && (
          <Box sx={{ mb: 4 }}>
            <Box mt={2}>
              <InputComponent
                formik={formik}
                title={t("Global.Phone")}
                name="phoneNumber"
              />
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {showFormScreen && (
            <ButtonFilled title={t("Global.Save")} type="submit" />
          )}

          <LinkSimple
            cancel
            onClick={onClose}
            to="#"
            label={t("Global.Cancel")}
          />
        </Box>
      </form>
    </Modal>
  );
};

export default PhoneNumberModal;
