import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { UserContext } from "../../../contexts/UserContext";
import SurgeonSearch from "../../library/Select/SurgeonSearch";
import DatePicker from "../../library/DatePicker/Datepicker";

export const SurgeryInformationForm = ({ setIsValid, disabled = false }) => {
  const { t } = useTranslation();
  const { me, setMe } = useContext(UserContext);
  const [isSurgeonValid, setIsSurgeonValid] = useState(false);

  function handleSurgeonSelected(selectedItem) {
    setMe((prevState) => ({ ...prevState, surgeon: selectedItem }));
  }

  function handleDateSelected(selectedDate) {
    setMe((prevState) => ({ ...prevState, surgeryDate: selectedDate }));
  }

  function handleSurgeonClear() {
    setIsSurgeonValid(false);
    handleSurgeonSelected(null);
  }

  function validateSurgeon(value) {
    setIsSurgeonValid(value?.[0]?.label);
  }

  //validates on change
  useEffect(() => {
    allInputsfilled();
  }, [me]); // eslint-disable-line react-hooks/exhaustive-deps

  function allInputsfilled() {
    if (
      me &&
      me.surgeon &&
      isSurgeonValid &&
      me.surgeryDate &&
      me.surgeryDate != null
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }
  return (
    <>
      <SurgeonSearch
        disabled={disabled}
        required
        handleOnSelectedItem={handleSurgeonSelected}
        title={t("Global.Surgeon")}
        placeholder={t("Surgery-Information.SelectSurgeon")}
        handleOnClear={handleSurgeonClear}
        isValid={(e) => validateSurgeon(e)}
      />

      <DatePicker
        placeholder={t("Surgery-Information.SelectDate")}
        handleOnChange={handleDateSelected}
        value={me?.surgeryDate}
      />
    </>
  );
};
