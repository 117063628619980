import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Box, Typography} from "@material-ui/core";

export function DialogComponent({ open, close, actions, content, title }) {

  return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        className="text-center"
      >
        <DialogTitle id="alert-dialog-title" className="d-flex justify-content-end">
          <IconButton aria-label="close" onClick={close}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center flex-column mb-1">
          {actions}
          <Box my={3}>
            <Typography variant="h6" className="closeDialog" onClick={close}>
              Close
            </Typography>
          </Box>
        </DialogActions>
      </Dialog>
  );
};
