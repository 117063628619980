import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import EstaAPIKit from "../../data/EstaAPIKit";

const initialState = {
  features: [
    { feature: "mia_flow", status: 0 },
    { feature: "esta_branded", status: 0 },
    {feature: 'us_surgeon_dt', status: 0}
  ]
};

const estaAPIKit = new EstaAPIKit();

export const getFeatures = createAsyncThunk('featureFlags/getAll',
  async (arg, thunkAPI) => {
    return await estaAPIKit.getFeatures().then((response) => response.json()).then((data)=>{
      return data.message && data.message.map((feat)=> ({feature: feat.feature, status: feat.status}));
    });
  }
);

export const featureFlagSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlag: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    cleanFeatureFlag: (state) => {
      return {
        ...state,
        ...initialState
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFeatures.fulfilled, (state, action) => {
      state.features = action.payload
    });
  }
})

export const { setFeatureFlag, cleanFeatureFlag } = featureFlagSlice.actions;

export default featureFlagSlice.reducer;
