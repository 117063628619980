import React, { createContext, useContext, useState } from "react";

import {AccountContext} from "./AccountContext"

export const ImplantContext = createContext({});

export const ImplantContextProvider = ({ children }) => {
  const { estaAPIKit } = useContext(AccountContext);
  const [breastData, setBreastData] = useState(null);
  const [glutealData, setGlutealData] = useState(null);

  function validateESerialNumber(params) {
    return estaAPIKit.validateDevice(params);
  }

  function validateSerialNumberAndValidationCode(params) {
    return estaAPIKit.validateDevice(params);
  }
  return (
    <ImplantContext.Provider
      value={{
        breastData,
        setBreastData,
        glutealData,
        setGlutealData,
        validateESerialNumber,
        validateSerialNumberAndValidationCode,
      }}
    >
      {children}
    </ImplantContext.Provider>
  );
};
