import React from "react";
import ButtonFilled from "../../Button/ButtonFilled";
import { Award } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const PurchaseWarrantyComponent = () => {
  const { t } = useTranslation();

  return (
    <div className=" mt-4 pt-2 bg-light">
      <div className="px-1 d-inline">
        <Award />
        <p className="pl-2 pr-0 font-black font-weight-bold d-inline font-size-14">
          {t("Patient-List.PurchaseExtendedWarranty")}
        </p>
      </div>
      <p className="px-4 pt-2 font-book font-size-14 mb-0">
        {t("Patient-List.PurchaseWarrantyDescription")}
      </p>
      <div className=" pb-3 d-flex justify-content-center">
        <ButtonFilled
          prepend={<Award />}
          title={t("Patient-List.PurchaseWarranty")}
          colorScheme="light-purple"
        />
      </div>
    </div>
  );
};

export default PurchaseWarrantyComponent;
