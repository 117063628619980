import React from "react"

export const AvatarPatient = ({color, size}) => (
  <div className="svg-container" >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="#634039" width={size} height={size}>
      <path
        d="M18 10a5 5 0 11-5 5 5 5 0 015-5zm5.73 10.43l-2.23-.55a6 6 0 01-7 0l-2.23.55A3 3 0 0010 23.34v1.16a1.5 1.5 0 001.5 1.5h13a1.5 1.5 0 001.5-1.5v-1.16a3 3 0 00-2.27-2.91z"
        fill="#634039"></path>
    </svg>
  </div>
);
