import { useState, useEffect, useContext } from "react";
import EstaAPIKit from "../data/EstaAPIKit";
import { AccountContext } from "../contexts/AccountContext.js";

export const useCountries = () => {
  const { gigyaCredentialsLoaded } = useContext(AccountContext);
  const estaAPIKit = new EstaAPIKit();
  const [countryList, setCountryList] = useState([]);
  const [countriesLoaded, setCountriesLoaded] = useState(false);

  function fetchCountries() {
    estaAPIKit
      .getCountries()
      .then((res) => res.json())
      .then((data) => {
        setCountryList(
          data.Countries.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            };
          })
        );
        setCountriesLoaded(true);
      });
  }

  const getCountryById = (id) => countryList.find((item) => item.id === id);
  const getCountryByCode = (code) =>
    countryList.find((item) => item.code === code);

  useEffect(() => {
    if (gigyaCredentialsLoaded) fetchCountries();
  }, [gigyaCredentialsLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  return { countriesLoaded, countryList, getCountryById, getCountryByCode };
};
