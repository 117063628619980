import React, { useEffect, useContext } from "react";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import { useTranslation } from "react-i18next";
import { AccountContext } from "../../contexts/AccountContext";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { UserContext } from "../../contexts/UserContext";

const DoctorFirstPage = () => {
  const { t } = useTranslation();
  const { logOut, accountInfo } = useContext(AccountContext);
  const featureFlag = useSelector((state) => state.featureFlagSlice.features);
  const { me } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      logOut();
    }, 1800000); // testing 10000 = 10s prod 1800000 = 30min
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonListUsProfile = [
    {
      title: t("My-Info.SeeMyPatient"),
      nextLink: "/doctor/patient-list",
    },
  ];

  const secondButtonListUSProfile = [
    {
      title: t("My-Info.ReportAnImplant"),
      nextLink: "/registration/full",
    },
    {
      title: t("My-Info.ReportAnExplant"),
      nextLink: "/doctor/explant-information",
    },
    {
      title: t("My-Info.ReportADisposal"),
      nextLink: "/doctor/disposal-information",
    },
  ];

  let isFeatureStatus = () =>
    featureFlag &&
    featureFlag.filter((feat) => feat.feature === "us_surgeon_dt")[0].status;
  let accountCountryUS =
    me?.userCountry === "US" && featureFlag && isFeatureStatus() === 1;

  return (
    <>
      <Helmet>
        <title>Home | MotivaImagine®</title>
        <meta name="pageType" content="Home" />
      </Helmet>

      <FullPageLayout
        backgroundImgPath={accountCountryUS ? "homepage-bg-7" : "homepage-bg-3"}
        position={accountCountryUS ? "rightUS" : "right"}
        title={
          accountCountryUS
            ? t("My-Info.GeneralInformation")
            : t("My-Info.Title")
        }
        secondTitle={accountCountryUS && t("My-Info.UpdateImplantInformation")}
        subtitle={accountCountryUS ? "" : t("My-Info.Description")}
        buttonList={
          accountCountryUS
            ? buttonListUsProfile
            : [
                {
                  title: t("My-Info.PreRegister"),
                  nextLink: "/registration/full",
                },
                {
                  title: t("My-Info.SeeMyPatientInformation"),
                  nextLink: "/doctor/patient-list",
                },
                {
                  title: t("My-Info.SearchImplants"),
                  nextLink: "/doctor/search-implant",
                },
              ]
        }
        secondaryButtonList={accountCountryUS ? secondButtonListUSProfile : []}
      />
    </>
  );
};

export default DoctorFirstPage;
