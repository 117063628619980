import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  ClearButton,
  AsyncTypeahead,
  Menu,
  MenuItem,
} from "react-bootstrap-typeahead";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "./styles.scss";

export default function AsyncSearchTypeahead({
  id,
  iconClass,
  title,
  placeholder,
  handleOnSelectedItem,
  setSelectedItem,
  itemList,
  fetchList,
  handleOnClear,
  defaultSelected,
  initialSelected,
  disabled,
  ...props
}) {
  const typeaheadRef = useRef();
  const isLoading = false;
  const [selected, setSelected] = useState([]);

  function handleOnChange(e) {
    setSelected(e);
    setSelectedItem(e);
    handleOnSelectedItem(e);
  }

  function handleThisClear(e) {
    handleOnClear();
  }

  const onSearch = useCallback((query) => {
    fetchList(query);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  useEffect(() => {
    if(initialSelected){
      setSelected(initialSelected);
    }
  }, []);

  return (
    <div>
      <p className="font-black mt-1">{title}</p>
      <div className="select-input-wrapper input-wrapper">
        <i className={`${iconClass} input-icon`}></i>

        <AsyncTypeahead
          disabled={disabled}
          ref={typeaheadRef}
          id={id}
          isLoading={isLoading}
          options={itemList}
          placeholder={placeholder}
          onChange={handleOnChange}
          onSearch={onSearch}
          emptyLabel={""}
          selected={selected}
          renderMenu={(results, menuProps) => {
            // Hide the menu when there are no results.
            if (!results.length) {
              return null;
            }
            return (
              <Menu {...menuProps}>
                {results.map((result, index) => (
                  <MenuItem key={index} option={result} position={index}>
                    {result.label}
                  </MenuItem>
                ))}
              </Menu>
            );
          }}
          {...props}
        >
          {({ onClear, selected }) => (
            <div className="rbt-aux">
              {!!selected.length && !disabled && (
                <ClearButton
                  onClick={(e) => {
                    onClear();
                    handleThisClear(e);
                  }}
                  onFocus={(e) => {
                    // Prevent the main input from auto-focusing again.
                    e.stopPropagation();
                  }}
                  onMouseDown={(e) => {
                    // Prevent input from blurring when button is clicked.
                    e.preventDefault();
                  }}
                />
              )}

              {/* {!selected.length && <FontAwesomeIcon icon={faCaretDown} />} */}
            </div>
          )}
        </AsyncTypeahead>
      </div>
    </div>
  );
}
