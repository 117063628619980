import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LinkBack from "../../components/library/Link/LinkBack";
import { UserContext } from "../../contexts/UserContext";
import { StaticFormContext } from "../../contexts/StaticFormContext";
import { useTranslation } from "react-i18next";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import "./ImplantPlacementPage.scss";
import { Navigate } from "react-router-dom";
import Registration from "../../components/library/Registration.jsx";
import {Helmet} from 'react-helmet-async';
import {AccountContext} from "../../contexts/AccountContext";
import {useTagManager} from "../../hooks/useTagManager";
import {isUndefined} from "lodash";

export default function ImplantPlacement() {
  const { t } = useTranslation();
  const { setMe, me } = useContext(UserContext);
  const { breastPlacementList } = useContext(StaticFormContext);
  const [nextLink, setNextLink] = useState(null);
  const navigate = useNavigate();

  const {accountInfo} = useContext(AccountContext);
  const gtm = useTagManager();

  useEffect(() => {
    if (me?.country_id === undefined) {
      navigate("/registration/implant-type");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOnChange(selectedItem) {
    setMe((prevState) => ({ ...prevState, implantPlacement: selectedItem }));

    let value;
    switch (selectedItem){
      case 1 :
        value = "Sub_glandular";
        break;
      case 2 :
        value = "Dual_plane";
        break;
      case 3 :
        value = "Sub_facial";
        break;
      case 4 :
        value = "Sub_muscular";
        break;
      default:
        value = "I_dont_know";
    }

    gtm({
      category: 'Implant_placement',
      action: value,
      event: 'interaction',
      segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
    });
  }

  function renderPlacementItem(breastImplant, title, description) {
    return (
      <label
        key={breastImplant.id}
        onClick={() => {
          handleOnChange(breastImplant.id);
          setNextLink("/registration/surgery-indication");
        }}
        className="implant-register-with__description-container__card label-card implant-card"
      >
        <div className="vertical-list-item__img-wrapper">
          {breastImplant.image && (
            <img src={breastImplant.image} alt={breastImplant.title} />
          )}
        </div>

        <div>
          <h5 className="vertical-list-item__title">{title}</h5>

          <p className="vertical-list-item__text text-muted">{description}</p>
        </div>

        <input type="radio" name="radio2" />
        <span className="radio-btn-card"></span>
      </label>
    );
  }

  if (nextLink) return <Navigate push to={nextLink} />;

  return (
    <>
      <Helmet>
        <title>Implant Placement | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>
      <Registration step={7}>
        <div className="pre-next-btns pre-next-btns-top">
          <LinkBack linkBack="/registration/incision-type-selection" />
        </div>
        <FiftyFiftyLayout
          backgroundImgPath="fifty-fifty-bg-1"
          title={t("Implant-Placement.Title")}
          subtitle={t("Implant-Placement.PageText")}
        >
          {breastPlacementList &&
            //Remove first item (undefined)
            breastPlacementList
              .filter((item) => item.id !== 0)
              .map((breastImplant) => {
                return renderPlacementItem(
                  breastImplant,
                  t(`API.ImplantPlacement-${breastImplant.id}`),
                  t(`API.ImplantPlacement-${breastImplant.id}-Description`)
                );
              })}
          {renderPlacementItem(
            { id: 0, image: null },
            t("Global.IDontKnow"),
            t(`API.ImplantPlacement-0-Description`)
          )}
        </FiftyFiftyLayout>
      </Registration>
    </>
  );
}
