import React from "react";
import { useTranslation } from "react-i18next";
import "./PreImplantRegistrationPage.scss";
import "../../components/library/BackgroundImage/BackgroundImage.scss";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import LinkSimple from "../../components/library/Link/LinkSimple";

export default function PreImplantRegistrationPage() {
  const { t } = useTranslation();

  return (
    <FullPageLayout
      backgroundImgPath="homepage-bg-1"
      position="center"
      title={t("Preimplant-Registration-Page-Three.Title")}
      subtitle={t("Preimplant-Registration-Page-Three.Description")}
      buttonList={[
        {
          title: t(
            "Preimplant-Registration-Page-Three.YesIHaveGotTheRegistration"
          ),
          nextLink: "/registration/implant-type",
        },
        // {
        //   title: t("Global.SkipForNow"),
        //   nextLink: "/my-implants/thank-you-for-visiting",
        // },
      ]}
      skipBtn={
        <div className="pre-next-btns">
          <div className="pre-next-btns__single-btn">
            <LinkSimple
              label={t("Global.SkipForNow")}
              to="/my-implants/thank-you-for-visiting"
            />
          </div>
        </div>
      }
    ></FullPageLayout>
  );
}
