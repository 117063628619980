import React from 'react';
import {Box} from "@mui/material";
import ButtonLogin from "../Button/ButtonLogin";
import GigyaKit from "../../../data/GigyaKit";
import {useTranslation} from "react-i18next";
import "./style.module.scss";
import {useTagManager} from "../../../hooks/useTagManager";


export const LoginComponent =({profile})=>{
  const gigya = new GigyaKit()
  const { t } = useTranslation();
  const gtm = useTagManager();

  const handleShowPatientRegisterScreenSet =()=> {
    const redirectAfterLoginURL = process.env.REACT_APP_DEPLOYED_FOLDER;
    gigya.showRegisterScreenSet(redirectAfterLoginURL);

    gtm({
      category: 'sign-up',
      action: 'open',
      segment: 'patient',
      event: 'interaction'
    });
  }

  const handleShowDoctorRegistrationScreenSet = () => {
    const redirectAfterLoginURL = process.env.REACT_APP_DEPLOYED_FOLDER;
    gigya.showDoctorRegisterScreenSet(redirectAfterLoginURL);

    gtm({
      category: 'sign-up',
      action: 'open',
      segment: 'surgeon',
      event: 'interaction'
    });
  }

  return (
    <>
      <p className="sign-up-btn mt-5" id="sign-up-btn"
         onClick={() => profile === "patient" ? handleShowPatientRegisterScreenSet() : handleShowDoctorRegistrationScreenSet()}>
        {t(profile === "patient" ? "Global.SignUpAsPatient" : "Global.SignUpAsDoctor")}
      </p>

      <Box className="login-signin-btn-container">
        <ButtonLogin id="sign-in-btn" />
      </Box>
    </>
  );
}
