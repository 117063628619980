import { Card } from "react-bootstrap";
import React from "react";
import "./CardInfo.scss";

export default function CardInfo({
  title,
  description,
  children
}) {
  return (
    <Card className="cardInfo">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        {children}
      </Card.Body>
    </Card>
  );
}
