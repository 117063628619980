import "./Form.scss";
import "./FormESN.scss";

import React, {useState, useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import MaskedInput from "react-text-mask";
import {useDispatch, useSelector} from "react-redux";
import Loader from "react-loader-spinner";


import {StaticFormContext} from "../../../contexts/StaticFormContext";
import EstaAPIKit from "../../../data/EstaAPIKit";
import {SuccessValidationCheckmark} from "../Icons/SuccessValidationCheckmark";

import {preRegistrationInformation} from "../../../store/slices/preRegisterSlice";
import {getMask} from '../../../utils';
import {Box} from "@mui/material";
import ScannerCode from "../ScannerCode";
import {isEmpty} from "lodash";


const FormHeading = ({title, placement}) => (
  <div
    className={`${placement === "right" && "pt-3" && ""}  font-black d-inline`}
  >
    <span className="pr-3">{title}</span>
  </div>
);

export default function FormDevice({
   typeDevice,
   validLength, setIsDeviceValid,
   handleValidation,
   setHandleValidation,
   required
 }) {

  const preRegistration = useSelector((state) => state.preRegisterSlice);
  const {currentWizard} = useContext(StaticFormContext);

  const {t} = useTranslation();
  const estaAPIKit = new EstaAPIKit();
  const dispatch = useDispatch();

  const [isValidate, setIsValidate] = useState(false);
  const [validationRan, setValidationRan] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  function showInvalid() {
    return (
      validationRan &&
      !isValidate
    );
  }

  function handleOnFocus(event) {
    setValidationRan(false);
  }

  async function validate() {
    setIsLoading(true);

    let payload = {
      implants: {
        type: currentWizard === "breast" ? "B" : "G",
        left: {
          serialNumber: null,
          validationCode: null,
          electronicSerial: null
        },
        right: {
          serialNumber: null,
          validationCode: null,
          electronicSerial: null
        }
      },
      tools: {
        injector: typeDevice === 'injector' ? preRegistration?.implantsInformation?.tools?.injector : null,
        balloon: typeDevice === 'balloon' ? preRegistration?.implantsInformation?.tools?.balloon : null
      }
    }

    const result = await estaAPIKit.validateDevice(payload);
    if (result?.status === "true") {
      setIsValidate(true);
      setIsDeviceValid(true);
      setValidationRan(true);
    } else {
      setError(result?.description);
      setIsDeviceValid(false);
      setIsValidate(false);
    }
    setIsLoading(false);
  }

  function handleOnBlur() {
    setError("");
    let serialNumber = typeDevice === "injector" ? preRegistration?.implantsInformation?.tools?.injector : preRegistration?.implantsInformation?.tools?.balloon;
    if (serialNumber && serialNumber.trim().length === validLength) {
      validate();
    }
  }

  useEffect(() => {
    if (handleValidation) {
      handleOnBlur();
      setHandleValidation(false);
    }
  }, [handleValidation]);

  useEffect(()=>{
    setIsDeviceValid(false);
    setIsValidate(false);
  },[preRegistration?.implantsInformation?.tools?.injector,  preRegistration?.implantsInformation?.tools?.balloon]);

  const getSerial = (value, type) => {
    const payload ={
      textUDI: value,
      type: type
    }
    estaAPIKit.extractSerialNumberByScan(payload)
      .then((res) => res.json())
      .then(result =>{
        if(result.response.validStructure){
          dispatch(preRegistrationInformation({
            implantsInformation: {
              ...preRegistration?.implantsInformation,
              tools: {
                injector: type === 'injector' ? result.response.serialNumber : preRegistration?.implantsInformation?.tools?.injector,
                balloon: type === 'balloon' ? result.response.serialNumber : preRegistration?.implantsInformation?.tools?.balloon
              }
            }
          }))
        }

      }).catch(error =>{
      console.error(error);
    });
  }

  return (
    <>
      <form className="esn-form">
        <div className="esn-form__input-wrapper">
          <div style={{width: '90%'}}>
            <p className="label-for-ESerialNumber text-muted">
              {t("Implant-Registration.SerialNumber")}
              {required && "*"}
            </p>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Box mr={1} >
                <ScannerCode extract={getSerial} type={typeDevice} title={t('Implant-Registration.ScanLabel')} />
              </Box>

              <MaskedInput
                mask={typeDevice === 'injector' ? getMask('injector') : getMask('SN')}
                placeholderChar={"\u2000"}
                type="text"
                className={`form-control serial-input ${
                  showInvalid() ? "is-invalid" : ""
                }`}
                id={`serial-number-input-${typeDevice}`}
                name="serial-number-input"
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                render={(ref, props) => {
                  return (
                    <input placeholder={typeDevice === 'injector' ? '00000X-000' : '00000000-00'} ref={ref} {...props} />
                  );
                }}
                onChange={({target}) => {
                  setError("");
                  const value = target.value;
                  dispatch(preRegistrationInformation({
                    implantsInformation: {
                      ...preRegistration?.implantsInformation,
                      tools: {
                        injector: typeDevice === "injector" ? value : preRegistration?.implantsInformation?.tools?.injector,
                        balloon: typeDevice === "balloon" ? value : preRegistration?.implantsInformation?.tools?.balloon,
                      }
                    }
                  }))
                }}
                value={
                  typeDevice === "injector" ? preRegistration?.implantsInformation?.tools?.injector : preRegistration?.implantsInformation?.tools?.balloon
                }
              />
            </Box>




            <div className="container-error">
              {error && (
                <div className="validation-message">
                  <p>{error}</p>
                </div>
              )}
            </div>


          </div>
          <div
            className="successfull-validation-checkmark-2"
            style={
              error
                ? {
                  display: "none",
                }
                : null
            }
          >
            {isValidate && <SuccessValidationCheckmark/>}
            {isLoading && (
              <Loader
                className={"loading"}
                type="TailSpin"
                color="#805474"
                height={20}
                width={20}
                visible={isLoading}
              />
            )}
          </div>
        </div>
      </form>
    </>);
}
