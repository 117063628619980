import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import md5 from "md5";
import MaskedInput from "react-text-mask";
import EstaAPIKit from "../../../data/EstaAPIKit";
import ButtonFilled from "../Button/ButtonFilled";
import "./WarrantyPaymentForm.scss";
import LinkSimple from "../../../components/library/Link/LinkSimple";
import Loader from "react-loader-spinner";

const CREDOMATIC_SECRET_KEY = process.env.REACT_APP_CREDOMATIC_SECRET_KEY;
const REDIRECT_URL = process.env.REACT_APP_CREDOMATIC_REDIRECT_URL;

export default function WarrantyPaymentForm({
  showTermsAndConditions,
  setShowTermsAndConditions,
}) {
  const [warrantyDetailData, setWarrantyDetailData] = useState(null);

  const creditCardForm = useRef();
  const [nameData, setNameData] = useState("");
  const [CCVData, setCCVData] = useState("");
  const [cardNumberData, setCardNumberData] = useState("");
  const [monthData, setMonthData] = useState("");
  const [yearData, setYearData] = useState("");

  const [userCardNumberData, setUserCardNumberData] = useState("");
  const [userCurrencyData, setUserCurrencyData] = useState("");
  const [userAmount, setUserAmount] = useState("");
  // const [userCurrencySymbol, setUserCurrencySymbol] = useState("");

  const [billAmountData, setBillAmountData] = useState("");
  const [timeData, setTimeData] = useState("");
  const [orderIdData, setOrderIdData] = useState("");
  const [hashData, setHashData] = useState("");
  const [ccExpData, setCCExpData] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [disabledState, setDisabledState] = useState(true);
  const [userId, setUserId] = useState(null);

  const { t } = useTranslation();
  const estaApiKit = new EstaAPIKit();

  // const currentYear = parseInt(String(new Date().getFullYear()).substr(2,3))
  const currentYear = new Date().getFullYear();
  const yearList = Array.apply(0, Array(8)).map(
    (_element, index) => index + currentYear
  );
  const monthList = [
    { name: t("Global.January"), value: "01" },
    { name: t("Global.February"), value: "02" },
    { name: t("Global.March"), value: "03" },
    { name: t("Global.April"), value: "04" },
    { name: t("Global.May"), value: "05" },
    { name: t("Global.June"), value: "06" },
    { name: t("Global.July"), value: "07" },
    { name: t("Global.August"), value: "08" },
    { name: t("Global.September"), value: "09" },
    { name: t("Global.October"), value: "10" },
    { name: t("Global.November"), value: "11" },
    { name: t("Global.December"), value: "12" },
  ];

  //Loader
  const [isLoading, setIsLoading] = useState(false);
  // const [confirmationScreen, setConfirmationScreen] = useState(false)
  // const loaderOff = () => { setIsLoading(false) }
  const loaderOn = () => {
    setIsLoading(true);
  };

  const MASK = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
  ];

  useEffect(() => {
    estaApiKit
      .getWarrantyDetails()
      .then((res) => res.json())
      .then((data) => {
        setWarrantyDetailData(data);
        setUserCurrencyData(data.currency);
        setUserAmount(data.price);
        setBillAmountData(data.price);
      });

    estaApiKit
      .getMe()
      .then((res) => res.json())
      .then((data) => {
        setUserId(data.id);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOnSubmit(e) {
    loaderOn();
    e.preventDefault();

    const amount = billAmountData;
    const time = Math.floor(Date.now() / 1000);
    setTimeData(time);

    const order = 3000;
    setOrderIdData(order);

    const stringToHash =
      order + "|" + amount + "|" + time + "|" + CREDOMATIC_SECRET_KEY;
    const hash = md5(stringToHash);
    setHashData(hash);
  }

  useEffect(() => {
    // This will be triggerad when we set hash in the handleOnSubmit function
    // This make sures that the state is updated and all the values that we are supposed to send
    // are rendered to the DOM.
    if (hashData && creditCardForm) {
      creditCardForm.current.submit();
    }
  }, [hashData]);

  useEffect(() => {
    if (userCurrencyData === "USD") {
      // setUserCurrencySymbol("$")
      // setBillAmountData("200.00")
    }
    if (userCurrencyData === "EUR") {
      // setUserCurrencySymbol("$")
      // setBillAmountData("220.00")
    }
    if (userCurrencyData === "GBP") {
      // setUserCurrencySymbol("£")
      // setBillAmountData("250.00")
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCurrencyData]);

  useEffect(() => {
    setCardNumberData(userCardNumberData.replace(/\s/g, ""));
  }, [userCardNumberData]);

  useEffect(() => {
    const expirationDate = `${monthData}${yearData}`;
    setCCExpData(expirationDate);
  }, [monthData, yearData]);

  useEffect(() => {
    validateAllInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedTerms, nameData, CCVData, cardNumberData, monthData, yearData]);

  function validateAllInputs() {
    if (
      acceptedTerms &&
      nameData.length > 0 &&
      CCVData.length > 0 &&
      cardNumberData.length > 0 &&
      monthData.length > 0 &&
      yearData.length > 0
    ) {
      setDisabledState(false);
    } else {
      setDisabledState(true);
    }
  }

  return (
    <>
      {warrantyDetailData && (
        <form
          ref={creditCardForm}
          onSubmit={handleOnSubmit}
          method="POST"
          acceptCharset="UTF-8"
          action={process.env.REACT_APP_CREDOMATIC_PAYMENT_URL}
          className="warranty-payment-form"
        >
          <div className="warranty-payment-form__input-container">
            <div className="warranty-payment-form__name-and-card-number-container">
              <input
                type="text"
                placeholder={t("Warranty.NameOnCard")}
                required
                value={nameData}
                onChange={(e) => setNameData(e.target.value)}
              />

              <MaskedInput
                mask={MASK}
                // guide={false}
                placeholderChar={"\u2000"}
                type="text"
                placeholder={t("Warranty.CardNumber")}
                // value={cardNumberData}
                required
                onChange={(e) => setUserCardNumberData(e.target.value)}
              />
            </div>

            <div className="warranty-payment-form__date-and-cvv-container">
              <div className="warranty-payment-form__date">
                <select required onChange={(e) => setMonthData(e.target.value)}>
                  <option value="0">{t("Warranty.Month")}</option>
                  {monthList.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <select required onChange={(e) => setYearData(e.target.value)}>
                  <option value="0">{t("Warranty.Year")}</option>
                  {yearList.map((item) => {
                    return (
                      <option key={item} value={String(item).substr(2, 3)}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
              <input
                type="text"
                placeholder={t("Warranty.CVV")}
                required
                value={CCVData}
                maxLength={4}
                onChange={(e) => setCCVData(e.target.value)}
              />
            </div>
          </div>

          <p className="warranty-payment-form__total-price">
            {t("Warranty.Your-Total-is")}:
            <span>
              {userAmount} {userCurrencyData}
            </span>
          </p>

          {/* <p>
            Price: {userAmount} {userCurrencyData}
          </p>
          <p>Billed as {billAmountData} $</p> */}

          <div className="warranty-payment-form__terms-of-use">
            <div className="row">
              <div className="col-1 p-0 d-flex align-items-center">
                <input className="checkCustom" type="checkbox" onClick={() => {
                  setAcceptedTerms(!acceptedTerms);
                }} />
              </div>
              <div className="col-7 p-0">
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  {t("Warranty.IAcceptTermsAndConditions")}
                </label>
              </div>
            </div>
          </div>
          <p
            className="showTermsAndConditions-btn"
            onClick={() => {
              setShowTermsAndConditions(!showTermsAndConditions);
            }}
          >
            {t("Extend-Warranty-Option.SeeFullTermsAndConditions")}
          </p>

          {/* HIDDEN FIELDS */}
          <input type="hidden" name="type" value="sale" />
          <input type="hidden" name="key_id" value="5920188" />
          <input type="hidden" name="hash" value={hashData} />
          <input type="hidden" name="time" value={timeData} />

          <input type="hidden" name="ccnumber" value={cardNumberData} />
          <input type="hidden" name="ccexp" value={ccExpData} />

          <input type="hidden" name="amount" value={billAmountData} />

          <input type="hidden" name="billingCurrency" value="USD" />
          <input type="hidden" name="orderid" value={orderIdData} />
          <input type="hidden" name="processorid" value="" />

          {/* User ID value */}
          <input type="hidden" name="merchant_defined_field_1" value={userId} />

          <input
            type="hidden"
            name="redirect"
            value={REDIRECT_URL}
            id="redirect"
          />
          <div className="button-alignment">
            {!isLoading && (
              <div>
                <ButtonFilled
                  title={t("Extend-Warranty-Option.PurchaseExtendedWarranty")}
                  // nextLink="/warranty/payment/confirmation"
                  type="submit"
                  disabled={disabledState}
                />
                <div className="pre-next-btns  mt-4">
                  <span />
                  <LinkSimple
                    label={t("Global.Cancel")}
                    to="/my-implants"
                    cancel
                  />
                </div>
              </div>
            )}
            <Loader
              type="TailSpin"
              color="#805474"
              height={30}
              width={30}
              visible={isLoading}
            />
          </div>
        </form>
      )}
    </>
  );
}
