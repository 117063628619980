import React from "react";
import "./MaintenancePage.scss";
import {Link,} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Helmet} from 'react-helmet-async';

export default function MaintenancePage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>MotivaImagine® | Maintenance Page</title>
      </Helmet>

      <div className="background__page">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-7"></div>
            <div className="col-md-12 col-lg-5 vh-100 d-flex align-items-center justify-content-center">
              <div>
                <h1 className="error-page__title color-coffee">
                  {t("MaintenancePage.Title")}
                </h1>
                <h1 className="error-page__subtitle color-coffee my-4">
                  {t(
                    "MaintenancePage.SubTitle"
                  )}
                </h1>
                <h1 className="error-page__subtitle color-coffee my-4">
                  {t(
                    "MaintenancePage.Description"
                  )}
                </h1>

                <div className="w-100 d-flex justify-content-center">
                  <Link
                    to={'/'}
                    className="go-back-to-browsing-btn"
                  >
                    {t("MaintenancePage.Action")}
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
