import "./Warranty.scss";

import { CameraFill } from "react-bootstrap-icons";
import {Link, useNavigate, useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Canvas2Image from "wd-canvas2image";
import React, { useContext, useEffect, useState } from "react";
import html2canvas from "html2canvas";

import { StaticFormContext } from "../../contexts/StaticFormContext";
import ButtonFilled from "../../components/library/Button/ButtonFilled";
import EnsureToken from "../../components/library/EnsureToken";
import EstaAPIKit from "../../data/EstaAPIKit";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import WarrantyDetail from "../../components/library/Warranty/WarrantyDetail";
import {Helmet} from 'react-helmet-async';

export default function WarrantyConfirmationPage() {
  const { t } = useTranslation();
  const { transactionId } = useContext(StaticFormContext);
  const [warrantyDetail, setWarrantyDetail] = useState(null);
  const estaAPIKit = new EstaAPIKit();
  const showScreenShotButton = false;

  const location = useLocation();
  const navigate = useNavigate();

  // Unfortunately the endpoint doesn't hold to naming conventions, so we need to reformat this object
  const fixWarrantyDetail = (data) => ({
    ...data,
    implants: data.implants.map((i) => ({
      end: data.endDateWarranty,
      ...i,
    })),
  });

  useEffect(() => {
    estaAPIKit
      .getBoughtWarrantyDetails(transactionId)
      .then((res) => res.json())
      .then((data) => setWarrantyDetail(fixWarrantyDetail(data))); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function takeScreenshot() {
    html2canvas(document.querySelector(".warrantyDetail")).then((canvas) => {
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      Canvas2Image.saveAsImage(
        canvas,
        canvasWidth,
        canvasHeight,
        "png",
        "motiva"
      );
    });
  }

  function renderDescription() {
    return (
      <>
        {t("WarrantyInfo.WarrantyExtendedNotice")}
        <div className="full-page-layout__section-container">
          {warrantyDetail && warrantyDetail["implants"] instanceof Array && (
            <WarrantyDetail
              price={warrantyDetail.price}
              currency={warrantyDetail.currency}
              transactionId={transactionId}
              implants={warrantyDetail["implants"]}
            />
          )}
        </div>
        {showScreenShotButton && (
          <div className="full-page-layout__btn-container">
            <ButtonFilled
              inline
              prepend={<CameraFill />}
              title={t("Global.TakeScreenshot")}
              onClick={takeScreenshot}
            />
          </div>
        )}
        <div className="full-page-layout__section-container full-page-layout--position--center">
          <Link to="/my-implants" className="my-link white-version">
            {t("Global.GoToHome")}
          </Link>
        </div>
      </>
    );
  }

  useEffect(() => {
    if(location.pathname === '/warranty/payment/confirmation'){
      navigate("/warranty/payment/confirmation", { replace: true });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Thank You | MotivaImagine®'</title>
        <meta name="pageType" content="Extended Warranty" />
      </Helmet>
      <EnsureToken>
        <FullPageLayout
          backgroundImgPath="homepage-bg-5"
          position="right"
          title={t("ExtendedWarrantyConfirmation.Title")}
          subtitle={renderDescription()}
          buttonList={[]}
        />
      </EnsureToken>
    </>
  );
}
