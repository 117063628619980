import React, { useState, useRef } from "react";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
// import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

// import "react-bootstrap-typeahead/css/Typeahead.css";
// import "./styles.scss";
// import 'bootstrap/dist/css/bootstrap.min.css';
//
const SelectComponent = ({
  title,
  placeholder,
  options,
  fixedValue,
  required,
  handleOnChange,
  createTicket,
  value,
  handleOnClear,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [selected, setSelected] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isValid, setIsValid] = useState(false);

  function handleThisClear(e) {
    handleOnClear(e);
    validate();
  }

  function onChange(val) {
    if (val) {
      setSelected(val);
      handleOnChange(val);
    }
  }

  function validate() {
    setValidated(true);

    let valid = true;

    if (fixedValue && selected.length === 0) {
      ref.current.clear();
    }

    if (required && selected.length === 0) {
      valid = false;
    }

    setIsValid(valid);
  }

  function showInvalid() {
    return validated && !isValid;
  }

  return (
    <>
      {!createTicket && (
        <div>
          <label className="font-black mt-1">
            {title ? title : t("Surgery-Information.Country")}
          </label>
          <div className="select-input-wrapper input-wrapper">
            <Typeahead
              ref={ref}
              id="typeahead"
              paginationText={t("Global.MoreResults")}
              inputProps={{ autoComplete: "new-password" }}
              className={showInvalid() && "is-invalid"}
              isInvalid={showInvalid()}
              options={options}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={validate}
              onFocus={() => setValidated(false)}
            >
              {({ onClear, selected }) => (
                <div className="rbt-aux">
                  {!!selected?.length && (
                    <ClearButton
                      onClick={(e) => {
                        onClear();
                        handleThisClear(e);
                      }}
                      onFocus={(e) => {
                        // Prevent the main input from auto-focusing again.
                        e.stopPropagation();
                      }}
                      onMouseDown={(e) => {
                        // Prevent input from blurring when button is clicked.
                        e.preventDefault();
                      }}
                    />
                  )}
                </div>
              )}
            </Typeahead>
          </div>
        </div>
      )}

      {createTicket && (
        <div className="my-custom-datepicker select-input-wrapper">
          <Typeahead
            id="typeahead"
            inputProps={{ autoComplete: "new-password" }}
            options={options}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={validate}
            onFocus={() => setValidated(false)}
            ref={ref}
          >
            {({ onClear, selected }) => (
              <div className="rbt-aux">
                {!!selected?.length && <ClearButton onClick={onClear} />}
                {/* {!selected.length && <FontAwesomeIcon icon={faCaretDown} />} */}
              </div>
            )}
          </Typeahead>
        </div>
      )}
    </>
  );
};

export default SelectComponent;
