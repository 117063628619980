import "./WarrantyDetail.scss";

import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React from "react";

import CardInfo from "../CardInfo/CardInfo";
import ImplantWarrantyDetail from "./ImplantWarrantyDetail";

export default function WarrantyDetail({
  implants,
  transactionId,
  disclaimer,
  hideValidUntil,
  price,
  currency,
}) {
  const { t } = useTranslation();
  return (
    <div className="warrantyDetail">
      {transactionId && (
        <div className="transactionInfo">
          {t("WarrantyInfo.TransactionID")}:{" "}
          <span className="font-black">{transactionId}</span>
        </div>
      )}
      {implants instanceof Array && (
        <CardInfo title={t("WarrantyInfo.PurchaseDetails")}>
          <ListGroup>
            {implants.map((implant, idx) => (
              <ListGroupItem key={idx}>
                <ImplantWarrantyDetail
                  key={idx}
                  hideValidUntil={hideValidUntil}
                  {...implant}
                />
              </ListGroupItem>
            ))}
          </ListGroup>
          <Card.Footer>
            <p>
              {t("WarrantyInfo.PurchaseTotalAmount")}: &nbsp;
              <span className="font-black">
                {price}&nbsp;{currency}
              </span>
            </p>
          </Card.Footer>
        </CardInfo>
      )}
      {disclaimer && <div class="disclaimer">{disclaimer}</div>}
    </div>
  );
}
