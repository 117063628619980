import * as Yup from "yup";

export const explantFormSchema = (t) => {
  const requiredText = t("Required.Field", { lng: "en" });

  return Yup.object().shape({
    explantDate: Yup.date().required(requiredText),
    originalImplantingPhysician: Yup.string().required(requiredText),
    originalImplantingDate: Yup.date().required(requiredText),
    reasonForRemoval: Yup.string().required(requiredText),
    patientInformation: Yup.object().shape({
      email: Yup.string()
        .required(requiredText)
        .email(t("Global.InvalidEmail", { lng: "en" })),
      firstName: Yup.string().required(requiredText),
      lastName: Yup.string().required(requiredText),
    }),
  });
};
