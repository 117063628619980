import React from "react";

const ErrorComponent = ({ userError }) => {
  // console.log(userError);
  return (
    <div
      style={{
        padding: "20px",
        margin: "10px",
        backgroundColor: "rgba(255,255,255,0.4)",
        color: "red",
        weight: "bold",
        fontSize: "1em",
        height: "4em",
        verticalAlign: "middle",
        borderRadius: "10px",
      }}
    >
      <p>An unexpected error occurred</p>
    </div>
  );
};

export default ErrorComponent;
