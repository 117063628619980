import React, { useContext, useEffect, useState } from "react";
import ButtonScan from "../../library/Button/ButtonScan";
import Form from "../../library/Form/Form";
import FileUploadModule from "../../library/PopUpModules/FileUploadModule";
import ButtonOutlined from "../../library/Button/ButtonOutlined";
import { StaticFormContext } from "../../../contexts/StaticFormContext";
import { useTranslation } from "react-i18next";
import { Shuffle } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { preRegistrationInformation } from "../../../store/slices/preRegisterSlice";
import { AccountContext } from "../../../contexts/AccountContext";
import { useTagManager } from "../../../hooks/useTagManager";
import { isMobile } from "react-device-detect";
import { isUndefined } from "lodash";

const FormHeading = ({ title, placement }) => (
  <div
    className={`${placement === "right" && "pt-3" && ""}  font-black d-inline`}
  >
    <span className="pr-3">{title}</span>
  </div>
);

const ImplantForm = ({
  placement,
  setIsImplantValid,
  setContainsValue,
  leftError,
  rightError,
  setRightError,
  setLeftError,
  handleValidation,
  setHandleValidation,
  isLeftRender,
}) => {
  return (
    <div className="form-wrapper-wrapper">
      <Form
        setIsImplantValid={setIsImplantValid}
        placement={placement}
        setContainsValue={setContainsValue}
        leftError={leftError}
        rightError={rightError}
        setRightError={setRightError}
        setLeftError={setLeftError}
        handleValidation={handleValidation}
        setHandleValidation={setHandleValidation}
        isLeftRender={isLeftRender}
        required
      />
    </div>
  );
};

export const ImplantsFormSN = ({ setIsValid, showValid }) => {
  const {
    setDetectedRightSerialNumber,
    setDetectedLeftSerialNumber,
    serialNumberLeft,
    setSerialNumberLeft,
    serialNumberRight,
    setSerialNumberRight,
    leftVC,
    setLeftVC,
    rightVC,
    setRightVC,
    setIsScanned,
    shouldRenderLeftForm,
    shouldRenderRightForm,
  } = useContext(StaticFormContext);

  const [isInconsistent, setIsInconsistent] = useState(false);

  const [showFileModule, setShowFileModule] = useState(false);
  const [isLeftImplantValid, setIsLeftImplantValid] = useState(false);
  const [isRightImplantValid, setIsRightImplantValid] = useState(false);
  const [duplicatedSN, setDuplicateSN] = useState(false);
  const [leftError, setLeftError] = useState("");
  const [rightError, setRightError] = useState("");
  const [handleValidation, setHandleValidation] = useState(false);
  const [containsValue, setContainsValue] = useState({});
  const { t } = useTranslation();
  const preRegistration = useSelector((state) => state.preRegisterSlice);

  const { accountInfo } = useContext(AccountContext);
  const gtm = useTagManager();

  const dispatch = useDispatch();

  function isFormValid() {
    return (
      checkIfAllInputsContainValue() &&
      checkIfInputsAreNotDuplicated() &&
      (!shouldRenderLeftForm() || isLeftImplantValid) &&
      (!shouldRenderRightForm() || isRightImplantValid)
    );
  }

  const checkIfInputsAreNotDuplicated = () => {
    if (serialNumberLeft === "" || serialNumberRight === "") {
      return true;
    } else if (serialNumberLeft === serialNumberRight) {
      return false;
    } else {
      return true;
    }
  };

  const checkIfAllInputsContainValue = () => {
    const isSerialNumberLeftValid = () =>
      serialNumberLeft?.trim().length >= 10 && leftVC?.length === 1;

    const isSerialNumberRightValid = () =>
      serialNumberRight?.trim().length >= 10 && rightVC?.length === 1;

    return (
      (!shouldRenderLeftForm() || isSerialNumberLeftValid()) &&
      (!shouldRenderRightForm() || isSerialNumberRightValid())
    );
  };

  function switchSerialNumbers() {
    if ((serialNumberRight && rightVC) || (serialNumberLeft && leftVC)) {
      dispatch(
        preRegistrationInformation({
          implantsValid: {
            implantL: preRegistration?.implantsValid?.implantR,
            implantR: preRegistration?.implantsValid?.implantL,
          },
        }),
      );
      setSerialNumberLeft(serialNumberRight);
      setSerialNumberRight(serialNumberLeft);
      setLeftVC(rightVC);
      setRightVC(leftVC);
      setRightError(leftError);
      setLeftError(rightError);
      setHandleValidation(true);
    }
  }

  const resetLeftPlacement = () => {
    setDetectedLeftSerialNumber("");
    setSerialNumberLeft("");
    setLeftVC("");
  };

  const resetRightPlacement = () => {
    setDetectedRightSerialNumber("");
    setSerialNumberRight("");
    setRightVC("");
  };

  // check if there's a better way to do this
  useEffect(
    () => {
      false && console.log(containsValue);
      setIsScanned(0);
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => setIsScanned(1),
    [showFileModule], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setIsValid(isFormValid());
    setDuplicateSN(
      checkIfAllInputsContainValue() && !checkIfInputsAreNotDuplicated(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    serialNumberLeft,
    serialNumberRight,
    leftVC,
    rightVC,
    isRightImplantValid,
    isLeftImplantValid,
  ]);

  useEffect(() => {
    let implantL = preRegistration?.implantsValid?.implantL;
    let implantR = preRegistration?.implantsValid?.implantR;

    if (
      (implantL === true && implantR === false) ||
      (implantL === false && implantR === true)
    ) {
      setIsInconsistent(true);
    } else {
      setIsInconsistent(false);
    }
  }, [
    preRegistration?.implantsValid?.implantL,
    preRegistration?.implantsValid?.implantR,
  ]);

  return (
    <>
      {showFileModule && <FileUploadModule setShowModule={setShowFileModule} />}
      <div className="row pb-5">
        <ButtonScan
          onClick={() => {
            window.scrollTo(0, 0);
            setShowFileModule(true);
            resetRightPlacement();
            resetLeftPlacement();

            if (isMobile) {
              gtm({
                category: "Registration_method",
                action: "Scan Label",
                event: "interaction",
                segment: isUndefined(accountInfo?.data?.userType)
                  ? "N/A"
                  : accountInfo?.data?.userType,
              });
            } else {
              gtm({
                category: "Registration_method",
                action: "Upload_label",
                event: "interaction",
                segment: isUndefined(accountInfo?.data?.userType)
                  ? "N/A"
                  : accountInfo?.data?.userType,
              });
            }
          }}
        />
      </div>
      {shouldRenderLeftForm() && (
        <>
          <FormHeading
            title={t("Implant-Registration.LeftImplant")}
            placement="left"
          />
          <ImplantForm
            placement="left"
            isLeftRender={true}
            setIsImplantValid={setIsLeftImplantValid}
            setContainsValue={setContainsValue}
            leftError={leftError}
            rightError={leftError}
            setRightError={setLeftError}
            setLeftError={setLeftError}
            handleValidation={handleValidation}
            setHandleValidation={setHandleValidation}
          />
        </>
      )}

      {shouldRenderRightForm() && (
        <>
          <FormHeading
            title={t("Implant-Registration.RightImplant")}
            placement="right"
          />
          <ImplantForm
            placement="right"
            setIsImplantValid={setIsRightImplantValid}
            setContainsValue={setContainsValue}
            leftError={rightError}
            rightError={rightError}
            setRightError={setRightError}
            setLeftError={setRightError}
            handleValidation={handleValidation}
            setHandleValidation={setHandleValidation}
          />
        </>
      )}
      {duplicatedSN && (
        <div className="validation-message">
          <p className="ml-1">
            {preRegistration.isESN
              ? t("ImplantRegistrationError.SameESN")
              : t("ImplantRegistrationError.SameSN")}
          </p>
        </div>
      )}

      {isInconsistent && (
        <>
          <div className="validation-message">
            <p className="ml-1">
              {t("ImplantRegistrationError.InconsistentType")}
            </p>
          </div>
        </>
      )}

      {shouldRenderLeftForm() && shouldRenderRightForm() && (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="text-center" style={{ margin: "0 40px" }}>
                <ButtonOutlined
                  prepend={<Shuffle />}
                  title={t("Global.SwitchSides")}
                  onClick={switchSerialNumbers}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="sn-matches-information text-muted pt-4 text-center">
                {t("Global.MakeSureTheSNMatchesTheCorectSide")}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
