import React, { useContext, useEffect } from "react";
import SpinnerSmall from "./SpinnerSmall";
import { AccountContext } from "../../../contexts/AccountContext";

export default function Spinner() {
  const { logOut } = useContext(AccountContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      logOut();
    }, 15000); // After 15s of loading send to login
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="spinner-container">
      <SpinnerSmall />
    </div>
  );
}
