import React from "react";
import ButtonFilled from "./ButtonFilled";
import { useTranslation } from "react-i18next";

export default function ButtonAddImplants(props) {
  const { t } = useTranslation();

  return (
    <>
      <ButtonFilled
        title={t("Global.AddImplants")}
        fullWidth="true"
        colorScheme="purple"
        {...props}
      />
    </>
  );
}
