import React from "react";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import { useTranslation, withTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import ButtonFilled from "../../components/library/Button/ButtonFilled";
import { AccountContext } from "../../contexts/AccountContext";
import { useNavigate } from "react-router-dom";

const UnderReview = () => {
  const { t } = useTranslation();
  const { logOut } = React.useContext(AccountContext);
  const navigate = useNavigate();

  async function onGoBackBtnClicked() {
    await logOut(false);
    navigate(
      {
        pathname: "/login-surgeon",
        search: "?ref=account-review",
      },
      {
        replace: true,
      },
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("UserDisabledPage.Title")} | MotivaImagine®</title>
      </Helmet>
      <FullPageLayout isPublic loginPage backgroundImgPath="homepage-bg-7">
        <Box
          sx={{
            mt: 6,
            display: "flex",
            flexDirection: "column",
            px: { sm: 15 },
            py: { sm: 15 },
            maxWidth: 650,
            mx: "auto",
            background: { md: "rgba(234, 217, 201, 0.80)" },
            textAlign: "center",
          }}
        >
          <h1 className="full-page-layout__mega-title color-coffee">
            {t("UserDisabledPage.Title")}
          </h1>

          <h6 className="full-page-layout__description font-book color-coffee">
            {t("UserDisabledPage.Description")}
          </h6>

          <ButtonFilled
            title={t("Global.GoBack")}
            onClick={onGoBackBtnClicked}
          />
        </Box>
      </FullPageLayout>
    </>
  );
};

export default withTranslation()(UnderReview);
