import React from "react"
import "./CardSelectVertical.scss"

export default function CardSelectVerticalItem({
  id,
  title,
  description,
  image,
  alt,
  // isSelected,
  setIsSelected,
}) {
  // const showImagery = () => {
  //   if (showImage) {
  //     return (
  //       <img className="card-image-top rounded-circle" src={image} alt={alt} />
  //     );
  //   } else {
  //     return (
  //       <label className="label">
  //         <input
  //           className="custom-control custom-radio form-check-input"
  //           type="radio"
  //           name="radiobtn"
  //           id={id}
  //           value="option"
  //           defaultChecked={isItemSelected()}
  //         />
  //         <span id="checkmark" className="checkmark"></span>
  //       </label>
  //     );
  //   }
  // };

  // function isItemSelected() {
  //   return id === isSelected
  // }

  function handleOnClick() {
    setIsSelected(id)
  }

  // function getIsSelectedClasses() {
  //   return isItemSelected() ? "active" : "";
  // }

  // const getCardBodyClasses = () => {
  //   if (showImage) {
  //     return "card-body-with-image";
  //   }
  //   if (showRadioBtn) {
  //     return "card-body-with-radio-button";
  //   }
  // };

  return (
    <div onClick={handleOnClick} className="vertical-list-item">
      <div className="vertical-list-item__inner-box">
        <div className="vertical-list-item__img-wrapper">
          <img src={image} alt={alt} />
        </div>
        <div>
          <h5 className="vertical-list-item__title">{title}</h5>
          <p className="vertical-list-item__text">{description}</p>
        </div>
      </div>
    </div>
  )
}
