import React, { useContext, useState } from "react";
// import CardSelectVerticalList from "../../components/library/CardSelectVertical/CardSelectVerticalList";
import LinkBack from "../../components/library/Link/LinkBack";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import Registration from "../../components/library/Registration.jsx";
import { StaticFormContext } from "../../contexts/StaticFormContext";
import {Helmet} from 'react-helmet-async';

export default function ImplantSideSelection() {
  const { setBreastImplants } = useContext(UserContext);
  const { t } = useTranslation();

  const [nextLink, setNextLink] = useState(null);

  const {
    currentWizard,
    setRegisterImplantsFor,
    setLeftVC,
    setRightVC,
    setSerialNumberLeft,
    setSerialNumberRight,
    setIsScanned,
    setMsgError,
  } = useContext(StaticFormContext);

  const buttonList = [
    {
      title: t("Patient-List-Implant-Card.LeftImplant"),
      onClick: () => {
        setRegisterImplantsFor("left");
      },
    },
    {
      title: t("Global.BothSides"),
      onClick: () => {
        setRegisterImplantsFor("both");
      },
    },
    {
      title: t("Patient-List-Implant-Card.RightImplant"),
      onClick: () => {
        setRegisterImplantsFor("right");
      },
    },
  ];

  function resetImplantForm() {
    setBreastImplants({});
    setSerialNumberLeft("");
    setSerialNumberRight("");
    setLeftVC("");
    setRightVC("");
    setIsScanned("");
    setMsgError("");
  }

  if (nextLink) return <Navigate push to={nextLink} />;

  return (
    <>
      <Helmet>
        <title>Select Side | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>
      <Registration step={2}>
        <FiftyFiftyLayout
          backgroundImgPath="fifty-fifty-bg-1"
          title={t("Implant-Side-Selection.Title")}
        >
          <div className="fixed-image-block-size pt-3">
            <img
              className="fifty-fifty-layout__content__img"
              src={
                currentWizard === "breast"
                  ? process.env.PUBLIC_URL + "/breast-implant.png"
                  : process.env.PUBLIC_URL + "/gluteal-implant.png"
              }
              alt="breast"
            />
          </div>

          <div className="fifty-fifty-layout__main-btn-container">
            {buttonList.map((btn, i) => (
              <label
                key={i}
                className="label"
                onClick={() => {
                  btn.onClick();
                  resetImplantForm();
                  setNextLink("/registration/card-type-selection");
                }}
              >
                {btn.title}
                <input type="radio" name="radio" />
                <span className="radio-btn"></span>
              </label>
            ))}
          </div>
          <div className="fifty-fifty-layout__btn-container"></div>

          <div className="pre-next-btns pre-next-btns-top">
            <div>
              <LinkBack linkBack="/registration/implant-type" />
            </div>
          </div>
        </FiftyFiftyLayout>
      </Registration>
    </>
  );
}
