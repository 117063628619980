import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeft } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../contexts/UserContext";
import "./LinkBtn.scss";

export default function LinkBack({
  linkBack,
  onClick,
  handleCountryReset = false,
}) {
  const { t } = useTranslation();
  const { setMe } = useContext(UserContext);
  const navigate = useNavigate();
  const resetCountry = () => {
    setMe((prevState) => ({ ...prevState, country: "" }));
  };
  const handleAction = () => {
    window.scrollTo(0, 0);
    onClick();
    navigate(-1);
  };
  return (
    <Link
      to={linkBack ? linkBack : "/"}
      className="my-link"
      onClick={() => {
        handleCountryReset && resetCountry();
        onClick && handleAction();
      }}
    >
      <ChevronLeft size="12" /> {t("Global.Back")}
    </Link>
    // </button>
  );
}
