import React, { useContext } from "react";
import { sprintf } from "sprintf-js";
import { useSelector } from 'react-redux'

import { StaticFormContext } from "../../../contexts/StaticFormContext";
import { UserContext } from "../../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import DateRenderer from "../../library/DateRenderer";

import "./PreRegistrationDetails.scss";
import {isEmpty} from "lodash";

export default function PreRegistrationDetails() {
  const preRegistration  = useSelector((state) => state.preRegisterSlice);
  const { t } = useTranslation();

  const isMia = (!isEmpty(preRegistration?.implantsInformation?.tools?.injector) && !isEmpty(preRegistration?.implantsInformation?.tools?.balloon));

  return (
    <div className="pre-registration-details">
      <dl>
        <dt>{t("PreRegistration.ImplantTypeLabel")}</dt>
        <dd>
          {preRegistration?.implantsInformation?.implant_type === "B"
            ? t("Global.Breasts")
            : t("Global.Gluteal")}
        </dd>
        <dt>{t("PreRegistration.ImplantSideSelectionLabel")}</dt>
        <dd>
          {preRegistration?.implantsFor === "both"
            ? t("Global.BothSides")
            : preRegistration?.implantsFor === "left"
            ? t("Patient-List-Implant-Card.LeftImplant")
            : t("Patient-List-Implant-Card.RightImplant")}
        </dd>
      </dl>
      {(preRegistration?.implantsFor === "both" || preRegistration?.implantsFor === "left") && (
        <>
          <h3>{t("Implant-Registration.LeftImplant")}</h3>
          <dl>
            <dt>{t("Implant-Registration.SerialNumber")}</dt>
            <dd>{preRegistration.implantsInformation?.implantL}</dd>
            {!preRegistration?.implantsInformation?.isQ && (
              <>
                <dt>{t("Implant-Registration.ValidationCode")}</dt>
                <dd>{preRegistration.implantsInformation?.validationL}</dd>
              </>
            )}
          </dl>
        </>
      )}
      {(preRegistration?.implantsFor === "both" || preRegistration?.implantsFor === "right") && (
        <>
          <h3>{t("Implant-Registration.RightImplant")}</h3>
          <dl>
            <dt>{t("Implant-Registration.SerialNumber")}</dt>
            <dd>{preRegistration.implantsInformation?.implantR}</dd>
            {!preRegistration?.implantsInformation?.isQ && (
              <>
                <dt>{t("Implant-Registration.ValidationCode")}</dt>
                <dd>{preRegistration.implantsInformation?.validationR}</dd>
              </>
            )}
          </dl>
        </>
      )}

      {isMia && (
        <>
          <h3>{t("Device.Devices")}</h3>
          <dl>
            <dt>{t("Implant-Registration.Injector")}</dt>
            <dd>{preRegistration?.implantsInformation?.tools?.injector}</dd>

            <dt>{t("Implant-Registration.Balloon")}</dt>
            <dd>{preRegistration?.implantsInformation?.tools?.balloon}</dd>
          </dl>
        </>
      )}

      <h3>{isMia ? t("Procedure-Information.Title") : t("Surgery-Information.Title")}</h3>
      <dl>
        {preRegistration?.implantsInformation?.implant_type === "B" && (
          <>
            <dt>{t("PreRegistration.ImplantPlacementLabel")}</dt>
            <dd>
              {preRegistration?.implantPlacement &&
                t(sprintf("API.ImplantPlacement-%s", preRegistration?.implantPlacement))}
            </dd>
            <dt>{t("PreRegistration.SurgeryIncisionTypeLabel")}</dt>
            <dd>
              {preRegistration?.incisionKind &&
                t(sprintf("API.IncisionType-%s", preRegistration?.incisionKind))}
            </dd>
            <dt>{isMia ? t("PreRegistration.ProcedureIndicationLabel") : t("PreRegistration.SurgeryIndicationLabel")}</dt>
            <dd>
              {preRegistration?.surgeryIndication &&
                t(sprintf("API.SurgeryIndication-%s", preRegistration?.surgeryIndication))}
            </dd>
          </>
        )}
        <dt>{t("Surgery-Information.Country")}</dt>
        <dd>{preRegistration?.country?.[0]?.label}</dd>
        <dt>{t("Global.Surgeon")}</dt>
        <dd>{preRegistration?.surgeon?.[0]?.label}</dd>
        <dt>{t("Patient-List-Implant-Card.Date")}</dt>
        <dd>{preRegistration?.surgeryDate}</dd>
      </dl>
    </div>
  );
}
