import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { sprintf } from "sprintf-js";

export const SelectComponent = (props) => {
  const {
    formik,
    title,
    name,
    isDisabled = false,
    handleChange,
    value,
    options,
    translationKey,
    required,
    id,
  } = props;

  const meta = formik?.getFieldMeta(name);
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} md={5} display="flex" alignItems="center">
        <Typography variant="body1" fontSize={16} fontWeight={300}>
          {title}
          {required && "*"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Select
          id={id}
          name={name && name}
          fullWidth
          style={{ width: "100%", height: "50px" }}
          className="form-control"
          value={(typeof name === "undefined" && value) || meta?.value || ""}
          onChange={handleChange || formik?.handleChange}
          onBlur={formik?.handleBlur}
          disabled={isDisabled}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#f2f2f2 !important",
              borderRadius: "10px !important",
              height: "50px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderRadius: "10px !important",
            },
            "& .MuiOutlinedInput-input": {
              height: "19px",
            },
          }}
        >
          {(options || [])
            .filter((option) => !required || option.id !== 0)
            .map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {translationKey
                  ? t(sprintf(translationKey, option.id))
                  : option?.translation
                    ? t(option.translation)
                    : option?.name}
              </MenuItem>
            ))}
        </Select>
        <Box mt={1}>
          {meta?.error && meta?.touched ? (
            <Typography variant="subtitle2" color="red">
              {meta.error}
            </Typography>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};
