import React, {useContext, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Box, Grid, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from "react-redux"
import {openErrorDialog} from "../../../store/slices/uiSlice";
import {AccountContext} from "../../../contexts/AccountContext";

export default function ErrorDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const iu  = useSelector((state) => state.uiSlice);
  const { accountInfo } = useContext(AccountContext);

  const handleTicketSupport = () => {

    if(iu.createTicket?.create){
      if(accountInfo && accountInfo?.data?.userType === "Surgeon"){
        window.open('https://motiva.health/surgeons-contact/', '_blank');
      }else{
        window.open('https://motiva.health/patients-contact/', '_blank');
      }

      dispatch(openErrorDialog({
        errorDialog:{
          open: false
        },
        createTicket: {
          create: false
        }
      }))
    }else{
      dispatch(openErrorDialog({
        errorDialog:{
          open: false
        },
        createTicket: {
          open: true
        }
      }))
    }
  };

  const closeDialog =()=>{
    dispatch(openErrorDialog({
      ...iu,
      errorDialog: {
        open: false
      }
    }))
  }

  return (
      <Dialog
        open={iu.errorDialog.open}
        fullWidth
        maxWidth="xs"
        className="text-center"
      >
        <DialogTitle id="alert-dialog-title" className="d-flex justify-content-end">
          <IconButton aria-label="close" onClick={()=>{closeDialog()}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container sx={{padding: '25px'}}>
              <Grid item xs={12} className="d-flex justify-content-center dialog">
                <Typography variant="h1" className="font-book">
                  {t("ErrorPage.500.title")}
                </Typography>
              </Grid>
              <Grid item xs={12} className="d-flex justify-content-center mt-5 dialogContent">
                <Typography variant="body2">
                  {t('ErrorPage.500.description')}
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center flex-column mb-1">
          <button onClick={()=>{handleTicketSupport()}} className="button-component btn btn-primary w-75 p-1">
            {t("Global.SupportRequest")}
          </button>
          <Box my={3}>
            <Typography variant="h6" className="closeDialog" onClick={()=>{closeDialog()}}>
              Close
            </Typography>
          </Box>
        </DialogActions>
      </Dialog>
  );
};
