import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { StaticFormContext } from "../../contexts/StaticFormContext";

export default function RegistrationStartedCorrectly({ debug, children }) {
  const { hasStartedRegistration } = useContext(StaticFormContext);

  return (
    <>
      {debug || hasStartedRegistration ? (
        children
      ) : (
        <Navigate to="/registration/implant-type" />
      )}
    </>
  );
}
