import React from "react";
import FullPageLayout from "../../components/library/Layout/FullPageLayout";
import SpinnerSmall from "../../components/library/Spinner/SpinnerSmall";
import { useTranslation, withTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const LoginLoading = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>MotivaImagine®</title>
        <meta name="pageType" content="User's Welcome Page" />
      </Helmet>
      <FullPageLayout
        isPublic
        backgroundImgPath="homepage-bg-1"
        position="center"
        megaTitle={""}
        description={t(
          "LoginLoading-Page.Description",
          "Retrieving your account information...",
        )}
        buttonList={[]}
        children={[
          <div className={"loader"} key={"spinner-small"}>
            <SpinnerSmall />
          </div>,
        ]}
      ></FullPageLayout>
    </>
  );
};

export default withTranslation()(LoginLoading);
