import { useLocation } from "react-router-dom";

export const useQueryString = () => {
  const location = useLocation();

  const getQueryParam = (name) => {
    const params = new URLSearchParams(location.search);

    return params.get(name);
  };

  return { getQueryParam }
}
