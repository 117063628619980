import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../.../../../../contexts/UserContext";
import { StaticFormContext } from "../.../../../../contexts/StaticFormContext";
import { useSelector, useDispatch } from "react-redux";
import { preRegistrationInformation } from "../../../store/slices/preRegisterSlice";
import { SelectComponent } from "../FormComponents/Select";
import { Box } from "@mui/material";

export const ImplantDetailsForm = () => {
  const { t } = useTranslation();
  const { isDoctor } = useContext(UserContext);
  const preRegistration = useSelector((state) => state.preRegisterSlice);
  const dispatch = useDispatch();
  const {
    registerImplantsFor,
    setRegisterImplantsFor,
    currentWizard,
    setCurrentWizard,
    resetImplantForm,
  } = useContext(StaticFormContext);

  useEffect(() => {
    setCurrentWizard(
      preRegistration?.implantsInformation?.implant_type === "B"
        ? "breast"
        : "gluteal",
    );
    setRegisterImplantsFor(preRegistration?.implantsFor);
  }, [preRegistration]);

  return (
    <div className="implant-information">
      {isDoctor() && <h3>{t("PreRegistration.ImplantInformation")}</h3>}

      <Box mt={2}>
        <SelectComponent
          id="preRegistration-implantType"
          title={
            isDoctor()
              ? t("PreRegistration.ImplantTypeLabel")
              : t("Implant-Type-Page.Title")
          }
          options={[
            { id: "breast", translation: "Global.Breasts" },
            { id: "gluteal", translation: "Global.Gluteal" },
          ]}
          value={
            preRegistration?.implantsInformation?.implant_type === "B"
              ? "breast"
              : "gluteal"
          }
          handleChange={(e) => {
            if (e) {
              dispatch(
                preRegistrationInformation({
                  implantsInformation: {
                    implant_type: e.target.value === "gluteal" ? "G" : "B",
                    implantL: "",
                    implantR: "",
                    validationL: "",
                    validationR: "",
                  },
                  implantsValid: {
                    implantL: "",
                    implantR: "",
                  },
                  isMiaValid: false,
                  isMiaImplants: false,
                }),
              );
              resetImplantForm();

              if (e.target.value === "gluteal") {
                dispatch(
                  preRegistrationInformation({
                    ...preRegistration?.implantPlacement,
                    ...preRegistration?.incisionKind,
                    ...preRegistration?.surgeryIndication,
                    implantPlacement: 0,
                    incisionKind: 0,
                    surgeryIndication: 0,
                  }),
                );
              }
            }
          }}
        />
      </Box>

      <Box mt={2}>
        <SelectComponent
          id="preRegistration-implantSide"
          title={
            isDoctor()
              ? t("PreRegistration.ImplantSideSelectionLabel")
              : t("Implant-Side-Selection.Title")
          }
          options={[
            {
              id: "left",
              translation: "Patient-List-Implant-Card.LeftImplant",
            },
            {
              id: "right",
              translation: "Patient-List-Implant-Card.RightImplant",
            },
            {
              id: "both",
              translation: "Global.BothSides",
            },
          ]}
          value={preRegistration?.implantsFor}
          handleChange={(e) => {
            if (e) {
              dispatch(
                preRegistrationInformation({
                  implantsFor: e.target.value,
                  implantsInformation: {
                    implant_type:
                      preRegistration?.implantsInformation?.implant_type,
                    implantL: "",
                    implantR: "",
                    validationL: "",
                    validationR: "",
                  },
                  implantsValid: {
                    implantL: "",
                    implantR: "",
                  },
                  isMiaValid: false,
                  isMiaImplants: false,
                  implantPlacement: 0,
                  incisionKind: 0,
                  surgeryIndication: 0,
                }),
              );
              setRegisterImplantsFor(e.target.value);
              resetImplantForm();
            }
          }}
        />
      </Box>
    </div>
  );
};
