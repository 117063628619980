import "../../../styles/custom.scss";
import "./FullPageLayout.scss";

import { Link } from "react-router-dom";
import React, { useContext, useEffect } from "react";

import { UserContext } from "../../../contexts/UserContext";
import ButtonFilled from "../Button/ButtonFilled";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import LinkSimple from "../Link/LinkSimple";
import ButtonOpenTicket from "../Button/ButtonOpenTicket";
import { Logo } from "../Logo";
import { AccountComponent } from "../AccountComponent";
import { Box } from "@mui/material";
import { Divider } from "@mui/material";

const FullPageLayout = ({
  children,
  isPublic,
  backgroundImgPath,
  position = undefined,
  megaTitle,
  title,
  secondTitle,
  subtitle,
  description,
  buttonList = [],
  secondaryButtonList,
  skipBtn,
  loginPage,
}) => {
  const { userError, me, isMeLoading } = useContext(UserContext);

  function canRenderChildren() {
    return isPublic || (!isMeLoading && !!me);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          width: "100%",
          position: "absolute",
          paddingRight: { xs: 2, md: 8 },
        }}
        mt={6}
      >
        <Box
          display="flex"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
          justifyContent="center"
          alignItems="center"
        >
          <Box>{!loginPage && <AccountComponent />}</Box>
          <Box>{!loginPage && <ButtonOpenTicket origin="general" />}</Box>
          <Box ml={{ xs: 1.5, md: 1 }} mt={{ xs: 1.1, md: 0 }}>
            <LanguageSelector isLogged={loginPage} />
          </Box>
        </Box>
      </Box>

      <div className={`full-page-layout ${backgroundImgPath}`}>
        <div className="full-page-layout--position">
          {canRenderChildren() && (
            <>
              {position === "left" && (
                <div className="full-page-layout--position--left">
                  <div className="full-page-layout__logo-container">
                    <Link
                      to="/"
                      className="full-page-layout__logo-container__logo"
                    >
                      <Logo type="isotype" />
                    </Link>
                  </div>

                  <div className="full-page-layout__spread-container">
                    {megaTitle && (
                      <h1 className="full-page-layout__mega-title color-coffee">
                        {megaTitle}
                      </h1>
                    )}
                    {title && (
                      <h3 className="full-page-layout__title font-black color-coffee">
                        {title}
                      </h3>
                    )}

                    {subtitle && (
                      <h6 className="full-page-layout__subtitle font-book color-coffee">
                        {subtitle}
                      </h6>
                    )}
                    {description && (
                      <h6 className="full-page-layout__description font-book color-coffee">
                        {description}
                      </h6>
                    )}

                    <div className="full-page-layout__btn-container">
                      {children}

                      {buttonList.map((btn, i) => (
                        <ButtonFilled
                          key={i}
                          title={btn.title}
                          onClick={btn.onClick}
                          nextLink={btn.nextLink}
                        />
                      ))}
                    </div>
                  </div>
                  {userError && <ErrorComponent userError={userError} />}
                </div>
              )}

              {position === "center" && (
                <div className="full-page-layout--position--center">
                  <div>
                    <Link
                      to="/"
                      className="full-page-layout__logo-container__logo"
                    >
                      <Logo type="logo" />
                    </Link>
                  </div>

                  <div className="full-page-layout__spread-container">
                    {megaTitle && (
                      <h1 className="full-page-layout__mega-title color-coffee">
                        {megaTitle}
                      </h1>
                    )}

                    {title && (
                      <h3 className="full-page-layout__title font-black color-coffee">
                        {title}
                      </h3>
                    )}

                    {subtitle && (
                      <h6 className="full-page-layout__subtitle font-book color-coffee">
                        {subtitle}
                      </h6>
                    )}

                    {description && (
                      <h6 className="full-page-layout__description font-book color-coffee">
                        {description}
                      </h6>
                    )}

                    <div className="full-page-layout__btn-container">
                      {children}

                      {buttonList.map((btn, i) => (
                        <ButtonFilled
                          key={i}
                          title={btn.title}
                          onClick={btn.onClick}
                          nextLink={btn.nextLink}
                        />
                      ))}
                    </div>
                    {skipBtn}
                  </div>
                  {userError && <ErrorComponent userError={userError} />}
                </div>
              )}

              {position === "right" && (
                <div className="full-page-layout--position--right">
                  <div className="full-page-layout__logo-container">
                    <Link
                      to="/"
                      className="full-page-layout__logo-container__logo"
                    >
                      <Logo type="isotype" />
                    </Link>
                  </div>

                  <div className="full-page-layout__spread-container">
                    <div>
                      {megaTitle && (
                        <h1 className="full-page-layout__mega-title color-coffee">
                          {megaTitle}
                        </h1>
                      )}

                      {title && (
                        <h3 className="full-page-layout__title font-black color-coffee">
                          {title}
                        </h3>
                      )}

                      {subtitle && (
                        <h6 className="full-page-layout__subtitle font-book color-coffee">
                          {subtitle}
                        </h6>
                      )}

                      {description && (
                        <h6 className="full-page-layout__description font-book color-coffee">
                          {description}
                        </h6>
                      )}
                    </div>

                    <div className="full-page-layout__btn-container">
                      {buttonList.map((btn, i) => (
                        <ButtonFilled
                          key={i}
                          title={btn.title}
                          onClick={btn.onClick}
                          nextLink={btn.nextLink}
                        />
                      ))}
                    </div>

                    {secondaryButtonList instanceof Array && (
                      <div className="full-page-layout__btn-container secondary">
                        {secondaryButtonList.map((btn, i) => (
                          <LinkSimple
                            cancel
                            white
                            key={i}
                            label={btn.title}
                            onClick={btn.onClick}
                            to={btn.nextLink}
                          />
                        ))}
                      </div>
                    )}
                    {children}
                  </div>
                  {userError && <ErrorComponent userError={userError} />}
                </div>
              )}

              {position === "rightUS" && (
                <div className="full-page-layout--position--right">
                  <div className="full-page-layout__logo-container">
                    <Link
                      to="/"
                      className="full-page-layout__logo-container__logo"
                    >
                      <Logo type="isotype" />
                    </Link>
                  </div>

                  <div className="full-page-layout__spread-container">
                    <div>
                      {title && (
                        <h4 className="full-page-layout__title fullWidthTitle color-coffee">
                          {title}
                        </h4>
                      )}
                    </div>

                    <div className="full-page-layout__btn-container">
                      {buttonList.map((btn, i) => (
                        <ButtonFilled
                          key={i}
                          title={btn.title}
                          onClick={btn.onClick}
                          nextLink={btn.nextLink}
                        />
                      ))}
                    </div>

                    <Divider
                      sx={{
                        mt: 6,
                        mb: 3,
                      }}
                    />

                    <div>
                      {title && (
                        <h4 className="full-page-layout__title fullWidthTitle color-coffee">
                          {secondTitle}
                        </h4>
                      )}
                    </div>

                    <div className="full-page-layout__btn-container">
                      {secondaryButtonList.map((btn, i) => (
                        <ButtonFilled
                          key={i}
                          title={btn.title}
                          onClick={btn.onClick}
                          nextLink={btn.nextLink}
                        />
                      ))}
                    </div>
                  </div>
                  {userError && <ErrorComponent userError={userError} />}
                </div>
              )}

              {position === undefined && children}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FullPageLayout;
