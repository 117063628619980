import React, { useState } from "react"
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout"
import LinkBack from "../../components/library/Link/LinkBack"
import { Navigate } from "react-router-dom"
import qidImg from "./qid-img.png"
import { useTranslation } from "react-i18next"
// import { UserContext } from "../../contexts/UserContext"
import RegistrationStartedCorrectly from "../../components/library/RegistrationStartedCorrectly"
import {Helmet} from 'react-helmet-async';

const ImplantQidTechnologyPage = () => {
  const [nextLink, setNextLink] = useState(null)
  const { t } = useTranslation()
  // const { setMe } = useContext(UserContext)

  const buttonList = [
    {
      title: t("Yes"),
      nextLink: "/registration/implant-register-method",
      value: true,
    },
    {
      title: t("No"),
      nextLink: "/registration/implant-registration-with-sn",
      value: false,
    },
    {
      title: t("Global.IDontKnow"),
      nextLink: "/registration/implant-registration-with-sn",
      value: false,
    },
  ]

  if(nextLink) return <Navigate push to={nextLink} />

  return (
    <>
      <Helmet>
        <title>Implant Qid | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>
      <RegistrationStartedCorrectly>
        <FiftyFiftyLayout
          backgroundImgPath="fifty-fifty-bg-1"
          title={`${t("Do-Your-Implants-Have-Qid-Technology")}`}
        >
          <div className="fixed-image-block-size">
            <img
              className="fifty-fifty-layout__content__img"
              src={qidImg}
              alt="qid"
            />
          </div>

          <div className="fifty-fifty-layout__main-btn-container">
            {buttonList.map((btn, i) => (
              <label
                key={i}
                className="label"
                onClick={(e) => setNextLink(btn.nextLink)}
              >
                {btn.title}
                <input type="radio" name="radio" value={btn.value} />
                <span className="radio-btn"></span>
              </label>
            ))}
          </div>

          <div className="fifty-fifty-layout__btn-container">
            <div>
              {/* <LinkBack linkBack="/registration/implant-side-selection" /> */}
              <LinkBack linkBack="/registration/implant-qid-technology" />
            </div>
          </div>
        </FiftyFiftyLayout>
      </RegistrationStartedCorrectly>
    </>
  )
}

export default ImplantQidTechnologyPage
