import React, {useContext, useEffect} from 'react';
import {Box, IconButton, Menu, MenuItem} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TagManager from "react-gtm-module";

import {AccountContext} from "../../../contexts/AccountContext";

import {AvatarComponent} from "../Avatar";
import {AvatarPatient} from "../Icons/AvatarPatient";
import {AvatarDoctor} from "../Icons/AvatarDoctor";

import {styleModuleAvatarComponent} from "./style.module";
import {delay, getMetaContent} from "../../../utils";
import GigyaKit from "../../../data/GigyaKit";
import {useTagManager} from "../../../hooks/useTagManager";
import {isUndefined} from "lodash";
import {UserContext} from "../../../contexts/UserContext";

export const AccountComponent = () => {

  const {accountInfo, logOut} = useContext(AccountContext);
  const {me, setMe} = useContext(UserContext);

  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const gigyaKit = new GigyaKit();
  const gtm = useTagManager();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    try {
      logOut();
      setMe(null);
      navigate("#/");

      gtm({
        category: 'logout',
        action: '/logout',
        label: 'logout',
        event: 'interaction',
        segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
      });
    } catch (error) {
      //console.error(error);
    }
  };

  useEffect(() => {
    setDataLayer();
  }, [location]);

  const setDataLayer = async () => {
    await delay(200);

    const getCurrentRoute = () => {
      const words = window.location.href.split("#");
      return words[1].substring(1, words[1].length);
    };

    if (accountInfo?.UID) {
      const tagManagerArgs = {
        dataLayer: {
          location: getCurrentRoute(),
          title: (document && document.title),
          pageType: getMetaContent('pageType'),
          event: "pageView",
          userId: accountInfo.UID,
          segment: accountInfo.data?.userType,
          lang: localStorage.getItem('i18nextLng')
        }
      }

      TagManager.dataLayer(tagManagerArgs)
    }
  }

  return (
    <Box className="avatar-btn">
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{padding: 0}}
        >
          <AvatarComponent content={me && me?.isDoctor ? <AvatarDoctor size={54}/> : <AvatarPatient size={54}/>}/>
        </IconButton>
      </Box>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={styleModuleAvatarComponent}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem onClick={() => gigyaKit.showProfileUpdateScreenSet(location.pathname)}>
          {t("My-Info.SeeMyProfileInformation")}
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          {t("Global.LogOut")}
        </MenuItem>
      </Menu>
    </Box>
  )
};
