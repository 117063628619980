import {useEffect, useState} from "react";

export const useScrollPosition =()=> {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(()=>{
    const updatePosition =()=>{
      setScrollPosition(Math.round(window.scrollY));
    }
    window.addEventListener('scroll',updatePosition);
    return ()=> window.removeEventListener('scroll',updatePosition);
  }, []);

  return scrollPosition;
};
