import React, { useState, useContext, useEffect } from "react";
import FiftyFiftyLayout from "../../components/library/Layout/FiftyFiftyLayout";
import LinkBack from "../../components/library/Link/LinkBack";
import "./ImplantRegisterMethodPage.scss";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserContext";
import Registration from "../../components/library/Registration.jsx";
import { ImplantCard } from "../../components/library/ImplantCardView/ImplantCardView";
import { StaticFormContext } from "../../contexts/StaticFormContext";
import {preRegistrationInformation} from "../../store/slices/preRegisterSlice";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from 'react-helmet-async';
import {AccountContext} from "../../contexts/AccountContext";
import {useTagManager} from "../../hooks/useTagManager";
import {isUndefined} from "lodash";

const ImplantRegisterMethodPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { me, setMe } = useContext(UserContext);
  const { implantCardType, currentWizard } = useContext(StaticFormContext);
  const {accountInfo} = useContext(AccountContext);
  const gtm = useTagManager();
  const [lang, setLang] = useState(me?.country_id);
  const preRegistration  = useSelector((state) => state.preRegisterSlice);
  const dispatch = useDispatch();

  const [nextLink, setNextLink] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (me?.country_id === undefined) {
      navigate("/registration/implant-type");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (me) {
      const { country_id } = me;
      setLang(country_id);
    }
  }, [me]);

  useEffect(() => {
      dispatch(preRegistrationInformation({
        ...preRegistration,
        isMiaImplants: false,
        implantsInformation: {
          ...preRegistration.implantsInformation,
          tools: {
            injector: null,
            balloon: null,
          }
        },
        implantsValid : {
          implantL: "",
          implantR: "",
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (nextLink) return <Navigate push to={nextLink} />;
  return (
    <>
      <Helmet>
        <title>Registration method | MotivaImagine®</title>
        <meta name="pageType" content="Implant Registration" />
      </Helmet>
      <Registration step={4}>
        <FiftyFiftyLayout
          backgroundImgPath="fifty-fifty-bg-1"
          title={t("How-Would-You-Like-To-Register-Your-Implants")}
        >
          <div className="implant-register-with">
            <ImplantCard
              className="implant-card-vertical"
              esnorsn
              type={implantCardType}
              lang={lang}
              isBreast={currentWizard === "breast"}
            />

            <div className="implant-register-with__description-container">
              <label
                onClick={() => {
                  setNextLink("/registration/implant-registration-with-sn");
                  setMe((prev) => ({ ...prev, isESN: false, isQ: false }));

                  gtm({
                    category: 'Registration_method',
                    action: 'SN',
                    event: 'interaction',
                    segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
                  });
                }}
                className="implant-register-with__description-container__card label-card"
              >
                <h3>{t("With-My-Serial-Number")} (SN)</h3>
                <p className="text-muted">
                  {t(
                    "Scan-Or-Upload-Your-Implant-Labels-Or-Manually-Fill-Out-The-Number-Sequence-For-Each-SN-And-Their-Validation-Codes"
                  )}
                </p>
                <input type="radio" name="radio2" />
                <span className="radio-btn-card"></span>
              </label>

              <label
                onClick={() => {
                  setNextLink("/registration/implant-registration-with-esn");
                  setMe((prev) => ({ ...prev, isESN: true, isQ: true }));

                  gtm({
                    category: 'Registration_method',
                    action: 'ESN',
                    event: 'interaction',
                    segment: isUndefined(accountInfo?.data?.userType) ? "N/A" : accountInfo?.data?.userType,
                  });
                }}
                className="implant-register-with__description-container__card label-card"
              >
                <h3>{t("With-My-Electronic-Serial-Number")} (ESN)</h3>
                <p className="text-muted">
                  {t(
                    "Manually-Fill-Out-Only-The-Number-Sequence-At-The-Top-Of-Each-Implant-Label"
                  )}
                </p>
                <input type="radio" name="radio2" />
                <span className="radio-btn-card"></span>
              </label>
            </div>
          </div>

          <div className="fifty-fifty-layout__btn-container"></div>
          <div className="pre-next-btns pre-next-btns-top">
            <div>
              <LinkBack
                handleCountryReset
                linkBack="/registration/card-type-selection"
              />
            </div>
          </div>
        </FiftyFiftyLayout>
      </Registration>
    </>
  );
};

export default ImplantRegisterMethodPage;
