import React from "react";

export default function ImageRadioSelectItem({
  title,
  id,
  name,
  isSelected,
  setIsSelected,
}) {
  function handleOnClick() {
    setIsSelected(id);
  }

  function isItemSelected() {
    return id === isSelected;
  }

  return (
    <div className="my-radio-btn" onClick={handleOnClick}>
      <label htmlFor={id} className="card-text">
        {title}
      </label>
      <input
        className=""
        type="radio"
        id={id}
        name={name}
        value="option"
        defaultChecked={isItemSelected()}
      />
    </div>
  );
}
