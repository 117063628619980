import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";

export const RequiredText = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Typography
        variant="body1"
        mt={{ xs: 1, md: 5 }}
        className="ignore-modal-style"
      >
        {t("Global.RequiredFields", { lng: "en" })}
      </Typography>
    </Box>
  );
};
