import React from "react"

export const SuccessValidationCheckmark = () => (
  <div className="val-check">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15.53"
      viewBox="0 0 20 15.53"
    >
      <path
        id="check-icon"
        d="M7.658,15.637a1.021,1.021,0,0,1-1.444,0L.449,9.87a1.531,1.531,0,0,1,0-2.166l.722-.722a1.531,1.531,0,0,1,2.166,0l3.6,3.6L16.663.855a1.531,1.531,0,0,1,2.166,0l.722.722a1.531,1.531,0,0,1,0,2.166Zm0,0"
        transform="translate(0 -0.406)"
        fill="#42d15f"
      />
    </svg>
  </div>
);
