import React from "react";
import { Grid, Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSwitch = styled(Switch)(({ checked, options, active }) => ({
  width: 54,
  height: 28,
  padding: 0,
  transition: "all 1s ease-in 3s",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    marginTop: 3,
    "&.Mui-checked": {
      transform: "translateX(27px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#6e0072",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    boxShadow: "none",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: active ? "#6e0072" : "#bbbbbb",
    opacity: 1,
    transition: "margin 1s ease-in 3s",
    color: "#fff",
    content: '""',
    "&:after": {
      position: "absolute",
      top: "50%",
      left: "28px",
      transform: "translateY(-41%)",
      width: 20,
      height: 16,
      content: `"${options[0]}"`,
      color: "#fff",
      fontSize: "10px",
      display: checked ? "none" : "block",
    },
    "&:before": {
      position: "absolute",
      top: "50%",
      right: "26px",
      transform: "translateY(-38%)",
      width: 20,
      height: 16,
      content: `"${options[1]}"`,
      color: "#fff",
      fontSize: "10px",
      display: checked ? "block" : "none",
    },
  },
}));

export const ToggleSwitch = (props) => {
  const { formik, title, name, options, active } = props;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={5} display="flex" alignItems="center">
        <Typography variant="body1" fontSize={16} fontWeight={300}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <CustomSwitch
          name={name}
          checked={formik.values[name] === true}
          options={options}
          active={active}
          onChange={(e, value) => {
            formik.setFieldValue(name, value === true);
          }}
        />
      </Grid>
    </Grid>
  );
};
